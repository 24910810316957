import { LOCALES } from 'src/i18n/locales';
import { TLocaleId } from 'src/i18n';

const frenchLangTranslations: { [key: string]: { [idKey in TLocaleId]: string } } = {
  [LOCALES['Français (French)']]: {
    'MursionPortal.SimAttendance.Tooltip.Error': 'Session was unable to be completed due to technical difficulties on the Learner’s side. (if the session was able to be completed after an error this session should be marked complete and not error).',// TODO add translations
    'SessionReports.ErrorOnLoadingVideo': 'Veuillez utiliser un ordinateur portable ou de bureau pour visualiser votre enregistrement.',
    'MursionPortal.ConfirmationModal.Project.InvitationsConfirmationText': 'Êtes-vous sûr de vouloir inviter <strong>tous les apprenants assignés</strong> qui n\'ont pas encore complété les scénarios du projet ? Ils recevront une invitation par e-mail pour planifier la nuit.',
    'MursionPortal.ConfirmationModal.Scenario.InvitationsConfirmationText': 'Êtes-vous sûr de vouloir inviter <strong>tous les apprenants assignés</strong> qui n\'ont pas encore terminé ce scénario ? Ils recevront une invitation par e-mail pour programmer la nuit.',
    'Clients.Modal.Label.VideoRecordingDataRetentionDays.Tooltip': 'Nombre de jours pendant lesquels le client souhaite conserver ses données d\'enregistrement vidéo. Après ce nombre de jours, les enregistrements vidéo seront supprimés.',
    'Settings.SSO.CopyScenarioLink': 'Copier le lien d\'invitation du scénario',
    'MursionPortal.Label.VideoRecordingSharing': 'Partage ou téléchargement par l\'utilisateur de ses vidéos de session',
    'MursionPortal.Status.Capitalized.LateCancelled': 'TARDIF_ANNULÉ',
    'MursionPortal.Status.Capitalized.EarlyCancelled': 'PLUS TÔT_ANNULÉ',
    'MursionPortal.Status.Abbreviation.LateCancelled': 'LC',
    'MursionPortal.Status.Abbreviation.Cancelled': 'C',
    'MursionPortal.Text.Congratulation': 'Félicitations !',
    'MursionPortal.Text.PasswordChanged': 'Votre mot de passe a été modifié',
    'MursionPortal.Text.PasswordShouldBeSame': 'Vos nouveaux mots de passe doivent correspondre.',
    'Filters.LateRescheduled': 'Reprogrammé tardivement',
    'MursionPortal.Dashboard.Chart.Tooltip.SimulationBreakdown.Scheduled': 'Les sessions à venir qui sont programmées mais qui ne sont pas encore terminées (y compris celles en cours)',
    'MursionPortal.Checkbox.EnableSmartMetrics': 'Activer l\'analyse vocale des apprenants par session',
    'Dashboard.SimulationTable.Column.TimesLateRescheduled': 'Heures de retard reprogrammées',
    'Dashboard.SimulationTable.ColumnTooltip.TimesLateRescheduled': 'Heures où cette simulation a été reportée tardivement',
    'SessionReports.Label.CopyVideoLink': 'Copier le lien vidéo',
    'Login.LoginAgain': 'Connectez-vous à nouveau',
    'Dashboard.VideosTable.Column.SessionStart': 'Début de la session',
    'Filters.Date.LastSevenDays': 'La semaine dernière',
    'Filters.Date.NextSevenDays': 'La semaine prochaine',
    'Filters.Date.ThisYear': 'Cette année',
    'Mursion.Portal.Status.LearnerMissed': 'Apprenant manqué',
    'Mursion.Portal.Status.SystemCancelled': 'Annulé',
    'Mursion.Portal.Status.LateCancelled': 'Annulation tardive',
    'Mursion.Portal.Status.EarlierCancelled': 'Annulation anticipée',
    // 'MursionPortal.Dashboard.Chart.label.LearnerStatus.LearnerCompleted': 'Complété',
    'MursionPortal.Dashboard.UpcomingSessions': 'Sessions à venir',
    'MursionPortal.Label.Opened': 'Ouvert',
    'MursionPortal.Label.ActedOn': 'Exécuté',
    'MursionPortal.Label.Unread': 'Non lu',
    'MursionPortal.Label.Timeline': 'Calendrier du projet',
    'Projects.ProjectInfo.Label.TimelineUtc': 'Calendrier du projet (UTC)',
    'MursionPortal.ScenarioInfo.Header.Description': 'Description',
    'MursionPortal.TimelineUTC': 'Calendrier du projet<code>{word}</code> (UTC)',
    'MursionPortal.Label.ChangePassword': 'Changer le mot de passe',
    'MursionPortal.Label.ChangeYourPassword': 'Changez votre mot de passe',
    'MursionPortal.Label.NewChangePassword': 'Saisissez votre nouveau mot de passe',
    'MursionPortal.Label.OldPassword': 'Saisissez votre mot de passe actuel',
    'MursionPortal.Label.RepeatChangePassword': 'Ressaisissez votre nouveau mot de passe',
    'MursionPortal.Prompt.SessionScheduled': '<code>Session</code> a été annulée !',
    'MursionPortal.Status.Booked': 'Réservé',
    'MursionPortal.Status.Pending': 'En attente',
    'MursionPortal.Completed': 'Complété',
    'MursionPortal.Status.Missed': 'Manqué',
    'MursionPortal.Status.Cancelled': 'Annulé',
    'MursionPortal.Status.LateCancelled': 'Annulation tardive',
    'MursionPortal.Status.EarlyCancelled': 'Annulation anticipée',
    'MursionPortal.Status.LicenseCancelled': 'Annulé',
    'MursionPortal.Status.Error': 'Erreur',
    'MursionPortal.ConversationalFlow.label.zoom.all': 'Tout',
    'Clients.ImportUsers.DownloadErrorsButton': 'Téléchargement des erreurs',
    'Mursion.Portal.Edit.Team.DownloadErrorButton': 'Téléchargement des erreurs',
    'MursionPortal.Dashboard.NextOnProject': 'Scénarios à programmer',
    'MursionPortal.Dashboard.NextOnProject.Footer.EndDate': 'Vous avez jusqu\'à {endDate} pour terminer ce scénario.',
    'MursionPortal.Dashboard.NextOnProject.Footer.CompletionRate': 'Vous avez jusqu\'à {endDate} pour terminer jusqu\'à {completionRate} fois.',
    'MursionPortal.Dashboard.NothingPlanned.text1': 'Tout est réglé ! Lorsque vous aurez',
    'MursionPortal.Dashboard.NothingPlanned.text2': 'quelque chose à programmer, cela apparaîtra ici.',
    'MursionPortal.Learner.Missed': 'APPRENANT MANQUÉ',
    'MursionPortal.AriaImage.Attribute.Next': 'Suivant :',
    'MursionPortal.AriaImage.Attribute.Close': 'Fermer :',
    'MursionPortal.AriaImage.Attribute.Back': 'Retour :',
    'MursionPortal.AriaImage.Attribute.Delete': 'Supprimer :',
    'MursionPortal.AriaImage.Attribute.Remove': 'Retirer',
    'MursionPortal.AriaImage.Attribute.Document': 'Document',
    'MursionPortal.AriaImage.Attribute.Video': 'Vidéo',
    'MursionPortal.AriaImage.Attribute.DeleteDocument': 'Supprimer le document',
    'MursionPortal.ScenarioInfo.CompletionRate': '{completionRate} Fois',
    'MursionPortal.Button.RefreshPage': 'Rafraîchir la page',
    'MursionPortal.Prompt.CalendarUpdatedOutdatedNotification': 'Êtes-vous sûr de vouloir fermer, votre page de calendrier resterait dépassée.',
    'Reports.SessionListHeader.Label.AverageTime': 'Temps moyen <code>dans chaque session</code>.',
    'MursionPortal.Users.Archive.HasSessions': 'Impossible d\'archiver le Simulation specialist car une ou plusieurs sessions sont programmées pour ce Simulation specialist. Veuillez échanger ou changer de Sim specialist.',
    'MursionPortal.Label.InactiveSIMCertification.Tooltip': 'À utiliser s\'il y a un Sim qui est certifié sur cette simulation, mais qui n\'est pas disponible pour de nouvelles simulations.',
    'MursionPortal.Label.InactiveSIMCertification': 'Certification Sim inactive',
    'Dashboard.Filters.FilterBySessionDate': 'FILTRER PAR DATES DE SESSION',
    'Dashboard.LeftPane.CategoryHeader.Emails': 'E-MAILS',
    'Projects.ProjectInfo.Label.CancellationDeadline.Tooltip': 'Il s\'agit du nombre de jours/heures/minutes dont dispose un apprenant pour annuler, une fois inscrit à une simulation dans le cadre du projet. Si les apprenants annulent dans le délai imparti, la session sera considérée comme complète à des fins de facturation.',
    'Projects.ProjectInfo.Checkbox.AllowSessionRecording': 'Autoriser l\'enregistrement des sessions',
    'MursionPortal.Label.SendRecordedVideosTo': 'Envoyer les vidéos enregistrées à :',
    'MursionPortal.Checkbox.AllowLearnerToRefuseRecording': 'Permettre à l\'apprenant de participer à la session s\'il ne consent pas à l\'enregistrement',
    'Projects.ProjectInfo.SchedulingInterval': 'Intervalle de programmation',
    'Projects.ProjectInfo.SchedulingInterval.Tooltip': 'Permet de planifier la programmation à une cadence différente de la durée de la session.',
    'Projects.ProjectInfo.SchedulingIntervalLength': 'Longueur de l\'intervalle de programmation',
    'Projects.ProjectInfo.SchedulingIntervalLength.tooltip': 'Détermine la fréquence des blocs d\'horaires qu\'un apprenant voit. Si la valeur est 15, les apprenants verront des blocs d\'horaires par intervalles de 15 minutes. Cette option ne définit pas la durée de la session, mais uniquement la fréquence des sessions.',
    'Projects.ProjectInfo.DeliveryMode.tooltip': 'La plateforme utilisée par vos apprenants. Définissez sur ML3 si vous souhaitez activer le logiciel de connexion directe Mursion Magic et/ou ML3. ML3z = Zoom. Mursion Meet = Google Meet.',
    'MursionPortal.Label.WebOnly': 'Web uniquement',
    'MursionPortal.Label.DesktopOnly': 'Ordinateur de bureau uniquement',
    'MursionPortal.Label.DesktopAndWeb': 'Ordinateur de bureau et Web',
    'MursionPortal.EmailCategory.CallsToAction': 'Demandes d\'intervention',
    'MursionPortal.Dashboard.EmailAnalytics.Label.Sent': 'Envoyé',
    'MursionPortal.Dashboard.EmailAnalytics.Label.AllEmails': 'Tous les e-mails',
    'MursionPortal.Dashboard.EmailAnalytics.Label.Trends': 'Tendances',
    'MursionPortal.Dashboard.EmailAnalytics.Label.CategorySummary': 'Résumés des catégories',
    'MursionPortal.Dashboard.EmailAnalytics.Label.RegistrationEmail': 'E-mails d\'inscription',
    'MursionPortal.Dashboard.EmailAnalytics.Label.CallToActionEmail': 'Emails de Demandes d\'intervention',
    'MursionPortal.Dashboard.EmailAnalytics.Label.SchedulingEmail': 'Programmation des e-mails',
    'MursionPortal.Dashboard.EmailAnalytics.Label.ReSchedulingEmail': 'E-mails de reprogrammation',
    'MursionPortal.Dashboard.EmailAnalytics.Label.VideoEmail': 'E-mails vidéo',
    'MursionPortal.Dashboard.EmailAnalytics.Label.EmailSent': 'E-mail envoyé',
    'MursionPortal.Dashboard.EmailAnalytics.Info.EmailAnalytics': 'L\'analyse des e-mails vous donne un aperçu de la façon dont les apprenants s\'engagent dans les <link>communications par e-mail de Mursion</link>. Les e-mails peuvent appartenir à l\'une des trois catégories suivantes',
    'MursionPortal.Dashboard.EmailAnalytics.Info.EmailOpened': 'Un apprenant a ouvert un ou plusieurs des e-mails qui lui ont été envoyés.',
    'MursionPortal.Dashboard.EmailAnalytics.Info.EmailActedOn': 'Un apprenant a ouvert l\'e-mail et a cliqué sur un ou plusieurs liens, comme les liens <strong>Calendrier</strong> ou <strong>Rejoindre la simulation</strong>.',
    'MursionPortal.Dashboard.EmailAnalytics.Info.Unread': 'Un apprenant n\'a pas ouvert l\'e-mail. S\'il y a un grand nombre d\'e-mails non lus, confirmez que vos apprenants les reçoivent. Vérifiez auprès de votre équipe informatique que <color>no-reply@mursion.com</color> n\'est pas bloqué par les filtres anti-spam de votre organisation.',
    'MursionPortal.Dashboard.Tooltip.RegisterEmail': 'E-mails à un utilisateur l\'invitant à créer un compte Mursion',
    'MursionPortal.Dashboard.Tooltip.CallToActionEmail': 'E-mails de rappel envoyés par un Account Owner depuis la Page du projet ou du scénario.',
    'MursionPortal.Dashboard.Tooltip.SchedulingEmail': 'E-mails spécifiques aux sessions. Invitation à planifier et autres e-mails de participation à une session',
    'MursionPortal.Dashboard.Tooltip.ReschedulingEmail': 'E-mails invitant un apprenant à reprogrammer une simulation manquée ou annulée.',
    'MursionPortal.Dashboard.Tooltip.VideoEmail': 'E-mails avertissant un apprenant qu\'une vidéo de sa simulation est disponible',
    'MursionPortal.Dashboard.EmailCategoryFilter': 'Catégorie d\'e-mail',
    'MursionPortal.Dashboard.EmailCategoryFilter.Search': 'Recherche de catégories d\'e-mails...',
    'MursionPortal.Dashboard.EmailTypeFilter.SessionRescheduled': 'Session reprogrammée',
    'MursionPortal.Dashboard.EmailTypeFilter.Search': 'Recherche de types d\'e-mails...',
    'MursionPortal.Text.NoImageAvailable': 'Aucune image disponible',
    'Mursion.Portal.Edit.Team.Instruction.ExportFile': 'Cliquez sur <strong>Télécharger les adhésions actuelles</strong> ci-dessous pour mettre à jour les affectations d\'équipe des apprenants (<strong>edit_learner_team_memberships.xlsx</strong>).',
    'Mursion.Portal.Edit.Team.DownloadExcelTemplate': 'Télécharger les adhésions actuelles',
    'MursionPortal.Project.SameDay': 'Le jour même',
    'MursionPortal.Project.CancelationDeadline.Validation': 'Le délai d\'annulation doit être supérieur à 0.',
    'ClientUserTable.AddNewUsers.RolesHeading': 'Vous ne pouvez ajouter qu\'un seul type d\'utilisateur à la fois. Sélectionnez le type d\'utilisateur que vous voulez ajouter.',
    'ClientUserTable.AddNewUsers.Roles.AccountOwner.Tooltip': 'Un Account Owner est l\'administrateur principal de toute votre organisation.',
    'ClientUserTable.AddNewUsers.Roles.Facilitator.Tooltip': 'Un Facilitator est un administrateur au niveau de l\'équipe qui gère une ou plusieurs équipes spécifiques.',
    'ClientUserTable.AddNewUsers.Roles.Learner.Tooltip': 'Un apprenant est une personne qui participe à une simulation Mursion. Il s\'agit de la majeure partie de votre base d\'utilisateurs.',
    'ClientUserTable.AddNewUsers.Heading': 'Ajouter un ou plusieurs nouveaux utilisateurs',
    'ClientUserTable.AddNewUsers.Roles.Heading': 'Attribuer un rôle aux utilisateurs',
    'ClientUserTable.AddNewUsers.ImportUsers': 'Veuillez suivre les étapes ci-dessous pour un maximum de 500 utilisateurs. Si vous importez plus de 500, utilisez notre fonctionnalité "<code>Importer des utilisateurs</code>".',
    'ClientUserTable.AddNewAttributes.label': 'Ajouter des attributs d\'utilisateur',
    'ClientUserTable.AddNewAttributes.Header.Text': 'Notez que ces attributs seront appliqués à tous les utilisateurs de cette liste.',
    'ClientUserTable.UserAuthentication.label': 'Authentification des utilisateurs',
    'ClientUserTable.NewUsersAdded.Heading': 'Nouvel(eaux) utilisateur(s) ajouté(s) !',
    'ClientUserTable.AddNewUsers.AssignTeams.Heading': 'Attribuer une ou plusieurs équipes aux utilisateurs',
    'ClientUserTable.AddNewUsers.AssignTeams.CreateTeam': 'Les apprenants et les Facilitators doivent être affectés à une équipe. Si vous n\'avez pas encore créé une équipe, veuillez <code>cliquer ici.</code>',
    'ClientUserTable.AddNewUsers.AssignTeams.SelectTeams': 'Sélectionnez une ou plusieurs équipes',
    'ClientUserTable.AddNewUsers.AssignTeams.UserEmails.Validation': 'Veuillez ajouter 500 utilisateurs ou moins. Pour importer plus de 500 utilisateurs, vous pouvez utiliser notre fonction "Importer des utilisateurs".',
    'ClientUserTable.AddNewUsers.AssignTeams.Validation': 'Vous devez sélectionner au moins une équipe.',
    'ClientUserTable.AddNewUsers.AssignTeams.UserEmails.Heading': 'Ajouter les adresses e-mail des utilisateurs',
    'ClientUserTable.AddNewUsers.AssignTeams.UserEmails.SubHeading': 'Vous pouvez ajouter un e-mail à la fois ou coller une liste. Les e-mails doivent être séparés par un espace, une virgule ou un point-virgule.',
    'ClientUserTable.AddNewUsers.AssignTeams.UserEmails.Placeholder': 'Saisissez ou collez les adresses électroniques ici.',
    'ClientUserTable.AddNewUsers.AssignTeams.UserEmails.MaximumUsersAllowed': '{maximumUsersAllowed} utilisateurs à la fois',
    'ClientUserTable.AddNewUsers.AssignTeams.UserEmails.Total': 'Total',
    'ClientUserTable.AddNewUsers.AssignTeams.UserEmails.Duplicate': 'Dupliquer',
    'ClientUserTable.AddNewUsers.AssignTeams.UserEmails.AlreadyExists': 'Existe déjà',
    'ClientUserTable.AddNewUsers.AssignTeams.UserEmails.Invalid': 'Invalide',
    'ClientUserTable.AddNewUsers.AddNewAttributes.SignLanguageInterpreter': 'Interprète en langue des signes',
    'ClientUserTable.AssignTeams.TotalUsers': 'Vous allez ajouter <code>{users} nouveau {role}</code>',
    'ClientUserTable.AssignTeams.AddAndUpdateUsers': 'Vous êtes sur le point d\'ajouter <code>{users} nouveau {role}</code> et de mettre à jour <code>{existingUsers} existant {role}</code>',
    'ClientUserTable.AssignTeams.UpdateUsers': 'Vous\ êtes sur le point de mettre à jour le <code>{existingUsers} existant {role}</code>',
    'ClientUserTable.AssignTeams.NumberOfTeams': 'Seront affectés à <code>{numberOfTeams} équipe(s)</code>.',
    'ClientUserTable.Confirmation.Heading': 'Veuillez confirmer avant d\'ajouter',
    'MursionPortal.InviteToSchedule.Confirmation.Text': 'Pour en savoir plus, cliquez sur le lien suivant : <code>Inviter à programmer</code>.',
    'MursionPortal.AccountOwnerGuides.Confirmation.Text': 'Guides de l\'Account Owner',
    'MursionPortal.FacilitatorGuides.Confirmation.Text': 'Guides du Facilitator',
    'MursionPortal.Users.Confirmation.Text': 'Votre ou vos utilisateurs ont été ajoutés au portail Mursion.',
    'MursionPortal.Confirmation.Header.Text.BackToUsers': 'Retour aux utilisateurs',
    'MursionPortal.Users.Next.Steps.Text': 'Prochaines étapes',
    'MursionPortal.Users.StepOne.Text': 'Étape 1 :',
    'MursionPortal.Users.StepTwo.Text': 'Étape 2 :',
    'MursionPortal.Users.StepThree.Text': 'Étape 3 :',
    'MursionPortal.Users.ThatIt.Text': 'C\'est ça !',
    'MursionPortal.Users.InviteToScheduleLearner.Text': 'Invitez vos apprenants à programmer',
    'MursionPortal.Users.LearnersLink.Text': 'Vous pouvez envoyer ce lien à vos apprenants',
    'MursionPortal.Users.InviteToSchedule.ProjectorScenario.Text': 'Utilisez le bouton <strong>Inviter à programmer</strong> sur n\'importe quel projet ou scénario.',
    'MursionPortal.Users.CopyInviteLink.Text': 'Copier le lien de l\'invitation',
    'MursionPortal.Users.ShareContent.Text': 'Partagez le contenu du support avec vos administrateurs',
    'MursionPortal.Users.AOAndFacilitators.Responsibilities.Text': 'Les Account Owners et les Facilitators ont des responsabilités différentes et peuvent avoir besoin d\'en savoir plus sur le portail. Partagez ces liens avec vos Account Owners et vos Facilitators afin qu\'ils puissent trouver des réponses à toutes les questions qu\'ils peuvent se poser au cours de leur parcours Mursion.',
    'MursionPortal.Users.Administrators.Text': 'Vos administrateurs auront désormais accès au portail Mursion.',
    'MursionPortal.Users.Progress.Text': 'Vos apprenants vont commencer à programmer leurs simulations. Vous pouvez suivre leur progression sur votre Tableau de bord.',
    'MursionPortal.Users.Access.Text': 'Assurez-vous que votre département informatique a donné à ces utilisateurs un accès à l\'application Mursion.',
    'MursionPortal.Users.Registrationemail.Text': 'Vos utilisateurs devraient avoir reçu leur e-mail d\'inscription de no-reply@mursion.com.',
    'MursionPortal.AddMoreUsers.Btn.Text': 'Ajouter plus d\'utilisateurs',
    'MursionPortal.Users.Ok.Btn.Text': 'OK, compris !',
    'Users.Button.AddNewUsers': 'Ajouter de nouveaux utilisateurs',
    'MursionPortal.Project.CancelationDeadline.UpperLimit.Validation': 'Le délai d\'annulation ne peut être supérieur à 365 jours.',
    'MursionPortal.Dashboard.EmailTypeFilter.PasswordReset': 'Réinitialiser le mot de passe',
    'MursionPortal.Dashboard.EmailTypeFilter.Verification': 'Vérification',
    'MursionPortal.Dashboard.EmailTypeFilter.InviteToSchedule': 'Invitation à prendre rendez-vous',
    'MursionPortal.Dashboard.EmailTypeFilter.Summary': 'Résumé',
    'MursionPortal.Dashboard.EmailTypeFilter.RescheuleMissed': 'Reprogrammation manquée',
    'MursionPortal.Dashboard.EmailTypeFilter.RescheuleCancelled': 'Reprogrammation annulé',
    'MursionPortal.Dashboard.EmailTypeFilter.PSRescheule': 'Reprogrammation du PS',
    'MursionPortal.Dashboard.EmailTypeFilter.VRecordingCorrupted': 'Enregistrements vidéo corrompus',
    'MursionPortal.Dashboard.EmailTypeFilter.VRecordingNotUploaded': 'Enregistrements vidéo non téléchargés',
    'MursionPortal.Dashboard.EmailTypeFilter.VRecordingAvailable': 'Enregistrement vidéo disponible',
    'MursionPortal.Dashboard.EmailTypeFilter.VRecordingShared': 'Enregistrement vidéo partagé',
    'MursionPortal.Dashboard.EmailTypeFilter.Booking': 'Réservation',
    'MursionPortal.Dashboard.EmailTypeFilter.CodeOfConuct': 'Code de conduite',
    'MursionPortal.Dashboard.EmailTypeFilter.UserRemoved': 'Utilisateur supprimé',
    'MursionPortal.Dashboard.EmailTypeFilter.AttendanceReminer': 'Rappel de l\'assiduité',
    'Users.Button.AddNewLearners': 'Ajouter de nouveaux apprenants',
    'MursionPortal.Scenario.Label.LastDelivered': 'Dernière livraison',
    'MursionPortal.Scenario.Label.TotalDeliveries': 'Livraisons totales',
    'MursionPortal.Scenario.Text.NotYetDelivered': 'Pas encore livré',
    'ClientUserTable.AddNewUsers.UpdateUserWarning': 'Ces utilisateurs {existingUsers} existent déjà. En procédant, ces utilisateurs seront ajoutés aux équipes sélectionnées (en plus de toutes les équipes auxquelles ils sont déjà affectés).',
    'MursionPortal.AddNewUsers.Facilitators': 'Facilitator(s)',
    'ClientUserTable.AddNewUsers.Learners': 'Apprenant(s)',
    'MursionPortal.Scheduling.TimeSlotsUnavailableMessage': 'La ou les plages horaires sélectionnées ne sont plus disponibles. Veuillez rafraîchir la page pour afficher les créneaux horaires disponibles',
    'MursionPortal.Client.EmailTypeFilter.SessionFirstReminder': 'Premier rappel de la session',
    'MursionPortal.Client.EmailTypeFilter.SessionSecondReminder': 'Deuxième rappel de la session',
    'Projects.ProjectInfo.Label.ShowSIMAvailaiblity': 'Afficher la disponibilité des SIM',
    'Projects.ProjectInfo.Label.ShowAvailaiblity.Tooltip': 'Lorsqu\'un apprenant s\'inscrit, il verra la disponibilité du Sim specialist pour la gamme que vous avez sélectionnée.',
    'Projects.InputValidation.GreaterThanZero': 'Doit être supérieur à 0.',
    'MursionPortal.Weeks': 'Semaine(s)',
    'MursionPortal.Scenario.CompletionRateFulfilled': 'You cannot schedule this simulation as you have completed the maximum number of simulations for this scenario or engagement as set by your organization.',// TODO add translations
    'MursionPortal.Dashboard.EmailTypeFilter.TimeSlotRequest': 'Demande de créneau horaire',
    'MursionPortal.Dashboard.EmailAnalytics.OpenAndActed.Label': 'Les données ouvertes et appliquées sont exactes au 24 novembre 2022.',
    'MursionPortal.Dashboard.EmailAnalytics.Prior.Label': 'Avant cette date, tous les e-mails sont classés comme non lus.',
    'MursionPortal.Learners.Filters.LearnerCompletion': 'Accomplissement de l\'apprenant',
    'MursionPortal.Learners.Filters.LearnerCompletion.NotYetCompleted': 'Pas encore terminé',
    'MursionPortal.Learners.Filters.LearnerCompletion.CompletedOneSession': 'A complété 1 session',
    'MursionPortal.Learners.Filters.LearnerCompletion.CompletedTwoPlusSessions': 'complété 2+ sessions',
    'MursionPortal.Learners.Filters.MissedLateActivity': 'Activité manquée ou tardive',
    'MursionPortal.Learners.Filters.MissedLateActivity.1Session': '1 session',
    'MursionPortal.Learners.Filters.MissedLateActivity.2Sessions': '2 sessions',
    'MursionPortal.Learners.Filters.MissedLateActivity.3Sessions': '3 sessions',
    'MursionPortal.Learners.Filters.MissedLateActivity.4PlusSessions': '4+ sessions',
    'MursionPortal.BackToCompletedSessions': 'Retour aux sessions terminées',
    'MursionPortal.Scenario.InviteToSchedule.Modal.Text': 'Souhaitez-vous envoyer des invitations à <strong>tous</strong> les apprenants assignés qui n\'ont pas encore terminé ce scénario ou <strong>sélectionner les apprenants</strong> à inviter ?',
    'MursionPortal.BackToSessionSummaries': 'Retour aux résumés des sessions',
    'MursionPortal.BackToLearnerSessions': 'Retour aux sessions pour les apprenants',
    'MursionPortal.LearnerSessionReport.SessionObjective': 'Objectif de la session',
    'MursionPortal.LearnerSessionReport.SessionStrategy': 'Dans quelle mesure avez-vous appliqué les stratégies ?',
    'MursionPortal.LearnerSessionReport.SessionStrategyIcon': 'Stratégie de la session Cible en œil de bœuf',
    'MursionPortal.LearnerSessionReport.SessionPerspective.Title': 'Continuez comme ça !',
    'MursionPortal.LearnerSessionReport.SessionDetail': 'Description du scénario',
    'MursionPortal.LearnerSessionReport.SessionDate': 'Date de la session',
    'ClientUserTable.AddNewUsers.Procced.Button.Tooltip': 'Veuillez supprimer tous les e-mails en double ou invalides avant de poursuivre.',
    'MursionPortal.BackToSessionDetail': 'Retour aux détails de la session',
    'MursionPortal.SimAttendance.Instructions.Label': 'Mursion Simulation Specialists:',// TODO add translations
    'MursionPortal.SimAttendance.Instructions.LabelText': 'Select an attendance status for each attendee (mouse over the status to learn more). Your status is gathered automatically – these statuses are for the learner ONLY.',// TODO add translations
    'MursionPortal.SimAttendance.Instructions.Label1': 'Software License Simulation Specialists:',// TODO add translations
    'MursionPortal.SimAttendance.Instructions.LabelText1': 'Select an attendance status for each attendee (mouse over the status to learn more). If you experienced an error in the session, please also provide the error details. To do this, select the type of error under the “SIM Reported Error” section and add context in the Additional Details text box. Additional details about attendance--even if it’s not an Error--are encouraged, but are optional. If you have any further questions about attendance, please reach out to your Simulation Program Admin.',// TODO add translations
    'MursionPortal.Dashboard.EmailAnalytics.Label.EmailAnalytics': 'Analyse des e-mails',
    'MursionPortal.Session.LearnerSession.Report': 'Rapport',
    'MursionPortal.Session.LearnerSession.Recording': 'Enregistrement',
    'MursionPortal.Session.LearnerSession.SessionDetails': 'Détails de la session',
    'MursionPortal.Session.LearnerSessionReport': 'Rapport de session pour les apprenants',
    'MursionPortal.Scenario.InviteToScedule.Modal.SelectLearners': 'Sélectionner les apprenants',
    'MursionPortal.Scenario.Button.Label': 'Copie des e-mails des apprenants',
    'MursionPortal.VideoStatusFilter.Label': 'Statut de la vidéo',
    'MursionPortal.TooltipText.VideoNotUploaded': 'Si cette vidéo n\'est pas mise en ligne après 48 heures, veuillez contacter l\'assistance.',
    'MursionPortal.VideoStatusFilter.SearchText': 'Recherche du statut de la vidéo...',
    'MursionPortal.VideoStatusFilter.Uploaded': 'Téléchargé',
    'MursionPortal.VideoStatusFilter.NotUploaded': 'Non téléchargé',
    'MursionPortal.Scenario.Invite.Modal.Button.Label': 'Inviter',
    'MursionPortal.Scenario.CopyEmails.Modal.Button.Label': 'Copie des e-mails',
    'MursionPortal.Scenario.AllLearners.InvitationsSentOvernight': 'Vos invitations seront envoyées pendant la nuit si l\'apprenant n\'a pas déjà programmé ou reçu une invitation au cours des 2 derniers jours.',
    'MursionPortal.Scenario.SelectedLearners.InvitationsSentOvernight': '<code>Les apprenants sélectionnés</code> recevront dans la nuit un e-mail les invitant à se programmer, s\'ils ne se sont pas déjà programmés ou n\'ont pas reçu d\'invitation au cours des 2 derniers jours.',
    'MursionPortal.Scenario.SelectedLearners.Confirm.Text': 'Êtes-vous sûr de vouloir inviter <code>tous les apprenants sélectionnés</code>',
    'ClientUserTable.Column.LastInvited': 'Dernier invité',
    'ClientUserTable.ColumnTooltip.LastInvited': 'La date à laquelle le dernier e-mail d\'invitation à la programmation a été envoyé. Cela peut être demain si vous venez d\'être invité.',
    'MursionPortal.Scenario.SelectedLearners.EmailsCopied': 'Les adresses e-mail des apprenants sélectionnés ont été copiées dans votre presse-papier.',
    'MursionPortal.ScenarioInfo.MarkAllInactive': 'Marquer tout Inactif',
    'MursionPortal.ScenarioInfo.DecertifyAll': 'Décertifier tout',
    'MursionPortal.ScenarioInfo.MarkAllInactive.Confirmation.Text': 'Êtes-vous sûr de vouloir rendre inactifs tous les Sim specialist ?',
    'MursionPortal.ScenarioInfo.DecertifyAll.Confirmation.Text': 'Êtes-vous sûr de vouloir décertifier tous les Sim specialist ?',
    'Dashboard.Button.ViewSession': 'Voir la session',
    'MursionPortal.SessionReport.NotAvailable': 'Non disponible',
    'Projects.ProjectInfo.Checkbox.EnableReportTabSurvey.OneToOne': 'Activer l\'onglet Rapport pour les livraisons 1:1',
    'MursionPortal.Learner.Session.Report': 'Rapport de session pour les apprenants',
    'MursionPortal.Scenario.Sim.InactivedAll': '{effectedSims} sur {totalSims} SIMs inactivés.',
    'MursionPortal.Scenario.Sim.DecertifiedAll': '{effectedSims} sur {totalSims} SIMs décertifiés.',
    'MursionPortal.Scenario.Sim.DecertifiedFew': '{remainingSims} Les SIMs ne peuvent pas être décertifiés car ils ont des sessions programmées. Marquez-les comme inactifs pour honorer leurs sessions et empêcher de futures affectations de sessions.',
    'MursionPortal.AriaLabel.ViewMore': 'Voir plus',
    'MursionPortal.SimAttendance.SimulationAttendance': 'Présence des apprenants de la simulation',
    'Mursion.Portal.Status.LearnerTechnicalError': 'Learner Technical Error',// TODO add translations,
    'Mursion.Portal.Status.TooLateToComplete': 'Trop tard pour compléter',
    'Mursion.Portal.Status.LearnerElectedToLeave': 'Learner Elected to Leave',// TODO add translations
    'Mursion.Portal.Status.Unprepared.Tooltip': 'L\'apprenant s\'est inscrit mais n\'a pas terminé la simulation parce qu\'il n\'était pas préparé ou pour d\'autres raisons.',
    'MursionPortal.Label.SchedulingRate': 'Taux de programmation',
    'Mursion.Portal.Status.No.Show': 'Absence',
    'MursionPortal.ScenarioInfo.SchedulingRate.Text': '{code} Fois',
    'MursionPortal.Scenario.Modal.Search.Placeholder': 'Rechercher l\'adresse e-mail, le prénom, le nom de famille',
    'MursionPortal.Scenario.SchedulingRateFulfilled': 'Vous devez d\'abord terminer la session prévue.',
    'MursionPortal.Scenario.InviteToScedule.Modal.Button.Tooltip': 'Sélectionnez l\'apprenant à inviter',
    'MursionPortal.Learner.Session.Report.Report.SimulationRecording': 'Réfléchissez à la conversation en écoutant l\'enregistrement de votre simulation',
    'Dashboard.SimulationTable.ColumnTooltip.SoftwareVersion': 'Indique le logiciel de session utilisé',
    'Dashboard.SimulationTable.Column.SoftwareVersion': 'Logiciel',
    'MursionPortal.LearnerSessionReport.YourPerspective': 'Votre point de vue',
    'MursionPortal.LearnerSessionReport.YourPerspectiveDescription': 'Votre confiance avant la simulation était <strong>{preConfidenceLevel}</strong> et après la simulation était <strong>{postConfidenceLevel}.</strong>',
    'MursionPortal.LearnerSessionReport.YourPerspective.VeryLow': 'très faible',
    'MursionPortal.LearnerSessionReport.YourPerspective.Low': 'faible',
    'MursionPortal.LearnerSessionReport.YourPerspective.Moderate': 'modéré',
    'MursionPortal.LearnerSessionReport.YourPerspective.High': 'haut',
    'MursionPortal.LearnerSessionReport.YourPerspective.VeryHigh': 'très élevé',
    'MursionPortal.LearnerSessionReport.Strategies.SuccessfullyStrategy': 'Vous avez démontré <strong>avec succès</strong> cette stratégie',
    'MursionPortal.LearnerSessionReport.Strategies.BenefitStrategy': 'Vous auriez <strong>intérêt à vous entraîner davantage</strong> à mettre en œuvre cette stratégie',
    'MursionPortal.SchedulingRate.ValidationMessage': 'Le taux de programmation doit être inférieur ou égal au taux d\'achèvement.',
    'MursionPortal.LearnerSessionReport.Footer.FooterTitle': 'Prochaines étapes : C\'est en forgeant qu\'on devient forgeron !',
    'MursionPortal.LearnerSessionReport.Footer.FooterSubTitle': 'La répétition est la clé pour que ces compétences deviennent une seconde nature.',
    'MursionPortal.LearnerSessionReport.SessionObjective.Body': 'Votre rapport sera prêt sous peu',
    'MursionPortal.Dashboard.NextOnProject.Footer.schedulingRateFulfilled.Text': 'You cannot schedule this simulation at this time as you have currently reached the scheduling limit for this scenario or engagement set by your organization.',// TODO add translations
    'MursionPortal.Status.Abbreviation.LicenseCancelled': 'C',
    'MursionPortal.Scenario.CharacterLimitExceeded.Message': 'Vous avez dépassé la limite de caractères de {exceededCharLength} caractères. Veuillez réviser.',
    'MursionPortal.Scenario.AddedCharacter.Message': '{added} de {totalChar}',
    'MursionPortal.Scenario.CharacterLimit.Message': 'Limite de caractères : {maxLength}',
    'MursionPortal.Scenario.Description.block.default.text': 'Ajouter la description ici',
    'MursionPortal.Scenario.AttachmentField.text': 'Vous pouvez ajouter d\'autres fichiers',
    'MursionPortal.sessionTable.Column.OriginalSim': 'SIM original',
    'MursionPortal.sessionTable.Column.NewSim': 'Nouveau SIM',
    'MursionPortal.Users.EditUser.InactivatedAll.Message': '{code} Le(s) certificat(s) a(ont) été désactivé(s).',
    'MursionPortal.Users.EditUser.InactivateAll.Confirmation': 'Êtes-vous sûr de vouloir désactiver tous les certificats pour ce Spécialiste SIM ?',
    'MursionPortal.Users.Modal.ActiveCerts.EmptyMessage': 'Aucun certificat actif',
    'MursionPortal.Users.Modal.InactiveCerts.EmptyMessage': 'Aucun certificat inactif',
    'MursionPortal.Users.Modal.ActiveCerts': 'Certificats actifs',
    'MursionPortal.Users.Modal.InactiveCerts': 'Certificats inactifs',
    'MursionPortal.Users.ActiveCerts.MarkAllInactive.Button': 'Marquer tout Inactif',
    'MursionPortal.SurveyFilter.Incomplete': 'Incomplet',
    'MursionPortal.SurveyFilter.SearchText': 'Enquête de recherche...',
    'MursionPortal.Dashboard.SurveyReminder.Text': 'Vous avez {surveyCount} enquêtes à compléter',
    'MursionPortal.Dashboard.RemindMeLater': 'Me rappeler plus tard',
    'MursionPortal.Dashboard.SurveysIncomplete': 'Enquêtes incomplètes',
    'MursionPortal.AriaLabel.NormalFont': 'Police normale',
    'MursionPortal.AriaLabel.MediumFont': 'Police moyenne',
    'MursionPortal.AriaLabel.LargeFont': 'Grande police',
    'MursionPortal.AriaLabel.Font': 'Police',
    'Dashboard.LeftPane.CategoryHeader.RescheduledAndCancelled': 'Reprogrammés et annulés',
    'MursionPortal.Dashboard.Summary.Certifications': 'Certifications',
    'MursionPortal.SessionsTable.ActionTitle.Pickup': 'ramasser',
    'MursionPortal.Label.MissCancellationRate': 'Taux d\'erreur/annulation',
    'MursionPortal.Label.NotAllowed': 'Non autorisé',
    'Dashboard.LeftPane.CategoryHeader.Simulations': 'SESSIONS',
    'Dashboard.LeftPane.Simulations.SimulationSummaries': 'Résumés des sessions',
    'Dashboard.LeftPane.Simulations.SimulationDetail': 'Détail de la session',
    'MursionPortal.Message.NotEnoughDataToDisplay': 'Il n\’y avait pas assez de données de cette session.',
    'MursionPortal.Message.Notdata.SessionMatrix': 'Il n\’y avait pas assez de données de cette session pour fournir des analyses de session.',
    'MursionPortal.Tooltip.SchedulingWindow': 'Les utilisateurs finaux peuvent définir cette valeur en jours, heures, minutes, après quoi seul l\’utilisateur serait en mesure de planifier une session.',
    'MursionPortal.DemandBasedScheduling.TimeBlockUnavailableSelectDate': 'Nous n\’avons pas de créneaux horaires disponibles pour le moment. Veuillez réessayer plus tard. <code1></code1>Contact <code>support</code> si vous avez besoin d\’une aide immédiate.',
    'Session.Edit.Modal.JoinSession': 'Démarrer la session',
    'MursionPortal.SimAttendance.Tooltip.Completed': 'L\’apprenant a terminé la partie simulation de la session (sans compter le débriefing à la fin de la session).',
    'MursionPortal.SimAttendance.Tooltip.Late': 'L\'apprenant s\'est connecté trop tard pour achever la simulation.',
    'MursionPortal.SimAttendance.Tooltip.Left': 'L\’apprenant s\’est intentionnellement déconnecté avant d\'achever la simulation. Cela peut être dû au fait qu\’un apprenant n\’est pas préparé ou qu\’un apprenant peut partir en cas d\’urgence.',
    'Session.Edit.Modal.JoinSessionBtnTooltip': 'Le bouton Démarrer la session doit être activé avant <code>{minutesBeforeJoin}</code> du début de la session.',
    'Dashboard.Simulations.SimulationSummaries.TableHeader': 'Données de résumés de session',
    'Dashboard.Learners.LearnerActivity.TableHeader': 'Données d\’activité de l\’apprenant',
    'Dashboard.Learners.LearnerSimulation.TableHeader': 'Données des sessions de l\’apprenant',
    'Dashboard.ContractProgress.Column.Missed': 'Apprenant manqué',
    'Dashboard.ContractProgress.Column.TotalRescheduled': 'Total reprogrammé',
    'Dashboard.ContractProgress.Column.RescheduledMakeup': 'Changement de programme',
    'Dashboard.ContractProgress.Column.LateRescheduledSimulations': 'Reprogrammation tardive',
    'Dashboard.ContractProgress.Column.EarlyRescheduledSimulations': 'Report anticipé',
    'Dashboard.ContractProgress.Column.CompletedUtilization': 'Fin de la session',
    'Dashboard.ContractProgress.Column.ScheduledUtilization': 'Estimation de l\'achèvement',
    'Dashboard.ContractProgress.ColumnTooltip.Remaining': 'Les sessions achetées moins les sessions terminées.',
    'Dashboard.ContractProgress.ColumnTooltip.TotalRescheduled': 'Nombre total de simulations planifiées',
    'Dashboard.ContractProgress.ColumnTooltip.RescheduledMakeup': 'Total des simulations qui sont marquées comme étant reportées',
    'Dashboard.ContractProgress.ColumnTooltip.LateRescheduledSimulations': 'simulations qui sont reprogrammées tardivement.',
    'Dashboard.ContractProgress.ColumnTooltip.EarlyRescheduledSimulations': 'simulations qui sont reprogrammées en avance.',
    'Dashboard.Learners.Column.SimulationId': 'Session - ID',
    'Dashboard.Learners.Column.SimulationId.CopyHover': 'Copier l\’ID de session',
    'Dashboard.Learners.Column.SSO_ID': 'ID de la SSO',
    'Dashboard.Learners.Column.Recorded': 'Enregistré',
    'Dashboard.Learners.Column.LearnerRecordingConsent': 'Consentement de l\’apprenant à enregistrer',
    'Dashboard.Learners.Column.RecordingRecipients': 'Enregistrement des destinataires',
    'Dashboard.Learners.Column.RecordingUploaded': 'Enregistrement téléchargé',
    'Dashboard.Learners.ColumnTooltip.SSOID': 'Utilisateurs qui ont été provisionnés',
    'Dashboard.Learners.ColumnTooltip.Recorded': 'Indique si la simulation a été enregistrée ou si elle a été refusée.',
    'Dashboard.Learners.ColumnTooltip.LearnerRecordingConsent': 'Indique si l\’apprenant a donné son consentement à l\’enregistrement de la simulation.',
    'Dashboard.Learners.ColumnTooltip.RecordingRecipients': 'Rôles auxquels l\’enregistrement de la simulation est envoyé.',
    'Dashboard.Learners.ColumnTooltip.RecordingUploaded': 'Indique si l\’enregistrement de la simulation a été téléchargé.',
    'MursionPortal.Dashboard.Chart.Tooltip.SimulationBreakdown.LateReschedule': 'Les sessions qui ont été reprogrammées tardivement par l\’apprenant.',
    'MursionPortal.Dashboard.Chart.label.SimulationBreakdown.LateReschedule': 'Report tardif',
    'MursionPortal.Dashboard.Chart.Header.LearnerStatus': 'Statut de l\’apprenant (tous les contrats)',
    'Dashboard.SimulationTable.Column.SimulationStatus': 'État de la session',
    'MursionPortal.Placeholder.ContractValue': 'Sélectionnez un contrat',
    'MursionPortal.Placeholder.NoContractValue': 'Aucun contrat disponible',
    'Dashboard.Learners.Column.AssignedTeams': 'Équipes assignées',
    'SessionReports.VideoRecordingDeletedWithSupportCase': 'Malheureusement, cette vidéo ne peut pas être visionnée en raison d\’un(e) #{supportCase}.',
    'MursionPortal.Dashboard.CompletedSessions': 'Sessions terminées',
    'MursionPortal.Dashboard.ViewAll': 'Tout voir',
    'MursionPortal.Dashboard.DateOfSimulation': 'Date de la session',
    'Dashboard.LeftPane.CategoryHeader.Videos': 'VIDÉOS',
    'Dashboard.LeftPane.Videos.NotUploaded': 'Enregistrements non téléchargés',
    'Dashboard.NotUploaded.TableHeader': 'Données enregistrées et non téléchargées',
    'Dashboard.VideosTable.Column.SessionId': 'SessionID',
    'Dashboard.VideosTable.Column.SimspecialistId': 'SIMSpecialistID',
    'Dashboard.VideosTable.Column.ClientId': 'ClientID',
    'Dashboard.VideosTable.Column.SessionEnd': 'Fin de session',
    'Dashboard.VideosTable.Column.SoftwareVersion': 'Version du scénario',
    'Dashboard.VideosTable.NoVideosError': 'Aucune vidéo n\’a été trouvée.',
    'Filters.SimSpecialist': 'Nom de SIM',
    'Filters.SimSpecialistPlaceHolderHint': 'Rechercher dans SimSpecialists',
    'MursionPortal.Placeholder.SimSpecialistValue': 'Sélectionnez un SimSpecialist',
    'Users.Table.ErrorOnLoadUserMessage': 'Une erreur s\’est produite lors du chargement de l\’utilisateur !',
    'Dashboard.ContractProgress.InfoBadge.ContractDates': 'Dates des contrats',
    'Dashboard.ContractProgress.InfoBadge.ContractID': 'ID du contrat',
    'Dashboard.Learners.Column.EarlyCancelled': 'Annulé en avance',
    'Dashboard.Learners.Column.LateReschedules': 'Reprogrammations tardives',
    'Dashboard.Learners.Column.EarlyReschedules': 'Reprogrammations anticipées',
    'Dashboard.Learners.Column.RescheduledMakeUp': 'Rétablissement de programme',
    'Dashboard.Learners.Column.ErrorSim': 'Erreur (SIM)',
    'Dashboard.Learners.Column.CancelledOps': 'Annulé (Ops)',
    'Dashboard.Learners.ColumnTooltip.AssignedProjects': 'Projets auxquels cet apprenant est actuellement affecté',
    'Dashboard.Learners.ColumnTooltip.AssignedTeams': 'Équipes auxquelles cet apprenant est actuellement affecté',
    'Dashboard.Learners.ColumnTooltip.AssignedScenarios': 'Scénarios auxquels cet apprenant est actuellement affecté',
    'Dashboard.Learners.ColumnTooltip.ScheduledScenarios': 'Scénarios programmés par cet apprenant mais pas encore terminés',
    'Dashboard.Learners.ColumnTooltip.CompletedScenarios': 'Scénarios effectués par cet apprenant',
    'Dashboard.Learners.ColumnTooltip.EarlyCancelled': 'Les simulations qui ont été annulées prématurément (avant la date limite d\'annulation) par l\'apprenant, l\'animateur ou le propriétaire du compte ou les simulations',
    'Dashboard.Learners.ColumnTooltip.LateReschedules': 'Les simulations qui ont été reprogrammées tardivement (après la date limite d\'annulation) par l\'apprenant, l\'animateur ou le propriétaire du compte',
    'Dashboard.Learners.ColumnTooltip.RescheduledMakeUp': 'Les simulations qui ont été manquées précédemment par l\'apprenant, l\'animateur, le propriétaire du compte ou les simulations',
    'Dashboard.Learners.ColumnTooltip.EarlyReschedules': 'Les heures de simulation qui ont été reprogrammées en avance (avant la date limite d\'annulation) par l\'apprenant, facilitator ou le account owner',
    'Dashboard.Learners.ColumnTooltip.ErrorSim': 'Les simulations qui se sont terminées par erreur du côté SIM de la connexion',
    'Dashboard.Learners.ColumnTooltip.CancelledOps': 'Simulations qui ont été annulées par Mursion',
    'SessionReports.VideoRecordingPermissionNotGranted': 'Cette vidéo n\’est pas disponible parce qu\’un participant ne voulait pas être enregistré.',
    'SessionReports.NoVideoAvailableForSession': 'Il n\’y a pas de vidéo pour cette session.',
    'MursionPortal.NotAvailable': 'N/A',
    'Projects.ProjectInfo.Checkbox.EnablePostSimulationSurvey.OneToOne': 'Activer le questionnaire de retours d\'expérience post-simulation SIM pour 1',
    'Projects.ProjectInfo.Checkbox.EnablePostSimulationSurvey.Workshop': 'Activer un questionnaire de retours d\'expérience SIM post-simulation pour les livraisons d\'ateliers',
    'Projects.ProjectInfo.Checkbox.SurveyEnabled.PostSimulationSurvey.OneToOne': 'Questionnaire de retours d\'expérience post-simulation SIM 1',
    'Projects.ProjectInfo.Checkbox.SurveyEnabled.PostSimulationSurvey.Workshop': 'Livraisons des ateliers du questionnaire de retours d\'information SIM post-simulation',
    'Clients.Portal.Button.EditMemberships': 'Modifier les adhésions',
    'Calendar.ProjectsList.ShowOnlyActiveProjects': 'Afficher uniquement les projets actifs',
    'MursionPortal.Status.Undefined': 'Non défini',
    'MursionPortal.Status.Orphan': 'Orphelin',
    'MursionPortal.Status.Reserved': 'Réservé',
    'MursionPortal.Status.Waif': 'Waif',
    'MursionPortal.Status.Upcoming': 'À venir',
    'MursionPortal.Status.Running': 'En cours d\'exécution',
    'MursionPortal.Status.NeedsReview': 'Examen des besoins',
    'MursionPortal.Status.Reviewed': 'Revue',
    'MursionPortal.ErrorMessage.NoDataAvailable': 'Il n\’y avait pas assez de données de cette session pour fournir des analyses de session.',
    'Dashboard.NotUploaded.MainHeader': 'Enregistrements non téléchargés',
    'MursionPortal.Message.NotEnoughDataToDisplay.ConversationalFlow': 'Il n\’y avait pas assez de données de cette session pour créer ce graphique',
    'MursionPortal.Label.SIMSpecialist': 'Spécialiste SIM',
    'Dashboard.SimulationTable.Column.LearnerRecordingConsent': 'Consentement de l\’apprenant à l\’enregistrement',
    'Dashboard.SimulationTable.Column.RecordingRecipients': 'Enregistrement des destinataires',
    'Dashboard.SimulationTable.Column.RecordingUploaded': 'Enregistrement téléchargé',
    'Dashboard.SimulationTable.ColumnTooltip.LearnerRecordingConsent': 'Indique si l\’apprenant a donné son consentement à l\’enregistrement de la simulation',
    'Dashboard.SimulationTable.ColumnTooltip.RecordingRecipients': 'Rôles auxquels l\’enregistrement de simulation est envoyé',
    'Dashboard.SimulationTable.ColumnTooltip.RecordingUploaded': 'Indique si l\’enregistrement de simulation a été téléchargé',
    'MursionPortal.ConversationalFlow.label.zoom': 'Zoom',
    'MursionPortal.label.LearnerInterjects': 'L\’apprenant intervient',
    'MursionPortal.label.AvatarInterjects': 'L\'avatar intervient',
    'MursionPortal.label.LearnerInterrupted': 'Apprenant interjeté',
    'MursionPortal.label.AvatarInterrupted': 'Avatar interjecté',
    'MursionPortal.label.Silence': 'Silence',
    'Clients.Modal.Label.UserRecordingConsent': 'Consentement d\’enregistrement de l\’utilisateur',
    'Clients.Modal.Label.UserRecordingConsent.ToolTip': 'Demandez à l\’utilisateur d\’enregistrer son consentement',
    'MursionPortal.Notset': 'Non défini',
    'Clients.Modal.Label.UserML3SocialRecordingConsent': 'Consentement d\’enregistrement de l\’utilisateur social de Mursion',
    'Clients.Modal.Label.UserML3SocialRecordingConsent.ToolTip': 'Demandez à l\’utilisateur son consentement pour être enregistré. Si cette option n\'est pas activée, le spécialiste SIM le demandera verbalement.',
    'MursionPortal.LearnerSimSelector.Sim.Available.ToolTip': 'Les SIM certifiés qui ne sont pas inscrits à une session et qui sont disponibles pour l\'heure choisie',
    'MursionPortal.LearnerSimSelector.Sim.Unavailable.ToolTip': 'Les SIM certifiés qui n\'ont aucune disponibilité dans le système et qui ne sont pas réservés pour une autre session à l\'heure choisie',
    'Settings.Config.Client.VideoRecordingDataRetentionPolicy': 'Afficher la configuration de la politique de conservation des données d\'enregistrement vidéo',
    'MursionPortal.DemandBasedScheduling.BackButton.BackToScenario': 'Retour au scénario',
    'MursionPortal.DemandBasedScheduling.BackButton.BackToCalendar': 'Retour au calendrier',
    'SessionReports.VideoRecordingDeletedDueToDataRetentionPolicy': 'Cette vidéo n\’est pas disponible en raison de la politique de conservation des données de votre entreprise.',
    'SessionReports.VideoRecordingAvailabilityMessage': 'Cette vidéo sera disponible jusqu\’au {videoAvailabilityDate}',
    'SessionReports.SessionOrLearnerStatusError': 'Aucune vidéo n\’a été enregistrée pour cette session en raison d\’une erreur technique.',
    'Clients.ImportUsers.NextStepButton': 'Prochaine étape',
    'Clients.ImportUsers.CancelButton': 'Annuler',
    'Clients.ImportUsers.UploadValidateButton': 'Télécharger + Valider',
    'Session.Table.Column.Attendance': 'Présence',
    'Session.Table.Column.Value.CompleteAttendance': 'Présence complète',
    'Session.Table.Column.Value.AttendanceComplete': 'Présence terminée',
    'Session.Table.Column.Value.NotApplicable': '(Sans objet)',
    'Session.Table.Column.Survey': 'Enquête',
    'Session.Table.Column.Value.CompleteGoogleForm': 'Remplissez le formulaire Google',
    'Session.Table.Column.Value.CompleteSurvey': 'Questionnaire complet',
    'Session.Table.Column.Value.SurveyComplete': 'Questionnaire terminé',
    'Projects.Modal.Text.LearnerSelected': 'Au moins l\’apprenant {maxLearners} doit être sélectionné',
    'Session.Table.Column.Value.SessionNotCompleted': 'La session n\’est pas terminée',
    'MursionPortal.Project.InviteToSchedule': 'Inviter à programmer',
    'MursionPortal.Project.InvitedToday': 'Invité aujourd\’hui',
    'MursionPortal.Project.YourInvitationsText': 'Vos invitations seront envoyées du jour au lendemain.',
    'MursionPortal.Project.InviteToSchedule.Tooltip': 'Des invitations sont déjà envoyées pour le scénario. Vous pourrez à nouveau envoyer des invitations demain.',
    'MursionPortal.Project.InvitedToday.Tooltip': 'Les apprenants qui n\’ont pas planifié ou annulé/manqué ce scénario recevront une invitation par e-mail pour planifier.',
    'MursionPortal.Project.InviteMessage.SentDate': 'Derniers envoyés {sentDate}',
    'ITManager.TechRestriction.EditDialog.ProviderErrorMsg': 'Veuillez sélectionner le fournisseur',
    'ITManager.TechRestriction.EditDialog.GlobalRestriction': 'Restriction globale',
    'ITManager.TechRestriction.EditDialog.HolidayRestriction': 'Restriction de vacances',
    'ITManager.TechRestriction.Table.Provider': 'Fournisseurs',
    'Session.Table.Column.Survey.HoverText': 'Si cette option est activée, les SIM recevront un lien pour compléter les retours d\'expérience sur l\'apprenant',
    'MursionPortal.Project.InviteMessage.InvitedBy': 'Invité par {user} - {role}',
    'MursionPortal.Project.LearnersNotYetScheduled': '{learners} les apprenants doivent encore planifier',
    'Session.Table.Column.Value.AttendanceRequired': 'Présence requise',
    'MursionPortal.Button.Schedule.Another': 'Planifier un autre',
    'MursionPortal.EulaAgreement.CodeOfConduct': 'Chaque simulation de Mursion implique un seul spécialiste de la simulation en direct qui assure le dialogue oral pour un maximum de cinq avatars virtuels dans une session d\'apprentissage. Le travail du spécialiste de la simulation consiste à présenter des défis de conversation pour permettre aux apprenants, comme vous, de pratiquer les compétences humaines complexes dans chaque scénario. Chez Mursion, nous reconnaissons l\'importance de créer un espace sûr où les gens peuvent courageusement pratiquer des conversations potentiellement stressantes, nuancées et complexes. Pour que les apprenants et le spécialiste de la simulation puissent participer en toute confiance, nous demandons aux individus des deux groupes de s\'engager à respecter un code de conduite de base.',
    'MursionPortal.EulaAgreement.CodeOfConduct.Header': 'Aperçu de la méthodologie et du code de conduite',
    'MursionPortal.EulaAgreement.UserAgreement.Header': 'Conditions d\’utilisation',
    'MursionPortal.Import.BackToUser': 'Retour à l\’utilisateur',
    'MursionPortal.Import.BackToTeam': 'Retour à l\’équipe',
    'Mursion.Portal.Edit.Team.Upload.AccurateUploading': 'Assurez-vous que vos données sont exactes avant de les télécharger.',
    'Mursion.Portal.Edit.Team.Upload.AskedReUpload': 'S\’il y a des erreurs dans votre fichier, elles seront affichées dans une feuille de calcul résultante à l\’étape 4. Suivez les instructions de l\’étape 4 pour corriger les erreurs dans le modèle d\’origine et télécharger à nouveau le fichier.',
    'Mursion.Portal.Edit.Team.Upload.IncludeNewUser': 'Vous pouvez également inclure de nouveaux utilisateurs à importer avec les utilisateurs défaillants lors du nouveau téléchargement.',
    'Mursion.Portal.Edit.Team.ReadyToUpload': 'Lorsque votre fichier est prêt, cliquez sur Télécharger + Valider',
    'Mursion.Portal.Edit.Team.UploadAndValidationFile': 'Téléchargement + Validation du fichier',
    'Mursion.Portal.Edit.Team.TeamAssignmentsUpdateSuccessfully': 'Affectations d\’équipe mises à jour avec succès',
    'Mursion.Portal.Edit.Team.UploadSuccessTitle': '{userCount} Utilisateurs importés!',
    'Mursion.Portal.Edit.Team.UploadFailureTitle': 'Erreur!',
    'Mursion.Portal.Edit.Team.UploadSuccess': 'Tous les utilisateurs ont été ajoutés avec succès au portail.',
    'Mursion.Portal.Edit.Team.DoneButton': 'Terminé',
    'Mursion.Portal.Edit.Team.DownloadSuccessful': 'Téléchargement réussi - Passez à l\’étape suivante',
    'Mursion.Portal.Edit.Team.DownloadError': 'Erreur de téléchargement - Veuillez réessayer',
    'Mursion.Portal.Edit.Team.UploadFileAgain': 'Télécharger à nouveau le fichier',
    'Mursion.Portal.Edit.Team.ContinueWithoutCorrecting': 'Continuer sans corriger les erreurs',
    'Mursion.Portal.Edit.Team.SuccessfulImportTitle': 'Importation réussie',
    'Mursion.Portal.Edit.Team.UnSuccessfulImportTitle': 'Échec de l\’importation',
    'Mursion.Portal.Import.User.HeaderTitle': 'Importer des utilisateurs',
    'Mursion.Portal.Import.User.Upload.AskedReUpload': 'S\’il y a des erreurs dans votre fichier, elles seront affichées dans une feuille de calcul résultante à l\’étape 4. Suivez les instructions de l\’étape 4 pour corriger les erreurs dans le <strong>original template</strong> et télécharger à nouveau le fichier.',
    'Mursion.Portal.Import.User.ReadyToUpload': 'Lorsque votre fichier est prêt et correctement formaté, veuillez télécharger le fichier pour validation',
    'Mursion.Portal.Import.User.ValidatingYourDataUntilTheUpload': 'Nous validons les données et importons les utilisateurs. Veuillez vérifier dans les 15 minutes ou lorsque vous recevez une alerte indiquant que ce processus est terminé.',
    'Mursion.Portal.Import.User.UploadSuccessTitle': '{successCount} Utilisateurs importés!',
    'Mursion.Portal.Import.User.ErrorContinueTitle': 'Continuez sans corriger les erreurs!',
    'Mursion.Portal.Import.User.ErrorContinue': '{failedCount} users failed to import as the errors were not fixed.', // TODO add translations
    'Settings.ScenarioBank.Form.ArtBundlesWarning': 'Veuillez renommer le(s) lot(s) d\’art à partir des noms par défaut',
    'Mursion.Portal.Import.User.Instruction.Upload': 'Cliquez sur <strong>Télécharger le modèle Excel</strong> ci-dessous et ouvrez le fichier téléchargé (<strong>client_internal_users.xlsx</strong> ou <strong>client_external_users.xlsx</strong>).',
    'Mursion.Portal.Import.User.Instruction.Format': '<strong>Dans le modèle, les champs e-mail, rôle et ID d\’authentification unique sont obligatoires – Ne les laissez pas vides. Ajoutez correctement les informations utilisateur sous chaque en-tête.</strong> NE MODIFIEZ PAS les en-têtes, la structure du modèle ou le nom de fichier, car cela pourrait entraîner un échec de l\’importation.',
    'Mursion.Portal.Import.User.Instruction.NewUser': 'Dans <strong>Columns J-S or F-O</strong>, ajoutez les noms des équipes auxquelles l\’utilisateur doit être affecté (assurez-vous que votre orthographe correspond exactement au nom).',
    'Mursion.Portal.Import.User.Instruction.Error': 'Une fois que vous avez entré tous vos utilisateurs, enregistrez le fichier sur votre bureau ou dans un endroit dont vous vous souviendrez et cliquez sur <strong>Continuer</strong>.',
    'Mursion.Portal.Import.User.Instruction.OriginalTemplate': 'S\’il y a des erreurs dans votre fichier, elles seront affichées dans une feuille de calcul résultante à l\’étape 4. Suivez les instructions pour corriger les erreurs dans le <strong>modèle d\’origine</strong> et télécharger à nouveau le fichier.',
    'Mursion.Portal.Import.User.Instruction.UploadList': 'Si vous choisissez de continuer sans corriger les erreurs, le fichier <strong>de liste de téléchargement ayant échoué au téléchargement</strong> ne sera disponible que pendant 7 jours.',
    'Mursion.Portal.Import.User.DownloadExcelTemplate': 'Télécharger le modèle Excel',
    'Mursion.Portal.Import.User.UploadFileStatus': 'Téléchargement du fichier',
    'Mursion.Portal.Import.User.DoNotCloseUntilUpload': 'Nous validons les données et importons les utilisateurs. Ce processus se poursuivra en arrière-plan, de sorte que vous pouvez accéder à un autre onglet ou fenêtre et revenir à cet écran à tout moment. Ce processus d\’importation devrait prendre moins de 5 minutes.',
    'Mursion.Portal.Import.User.FileStatus.validation': 'Importation d\’utilisateurs',
    'Mursion.Portal.Import.User.CorrectErrorSubHeading': 'Dernière étape : Corriger les erreurs',
    'Mursion.Portal.Import.User.SuccessCount': '{successCount} users were successfully imported; {failedCount} users failed to import – please review.', // TODO add translations
    'Mursion.Portal.Import.User.DownloadFile': 'Click the <strong>Download Errors</strong> button and open the file to view the errors.', // TODO add translations
    'Mursion.Portal.Import.User.FileTabs': '<strong>Dans le modèle, les champs d\’e-mail et de rôle sont obligatoires – Ne les laissez pas vides</strong>. NE MODIFIEZ PAS les en-têtes, la structure du modèle ou le nom de fichier, car cela pourrait entraîner un échec de l\’importation.',
    'Mursion.Portal.Import.User.ReviewCorrect': 'Corrigez les erreurs dans <strong>original template</strong> (either <strong>client_internal_users.xlsx</strong> ou <strong>client_external_users.xlsx</strong>) et retéléchargez le fichier.',
    'Mursion.Portal.Import.User.DownloadErrorButton': 'Erreurs de téléchargement',
    'Mursion.Portal.Edit.Team.EditTeamTitle': 'Modifier les adhésions d\’équipe',
    'Mursion.Portal.Edit.Team.Instruction.UpdateFile': 'Ajoutez correctement les informations utilisateur sous chaque en-tête. Dans les colonnes L à U, ajoutez les noms des équipes auxquelles l\’apprenant doit être affecté (c\’est-à-dire les équipes à ajouter ou à mettre à jour), une colonne par équipe. Il est acceptable de laisser certaines colonnes d\’équipe vides si nécessaire. Veuillez vous assurer que cela est fait correctement, car cela écrasera les affectations d\’équipe existantes.',
    'Mursion.Portal.Edit.Team.Instruction.Process': 'Téléchargez un fichier à la fois - limitez le nombre à 5 000 utilisateurs par fichier.',
    'Mursion.Portal.Edit.Team.Instruction.ProfileFields': '<strong>Les champs d\’e-mail et de rôle sont obligatoires – Ne les laissez pas vides.</strong> Ne modifiez pas les en-têtes, la structure du modèle ou le nom de fichier, car cela pourrait entraîner un échec de l\’importation.',
    'Mursion.Portal.Edit.Team.Instruction.ErrorOccur': 'Les formats de fichiers pris en charge sont uniquement .xlsx.',
    'Mursion.Portal.Edit.Team.DoNotCloseUntilUpload': 'Nous validons les données et mettons à jour les affectations d\’équipe. Ce processus se poursuivra en arrière-plan, de sorte que vous pouvez accéder à un autre onglet ou fenêtre et revenir à cet écran à tout moment. Ce processus devrait prendre moins de 5 minutes.',
    'Mursion.Portal.Edit.Team.FileStatus.validation': 'Mise à jour des adhésions à l\’équipe',
    'Mursion.Portal.Edit.Team.UploadFailureNotification': 'Votre fichier contient plus de 5 000 utilisateurs. Veuillez contacter l\’assistance ou télécharger plusieurs fichiers avec des utilisateurs de moins de 5k.',
    'Mursion.Portal.Edit.Team.ReviewCorrect': '<strong>Dans le modèle, les champs d\’e-mail et de rôle sont obligatoires – Ne les laissez pas vides.</strong> NE MODIFIEZ PAS les en-têtes, la structure du modèle ou le nom de fichier, car cela pourrait entraîner un échec de l\’importation.',
    'Mursion.Portal.Edit.Team.DownloadFile': 'Corrigez les erreurs dans le fichier de modèle d\’origine (<strong>edit_learner_team_memberships.xlsx</strong>) - NE MODIFIEZ PAS les en-têtes, la structure du modèle ou le nom de fichier, car cela pourrait entraîner un échec de l\’importation.',
    'Mursion.Settings.ScenarioBank.Modal.ChecklistURL': 'URL de la liste de vérification SIM',
    'Mursion.Settings.ScenarioBank.Modal.MustBeAValidURL': 'Doit être une URL valide',
    'MursionPortal.Label.SpeakingIsZero': 'est de 0 %',
    'MursionPortal.Label.InterruptionIsZero': 'qui est de 0%',
    'MursionPortal.CodeOfConduct.SchedulingFlow.Text': 'En choisissant de planifier votre session Mursion, vous acceptez le modèle de livraison de simulation de Mursion et confirmez que vous respecterez et respecterez le Code de conduite Mursion. Pour en savoir plus<code>, cliquez ici</code>.',
    'Mursion.Portal.Import.User.InvalidFile': 'Seul le modèle csv ou Excel sera téléchargé.',
    'Mursion.Portal.Import.User.MaxFileSize': 'La taille du fichier ne doit pas dépasser 5 Mo',
    'Mursion.Portal.Import.User.Tooltip': 'Cliquez ici pour importer de nouveaux utilisateurs ou mettre à jour les utilisateurs existants à partir d\’un fichier d\’exportation.',
    'Mursion.Portal.Edit.Team.Tooltip': 'Cliquez ici pour attribuer ou réattribuer l\’adhésion à l\’équipe en bloc.',
    'MursionPortal.Project.InviteLearnersToSchedule': 'Inviter les apprenants à planifier',
    'MursionPortal.CheckboxLabel.SchedulingByBookIt': 'Planification par BookIt',
    'Dashboard.Report.SendReport': 'Envoyer un rapport',
    'Dashboard.Report.ScheduleReport': 'Rapport de planification',
    'Dashboard.Report.EditSchedule': 'Modifier le programme',
    'Dashboard.Report.ViewSchedule': 'Voir le programme',
    'Dashboard.Report.DeleteSchedule': 'Supprimer le programme',
    'Dashboard.Report.Filter.Title': 'Filtres sélectionnés',
    'Dashboard.Report.SelectReportFrequency.Label': 'Sélectionnez la fréquence du rapport',
    'Dashboard.Report.SelectSessions.Label': 'Sélectionnez les sessions à inclure',
    'Dashboard.Report.SelectReportStartDate.Label': 'Sélectionnez la date de début du rapport',
    'Dashboard.Report.SelectReportStartDate.Tooltip': 'Si vous avez choisi de recevoir des rapports chaque semaine, votre rapport sera publié chaque semaine le jour que vous avez sélectionné. Si vous avez sélectionné chaque mois, il viendra à cette date tous les mois.',
    'Dashboard.Report.SelectAccountOwner.Label': 'Sélectionnez le propriétaire du compte ou l\’animateur',
    'Dashboard.Report.Dropdown.Select.Placeholder': 'Sélectionner',
    'Dashboard.Report.ManuallyAddRecipients.Label': 'Ajouter manuellement des destinataires',
    'Dashboard.Report.ManuallyAddRecipients.Label.Placeholder': 'Ajouter un e-mail ici',
    'Dashboard.Report.RecipientsBox.Label': 'Destinataires',
    'Dashboard.Report.RecipientsBox.RecipientMaxLimit': 'Les destinataires ne peuvent pas dépasser 30',
    'Dashboard.Report.SendReport.ConfirmationModal.Text': 'Le rapport a été envoyé aux destinataires.',
    'Dashboard.Report.ScheduleReport.ConfirmationModal.HeadingText': 'Votre rapport a été programmé!',
    'Dashboard.Report.ScheduleReport.ConfirmationModal.Text': 'Arrêtez ou modifiez-le à tout moment en cliquant sur <strong>Modifier la planification.</strong>',
    'Dashboard.Report.EditSchedule.ConfirmationModal.HeadingText': 'Les modifications ont été enregistrées!',
    'Dashboard.Report.EditSchedule.ConfirmationModal.Text': 'Arrêtez les rapports à tout moment en cliquant sur <strong>Modifier la planification.</strong>',
    'Dashboard.Report.DeleteSchedule.ConfirmationModal.Text': 'Le programme\' a été supprimé',
    'Dashboard.Report.DeleteSchedule.ConfirmationModal.ConfirmationText': 'Êtes-vous sûr de vouloir supprimer le programme de ce rapport ?',
    'Dashboard.Report.ConfirmationModal.Button': 'J\’ai compris!',
    'Dashboard.Report.DeleteSchedule.ConfirmationButton': 'Oui, Supprimer',
    'Dashboard.Report.EditSchedule.EditButton': 'Enregistrer les modifications de planification',
    'Dashboard.Report.ReportFrequencyOption.Daily': 'QUOTIDIEN',
    'Dashboard.Report.ReportFrequencyOption.Weekly': 'HEBDOMADAIRE',
    'Dashboard.Report.ReportFrequencyOption.Monthly': 'MENSUEL',
    'Dashboard.Report.ReportSessionOption.PreviousDay': 'Sessions De la veille',
    'Dashboard.Report.ReportSessionOption.Previous7Days': 'Sessions 7 jours précédents',
    'Dashboard.Report.ReportSessionOption.CurrentSessions': 'Sessions du jour en cours',
    'Dashboard.Report.ReportSessionOption.Next7Days': 'Sessions des 7 prochains jours',
    'Dashboard.Report.ReportSessionOption.MonthToDate': 'Sessions Mois à ce jour',
    'MursionPortal.Dashboard.UpcomingSession': 'Session à venir',
    'Dashboard.Report.ReportSessionOption.PreviousFullMonth': 'Sessions Mois complet précédents',
    'Dashboard.Report.ReportSessionOption.Previous30Days': 'Sessions 30 jours précédents',
    'Projects.ProjectInfo.CheckBox.ScenarioSequencing': 'Séquençage de scénarios',
    'Projects.ProjectInfo.CheckBox.ScenarioSequencing.Text': 'Cliquez sur le bouton pour afficher et configurer l\’ordre des scénarios dans ce projet.',
    'Projects.ProjectInfo.Button.ScenarioSequence': 'Séquence de scénarios',
    'MursionPortal.Projects.DragAndDropModal.Text': 'Scénarios glisser-déposer par ordre de préférence',
    'MursionPortal.Projects.DragAndDropModal.DragHandlerImage': 'Faites glisser l\’image de la poignée',
    'MursionPortal.Projects.DragAndDropModal.ModalCloseIcon': 'Fermer l\’icône',
    'MursionPortal.Project.Scheduled.NextDate': 'Planifié {nextDate}',
    'MursionPortal.Project.Sequencing': 'Séquençage',
    'MursionPortal.Project.Checkbox.Sequencing': 'Activer le séquençage',
    'MursionPortal.Dashboard.Join.Tooltip': 'Une fois cette session disponible, vous pourrez la rejoindre',
    'MursionPortal.Project.InviteToScheduleProject.Tooltip': 'Les apprenants qui n\’ont pas planifié ou annulé/manqué des scénarios dans ce projet recevront une invitation par e-mail à planifier.',
    'MursionPortal.Projects.DragAndDropModal.KeyboardShortcuts': 'Raccourcis clavier',
    'MursionPortal.Projects.DragAndDropModal.KeyboardShortcuts.SpaceBar': 'Touche de la barre d\’espace',
    'MursionPortal.Projects.DragAndDropModal.KeyboardShortcuts.SpaceBar.Text': 'Sélectionnez ou supprimez le scénario.',
    'MursionPortal.Projects.DragAndDropModal.KeyboardShortcuts.ArrowKeys': 'Touches fléchées droite et gauche ou touches fléchées haut et bas',
    'MursionPortal.Projects.DragAndDropModal.KeyboardShortcuts.ArrowKeys.Text': 'Faites glisser le scénario vers la gauche et la droite.',
    'MursionPortal.Projects.DragAndDropModal.KeyboardShortcuts.Esc': 'Touche Échap',
    'MursionPortal.Esc': 'Esc',
    'MursionPortal.Projects.DragAndDropModal.KeyboardShortcuts.Esc.Text': 'Fermez la séquence de scénario modale',
    'MursionPortal.Projects.DragAndDropModal.KeyboardShortcuts.Enter': 'Entrez la clé',
    'MursionPortal.Projects.DragAndDropModal.KeyboardShortcuts.Enter.Text': 'Fermez l\’info-bulle des raccourcis clavier',
    'MursionPortal.Projects.DragAndDropModal.Tooltip': 'Ouvrez l\’info-bulle des raccourcis clavier',
    'Dashboard.Report.DuplicateEntry.ErrorMessage': 'La saisie en double n\’est pas autorisée',
    'Projects.ProjectInfo.EnableSessionTimeStep': 'Activer l\'étape de temps de session',
    'Projects.ProjectInfo.SessionTimeStepInfo': 'L\’étape de temps de session au niveau du licencié sera utilisée si la valeur de l\’étape de session n\’est pas définie au niveau du projet',
    'Session.Edit.Modal.JoinSimulation': 'Rejoindre la session',
    'MursionPortal.Selector.RequestStatus': 'Statut de la demande',
    'MursionPortal.Dashboard.RetakeSimulation': 'Simulation de reprise',
    'MursionPortal.Dashboard.NextSimulationAssigned': 'Simulation suivante assignée',
    'MursionPortal.AriaLabel.SelectTime': 'Sélectionnez l\’heure',
    'Dashboard.Report.Frequency.Date.Select.Placeholder': 'Sélectionnez la Date de début',
    'MursionPortal.InstructoScenarioTeamMapping.Header': 'Sélectionnez le scénario et l\’équipe que vous souhaitez mapper',
    'MursionPortal.InstructoScenarioTeamMapping.MappingAlreadyExistMessage': 'Ce scénario et cette équipe ont déjà été mappés à un contexte différent.',
    'MursionPortal.DataSummaryPoints.manualFulFilled': 'Rempli manuellement',
    'MursionPortal.DataSummaryPoints.pendingRequest': 'En attente basé sur la demande',
    'MursionPortal.DataSummaryPoints.declined': 'Demandes refusées',
    'MursionPortal.DataSummaryPoints.avgFulfilled': 'Remplissage moyen pour manuel',
    'MursionPortal.DataSummaryPoints.totalSwap': 'Total des échanges',
    'MursionPortal.DataSummaryPoints.swapAccepted': 'Échanges acceptés',
    'MursionPortal.Dashboard.MissedSimulations': 'Simulations manquées',
    'MursionPortal.Dashboard.RescheduledSimulations': 'Simulations reprogrammées',
    'MursionPortal.Dashboard.BookedSimulations': 'Simulations réservées',
    'MursionPortal.Dashboard.CancelledSimulations': 'Simulations annulées',
    'Dashboard.UserTable.Column.In': 'Dans',
    'Projects.ProjectInfo.ConfirmationDialog.ScenarioSequencing.Text': 'Voulez-vous vraiment désactiver le séquençage ? Cela supprimerait également la numérotation des séquences dans chaque scénario.',
    'Projects.ProjectInfo.Disable.Sequencing': 'Désactiver le séquençage',
    'MursionPortal.ErrorMessage.TechnicalIssueMsg': 'Veuillez réessayer. Nous avons rencontré un problème technique.',
    'SessionFlow.Text.Event.DeletedByUsername': 'Supprimé par {user}',
    'Settings.ScenarioBank.Modal.timestepShouldBeAtLeast': 'Décochez "Activer le pas de temps de session" ou entrez la valeur du pas de temps au moins {minutes} minutes',
    'MursionPortal.AriaImage.Attribute.Previous': 'Précédent',
    'MursionPortal.AriaImage.Attribute.Notify': 'Notifier',
    'MursionPortal.AriaImage.Attribute.Upload': 'Télécharger',
    'MursionPortal.Report.FilterHeading.SoftwareVersions': 'Versions du logiciel',
    'MursionPortal.Report.FilterHeading.DeliveryModes': 'Modes de livraison',
    'MursionPortal.Report.FilterHeading.LearnerSessionStatuses': 'Statuts des sessions de l\’apprenant',
    'MursionPortal.Report.FilterHeading.LearnerStatuses': 'Statuts de l\’apprenant',
    'MursionPortal.Report.FilterHeading.SimulationStatus': 'État de la simulation',
    'MursionPortal.Project.DuplicateProjectBtnTooltip': 'Cela dupliquerait tous les paramètres et la configuration de ce projet vers un nouveau projet',
    'MursionPortal.Project.DuplicateProjectBtnText': 'Dupliquer vers Magic Project',
    'MursionPortal.ScenarioInfo.Label.Until': 'Vous avez jusqu\’au {date} pour prendre cela jusqu\’à {completionRate} fois.',
    'Clients.Modal.Button.Schedule.Tooltip.Text': 'Vous devez d\’abord planifier le scénario précédent',
    'MursionPortal.Scenario.Name': 'Nom du scénario',
    'MursionPortal.ScenarioCard.BackToProjects.Button': 'Retour à Projets',
    'MursionPortal.ScenarioCard.ViewMore.Button': 'Voir plus',
    'MursionPortal.ScenarioInfo.Documents': 'Documents de scénario',
    'MursionPortal.ScenarioInfo.Documents.File': 'Fichier {extension}',
    'MursionPortal.ScenarioCard.ViewSessions': 'Voir les sessions',
    'MursionPortal.Label.ScenarioPreview': 'Aperçu du scénario',
    'MursionPortal.ScenarioInfo.AssignedTeams': 'Rechercher ou sélectionner Équipe(s)',
    'MursionPortal.ScenarioInfo.CertifiedSIMSpecialists': 'Spécialistes SIM certifiés',
    'MursionPortal.ScenarioInfo.AddSimSpecialist': 'Ajouter spécialiste Sim',
    'MursionPortal.ScenarioInfo.CertifiedSIMSpecialist.PencilIcon': 'Modifier l\’icône',
    'MursionPortal.ScenarioInfo.CertifiedSIMSpecialist.DeleteButton': 'Supprimer SIM',
    'MursionPortal.ScenarioInfo.CertifiedSIMSpecialist.SearchText': 'Rechercher ou sélectionner un ou plusieurs spécialistes SIM',
    'MursionPortal.ScenarioInfo.CertifiedSIMSpecialist.AddSim': 'Ajouter Sim',
    'MursionPortal.Label.InactiveSIMSpecialists': 'Spécialistes SIM inactifs',
    'MursionPortal.Label.Search.InactiveSIMSpecialists': 'Rechercher ou sélectionner un ou plusieurs spécialistes SIM inactifs',
    'MursionPortal.Project.InviteToSchedule.Sequencing.Tooltip': 'Le séquençage est défini pour ce scénario et aucun apprenant n\’a encore planifié le scénario précédent.',
    'Clients.Modal.Label.FacilitatorView.Tooltip': 'S\’il est défini sur Niveau client, les animateurs pourront voir les détails du niveau propriétaire du compte. S\’il est défini au niveau de l\’équipe, l\’animateur ne peut voir que les informations sur les équipes auxquelles il est affecté.',
    'Clients.Modal.Label.VoiceAnalytics.Tooltip': 'Fournit aux apprenants une analyse automatisée de leur session.',
    'Clients.Modal.Label.Show.Utilization.Reporting.Tooltip': 'Ajoute des rapports d\’utilisation au tableau de bord du propriétaire du compte.',
    'Clients.Modal.Label.VideoRecordingDataRetentionPolicy.Tooltip': 'Activez si le client a une politique de rétention des données personnalisée.',
    'MursionPortal.Label.SoftwareType.Tooltip': '"Web uniquement" indique que les apprenants ne peuvent accéder à leurs sessions que via Mursion Magic, la plate-forme basée sur un navigateur.',
    'Projects.ProjectInfo.Label.Timeline.Tooltip': 'Toutes les sessions de ce projet doivent être terminées dans le cadre de la chronologie du projet.',
    'Projects.ProjectInfo.Checkbox.AllowSessionRecording.Tooltip': 'Décochez cette case pour empêcher les enregistrements.',
    'MursionPortal.Checkbox.AllowLearnerToRefuseRecording.Tooltip': 'Les apprenants peuvent refuser l\’enregistrement. Si cette option n\’est pas cochée et qu\’un apprenant refuse l\’autorisation d\’enregistrement, il ne pourra pas assister à la session.',
    'Projects.ProjectInfo.label.Tags.Tooltip': 'Les tags sont des regroupements organisationnels permettant de trouver rapidement des projets similaires au niveau du client.',
    'Projects.ProjectInfo.label.DemandTime.Tooltip': 'Délai dans lequel les apprenants peuvent demander des simulations pour les créneaux de demande',
    'Projects.ProjectInfo.label.DemandSlots.Tooltip': 'Définit le nombre d\’options de planification qu\’un apprenant voit lors de la planification. S\’il est mis à zéro, l\'apprenant pourra réserver instantanément un créneau horaire ouvert.',
    'Projects.ProjectInfo.label.Rescheduling.Tooltip': 'Permet aux apprenants de reprogrammer des sessions. Notez que l\’apprenant ne peut pas réserver une session le même jour à moins que l\’option Le même jour ne soit cochée.',
    'MursionPortal.Project.SameDay.Tooltip': 'Permet aux apprenants de reprogrammer pour plus tard dans la même journée calendaire.',
    'Cleints.Modal.Label.AvailablePrivateSSOLabel': 'Activer la SSO privée:',
    'Cleints.Modal.Label.DomainsLabel': 'Domaine Web client:',
    'Clients.Modal.Label.Logo': 'Logo du client:',
    'Projects.ProjectInfo.Label.CancellationDeadline': 'Délai d\’annulation',
    'Clients.Modal.Label.MursionSocialAttendance.ToolTip': 'Recueillir la présence des apprenants lors des sessions sociales de Mursion.',
    'Clients.Modal.Label.VideoRecordingDataRetentionDays': 'Conservation des données d\'enregistrement vidéo (en jours):',
    'Clients.Modal.Label.VideoRecordingDataRetentionPolicy': 'Politique de conservation des données d\’enregistrement vidéo:',
    'MursionPortal.PasswordRequirementMessage.SpecialCharaters': 'au moins un caractère spécial',
    'MursionPortal.ErrorMessage.InvalidCaptcha': 'Captcha non valide. Veuillez réessayer.',
    'MursionPortal.Project.MissedAlert.TooLateJoin': 'Vous êtes arrivé un peu trop tard pour vous joindre',
    'MursionPortal.Project.MissedAlert.DontWorry': 'Ne vous inquiétez pas ! Cela se produit.',
    'MursionPortal.Project.MissedAlert.ClickReschedule': 'Cliquez sur Replanifier votre simulation.',
    'MursionPortal.Project.EmailMissedLink.TooLateJoin.ClickRescheduleTxt': 'Cliquez ci-dessous pour reprogrammer votre simulation.',
    'MursionPortal.Password.Strength.Tooweak': 'Le mot de passe est trop faible',
    'MursionPortal.Password.Strength.Weak': 'Le mot de passe est faible',
    'MursionPortal.Password.Strength.Medium': 'Le mot de passe est moyen',
    'MursionPortal.Password.Strength.Strong': 'Le mot de passe est robuste',
    'Dashboard.LeftPane.User.SchedulingInfo':'Infos du planning',
    'Dashboard.Button.ExportToEmail':'Exportation de données',
    'Filters.All':'Tous',
    'Filters.More':' plus',
    'Filters.AllExcept':'Tous sauf',
    'Filters.MoreClients':' plus de clients',
    'Filters.AllClients':'Tous les clients',
    'Filters.Role':'Rôle',
    'Filters.TimeZone':'Fuseau horaire',
    'Dashboard.UserActivity.TableHeader':'Données d\'activités d\'utilisateurs',
    'MursionPortal.GenerateLearnerSurveyLink':'Copier l\'hyperlien de l\'enquête',
    'MursionPortal.SurveyLinkLabel':'Votre hyperlien d\'enquête a été copier sur votre presse-papier',
    'MursionPortal.Attention':'Attention!',
    'MursionPortal.LinkCannotBeGenerated':'L\'hyperlien ne peut pas être généré car le champ d\'identification est absent dans le scénario de ce projet.',
    'Dashboard.UserTable.Column.UserName':'Nom',
    'Dashboard.UserTable.Column.DateInvited':'Invité',
    'Dashboard.UserTable.Column.DateRegistered':'Enregistré',
    'Dashboard.UserTable.Column.DateArchived':'Archivé',
    'Dashboard.UserTable.Column.DateLastLogin':'Dernière connexion',
    'Dashboard.UserTable.Column.UsedSoftwareVersions':'Logiciel',
    'Dashboard.UserTable.Column.SessionsScheduled':'Simulations programmées',
    'Dashboard.UserTable.Column.SessionsCompleted':'Simulations complétées',
    'Dashboard.UserTable.Column.SessionsMissed':'Simulations manquées',
    'Dashboard.UserTable.Column.LateCancellations':'Simulations annulées',
    'Dashboard.UserTable.Column.SessionsError':'Erreurs de simulations',
    'Session.Table.Column.ProjectId':'ID du contrat',
    'Session.Edit.Modal.Button.Leave':'Annuler ma session',
    'Users.TableModal.SSOIDPlaceHolder':'ID SSO',
    'Users.Table.Modal.SSOID':'ID SSO',
    'Dashboard.SchedulingInfo.Filter.IndustriesAll':'Industrie',
    'Dashboard.SchedulingInfo.Filter.UsedSoftwareVersion':'Logiciel',
    'Dashboard.SchedulingInfo.MainHeader':'Infos du planning',
    'Dashboard.SchedulingInfo.TableHeader':'Planning des données d\'info',
    'RestorePassword.ResetPassword':'Réinitialiser le mot de passe',
    'RestorePassword.EmailHint':'entrez votre courriel',
    'Dashboard.ProjectUtilization.MainHeader':'Utilisation des projets',
    'Settings.Config.ProjectAndScenario.AllowMursionMagic':'Type de Mursion Magic',
    'Dashboard.SessionLearners.MainHeader':'Session d\'apprenants',
    'MursionPortal.Table.ScrollMessage':'Défiler vers la gauche pour voir des colonnes additionnelles',
    'Dashboard.ProjectsActivity.MainHeader':'Activité du projet¡',
    'MursionPortal.Table.ColumnHeader.ProjectId':'ID du contrat',
    'MursionPortal.Button.ClearAll':'Effacer tout',
    'MursionPortal.Dashboard.Chart.Registration':'Enregistrement d\'utilisateur',
    'MursionPortal.Dashboard.Chart.InviteAll':'Inviter tous',
    'MursionPortal.Dashboard.Chart.Unregistered':'Non enregistré',
    'MursionPortal.Dashboard.Chart.Registered':'Enregistré',
    'MursionPortal.Dashboard.Chart.Login':'Login',
    'MursionPortal.Dashboard.Chart.NotLoggedIn':'Pas connecté',
    'MursionPortal.Dashboard.Chart.LoggedIn':'Connecté',
    'MursionPortal.Dashboard.Chart.Scheduling':'Utilisateurs planifiés',
    'MursionPortal.Dashboard.Chart.NotScheduled':'Non planifié',
    'MursionPortal.Dashboard.Chart.Simulations':'Simulations',
    'MursionPortal.Dashboard.Chart.LateCancellations':'Annulations tardives',
    'MursionPortal.Dashboard.Chart.Completed':'Terminé',
    'MursionPortal.Dashboard.Chart.Errors':'Erreurs',
    'MursionPortal.Dashboard.Chart.Scheduled':'Planifié',
    'MursionPortal.Dashboard.Chart.Tooltip.Registered':'Les utilisateurs qui ont été invités ou les utilisateurs SSO provisionnés et connectés.',
    'MursionPortal.Dashboard.Chart.Tooltip.Unregistered':'Les utilisateurs qui ont été invités, mais qui ne sont pas encore connectés ou les utilisateurs SSO provisionnés et qui ne se sont pas encore connectés.',
    'MursionPortal.Dashboard.Chart.Tooltip.NotScheduled':'Utilisateurs SSO enregistrés/provisionnés qui n’ont pas encore planifié de simulation',
    'MursionPortal.Dashboard.Chart.Tooltip.Scheduled':'Utilisateurs SSO enregistrés/provisionnés qui ont planifié au moins une simulation',
    'MursionPortal.Label.ProjectId':'ID du contrat',
    'MursionPortal.Label.SoftwareType':'Type',
    'MursionPortal.Label.SharingOrDownloading':'N’autorisez pas le partage ou le téléchargement',
    'Settings.SSO.Modal.EmployeeIDClaimName':'Nom de la demande d’identification de l\'employé',
    'Settings.SSO.Modal.LocationClaimName':'Nom de la demande d’emplacement',
    'Settings.SSO.Modal.DepartmentClaimName':'Nom de la demande du département',
    'Settings.SSO.Modal.PhoneClaimName':'Nom de la demande du téléphone',
    'Settings.SSO.Modal.TitleClaimName':'Nom de la demande du titre',
    'Settings.SSO.Modal.TimezoneClaimName':'Nom de la demande de fuseau horaire',
    'Settings.SSO.Modal.LanguageClaimName':'Nom de la demande de la langue',
    'MursionPortal.ProjectId':'ID du contrat',
    'MursionPortal.AltText.MursionPortal':'Portail Mursion',
    'MursionPortal.Message.ClickToOpenApplicationDefault':'Cliquez ci-dessous pour assister à votre simulation',
    'MursionPortal.Message.ClickToOpenApplicationDesktopWeb':'Cliquez sur le bouton ci-dessous pour accéder à votre simulation par l’entremise de l’application de bureau Mursion',
    'MursionPortal.Message.OpenWebApplication':'Vous pouvez également accéder à votre simulation dans Mursion Magic, notre application basée sur le Web',
    'MursionPortal.Button.OpenMursionWeb':'Lancer Mursion Magic',
    'MursionPortal.Message.Or':'OU',
    'MursionPortal.Message.PortalHeadingText':'Simulations de formation EQ',
    'ITManager.TechRestriction.Table.CreateButton':'Ajouter une restriction',
    'ITManager.TechRestriction.EditDialog.TagName':'Nom de la balise',
    'ITManager.TechRestriction.EditDialog.Title':'Éditer la restriction technique',
    'ITManager.TechRestriction.CreateDialog.Title':'Nouvelle restriction technique',
    'MursionPortal.DateTimePicker.StartDate':'Date de début',
    'MursionPortal.DateTimePicker.EndDate':'Date de fin',
    'MursionPortal.DateTimePicker.TimeNotAvailable':'L’heure sélectionnée n’est pas disponible.',
    'ITManager.TechRestriction.ViewDialog.Title':'Afficher la restriction technique’',
    'MursionPortal.DateTimePicker.Timezone':'Fuseau horaire',
    'MursionPortal.Text.Team':'équipe',
    'MursionPortal.Text.Industry':'industrie',
    'MursionPortal.Text.Environment':'environnement',
    'MursionPortal.Text.Avatar':'avatar',
    'MursionPortal.Status.Capitalized.Upcoming':'À VENIR',
    'MursionPortal.Status.Capitalized.Undefined':'NON DÉFINI',
    'MursionPortal.Status.Capitalized.Orphan':'ORPHELIN',
    'MursionPortal.Status.Capitalized.Waif':'ABANDONNÉ',
    'MursionPortal.Status.Capitalized.Reserved':'RÉSERVÉ',
    'MursionPortal.Status.Capitalized.Booked':'ENREGISTRÉ',
    'MursionPortal.Status.Capitalized.Pending':'EN ATTENTE',
    'MursionPortal.Status.Capitalized.Running':'EN COURS',
    'MursionPortal.Status.Capitalized.Missed':'MANQUÉ',
    'MursionPortal.Status.Capitalized.NeedsReview':'BESOIN DE RÉVISION',
    'MursionPortal.Status.Capitalized.Reviewed':'RÉVISÉ',
    'MursionPortal.Status.Capitalized.Cancelled': 'ANNULÉ',
    'MursionPortal.Status.Capitalized.LicenseCancelled':'ANNULÉ',
    'MursionPortal.Status.Capitalized.Error':'ERREUR',
    'MursionPortal.Status.Capitalized.SimError': 'ERROR',// TODO add translations
    'MursionPortal.Status.Capitalized.Offline':'HORS LIGNE',
    'MursionPortal.Status.Abbreviation.PendingNoLearners':'P',
    'MursionPortal.Status.Abbreviation.PendingNoParticipants':'R',
    'MursionPortal.Status.Abbreviation.Swap':'S',
    'MursionPortal.Status.Abbreviation.Prebooked':'PB',
    'MursionPortal.Status.Abbreviation.Booked':'B',
    'MursionPortal.Status.Abbreviation.Active':'A',
    'MursionPortal.Status.Abbreviation.Completed':'C',
    'MursionPortal.Status.Abbreviation.Missed':'M',
    'MursionPortal.Status.Abbreviation.NeedsReview':'NR',
    'MursionPortal.Status.Abbreviation.Error':'E',
    'Settings.SSO.Table.NoSsoConfigurationsFound':'Aucune configuration SSO n’a été trouvée.',
    'MursionPortal.Label.SelfReviewTracker':'SUIVI DE L’AUTO-ÉVALUATION',
    'MursionPortal.ToggleButton.All':'tous',
    'MursionPortal.Label.PerformanceTracker':'SUIVI DES PERFORMANCES',
    'MursionPortal.ToggleButton.TurnTaking':' PRISE DE TOUR',
    'MursionPortal.Tooltip.Collaborative':'Ceci est une mesure du temps pendant lequel vous et l\'avatar ou les avatars avez pris des tours sans vous interrompre pendant la conversation.',
    'MursionPortal.Tooltip.Disruptive':'Ceci est une mesure du temps pendant lequel vous et l’avatar ou les avatars vous avez interrompu au cours de la conversation.',
    'MursionPortal.Tooltip.Speaking':'Ceci est une mesure du temps que vous avez pris pour discutez avec l’avatar ou les avatars pendant la conversation.',
    'MursionPortal.Tooltip.Listening':'Ceci est une mesure du temps que vous avez pris pour écouter l’avatar ou les avatars pendant la conversation.',
    'MursionPortal.Tooltip.MiScore':'Votre efficacité sociale pendant la conversation.',
    'MursionPortal.Tooltip.Percentile':'Inclure une description de ce que cela signifie quelque part dans l\'IU',
    'MursionPortal.Label.MiScore':'Score MI',
    'MursionPortal.Label.Percentile':'centile',
    'MursionPortal.Label.NotEnoughData':'Données insuffisantes',
    'MursionPortal.Label.Listening':'Écouter',
    'MursionPortal.Label.Speaking':'Parler’',
    'MursionPortal.Label.Collaborative':'Collaboratif',
    'MursionPortal.Label.Disruptive':'Disruptif',
    'MursionPortal.ToggleButton.LocalLeaderboard':'Classement local',
    'MursionPortal.ToggleButton.GlobalLeaderboard':'Classement global',
    'MursionPortal.ToggleButton.DeepInsights':'Recommandations profondes',
    'MursionPortal.ErrorMessage.DataBeingProcessed':'Les données d’analyse de session sont en cours de traitement. Les données seront affichées sur la page une fois le traitement terminé.',
    'MursionPortal.ErrorMessage.UnsupportedFormat':'L\'analyse de session est vide ou elle a un format qui n\'est pas supporté.',
    'MursionPortal.Label.Positive':'Positif',
    'MursionPortal.Label.Negative':'Négatif',
    'MursionPortal.Label.Neutral':'Neutre',
    'MursionPortal.Header.Tracking':'Suivi',
    'MursionPortal.Button.ReadMore':'En savoir davantage',
    'MursionPortal.Button.Hide':'Masquer',
    'MursionPortal.Title.Interchange':'Échange. Conversation naturelle et prise de tour sans intervention ni interjection',
    'MursionPortal.Title.InterventionByYou':'Intervention de votre part. Interrompre avec succès une personne qui parle, l’empêchant de continuer',
    'MursionPortal.Title.InterventionByAvatars':'Intervention des avatars. Réussir à interrompre quelqu’un qui parle, l’empêchant de continuer',
    'MursionPortal.Title.InterjectionByYou':'Interjection par vous. Tenter d’interrompre quelqu’un qui parle sans succès',
    'MursionPortal.Title.InterjectionByAvatars':'Interjection par les avatars. Tenter d’interrompre quelqu’un qui parle sans succès',
    'MursionPortal.Title.Pauses':'Pauses. Plus de 0,2 s et moins de 0,75 s',
    'MursionPortal.Label.VoiceSelf':'Sa voix',
    'MursionPortal.Label.VoiceOthers':'Autre voix',
    'MursionPortal.Label.Avatars':'Avatars',
    'MursionPortal.Label.You':'Vous',
    'MursionPortal.ColumnHeader.User':'Utilisateur',
    'MursionPortal.ColumnHeader.Percentile':'Centile',
    'MursionPortal.ColumnHeader.ScenariosCompleted':'Scénarios terminés',
    'MursionPortal.Message.LeaderboardsUpdated':'Les classements seront mis à jour dès que davantage de personnes auront terminé leurs simulations.',
    'MursionPortal.Message.CheckBack':'Merci de bien vérifier votre position au classement de temps à autre !',
    'MursionPortal.Label.SimStart':'Débuter la Sim',
    'MursionPortal.Label.SimEnd':'Fin de la Sim',
    'MursionPortal.Tooltip.Summary.Speaking':'Temps passé à parler à l\'avatar ou aux avatars lors de la conversation entière',
    'MursionPortal.Tooltip.Summary.Listening':'Temps passé à écouter l’avatar ou aux avatars pendant toute la conversation',
    'MursionPortal.Capitalized.NotAvailable':'N/A',
    'MursionPortal.ColumnHeader.Trait':'Trait',
    'MursionPortal.ColumnHeader.Me':'Moi',
    'MursionPortal.ColumnHeader.OthersAvg':'Autres (moy.)',
    'MursionPortal.TooltipText.Speaking':'Vous parlez plus de {percentage} des personnes.',
    'MursionPortal.TooltipText.Listening':'Vous écoutez plus de {percentage} des personnes.',
    'MursionPortal.TooltipText.Collaborative':'Vous êtes plus collaboratif que {percentage} des personnes.',
    'MursionPortal.TooltipText.Disruptive':'Vous êtes plus perturbateur que {percentage} des personnes.',
    'MursionPortal.Label.FeedbackValue':'Valeur de rétroaction',
    'MursionPortal.Button.AnalyticsData':'Données analytiques',
    'MursionPortal.Button.RawAnalyticsData':'Données d’analyse brutes',
    'MursionPortal.Header.SocialEffectiveness':'Efficacité sociale',
    'MursionPortal.Label.Current':'Actuel',
    'MursionPortal.Header.ArticulationRate':'Vitesse d’articulation',
    'MursionPortal.Label.Average':'Moyenne',
    'MursionPortal.Header.Conversation':'Conversation',
    'MursionPortal.Header.SpeakingListening':'Parlant/écoutant',
    'MursionPortal.Header.CumulativeTurnTaking':'Prise de tour cumulé',
    'MursionPortal.Header.InstantaneousTurnTaking':'Prise de tour instantané',
    'MursionPortal.ColumnHeader.ScenarioTemplate':'MODÈLE_SCÉNARIO',
    'MursionPortal.ColumnHeader.Provider':'FOURNISSEURS',
    'MursionPortal.Label.AbbreviatedMinute':'m',
    'MursionPortal.Header.Mursion':'<code>M</code>ursion',
    'MursionPortal.Header.Index':'<code>I</code>ndex',
    'MursionPortal.Header.Score':'Score',
    'MursionPortal.visibilityHidden.Selected':'choisi',
    'MursionPortal.AriaLabel.CloseTechSupport':'fermer le soutien technique',
    'MursionPortal.Monitoring':'Surveillance',
    'MursionPortal.Monitoring.User.FirstLetter':'U',
    'MursionPortal.Monitoring.Peer.Label':'PAIR - PAIR',
    'MursionPortal.Monitoring.Peer.NoUser':'Veuillez sélectionner l’utilisateur pour afficher les détails',
    'MursionPortal.Monitoring.Sessions.Total':'Nombre total de sessions',
    'MursionPortal.Monitoring.Sessions.Displayed':'Sessions ML3 (affichées)',
    'MursionPortal.Monitoring.Sessions.NotDisplayed':'Ce n\'est pas des sessions ML3',
    'MursionPortal.Monitoring.Card.StartTime':'Heure de début',
    'MursionPortal.Monitoring.Notification.NewSession':'Une nouvelle session a été ajoutée.',
    'MursionPortal.ClickRefresh':'Cliquez sur « Actualiser » pour afficher les modifications.',
    'MursionPortal.AriaLabel.DraftScenarioSuperScript':'Scénario d\'ébauche au format exposant {statusConfiguration}',
    'MursionPortal.AriaLabel.FinalizedScenarioSuperScript':'Scénario final en format exposant {statusConfiguration}',
    'MursionPortal.AriaLabel.StartedScenarioSuperScript':'Scénario de départ en format exposant {statusConfiguration}',
    'MursionPortal.AriaLabel.FinishedScenarioSuperScript':'Scénario terminé en format exposant {statusConfiguration}',
    'MursionPortal.AriaLabel.ArchivedScenarioSuperScript':'Scénario archivé en format exposant{statusConfiguration}',
    'Calendar.Button.ScheduleEventON':'Planifier l’événement le {cetteDate}',
    'Calendar.Button.CantScheduleON':'Impossible de planifier l\'événement le {cetteDate}',
    'MursionPortal.AriaLabel.ChooseFileButton':'choisir le bouton d\'un fichier',
    'MursionPortal.Label.VisibilityHidden.FileUploaded':'Fichier téléchargé',
    'MursionPortal.VisibilityHidden.EntityAdded':'Entité ajoutée',
    'MursionPortal.VisibilityHidden.EntityRemoved':'Entité supprimée',
    'MursionPortal.Legend.VisibilityHidden.StationTypeHomeOffice':'Type de station (bureau, domicile)',
    'MursionPortal.Legend.VisibilityHidden.HoursFullTimePartTime':'Heures, (Temps plein, temps partiel)',
    'MursionPortal.AriaLabel.ShowTechSupport':'montrer le soutien technique',
    'MursionPortal.Monitoring.Card.UntilTheEnd':'Temps restant',
    'MursionPortal.Monitoring.Card.OverTime':'Heures supplémentaires',
    'MursionPortal.Title.DemandBasedSessionScheduling':'Planification de session basée sur la demande',
    'MursionPortal.CheckboxLabel.SchedulingByDemand':'Planification par demande',
    'MursionPortal.CheckboxLabel.ProjectTimezone':'Fuseau horaire du projet',
    'MursionPortal.CheckboxLabel.DemandTime':'Temps de demande',
    'MursionPortal.Label.DemandTimeStart':'Début de l’heure de la demande',
    'MursionPortal.Label.DemandTimeEnd':'Heure de fin de la demande',
    'MursionPortal.Label.DemandWindow':'Fenêtre de la demande',
    'MursionPortal.Project.Days':'Jour(s)',
    'MursionPortal.MyProfile.Hover.Profile':'profil {nomd\'utilisateur}',
    'Mursion.Portal.SessionUserStatus.Connected':'CONNECTÉ',
    'Mursion.Portal.SessionUserStatus.Late':'EN RETARD',
    'Mursion.Portal.SessionUserStatus.Immersing':'IMMERSION',
    'Mursion.Portal.SessionUserStatus.Left':'QUITTER',
    'Mursion.Portal.SessionUserStatus.Declined':'REFUSÉ',
    'MursionPortal.DemandBasedScheduling.SubmitPage.ThankYou':'Merci!',
    'MursionPortal.DemandBasedScheduling.SubmitPage.Message':'Nous travaillons à finaliser votre simulation en fonction des créneaux horaires que vous avez fournis. Attendez-vous à recevoir un courriel de notre part avec plus de détails. À bientôt!',
    'MursionPortal.DemandBasedScheduling.SubmitPage.GoToDashboard':'Accédez à Mon tableau de bord',
    'MursionPortal.DemandBasedScheduling.ScheduleASession':'Planifier une session',
    'MursionPortal.DemandBasedScheduling.Details':'DÉTAILS',
    'MursionPortal.DemandBasedScheduling.Slot':'CRÉNEAU {comptage}',
    'MursionPortal.DemandBasedScheduling.PickDateAndTime':'Choisir date + heure à établir',
    'MursionPortal.DemandBasedScheduling.Button.Set':'INITIALISER',
    'MursionPortal.DemandBasedScheduling.Button.Clear':'EFFACER',
    'MursionPortal.DemandBasedScheduling.Jumbotron.Text':'Les détails du scénario seront chargés une fois que vous aurez effectué une sélection',
    'MursionPortal.AriaDescribedby.StartDate':'Date de début : Appuyez sur la touche point d\'interrogation pour obtenir les raccourcis clavier permettant de modifier les dates.',
    'MursionPortal.AriaDescribedby.EndDate':'Date de fin : Appuyez sur la touche point d\'interrogation pour obtenir les raccourcis clavier permettant de modifier les dates.',
    'MursionPortal.VisibilityHidden.Footer':'Pied de page',
    'MursionPortal.VisibilityHidden.Header':'En-tête',
    'MursionPortal.AriaLabel.FullScreen':'plein écran',
    'MursionPortal.AriaLabel.ClientNameEventsDisplayed':'Événements {Nomclient} du client affichés sur le calendrier',
    'MursionPortal.AriaLabel.SideNavigationToggle':'Bouton de navigation latérale',
    'MursionPortal.AriaLabel.AddTags':'Ajouter des balises',
    'MursionPortal.VisibilityHidden.Navigation':'Navigation',
    'MursionPortal.AriaLabel.SortByDescendingOrderButton':'bouton trier par ordre décroissant',
    'MursionPortal.AriaLabel.SortByAscendingOrderButton':'bouton trier par ordre croissant',
    'MursionPortal.VisibilityHidden.ProjectsTabSelected':'Onglet projets sélectionné',
    'MursionPortal.VisibilityHidden.TeamsTabSelected':'Onglet équipes sélectionné',
    'MursionPortal.VisibilityHidden.SessionsTabSelected':'Onglet sessions sélectionné',
    'MursionPortal.VisibilityHidden.UsersTabSelected':'Onglet Utilisateurs sélectionné',
    'MursionPortal.Error.PageNotFound':'Page 404 introuvable',
    'MursionPortal.Text.PageNotFound':'Page introuvable. Vérifiez que vous disposez des autorisations nécessaires pour afficher la page si le lien est correct.',
    'MursionPortal.Text.Success':'Réussite!',
    'MursionPortal.Text.YouAreNowSignedIn':'Vous êtes maintenant connecté au logiciel de simulation Mursion.',
    'MursionPortal.Text.PleaseReturnToDesktop':'Revenez à l\'application de bureau en cliquant sur {sautdeligne} sur l\'icône \’M\’ dans la barre des tâches de votre ordinateur (Windows) ou Dock (Mac).',
    'MursionPortal.Client.EmailSettings.Header.DemandBased':'Basé sur la demande',
    'MursionPortal.Client.EmailSettings.TimeSlotsReceived':'Créneaux horaires reçus',
    'MursionPortal.Label.Past':'Précédant',
    'MursionPortal.Monitoring.SessionsSummary.Preparation':'Préparation',
    'MursionPortal.Monitoring.SessionsSummary.Normal':'Normal',
    'MursionPortal.Monitoring.SessionsSummary.Error':'Erreur',
    'MursionPortal.Monitoring.SessionsSummary.Issue':'Problème',
    'MursionPortal.Monitoring.SessionsSummary.NotLoggedIn':'Pas connecté',
    'MursionPortal.Monitoring.Legend.Label':'Légende',
    'MursionPortal.Monitoring.Legend.Dashboard':'Tableau de bord',
    'MursionPortal.Label.SimSpecialist':'Spécialiste Sim',
    'MursionPortal.Monitoring.SessionsSummary.ConnectedToPeers':'Connecté à des pairs',
    'Requests.Table.CertifiedScenarios':'Scénarios certifiés',
    'Requests.Table.UncertifiedScenarios':'Scénarios non certifiés',
    'Requests.Table.NoUpComingDemands':'Vous n’avez pas de demandes basées sur une demande avec les critères ci-dessus.',
    'Requests.Table.DeclineReason':'Refuser - Sélectionner le motif',
    'Requests.Table.UnAvailable':'Non disponible',
    'Requests.Table.NotPrepared':'Non préparé',
    'Requests.Table.Other':'Autre',
    'Requests.Table.Availability':'Sélectionner la disponibilité',
    'Requests.Table.Declined':'Refusé',
    'Requests.Table.SuccessfullySent':'Demande envoyée avec succès',
    'Requests.Table.RequestCertificate':'Demande de certificat',
    'Requests.Table.DeclineReasonText':'Refuser le motif',
    'Requests.Table.AvailabilitySlot':'Plage de disponibilité',
    'Requests.Table.DemandBased':'Basé sur la demande',
    'MursionPortal.Setting.Integrations':'Intégration',
    'MursionPortal.Setting.Integrations.Title':'Veuillez sélectionner une intégration',
    'MursionPortal.Setting.Integrations.Header.PlatformConfigurations':'Configurations de plate-forme',
    'MursionPortal.Button.AddConfigurations':'Ajouter des configurations',
    'Settings.LTI.PlaceHolder.EnterConfigName':'Entrer le nom de configuration',
    'Settings.LTI.Table.ColumnHeader.DeploymentID':'Identifiant de déploiement',
    'Settings.LTI.Table.ColumnHeader.Version':'Version',
    'Settings.LTI.Table.Caption':'liste de configuration lti',
    'Settings.LTI.Table.Hover.ViewEditLti':'Afficher/modifier Lti',
    'Settings.LTI.Table.NoLtiConfigFound':'Aucune configuration Lti trouvée',
    'MursionPortal.EditLtiModal.Button.DeleteLti':'Supprimer la configuration',
    'MursionPortal.EditLtiModal.Button.SaveLtiConfig':'Sauvegarder la configuration',
    'MursionPortal.Lti.NewLTIConfig':'Nouvelle configuration LTI',
    'MursionPortal.Lti.CreateLTIConfig':'Créer une configuration LTI',
    'Settings.LTI.Modal.PlatformIssuerIdentifier':'Identifiant de l’émetteur de la plate-forme',
    'Settings.LTI.Modal.PublicKeysetEndpoint':'Point d’extrémité du jeu de clés publiques',
    'Settings.LTI.Modal.DeploymentId':'Identifiant de déploiement',
    'Settings.LTI.Modal.RolesMapping':'Assignation des rôles',
    'Settings.LTI.Modal.RolesMapping.PortalRole':'Rôle de portail',
    'Settings.LTI.Modal.RolesMapping.LTIRole':'Rôle LTI',
    'MursionPortal.ErrorMessage.LtiIdMustBeSpecified':'L\'Identifiant LTI doit être spécifié.',
    'MursionPortal.Setting.Integrations.LTIVersion':'Version LTI',
    'MursionPortal.Dialog.CreateLTIConfiguration.SuccessMessage':'La configuration LTI a été créée avec succès.',
    'MursionPortal.DemandBasedScheduling.TimeBlockUnavailableReserved':'Tranche horaire non disponible - réservé pour une autre simulation programmée.',
    'Requests.Table.ViewAvailability':'Afficher la disponibilité',
    'Settings.LTI.Modal.DeletionConfirmationText':'Voulez-vous vraiment supprimer cet assignation des rôles?',
    'Settings.ScenarioBank.Modal.InactiveCertification':'Certification inactive',
    'Settings.ScenarioBank.Modal.NoInactiveSimSpecialist':'Aucun spécialiste sim inactif',
    'Settings.ScenarioBank.Modal.EditInactiveCertification':'Modifier la certification inactive',
    'Settings.ScenarioBank.Modal.NoInactiveCertification':'Aucune certification inactive',
    'MursionPortal.RequestStatus.All':'Statut de la demande : tous',
    'MursionPortal.RequestStatus.Accepted':'Statut de la demande : Accepté',
    'MursionPortal.RequestStatus.Declined':'Statut de la demande : Refusé',
    'MursionPortal.RequestStatus.Pending':'Statut de la demande : En attente',
    'Requests.Table.RequestCertification':'Demande de certification',
    'Requests.Table.RequestTime':'Demande d\'heure',
    'Requests.Table.ScheduleType':'Type d’horaire',
    'Requests.Table.AcceptTime':'Heure d\'acceptation',
    'Requests.Table.Scheduling':'Planification',
    'Requests.Table.FulfilledIn':'Rempli dans',
    'MursionPortal.DemandBasedScheduling.Timezone':'Fuseau horaire',
    'MursionPortal.DemandBasedScheduling.Timezone.Tooltip':'Accédez aux paramètres de votre profil pour modifier le fuseau horaire.',
    'MursionPortal.Results.Found':'{comptage} résultats trouvés',
    'MursionPortal.Header.VisibilityHidden.ClientUsers':'Utilisateurs clients',
    'MursonPortal.SchedulingType.Auto':'AUTO',
    'MursonPortal.SchedulingType.Manual':'MANUEL',
    'MursionPortal.ScheduleTypeSelector.All':'Planification: tout',
    'MursionPortal.ScheduleTypeSelector.Auto':'Planification : Auto',
    'MursionPortal.ScheduleTypeSelector.Manual':'Planification : Manuel',
    'MursionPortal.ScheduleTypeSelector.NA':'Planification: N/A',
    'Integrations.LTI.AppId':'Identifiant de l’application',
    'Integrations.LTI.ConfigurationId':'Identifiant de la configuration',
    'Settings.LTI.ModalHeader.EditLtiConfiguration':'Modifier une configuration LTI',
    'Settings.LTI.Modal.DeletionConfigConfirmationText':'Voulez-vous vraiment supprimer cette configuration LTI?',
    'MursionPortal.Rescheduling.SubmitPage.Message':'Nous vous enverrons un courriel dans un instant avec plus de détails. A bientôt!',
    'MursionPortal.Rescheduling.RescheduleSimulation':'Replannifier la simulation',
    'MursionPortal.Rescheduling.SlotSelectionMessage':'Nous sommes désolés que vous n\'ayez pas pu faire votre simulation. Choisissez un jour et une heure qui vous conviennent!',
    'MursionPortal.IVSBrowserWarning.Safari17.Message1': 'The browser version you are currently using is not supported for your Mursion session',  // TODO: add translations
    'MursionPortal.IVSBrowserWarning.Safari17.Message2': 'When joining your Mursion simulation, use one of these browsers:',  // TODO: add translations
    'MursionPortal.IVSBrowserWarning.Safari17.Firefox': "Firefox",  // TODO: add translations
    'MursionPortal.IVSBrowserWarning.Safari17.Chrome': "Chrome", // TODO: add translations
    'MursionPortal.IVSBrowserWarning.Safari17.Chrome.Subtitle': "Recommended", // TODO: add translations
    'MursionPortal.IVSBrowserWarning.Safari17.Edge': "Edge", // TODO: add translations
    'MursionPortal.Result.Found':'{comptage} résultats trouvés',
    'MursionPortal.Project.Rescheduling':'Replanifier',
    'Session.Edit.Modal.MissedSession':'SESSION MANQUÉE',
    'Session.Edit.Modal.NoLongerRequired':'n’est plus nécessaire',
    'Session.Edit.Modal.TechDifficulties':'éprouve des difficultés techniques',
    'Session.Edit.Modal.Other':'autre',
    'Session.Edit.Modal.CancelBecause':'Oui, annulez la session parce que',
    'Session.Edit.Modal.CancelSimulation':'Annuler la simulation',
    'MursionPortal.UserConsent.Heading':'Autorisations pour l’enregistrement',
    'MursionPortal.UserConsent.Title':'Voulez-vous enregistrer cette session de simulation pour votre propre parcour d’apprentissage?',
    'MursionPortal.UserConsent.Description':'Si vous dites « Je consens », le logiciel Mursion traitera votre simulation en direct et fera un enregistrement vidéo de votre session de simulation pour votre examen sur le portail du logiciel Mursion. Veuillez noter qu’à la demande du client qui a concédé sous licence le logiciel Mursion en votre nom, une copie de votre ou vos séances de simulation enregistrées seront partagées avec un coach, un mentor ou un examinateur sélectionné par le client. Tous les réviseurs sont contractuellement tenus de suivre la <code>politique de confidentialité de Mursion</code>, sauf si vous avez accepté des conditions de confidentialité différentes. Sauf si la loi applicable l’exige, Mursion ne distribuera ni ne divulguera votre enregistrement vidéo sans votre permission. Mursion utilisera les données dépersonnalisés de votre utilisation de son logiciel pour améliorer sa technologie de simulation.',
    'MursionPortal.UserConsent.IConsent':'Je consens',
    'MursionPortal.UserConsent.IDoNotConsent':'Je ne consens pas',
    'MursionPortal.RecordingLinkPermisssion.Title':'Autorisations pour l’enregistrement',
    'MursionPortal.RecordingLinkPermisssion.DescriptionOne':'Voulez-vous recevoir un enregistrement de cette session?',
    'MursionPortal.RecordingLinkPermisssion.DescriptionTwo':'Si c’est le cas, veuillez vérifier votre boîte de réception et votre adresse électronique. Vous recevrez un lien demain s’il est disponible.',
    'MursionPortal.PublicSession.PageTitle':'Entrez vos coordonnées pour rejoindre une simulation',
    'MursionPortal.PublicSession.Form.label.FirstName':'Prénom',
    'MursionPortal.PublicSession.Form.label.LastName':'Nom de famille',
    'MursionPortal.PublicSession.Form.label.Email':'Courriel (facultatif)',
    'PublicSession.Form.Button.Join':'Joindre',
    'MursionPortal.PublicSession.Form.Placeholder.FirstName':'Votre prénom',
    'MursionPortal.PublicSession.Form.Placeholder.LastName':'Votre nom',
    'MursionPortal.PublicSession.Form.Placeholder.Email':'name@provider.com',
    'MursionPortal.PublicSession.Form.Placeholder.HaveAccount':'Vous avez déjà un compte?',
    'MursionPortal.PublicSession.Form.Placeholder.LogInHere':'Connectez-vous ici',
    'Session.Edit.Modal.ToolTip.Google':'Ajouter un événement à votre calendrier Google. Confirmer l’autorisation si nécessaire',
    'Session.Edit.Modal.ToolTip.OutlookIcalOther':'Cliquer pour télécharger l’événement sur votre bureau et l’ajouter à votre application de calendrier',
    'Session.Edit.Modal.SimSpecialistAssigned':'Spécialiste Sim assigné',
    'Session.Edit.Modal.SessionSimSpecialistField.AddSIMSpecialist':'Ajouter un spécialiste SIM',
    'Session.Edit.Modal.DemandBasedTimeSlots':'Créneaux horaires basés sur la demande',
    'Session.Edit.Modal.Reschedule':'Replanifier',
    'Session.Edit.Modal.AddLearner':'Ajouter un apprenant',
    'MursionPortal.Label.SearchOrSelectLearners':'Rechercher ou sélectionner un ou plusieurs apprenants',
    'MursionPortal.Client.EmailSettings.Header.Rescheduling':'Replanifier',
    'MursionPortal.Client.EmailSettings.RescheduleMissedSession':'Replanifier la session manquée',
    'Session.Edit.Modal.OtherPrioritiesAtWork':'autres priorités au travail',
    'MursionPortal.Modal.Header.Sorry':'Désolé!',
    'MursionPortal.Modal.UnschedulableSessionMsg':'La session n’est pas planifiable.',
    'MursionPortal.Rescheduling.Slot.NoLongerAvailable':'L’heure sélectionnée n’est plus disponible, veuillez sélectionner une autre option.',
    'MursionPortal.EmailVerificationPage.Success':'Succès de la vérification du courriel!',
    'MursionPortal.EmailVerificationPage.SuccessDesc':'Votre courriel a été vérifié avec succès',
    'MursionPortal.EmailVerificationPage.Error':'La vérification du courriel a échoué!',
    'MursionPortal.EmailVerificationPage.ErrorDesc':'Quelque chose est arrivé, veuillez réessayer afin de vérifier votre courriel',
    'Settings.Config.Heading.SplashPageAttendance':'PRÉSENCE',
    // 'Clients.Modal.Label.ShowSplashPage':'Présence d’un utilisateur non enregistré/Enregistrement du consentement',
    // 'Clients.Modal.Label.ShowSplashPage.ToolTip':'Recueillir la participation et l’enregistrement du consentement des utilisateurs non enregistrés dans les simulations ML3z/Meet.',
    // 'Settings.Config.Invitation.ShowSplashPage':'Recueillir la participation et l’enregistrement du consentement des utilisateurs non enregistrés dans les simulations ML3z/Meet.',
    'Settings.Config.External.Users':'(Peut être désactivé dans la configuration du client)',
    'Settings.Config.Invitation.ExternalUser':'Pour la présence d’utilisateurs externes',
    'MursionPortal.Scenario.Vignette.ButtonText.ShowMore.SessionDetails':'détails de la session',
    'MursionPortal.DemandBasedScheduling.ProjectDropdown.Placeholder':'Sélectionnez un projet',
    'MursionPortal.DemandBasedScheduling.ScenarioDropdown.Placeholder':'Sélectionnez un scénario',
    'Session.Edit.Modal.Label.SearchOrSelectASimSpecialist':'Recherchez ou sélectionnez un spécialiste SIM',
    'MursionPortal.Button.Exclamation.GotIt':'J’ai compris!',
    'MursionPortal.Modal.UnavailableSlotMsg':'L’heure que vous avez sélectionnée n’est plus disponible. Veuillez choisir un nouveau créneau horaire.',
    'MursionPortal.SimAttendance.NoLongerAvailable':'Vous ne pouvez plus modifier la présence pour cette simulation.',
    'MursionPortal.SimAttendance.Midnight':'Vous devez terminer votre présence avant minuit chaque jour. La présence pour cette simulation sera par défaut l’état du système. Veuillez contacter le support si quelque chose d’anormal s’est produit.',
    'MursionPortal.SimAttendance.Instructions':'Instructions',
    'MursionPortal.SimAttendance.Attendee':'Participant',
    'MursionPortal.SimAttendance.Tooltip.Missed':'L’apprenant ne c\'est jamais connecté à la simulation.',
    'MursionPortal.SimAttendance.Tooltip.Declined':'L’apprenant s’est connecté à la simulation, mais a refusé de participer et est sorti avant d’avoir terminé au moins 50% de la simulation.',
    'MursionPortal.SimAttendance.Add.Attendee':'Ajouter un participant',
    'Session.Edit.Modal.EditAttendance':'Modifier la présence',
    'Session.Edit.Modal.CompleteAttendance':'Présence complète',
    'MursionPortal.SimAttendance.Late':'En retard',
    'MursionPortal.SimAttendance.Left':'Quitter',
    'MursionPortal.Instructions.First':'Sélectionner un statut de présence pour chaque participant (passer la souris sur le statut pour en savoir plus). Si un apprenant n’est pas répertorié, cliquez sur',
    'MursionPortal.Instructions.Second':'la fonction en bas à gauche.',
    'MursionPortal.TableColumn.SimsAction':'Action de Sim',
    'Session.Edit.Modal.Label.SelectTrainer':'Rechercher ou sélectionner un formateur',
    'MursionPortal.Project.DemandSlots':'Demande de créneaux',
    'MursionPortal.CompanyCard.InviteOperations':'Inviter des opérations',
    'MursionPortal.CompanyCard.Modal.Header.NewOperations':'Nouvelles opérations',
    'Settings.Config.Invitation.ShowContractFeature':'Autoriser l’ajout de contrats',
    'Settings.Config.Contract.Description':'(pour le suivi de l’avancement du contrat)',
    'MursionPortal.Contract.Edit':'Modifier',
    'MursionPortal.Contract.ContractName':'Nom du contrat',
    'MursionPortal.Contract.ContractID':'Identifiant du contrat',
    'MursionPortal.Contract.ContractID.PlaceHolder':'Entrez l’Identifiant de contrat à partir de Hubspot',
    'MursionPortal.Contract.StartAndEndDates':'Dates de début et de fin du contrat',
    'MursionPortal.Contract.Notes.PlaceHolder':'Entrez d’autres informations à afficher uniquement pour les CSM et les BDD.',
    'MursionPortal.Contract.LineItem.Add':'Ajouter une ligne d\'article de contrat',
    'MursionPortal.Contract.LineItem.Add.Instruction':'Vous pouvez ajouter plusieurs postes de simulation. Tous les autres types ne peuvent avoir qu’un seul article.',
    'MursionPortal.Contract.LineItemType':'Type d\'article',
    'MursionPortal.Contract.LineItemType.PlaceHolder':'Sélectionner le type d\'article',
    'MursionPortal.Contract.Quantity':'Quantité',
    'MursionPortal.Contract.Quantity.PlaceHolder':'Entrer la quantité',
    'MursionPortal.Contract.DeliveryMode.PlaceHolder':'Sélectionner le mode de livraison',
    'MursionPortal.Contract.SaveContract':'Enregistrer les détails du contrat',
    'MursionPortal.Contract.Length.PlaceHolder':'Sélectionner la longueur',
    'MursionPortal.Contract.SowName.PlaceHolder':'Entrer le nom du contrat',
    'MursionPortal.Client.Tab.Contracts':'Contrats',
    'MursionPortal.Client.Contracts':'Contrats clients',
    'MursionPortal.Client.Create.Contracts':'Créer un contrat',
    'MursionPortal.Client.Contract.ID':'Identifiant du contrat',
    'MursionPortal.NewUser.Optional':' (facultatif)',
    'MursionPortal.NewUser.SuchAGoogleAccountOrOtherSsoProvider.TooltipText':'Par exemple, un compte Google ou un autre fournisseur de SSO',
    'MursionPortal.UserConsentDeclineConfirmation.Heading':'Veuillez confirmer votre choix.',
    // 'MursionPortal.UserConsentDeclineConfirmation.Title':'Êtes-vous certain ? Pour continuer sans enregistrer, cliquer sur le bouton Confirmer. Pour modifier votre consentement à l’enregistrement, cliquer sur le bouton Annuler.',
    'Settings.Config.Invitation.ShowSowFeature':'Autoriser l’ajout de Cahier de Charge',
    'Settings.Config.SOW.Description':'(pour le suivi des progrès des Cahiers de charges)',
    'MursionPortal.ScenarioBank.StandardizedScenario':'Scénario standardisé',
    'MursionPortal.ScenarioBank.ShowOnlyBundleScenario':'Afficher uniquement les scénarios standardisés',
    'Settings.Config.Invitation.SimWorkforceManagement':'Gestion des effectifs SIM',
    'Settings.Config.SimWorkforceManagement.Description':'Activé signifie que le rapport est disponible dans la page du tableau de bord et désactivé signifie que le rapport n’est pas là du tout.',
    'MursionPortal.ScenarioBank.BundleScenario':'Scénarios groupés',
    'MursionPortal.Scheduling.SimAvailable.TimeSlotsTooltipMsg':'Sélectionnez ce créneau horaire pour confirmer automatiquement votre réservation de scénario !',
    'Dashboard.LeftPane.CategoryHeader.SupplyManagement':'Gestion des effectifs de SIM',
    'Dashboard.LeftPane.SupplyManagement.Scheduling':'Planification',
    'Dashboard.SupplyManagement.Scheduling.MainHeader':'Planification',
    'Dashboard.SupplyManagement.Scheduling.TableHeader':'Planification des données',
    'Dashboard.LeftPane.SupplyManagement.ClientRoster':'Liste des clients',
    'Dashboard.SupplyManagement.ClientRoster.MainHeader':'Liste des clients',
    'Dashboard.SupplyManagement.ClientRoster.TableHeader':'Données de la liste des clients',
    'MursionPortal.Table.ColumnHeader.Time':'Temps',
    'MursionPortal.Table.ColumnHeader.AllScheduledSessions':'Toutes les programations (Sessions)',
    'MursionPortal.Table.ColumnHeader.SimsAvailable':'SIMs disponibles (Personnes)',
    'MursionPortal.Table.ColumnHeader.AvailabilityHours':'Disponibilité (Heures)',
    'MursionPortal.Table.ColumnHeader.DemandBasedRequests':'Toutes les demandes basées sur les demandes (Total - les 3 demandes)',
    'MursionPortal.Table.ColumnHeader.SlotOneRequests':'Emplacement 1 des requêtes',
    'MursionPortal.Table.ColumnHeader.SlotTwoRequests':'Emplacement 2 des requêtes',
    'MursionPortal.Table.ColumnHeader.SlotThreeRequests':'Emplacement 3 des requêtes',
    'MursionPortal.Table.ColumnHeader.PendingRequests':'Requêtes en attente (Sessions)',
    'MursionPortal.Table.ColumnHeader.Auto':'Auto',
    'MursionPortal.Table.ColumnHeader.Manual':'Manuel',
    'MursionPortal.Table.ColumnHeader.CapacityIndicator':'Indicateur de capacité',
    'MursionPortal.EmptyMessage.NoSupplyManagementDataYet':'Il n’y a pas encore de données sim sur la gestion des effectifs',
    'MursionPortal.Table.ColumnHeader.SimCertified':'Certifiés SIMs',
    'MursionPortal.Table.ColumnHeader.ScheduledHours':'Planifié (Heures)',
    'MursionPortal.Table.ColumnHeader.Sessions':'Sessions',
    'MursionPortal.Table.ColumnHeader.AvgFullfilled':'Moyenne accomplie en',
    'MursionPortal.Table.ColumnHeader.SlotOneFullFillment':'Emplacement 1 Accomplissement',
    'MursionPortal.Table.ColumnHeader.Cancelled':'Annulé',
    'MursionPortal.Table.ColumnHeader.Missed':'Manqué',
    'MursionPortal.Table.ColumnHeader.Reschedules':'Replanifications',
    'MursionPortal.Table.ColumnHeader.Error':'Erreur',
    'MursionPortal.Table.ColumnHeader.Completed':'Terminé',
    'MursionPortal.Table.ColumnHeader.Other':'Autre',
    'MursionPortal.Table.ColumnHeader.SchedulingWindow':'Fenêtre de planification',
    'MursionPortal.Table.ColumnHeader.RequestDemand':'Nombre de requêtes (demande)',
    'MursionPortal.Table.ColumnHeader.AutoFulfilled':'Accompli automatiquement',
    'MursionPortal.Table.ColumnHeader.Pending':'En attente',
    'MursionPortal.Table.ColumnHeader.SlotTwoFullFillment':'Emplacement 2 Accomplissement',
    'MursionPortal.Table.ColumnHeader.SlotThreeFullFillment':'Emplacement 3 Accomplissement',
    'MursionPortal.Table.ColumnHeader.UnutilizedAvailability':'Disponibilité inutilisée (Heures)',
    'MursionPortal.Table.ColumnHeader.TotalAvailabilityEntered':'Disponibilité totale saisie',
    'MursionPortal.Table.ColumnHeader.AverageHoursWeek':'Nombre moyen d’heures/semaine',
    'MursionPortal.Table.ColumnHeader.AvailabilityUtilization':'Utilisation de la disponibilité',
    'MursionPortal.SupplyManagement.Above':'Ci-dessus',
    'MursionPortal.SupplyManagement.Below':'Ci-dessous',
    'MursionPortal.SupplyManagement.At':'À',
    'MyProfile.ProfServiceRole.Tooltiptext':'Utilisateurs avec notre niveau d’accès le plus élevé',
    'MyProfile.AccManager.Tooltiptext':'Créer des scénarios, des équipes, ajouter des utilisateurs et assigner des équipes aux simulations',
    'MyProfile.SimSpecialist.Tooltiptext':'Aussi connu sous le nom de « Sim », ce rôle effectue des simulations pour les apprenants.',
    'MyProfile.Buyer.Tooltiptext':'Un utilisateur qui gère un projet Mursion pour son organisation',
    'MyProfile.Facilitator.Tooltiptext':'Participant à la simulation qui aide à la réalisation de la simulation',
    'MyProfile.Learner.Tooltiptext':'Participant à la simulation',
    'MyProfile.Operations.Tooltiptext':'Employé de Mursion qui a la possibilité d’ajouter et de modifier des contrats',
    'MyProfile.SimDesigner.Tooltiptext':'Utilisateur ayant la possibilité de créer et de modifier des banques de scénarios',
    'MursionPortal.Label.AssignedUserRoles':'Rôles d’utilisateur attribués',
    'MursionPortal.Label.AssignedUserRole':'Rôle d’utilisateur attribué',
    'Clients.Modal.Projects.Button.CreateFromBank':'Créer à partir de la banque',
    'MursionPortal.Legend.VisibilityHidden.ScenarioPanel.Buttons':'Créer un nouveau, client régulier, Créer à partir de la banque de boutons de scénario',
    'MursionPortal.Label.ArtBundle':'Ensemble d\'art',
    'Settings.ScenarioBank.Modal.ToolTip.ThisIsTheArtContentMsg':'C’est le contenu artistique 3D des environnements et des avatars qui sera utilisé dans la simulation. Le contenu de ce dossier est affiché dans la section intitulée « ensemble d\'art » ci-dessous.',
    'MursionPortal.SessionWizard.Label.ScenarioHasNoArtBundles':'Le scénario n’a pas d\'ensembles d\'art assignés.',
    'MursionPortal.Label.ArtProject':'Projet artistique',
    'MursionPortal.Label.UpperCase.ArtProject':'PROJET ARTISTIQUE',
    'Settings.Config.SimulationContent.ArtProjectFolder':'Dossier Projet artistique S3',
    'Settings.ScenarioBank.Modal.ThisScenarioHasNoArtProject':'Le scénario n’a pas de projet artistique assigné',
    'MursionPortal.SessionWizard.Label.SelectArtContentForUse':'Sélectionnez le projet artistique à utiliser',
    'Projects.Message.NoArtProjectFound':'Aucun projet artistique trouvé',
    'Settings.ScenarioBank.Form.ConfirmArchiveScenarioBank':'Il existe des scénarios enfants actifs créés à partir de la banque de ce scénario. Vous devez archiver chacun d’entre eux individuellement.',
    'Settings.StandardizedScenarioBank.EditConfirmation':'Voulez-vous vraiment mettre à jour tous les scénarios existants créés à partir de celui-ci?',
    'Settings.StandardizedScenarioBank.AttachmentsEditConfirmation':'La mise à jour des pièces jointes modifiera tous les scénarios existants créés à partir de celui-ci.',
    'MursionPortal.Contract.Ending':'Finalisation',
    'MursionPortal.ProjectContracts.DuplicateError':'Le contrat que vous avez sélectionné chevauche un autre contrat ajouté à ce projet. Veuillez sélectionner une autre opération ou contacter pour demander un ordre de modification',
    'MursionPortal.ProjectContracts.DateErrorHeading':'Aucun contrat n’existe pour les dates de ce projet',
    'MursionPortal.ProjectContracts.DateError':'Il n’y a pas de contrat pour soutenir ces dates de début et de fin de projet. Aucun projet ne doit être créé sans un contrat valide. Voulez-vous continuer?',
    'MursionPortal.Contract.ViewDetails':'Voir les détails',
    'MursionPortal.Contract.PleaseAddContractToProject':'Veuillez ajouter le contrat au projet',
    'MursionPortal.Contract.AddContractToProject':'Ajouter un contrat au projet',
    'MursionPortal.Contract.SelectContractToProject':'Sélectionner le contrat que vous souhaitez ajouter à ce projet - veuillez en ajouter un à la fois',
    'MursionPortal.Contract.AddSelectedContract':'Ajouter un contrat sélectionné',
    'MursionPortal.Contract.AddContract':'Ajouter un contrat',
    'MursionPortal.Contract.LineItemList':'Liste des articles',
    'MursionPortal.DemandBasedScheduling.InstantBooking':'Réservation instantanée',
    'MursionPortal.DemandBasedScheduling.AllSlots':'Soumettre des créneaux',
    'MursionPortal.DemandBasedScheduling.ScheduleYourScenario':'Nous planifierons votre scénario dans l’un des créneaux horaires de votre choix.',
    'MursionPortal.DemandBasedScheduling.SelectOneOfTheseSlotsForAnInstantBooking':'Sélectionnez l’un de ces créneaux pour une réservation instantanée.',
    'MursionPortal.DemandBasedScheduling.TheseSlotDoNotwork':'Si ces créneaux ne fonctionnent pas, veuillez sélectionner « Soumettre les créneaux » pour soumettre les heures qui fonctionnent pour vous.',
    'MursionPortal.AltText.CrossButton':'image du bouton croisé',
    'MursionPortal.Title.BankScenario':'Banque du scénario',
    'Dashboard.LeftPane.SupplyManagement.SimRoster':'Tableau de service des Sims',
    'Dashboard.SupplyManagement.SimRoster.MainHeader':'Tableau de service des Sims',
    'Dashboard.SupplyManagement.SimRoster.TableHeader':'Données du tableau de service des clients',
    'MursionPortal.Table.ColumnHeader.SimFullName':'Nom complet du SIM',
    'MursionPortal.Table.ColumnHeader.LanguagesDelivered':'Langues livrées (certifiées dans, la langue)',
    'MursionPortal.Table.ColumnHeader.Tier':'Niveau',
    'MursionPortal.Table.ColumnHeader.Timezone':'Fuseau horaire',
    'MursionPortal.Table.ColumnHeader.Scheduled':'Planifié',
    'MursionPortal.Table.ColumnHeader.ProjectsHoursWeekTotal':'Nombre total d’heures/semaine projetées',
    'MursionPortal.Table.ColumnHeader.ActiveClientCertifications':'Certifications Client actif (facturables)',
    'MursionPortal.Table.ColumnHeader.InactiveCertifications':'Certification inactive',
    'MursionPortal.Table.ColumnHeader.Declined':'Refusé',
    'MursionPortal.Table.ColumnHeader.AvgFullfillTimeForManual':'Temps de remplissage moyen par Manuel',
    'MursionPortal.Table.ColumnHeader.Queue':'File d’attente',
    'MursionPortal.Table.ColumnHeader.Availability':'Disponibilité',
    'MursionPortal.Filters.Tier':'Niveau',
    'MursionPortal.Filters.PlaceHolder.SearchTier':'Niveau de recherche',
    'MursionPortal.TierOptions.Tier1':'Niveau 1',
    'MursionPortal.TierOptions.Tier2':'Niveau 2',
    'MursionPortal.TierOptions.Tier3':'Niveau 3',
    'MursionPortal.TierOptions.Tier4':'Niveau 4',
    'MursionPortal.TierOptions.LeadSimulation':'Spécialiste principal de la simulation',
    'MursionPortal.Contract.BackToCLient':'Retour au client',
    'MursionPortal.DeliveryMode.Workshop':'Atelier',
    'MursionPortal.DeliveryMode.PrivatePractice':'Pratique privée',
    'MursionPortal.DemandBasedScheduling.SimOnlySlotSelectionMessage':'Veuillez choisir un jour et un créneau horaire qui vous conviennent.',
    'Dashboard.LeftPane.CategoryHeader.Performance':'UTILISATION',
    'Dashboard.LeftPane.Performance.ContractOverview':'Vue d’ensemble',
    'Dashboard.LeftPane.Performance.ContractSummaries':'Résumés des contrats',
    'Dashboard.LeftPane.Performance.ContractProgress':'Avancement du contrat',
    'Dashboard.LeftPane.CategoryHeader.Learners':'APPRENANTS',
    'Dashboard.LeftPane.Learners.LearnerActivity':'Activité de l’apprenant',
    'Dashboard.LeftPane.Learners.LearnerSimulations':'Simulations d’apprenants',
    'Dashboard.Total':'Nombre total d’entrées',
    'Filters.SimulationStatus':'État de la simulation',
    'Filters.SimulationStatusPlaceholder':'État de la simulation de recherche...',
    'Filters.LearnerStatus':'Statut d’apprenant',
    'Filters.LearnerStatusPlaceHolderHint':'Rechercher le statut de l’apprenant...',
    'Filters.SimulationRecorded':'Simulation enregistrée',
    'Filters.Standardized':'Standardisé',
    'Filters.Boolean.Yes':'Oui',
    'Filters.Boolean.No':'Non',
    'Dashboard.UserTable.Column.Roles':'Rollen',
    'Dashboard.ContractSummaries.MainHeader':'Résumés des contrats',
    'Dashboard.ContractOverview.MainHeader':'Vue d’ensemble',
    'Dashboard.ContractProgress.MainHeader':'Avancement du contrat',
    'Dashboard.ContractProgress.Navigation.ContractItems':'Éléments du contrat',
    'Dashboard.ContractProgress.Navigation.Projects':'Projets',
    'Dashboard.ContractProgress.Navigation.Scenarios':'Scénarios',
    'Dashboard.ContractProgress.Column.Item':'Article',
    'Dashboard.ContractProgress.Column.Purchased':'Acheté',
    'Dashboard.ContractProgress.Column.Remaining':'Restants',
    'Dashboard.ContractProgress.Column.Completed':'Terminé',
    'Dashboard.ContractProgress.Column.Scheduled':'Planifié',
    'Dashboard.ContractProgress.Column.LateCancellations':'Annulations tardives',
    'Dashboard.ContractProgress.Column.ProjectName':'Nom du projet',
    'Dashboard.ContractProgress.Column.StartDate':'Date de début',
    'Dashboard.ContractProgress.Column.EndDate':'Date de fin',
    'Dashboard.ContractProgress.Column.Error':'Erreur',
    'Dashboard.ContractProgress.Column.ScenarioName':'Nom du scénario',
    'Dashboard.ContractProgress.Column.Standardized':'Standardisé',
    'Dashboard.ContractProgress.Column.LearnersAssigned':'Apprenants assignés',
    'Dashboard.ContractProgress.Column.LearnersCompleted':'Apprenants terminés',
    'Dashboard.ContractProgress.Column.LearnersCompletedPercent':'% d’apprenants ayant terminés',
    'Dashboard.ContractProgress.Column.Client':'Client',
    'Dashboard.ContractProgress.Column.ContractName':'Nom du contrat',
    'Dashboard.ContractProgress.Column.ContractId':'Identifiant du contrat',
    'Dashboard.ContractProgress.Column.ContractNameHoverTitle':'Identifiant de la copie',
    // 'Dashboard.ContractProgress.Column.PercentComplete':'% Terminé',
    'Dashboard.ContractProgress.Column.ErrorLearner':'Erreur (Apprenant)',
    'Dashboard.ContractProgress.Column.LearnersInvited':'Apprenants invités',
    'Dashboard.ContractProgress.Column.LearnersScheduledPercent':'% d’apprenants programmés',
    'Dashboard.ContractProgress.ColumnTooltip.Client':'Nom du client',
    'Dashboard.ContractProgress.ColumnTooltip.ContractName':'Nom du contrat',
    'Dashboard.ContractProgress.ColumnTooltip.PercentComplete':'Pourcentage de jours accomplis du contrat',
    'Dashboard.ContractProgress.ColumnTooltip.CompletedUtilization':'Simulations effectuées en pourcentage des simulations achetées',
    'Dashboard.ContractProgress.ColumnTooltip.ScheduledUtilization':'Simulations terminées et planifiées en pourcentage des simulations achetées',
    'Dashboard.ContractProgress.ColumnTooltip.MissedLearner':'Les simulations qui ont été manquées par l’apprenant.',
    'Dashboard.ContractProgress.ColumnTooltip.ErrorLearner':'Simulations qui se sont terminées par une erreur du côté de la connexion de l’apprenant',
    'Dashboard.ContractProgress.ColumnTooltip.LearnersInvited':'Apprenants qui ont été invités à planifier une simulation',
    'Dashboard.ContractProgress.ColumnTooltip.LearnersScheduledPercent':'Apprenants qui ont programmé au moins une simulation en temps que pourcentage de ceux qui ont été invités à planifier une simulation',
    'Dashboard.ContractProgress.ColumnTooltip.Item':'Articles contractés dans le contrat',
    'Dashboard.ContractProgress.ColumnTooltip.Purchased':'Nombre total de simulations achetées dans le cadre d’un contrat',
    'Dashboard.ContractProgress.ColumnTooltip.Completed':'Simulations qui ont été réalisées avec succès',
    'Dashboard.ContractProgress.ColumnTooltip.Scheduled':'Simulations à venir qui sont planifiées mais qui n’ont pas encore été achevées (y compris celles en cours d’exécution)',
    'Dashboard.ContractProgress.ColumnTooltip.Missed':'Simulations qui ont été manquées par l’apprenant',
    'Dashboard.ContractProgress.ColumnTooltip.LateCancellations':'Simulations qui ont été annulées en retard (après la date limite d’annulation) par l’apprenant, l’animateur ou le propriétaire du compte ou simulations où l’apprenant est parti ou a refusé',
    'Dashboard.ContractProgress.ColumnTooltip.Error':'Simulations qui se sont terminées par une erreur du côté de la connexion de l’apprenant',
    'Dashboard.ContractProgress.ColumnTooltip.ProjectName':'Nom du projet',
    'Dashboard.ContractProgress.ColumnTooltip.StartDate':'Date de début du contrat telle qu’indiquée dans le contrat.',
    'Dashboard.ContractProgress.ColumnTooltip.EndDate':'Date de fin du contrat telle qu’indiquée dans celui-ci.',
    'Dashboard.ContractProgress.ColumnTooltip.ScenarioName':'Nom du scénario',
    'Dashboard.ContractProgress.ColumnTooltip.Standardized':'Il s’agit d’un scénario standardisé',
    'Dashboard.ContractProgress.ColumnTooltip.LearnersAssigned':'Nombre d\'apprenants auxquels un scénario a été attribué',
    'Dashboard.ContractProgress.ColumnTooltip.LearnersCompleted':'Les apprenants qui ont terminé au moins un pourcentage de simulation comparativement à ceux qui ont été invités à en planifier une.',
    'Dashboard.ContractProgress.ColumnTooltip.LearnersCompletedPercent':'Pourcentage d’apprenants distincts qui ont terminé le scénario',
    'Dashboard.Learners.Column.Provider':'Fournisseurs',
    'Dashboard.Learners.Column.Client':'Client',
    'Dashboard.Learners.Column.LearnerName':'Nom de l\'apprenant',
    'Dashboard.Learners.Column.DateInvited':'Date de l’invitation',
    'Dashboard.Learners.Column.LastLogin':'Dernière connexion',
    'Dashboard.Learners.Column.NoAssignedTeams':'Aucune équipe affectée',
    'Dashboard.Learners.Column.NoAssignedScenarios':'Aucun scénario affecté',
    'Dashboard.Learners.Column.NoCompletedScenarios':'Aucun scénario terminé',
    'Dashboard.Learners.Column.Completed':'Terminé',
    'Dashboard.Learners.Column.Scheduled':'Planifié',
    'Dashboard.Learners.Column.LateCancelled':'Annulé tardivement',
    'Dashboard.Learners.Column.EndedInError':'Fini en erreur',
    'Dashboard.Learners.Column.ProjectName':'Nom du projet',
    'Dashboard.Learners.Column.ScenarioName':'Nom du scénario',
    'Dashboard.Learners.Column.ScheduledStartDate':'Date de début prévue',
    'Dashboard.Learners.Column.ScheduledStartTime':'Heure de début planifiée',
    'Dashboard.Learners.Column.ScheduledEndTime':'Heure de fin planifiée',
    'Dashboard.Learners.Column.SimulationStatus':'État de la simulation',
    'Dashboard.Learners.Column.LearnerStatus':'Statut d’apprenant',
    'Dashboard.Learners.Column.LearnerTimeSpent':'Temps passé par l’apprenant',
    'Dashboard.Learners.Column.SimSpecialistName':'Nom du spécialiste Sim',
    'Dashboard.Learners.Column.SimSpecialistStatus':'Statut du spécialiste Sim',
    'Dashboard.Learners.Column.Report':'Rapport',
    'Dashboard.Learners.ColumnTooltip.Provider':'Nom du fournisseur/détenteur de licence',
    'Dashboard.Learners.ColumnTooltip.Client':'Nom du client',
    'Dashboard.Learners.ColumnTooltip.LearnerName':'Nom et prénom de l’utilisateur',
    'Dashboard.Learners.ColumnTooltip.DateInvited':'Date à laquelle un apprenant a été invité pour la première fois à planifier un scénario',
    'Dashboard.Learners.ColumnTooltip.LastLogin':'Date à laquelle un utilisateur s’est connecté pour la dernière fois au portail',
    'Dashboard.Learners.ColumnTooltip.NoAssignedTeams':'Nombre d’équipes qui ont été affectées à un apprenant',
    'Dashboard.Learners.ColumnTooltip.NoAssignedScenarios':'Nombre de scénarios distincts qui ont été attribués à un apprenant',
    'Dashboard.Learners.ColumnTooltip.NoCompletedScenarios':'Nombre de scénarios distincts qui ont été complétés par un apprenant',
    'Dashboard.Learners.ColumnTooltip.Completed':'Simulations qui ont été réalisées avec succès',
    'Dashboard.Learners.ColumnTooltip.Scheduled':'Simulations à venir qui sont planifiées mais qui n’ont pas encore été achevées (y compris celles en cours d’exécution)',
    'Dashboard.Learners.ColumnTooltip.Missed':'Simulations qui ont été manquées par l’apprenant',
    'Dashboard.Learners.ColumnTooltip.LateCancelled':'Les simulations qui ont été annulées plus tard (après la date limite d’annulation) par l’apprenant, l’animateur ou le propriétaire du compte ou les simulations.',
    'Dashboard.Learners.ColumnTooltip.EndedInError':'Simulations qui se sont terminées par une erreur du côté de la connexion de l’apprenant',
    'Dashboard.Learners.ColumnTooltip.ProjectName':'Nom du projet',
    'Dashboard.Learners.ColumnTooltip.ScenarioName':'Nom du scénario',
    'Dashboard.Learners.ColumnTooltip.ScheduledStartDate':'Date à laquelle cette simulation devait commencer',
    'Dashboard.Learners.ColumnTooltip.ScheduledStartTime':'Heure à laquelle cette simulation devait commencer',
    'Dashboard.Learners.ColumnTooltip.ScheduledEndTime':'Heure à laquelle cette simulation devait se terminer',
    'Dashboard.Learners.ColumnTooltip.SimulationStatus':'Statut final de la simulation',
    'Dashboard.Learners.ColumnTooltip.LearnerStatus':'Statut final de l’apprenant pour la simulation',
    'Dashboard.Learners.ColumnTooltip.LearnerTimeSpent':'Les minutes d’apprentissage passées dans la simulation. Pour les apprenants Zoom et Meet, cela est estimé en fonction du spécialiste SIM si c\'est terminé',
    'Dashboard.Learners.ColumnTooltip.SimSpecialistName':'Spécialiste de la simulation chargé de livrer la simulation',
    'Dashboard.Learners.ColumnTooltip.SimSpecialistStatus':'Statut final du spécialiste SIM pour la simulation',
    'Dashboard.Learners.ColumnTooltip.SimulationId':'Identifiant unique de la simulation',
    'Dashboard.Learners.ColumnTooltip.Report':'Lien vers le rapport de simulation pour afficher (si disponible) l’enregistrement et l’analyse de celle-ci.',
    'Dashboard.SessionLearners.TableHeader':'Données des apprenants de session',
    'MursionPortal.FilterHeading.Filter':'FILTRE',
    'MursionPortal.FilterHeading.Graph':'GRAPHIQUES',
    'MursionPortal.Table.NoRecordsMessage':'Aucun résultat trouvé!',
    'MursionPortal.Filters.DeliveryMode':'Mode de livraison',
    'MursionPortal.Filters.PlaceHolder.DeliveryMode':'Rechercher mode de livraison...',
    'MursionPortal.Dashboard.Chart.Tooltip.Utilized':'Nombre total utilisé pour l\'article contractuel. Comprend les simulations terminées, manquées ou annulées/reprogrammées (après la date limite).',
    'MursionPortal.Dashboard.Chart.Tooltip.SimulationBreakdown.Completed':'Simulations qui ont été réalisées avec succès.',
    'MursionPortal.Dashboard.Chart.Tooltip.SimulationBreakdown.LearnerMissed':'Les simulations qui ont été manquées par l’apprenant.',
    'MursionPortal.Dashboard.Chart.Tooltip.SimulationBreakdown.Error':'Des simulations qui se sont terminées par une erreur du côté de l’apprenant de la connexion.',
    'MursionPortal.Dashboard.Chart.Tooltip.SimulationBreakdown.LateCancellation':'Les simulations qui ont été annulées tardivement (après la date limite d’annulation) par l’apprenant, l’animateur ou le titulaire du compte ou simulations lorsque l’apprenant est parti ou a refusé.',
    'MursionPortal.Dashboard.Chart.Tooltip.SimulationBreakdown.EarlyCancellation':'Les simulations qui ont été annulées plus tôt (avant la date limite d’annulation) par l’apprenant, l’animateur ou le titulaire du compte.',
    'MursionPortal.Dashboard.Chart.label.SimulationBreakdown.Completed':'Terminé',
    'MursionPortal.Dashboard.Chart.label.SimulationBreakdown.Error':'Erreur',
    'MursionPortal.Dashboard.Chart.label.SimulationBreakdown.LateCancellation':'Les tardifs peuvent.',
    'MursionPortal.Dashboard.Chart.label.SimulationBreakdown.EarlyCancellation':'Les anticipés peuvent.',
    'MursionPortal.Dashboard.Chart.PrivatePractice1':'30m Pratique privée',
    'MursionPortal.Dashboard.Chart.PrivatePractice2':'60m Pratique privée',
    'MursionPortal.Dashboard.Chart.Workshop1':'60m Atelier',
    'MursionPortal.Dashboard.Chart.Workshop2':'90m Atelier',
    'MursionPortal.Dashboard.Chart.StringType.Utilized':'Utilisé',
    'MursionPortal.Dashboard.Chart.Header.SimulationBreakdown':'Analyse de la simulation',
    'MursionPortal.SessionRecording':'Enregistrement de session',
    'MursionPortal.VoiceAnalytics':'Analyse vocale',
    'MursionPortal.VideoTab':'Vidéo',
    'MursionPortal.SessionInformation':'Informations de la session',
    'MursionPortal.SmartMatrix':'Indicateurs intelligents',
    'MursionPortal.DeliveryDetails':'Détails de livraison',
    'MursionPortal.ConversationalFlow':'Flux conversationnel',
    'MursionPortal.ConversationalFlowTooltip':'Ce graphique illustre votre efficacité au cours d’une conversation. Ils est composé d’entrées à tour de rôle qui révèlent à quel point vous êtes capable de participer à une conversation constructive.',
    'MursionPortal.BalancedTalkTime':'Conversation',
    'MursionPortal.BalancedFeedback':'Rétroaction équilibrée',
    'MursionPortal.ConversationalDisruptions':'Interruption',
    'MursionPortal.DisruptionsFeedback':'Commentaires sur les perturbations',
    'MursionPortal.Heading.SessionSmartMetrics':'Analyse vocale de session:',
    'Clients.Modal.Label.SmartMetrics':'Analyse vocale:',
    'Clients.Modal.Projects.Header.SessionSmartMetrics':'Analyse vocale de session:',
    'Clients.Modal.Projects.Checkbox.EnableSmartMetrics':' Activer l’analyse vocale',
    'Filters.Contract':'Contrat',
    'Filters.Session': 'Session',
    'Clients.Modal.Label.ShowSowReporting':'Afficher les rapports des cahiers de charges:',
    'Clients.Modal.Label.ShowSurveyEnabled':'Envoyer un sondage post-simulation auprès des apprenants:',
    'Dashboard.SimulationTable.Column.Provider':'Fournisseurs',
    'Dashboard.SimulationTable.Column.Client':'Client',
    'Dashboard.SimulationTable.Column.Project':'Projet',
    'Dashboard.SimulationTable.Column.ScenarioName':'Nom du scénario',
    'Dashboard.SimulationTable.Column.ScheduledStartDate':'Date de début prévue',
    'Dashboard.SimulationTable.Column.ScheduledStartTime':'Heure de début planifiée',
    'Dashboard.SimulationTable.Column.ScheduledEndTime':'Heure de fin planifiée',
    'Dashboard.SimulationTable.Column.LearnersNames':'Équipe(s) de/des apprenant(s)',
    'Dashboard.SimulationTable.Column.LearnersEmails':'Courriels apprenant(s)',
    'Dashboard.SimulationTable.Column.LearnersStatus':'Statut de l\'apprenant(s)',
    'Dashboard.SimulationTable.Column.LearnersTimeSpent':'Apprenant(s) Temps passé(s)',
    'Dashboard.SimulationTable.Column.SimSpecialistName':'Nom du spécialiste Sim',
    'Dashboard.SimulationTable.Column.SimSpecialistStatus':'Statut du spécialiste Sim',
    'Dashboard.SimulationTable.Column.SimSpecialistTimeSpent':'Temps passé par le spécialiste SIM',
    'Dashboard.SimulationTable.Column.ScheduledBy':'Planifié par',
    'Dashboard.SimulationTable.Column.ScheduledAt':'Prévu à',
    'Dashboard.SimulationTable.Column.Recorded':'Enregistré',
    'Dashboard.SimulationTable.Column.ClientId':'Identifiant client',
    'Dashboard.SimulationTable.Column.LearnerName':'Nom de l\'apprenant',
    'Dashboard.SimulationTable.Column.LearnerName.ButtonHoverTitle':'Copier le courriel de l’apprenant',
    'Dashboard.SimulationTable.Column.LearnerEmail':'Courriel de l’apprenant',
    'Dashboard.SimulationTable.Column.LearnerStatus':'Statut d’apprenant',
    'Dashboard.SimulationTable.Column.LearnerTimeSpent':'Temps passé par l\'apprenant',
    'Dashboard.SimulationTable.ColumnTooltip.Provider':'Nom du fournisseur/détenteur de licence',
    'Dashboard.SimulationTable.ColumnTooltip.Client':'Nom du client',
    'Dashboard.SimulationTable.ColumnTooltip.Project':'Nom du projet',
    'Dashboard.SimulationTable.ColumnTooltip.ScenarioName':'Nom du scénario',
    'Dashboard.SimulationTable.ColumnTooltip.ScheduledStartDate':'Date à laquelle cette simulation devait commencer',
    'Dashboard.SimulationTable.ColumnTooltip.ScheduledStartTime':'Heure à laquelle cette simulation devait commencer',
    'Dashboard.SimulationTable.ColumnTooltip.ScheduledEndTime':'Heure à laquelle cette simulation devait se terminer',
    'Dashboard.SimulationTable.ColumnTooltip.SimulationStatus':'Statut final de la simulation',
    'Dashboard.SimulationTable.ColumnTooltip.LearnersNames':'Nom(s) des apprenants inscrits et non inscrits pour la simulation',
    'Dashboard.SimulationTable.ColumnTooltip.LearnersEmails':'Courriel(s) des apprenants inscrits et non inscrits pour la simulation',
    'Dashboard.SimulationTable.ColumnTooltip.LearnersStatus':'Statut final de l’apprenant pour la simulation',
    'Dashboard.SimulationTable.ColumnTooltip.LearnersTimeSpent':'Temps passé connecté à la simulation par les apprenants qui ont rejoint la simulation',
    'Dashboard.SimulationTable.ColumnTooltip.SimSpecialistName':'Nom du spécialiste Sim',
    'Dashboard.SimulationTable.ColumnTooltip.SimSpecialistStatus':'Statut final du spécialiste SIM pour la simulation',
    'Dashboard.SimulationTable.ColumnTooltip.SimSpecialistTimeSpent':'Durée réelle, en minutes, de cette simulation',
    'Dashboard.SimulationTable.ColumnTooltip.ScheduledBy':'Nom de l’utilisateur qui a planifié la simulation',
    'Dashboard.SimulationTable.ColumnTooltip.ScheduledAt':'Date et heure auxquelles cette simulation a été programmée',
    'Dashboard.SimulationTable.ColumnTooltip.Recorded':'Indique si la simulation a été enregistrée ou si elle n’a pas été autorisée.',
    'Dashboard.SimulationTable.ColumnTooltip.LearnerName':'Nom(s) des apprenants inscrits et non inscrits pour la simulation',
    'Dashboard.SimulationTable.ColumnTooltip.LearnerStatus':'Statut final de l’apprenant pour la simulation',
    'Dashboard.SimulationTable.ColumnTooltip.LearnerTimeSpent':'Les minutes d’apprentissage passées dans la simulation. Pour les apprenants Zoom et Meet, cela est estimé en fonction du spécialiste SIM si c\'est terminé',
    'MursionPortal.Label.VideoTimeLineSlice':'Tranches de montage vidéo',
    'MursionPortal.Label.SpeakingMoreThanSixty':'Plus de 60 %, c\'est de l\'oral',
    'MursionPortal.Label.SpeakingBetweenSixty':'Entre 40 % et 60 %, c\'est de l\'oral',
    'MursionPortal.Label.InterruptionMoreThanSixty':'Plus de 60 % sont des interruptions',
    'MursionPortal.Label.InterruptionBetweenSixty':'Entre 40% et 60%, c\'est des interruptions',
    'MursionPortal.Label.SpeakingLessThanFourty':'L\'oral constitut moins de 40%',
    'MursionPortal.Label.InterruptionLessThanFourty':'Les interruptions constituent moins de 40%',
    'MursionPortal.Label.Interruption.ByAvatar':'Par avatar',
    'MursionPortal.Label.Interruption.ByLearner':'Par apprenant',
    'MursionPortal.Projects.NoContractsFound':'Aucun contrat n’a été trouvé.',
    'MursionPortal.Projects.ProjectContracts':'Contrats de projet',
    'MursionPortal.Tooltip.AvatarInterrupt':'Avatar tente d’interrompre l\'orateur avec succès.',
    'MursionPortal.Tooltip.LearnerInterrupt':'L’apprenant tente d’interrompre l’orateur avec succès.',
    'MursionPortal.Tooltip.Pauses':'Pauses. Plus de 0,2 s et moins de 0,75 s',
    'MursionPortal.Tooltip.Silence':'Pause à l\'oral de plus de 10 secondes',
    'MursionPortal.Tooltip.AvatarSpeaking':'L\'avatar tente de parler avec succès.',
    'MursionPortal.Tooltip.LearnerSpeaking':'L’apprenant tente de parler avec succès.',
    // 'MursionPortal.label.LearnerInterrupts':'L’apprenant interrompt',
    // 'MursionPortal.label.AvatarInterrupts':'L’avatar interrompt',
    'MursionPortal.label.Pause':'Pause',
    'MursionPortal.Label.Silence':'Silence',
    'MursionPortal.label.AvatarSpeaking':'L\'avatar parle',
    'MursionPortal.label.LearnerSpeaking':'L\'apprenant parle',
    'MursionPortal.Contract.DuplicateLineItem':'Une ligne d\'article existe déjà pour cette longueur de simulation et ce mode de livraison',
    'MursionPortal.Contract.DeleteLineItemIcon':' Supprimer l’icône de ligne d\'article',
    'SessionReports.Label.ShareVideoLink':'Partager le lien vidéo',
    'MursionPortal.Title.Silence':'Pause à l\'oral de plus de 10 secondes',
    'Dashboard.LeftPane.User.UserActivity':'Activité de l’utilisateur',
    'Dashboard.LeftPane.Performance.SelfService':'Utlisation en libre-service',
    'MursionPortal.Placeholder.ClientValue':'Sélectionner un client',
    'Filters.Date.FromDate':'À partir d\'une date',
    'Filters.Date.ToDate':'À ce jour',
    'Dashboard.UserActivity.MainHeader':'Activité de l’utilisateur',
    'Dashboard.SelfService.MainHeader':'Utilisation en libre-service',
    'Dashboard.SelfService.Column.SelfServiceProvider':'Fournisseur',
    'Dashboard.SelfService.Column.SelfServiceTotalHoursUtilized':'Nombre total d’heures utilisées',
    'Dashboard.SelfService.Column.SelfServiceHoursCompleted':'Heures completées',
    'Dashboard.SelfService.Column.SelfServiceHoursMissed':'Heures manquées',
    'Dashboard.SelfService.Column.SelfServiceHoursErrored':'Heures erronées',
    'Dashboard.SelfService.Column.SelfServiceSimulationsComplete':'Simulations terminées',
    'Dashboard.SelfService.Column.SelfServiceSimulationsMissed':'Simulations manquées',
    'Dashboard.SelfService.Column.SelfServiceSimulationsErrored':'Simulations erronées',
    'Dashboard.SelfService.ColumnTooltip.SelfServiceProvider':'Fournisseur',
    'Dashboard.SelfService.ColumnTooltip.SelfServiceTotalHoursUtilized':'Simulations qui ont été réalisées avec succès',
    'Dashboard.SelfService.ColumnTooltip.SelfServiceHoursCompleted':'Heures completées',
    'Dashboard.SelfService.ColumnTooltip.SelfServiceHoursMissed':'Simulations qui ont été manquées par l’apprenant',
    'Dashboard.SelfService.ColumnTooltip.SelfServiceHoursErrored':'Simulations qui se sont terminées par une erreur du côté de la connexion de l’apprenant',
    'Dashboard.SelfService.ColumnTooltip.SelfServiceSimulationsComplete':'Simulations terminées',
    'Dashboard.SelfService.ColumnTooltip.SelfServiceSimulationsMissed':'Simulations manquées',
    'Dashboard.SelfService.ColumnTooltip.SelfServiceSimulationsErrored':'Simulations erronées',
    'Dashboard.Learners.Column.AssignedProjects':'Projets assignés',
    'Dashboard.Learners.Column.AssignedScenarios':'Scénarios assignés',
    'Dashboard.Learners.Column.ScheduledScenarios':'Scénarios planifiés',
    'Dashboard.Learners.Column.CompletedScenarios':'Scénarios terminés',
    'Settings.Config.SessionReport.ShowSmartMetrics':'Analyse vocale',
    'Settings.SSO.CopySsoDeepLink':'Copier le SSO Deep Link',
    'Settings.SSO.CopySsoDeepLinkNotification':'Votre lien SSO a été copié sur votre presse-papiers',
    'MursionPortal.Dashboard.Chart.Assigned':'Assignés',
    'MursionPortal.Dashboard.Chart.Tooltip.SimulationBreakdown.Simulation':'Simulations analysées par statut.',
    'MursionPortal.Dashboard.Chart.Header.NoDataAvailable':'Aucune donnée disponible',
    'Projects.ProjectInfo.Label.SurveyControl':'Enquêtes sur les projets',
    'Projects.ProjectInfo.Checkbox.EnablePreSimulationSurvey':'Activer l’enquête pré-simulation auprès des apprenants',
    'Projects.ProjectInfo.Checkbox.EnablePostSimulationSurvey':'Activer l’enquête post-simulation auprès des apprenants',
    'Projects.ProjectInfo.Checkbox.SurveyEnabled':'Enquête activée',
    'Projects.ProjectInfo.Checkbox.SurveyEnabled.PreSimulationSurvey':'Sondage pré-simulation auprès des apprenants',
    'Projects.ProjectInfo.Checkbox.SurveyEnabled.PostSimulationSurvey':'Sondage post-simulation auprès des apprenants',
    'Settings.SSO.ModalBody.SelectDestination':'Sélectionner la destination du lien',
    'Settings.SSO.ModalBody.SelectDestination.SessionsTab':'Onglet Sessions',
    'Settings.SSO.ModalBody.SelectDestination.CalendarTab':'Onglet Calendrier',
    'Settings.SSO.ModalBody.SelectDestination.ReportsTab':'Onglet Rapports',
    'Settings.SSO.ModalBody.SelectDestination.DashboardTab':'Onglet Tableau de bord',
    'Settings.SSO.ModalBody.SelectDestination.ScenarioCard':'Carte de scénario',
    'Settings.SSO.ModalBody.SelectDestination.ScenarioTabSelectTitle':'Sélectionner d’abord un projet puis un scénario',
    'Settings.SSO.Button.CreateDeepLink':'Créer un SSO Deep Link',
    'Settings.SSO.ModalHeader.DeepLink':'Copier le SSO Deep Link',
    'MursionPortal.Dashboard.Chart.Header.LearnerSimulations':'Nombre de scénarios assignés',
    'Clients.Modal.Label.MursionSocialAttendance':'Présence sociale de Mursion:',
    'MursionPortal.CodeOfConduct.Text':'En choisissant de planifier votre session Mursion, vous acceptez de respecter et d’honorer le <code>Code de conduite Mursion</code>.',
    'MursionPortal.Dashboard.DonutChart.Tooltip.ContractProgress.CompletedAllAvailable':'Apprenants qui ont terminé tous les scénarios qui étaient à leur disposition',
    'MursionPortal.Dashboard.DonutChart.Tooltip.ContractProgress.NoneAvailableToSchedule':'Les apprenants qui n’ont pas encore eu de scénario disponible à planifier',
    'MursionPortal.Dashboard.DonutChart.Tooltip.ContractProgress.NotYetScheduled':'Les apprenants avec des scénarios à leur disposition, mais n’ayant pas encore planifié de simulation',
    'MursionPortal.Dashboard.DonutChart.Tooltip.ContractProgress.Scheduled':'Les apprenants qui ont une ou plusieurs simulations planifiées qu\'ils n\'ont pas encore terminées',
    'MursionPortal.DateRangeSelector.Label.DateRange':'Plage de dates ',
    'MursionPortal.DemandBasedScheduling.TimeBlockUnavailableReservedText':'Déjà sélectionné - veuillez sélectionner un autre bloc horaire.',
    'MursionPortal.EndDate':'Date de fin',
    'MursionPortal.Label.FilterSIMsBy':'Filtrer les SIMs par',
    'MursionPortal.LearnerSimSelector.Learner':'Apprenants',
    'MursionPortal.LearnerSimSelector.Learner.Selected':'Apprenant sélectionné',
    'Dashboard.Filters.SimulationID':'Identifiant de la simulation',
    'MursionPortal.LearnerSimSelector.Sim.Selected':'Spécialiste Sim',
    'MursionPortal.LearnerSimSelector.Sim.Unavailable':'Certifié et indisponible',
    'MursionPortal.LearnerSimSelector.Tab.Search.Learner':'Rechercher ou sélectionner un apprenant',
    'MursionPortal.LearnerSimSelector.Tab.Search.Sim':'Rechercher ou sélectionner un spécialiste SIM',
    'MursionPortal.Placeholder.ProvidersValue':'Sélectionner un fournisseur',
    'MursionPortal.ProjectContract.Confirmation':'Êtes-vous sûr de vouloir supprimer le contrat de ce projet?',
    'MursionPortal.Scheduling.Label.DateTimeTab':'Date + Heure',
    'MursionPortal.Scheduling.NoAvailabilityForCertifiedAndAvailableSimSpecialist':'Aucune disponibilité n’existe pour un SIM certifiée.',
    'MursionPortal.Scheduling.NoAvailabilityForCertifiedSimSpecialist':'Il n’y a pas de Sims certifiés disponibles',
    'MursionPortal.SessionFlow.Label.AreYouSure':'Êtes-vous certain?',
    'MursionPortal.SessionFlow.Label.BeforeDeletionPleaseEnsureTheFollowing':'Avant la suppression, veuillez vous assurer de ce qui suit:',
    'MursionPortal.SessionFlow.Label.BeforeDeletionPleaseEnsureTheFollowing.Text1':'Vous avez vérifié que l’utilisateur qui demande a le pouvoir de supprimer (méfiez-vous des simulations de groupe et du consentement de l’apprenant).',
    'MursionPortal.SessionFlow.Label.BeforeDeletionPleaseEnsureTheFollowing.Text2':'Vous avez créé un dossier d’assistance qui indique qui demande la suppression et que vous avez vérifié auprès du propriétaire du compte si nécessaire.',
    'MursionPortal.SessionFlow.Label.DeleteRecording':'Supprimer l’enregistrement',
    'MursionPortal.SessionFlow.Label.ProviderName':'Nom du fournisseur',
    'MursionPortal.SessionFlow.Label.ReminderThisCanNotBeUndone':'RAPPEL : Cela ne peut pas être annulé!',
    'MursionPortal.SessionFlow.Label.ScenarioDetails':'Détails du scénario',
    'MursionPortal.SessionFlow.Label.SessionStartEndDateTime':'Date de début et heure de fin de la session',
    'MursionPortal.SessionFlow.Label.SupportCase':'Cas d’assistance #*',
    'MursionPortal.SessionFlow.Label.YouAreAboutToDeletevideoRecording':'Vous êtes sur le point de supprimer un enregistrement vidéo',
    'MursionPortal.SessionNotStarted.AudioVideoSettings': '« Vérifiez vos paramètres audio et vidéo »',
    'MursionPortal.SessionNotStarted.DescriptionOne': '« Pendant que vous attendez, vous pouvez : »',
    'MursionPortal.SessionNotStarted.DescriptionTwo':"Cette fenêtre contextuelle disparaîtra <code>{valeur} quelques</code> minutes avant votre simulation. Cliquer sur Actualiser si la page ne se met pas à jour automatiquement à ce moment-là.",
    'MursionPortal.SessionNotStarted.Heading':'« Vous êtes un peu en avance »',
    'MursionPortal.SessionNotStarted.PreSimTips':'« Consultez ces <code>conseils de pré-simulation</code> »',
    'MursionPortal.SessionNotStarted.ScenarioDetails':'« Examiner les <code>détails du scénario</code> »',
    'MursionPortal.StartDate':'Date de début',
    'MursionPortal.Survey.Thankyou.Msg':'Nous vous remercions du temps que vous avez consacré à ce sondage. Votre réponse a été enregistrée.',
    'MursionPortal.Title.Hours':'Heure(s)',
    'MursionPortal.Title.Minutes':'Minute(s)',
    'MursionPortal.UserConsentDeclineConfirmation.PermissionDoesNotLieWithLearner': 'Veuillez indiquer si vous consentez ou non à aller de l’avant et faire enregistrer vos simulations. Si vous n’y consentez pas, vous ne pouvez pas poursuivre votre session de simulation et le logiciel cessera. Si vous y consentez, votre ou vos séances de simulation seront enregistrées et chaque enregistrement sera stocké en toute sécurité pendant 12 mois, date à laquelle ils seront archivés.',
    'MursionPortal.UserConsentDeclineConfirmation.PermissionsLiesWithLearner':'Êtes-vous certain ? Pour continuer sans enregistrer, cliquer sur le bouton Confirmer. Pour modifier votre consentement à l’enregistrement, cliquer sur le bouton Annuler.',
    'Session.Edit.Modal.JoinSimulationBtnTooltip':'Vous pouvez rejoindre <code>{minutesAvantderejoindre}</code> quelques minutes avant le début de votre simulation.',
    'Session.Edit.Modal.LastEmailTrigger':'Dernier courriel envoyé à l’apprenant : {dated\'envoi}',
    'Session.Edit.Modal.LastEmailTrigger.Sender.UserName':'Par : {Nomutilisteur} {Heured\'envoi}',
    'Session.Rescheduling.Email.Reminder.Msg':'Envoi d’un rappel par courriel à l’apprenant!',
    'Settings.Config.Invitation.MursionSocialAttendance':'Recueillir la présence sociale de Mursion.',
    'Settings.LTI.Modal.SendScore':'Envoyer le score',
    'Dashboard.ContractProgress.Column.Provider':'Fournisseurs',
    'Settings.SSO.CopyDeepLinkNotification':'Votre lien a été copié sur votre presse-papiers',
    'MursionPortal.Dashboard.Table.Empty.Body.Message':'Les détails seront chargés une fois que vous aurez effectué une sélection',
    'Settings.SSO.ModalBody.ProjectSelector.DisabledTitle':'Sélectionner d’abord l\'option correcte',
    'Settings.SSO.ModalBody.ScenarioSelector.DisabledTitle':'Sélectionner premièrement un projet',
    'MursionPortal.Label.LearnerListening':'Écoute de l\'apprenant',
    'MursionPortal.Label.InterruptionCount':'Temps',
    'MursionPortal.ConversationalFlow.label.videoTimeLine':'Chronologie vidéo',
    'MursionPortal.ConversationalFlow.label.zoomTimeLine':'Zoom',
    'MursionPortal.LearnerSimSelector.Sim.Available':'Certifié et disponible',
    'MursionPortal.Button.View':'Voir',
    'MursionPortal.Dashboard.NewSimulationAssigned':'Nouvelle simulation assignée',
    'MursionPortal.Dashboard.MakeUpSimulation':'Simulation imaginée',
    'MursionPortal.Dashboard.MissedSimulation':'Atelier manqué',
    'MursionPortal.Dashboard.CompletedSimulations':'Simulations terminées',
    'MursionPortal.Dashboard.BackToDashboard':'Retour au tableau de bord',
    'MursionPortal.Dashboard.CancelledSimulation':'Atelier manqué',
    'MursionPortal.ScenarioSearchList.Dialog.Title':'Vous avez une disponibilité récurrente planifiée pendant cette période. Souhaitez-vous également mettre à jour votre disponibilité récurrente? ',
    'MursionPortal.ScenarioSearchList.Dialog.UpdateText':'Je souhaite uniquement mettre à jour ma disponibilité pour {datedeDébut}',
    'MursionPortal.ScenarioSearchList.Dialog.UpdateText.ToDate':'Je souhaite mettre à jour ma disponibilité récurrente à partir de {date}',
    'Mursion.Portal.Status.Connected':'CONNECTÉ',
    'Mursion.Portal.Status.Late':'EN RETARD',
    'Mursion.Portal.Status.Immersing':'IMMERSION',
    'Mursion.Portal.Status.Left':'RESTE',
    'Mursion.Portal.Status.Declined':'REFUSÉ',
    'Login.EditFile': 'Modifier {file} et sauvegarder pour actualiser.',
    'Login.Hello': 'Bonjour',
    'Login.Title': 'Bienvenue sur le portail Mursion',
    'Login.Description': 'Concevez, planifiez et gérez vos simulations de réalité virtuelle',
    'Login.PrivacyPolicy': 'politique de confidentialité',
    'Login.PrivacyDataInfo': 'informations sur la protection des données',
    'Login.TechnicalSupport': 'assistance technique',
    'Login.SignIn': 'Se connecter',
    'Login.Next': 'Suivant',
    'Login.Email': 'E-mail',
    'Login.Password': 'Mot de passe',
    'Login.ForgotPassword': 'Mot de passe oublié?',
    'Login.Login': 'Connexion',
    'Login.EmailHint': 'entrer votre Email',
    'Login.PasswordHint': 'tapez votre mot de passe',
    'Login.NewPasswordHint': 'Entrez un nouveau mot de passe',
    'Login.RepeatPasswordHint': 'saisir à nouveau le nouveau mot de passe',
    'Login.Image': 'Logo-Mursion',
    'Filters.Heading': 'FILTRES :',
    'Dashboard.LeftPaneHeader': 'TABLEAU DE BORD',
    'Dashboard.LeftPane.CategoryHeader.Users': 'UTILISATEURS',
    'Dashboard.LeftPane.CategoryHeader.Projects': 'PROJETS',
    'Dashboard.LeftPane.Project.ProjectActivity': 'Activité de projets',
    'Dashboard.LeftPane.Project.ProjectUtilization': 'Utilisation des projets',
    'Dashboard.LeftPane.CategoryHeader.Sessions': 'SESSIONS',
    'Dashboard.LeftPane.Session.SessionLearner': 'Apprenants de la session',
    'Dashboard.LeftPane.CategoryHeader.Legacy': 'DONNÉES ANTERIEURES',
    'Dashboard.VideosTable.HoverTitle': ' ', // TODO: add translations
    'Dashboard.Button.ExportToEmailSentMsg': 'Le rapport sera bientôt envoyé à {email}.',
    'Dashboard.Button.ExportToEmail.HoverTitle': 'Exporter les données',
    'Dashboard.Button.ExportToEmail.HoverTitleNotAvailable': 'L’exportation n’est pas disponible',
    'Dashboard.Next': 'Suivant',
    'Dashboard.Last': 'Dernier',
    'Dashboard.Help': 'Aide',
    'Dashboard.First': 'Premier',
    'Dashboard.Back': 'Retour',
    'FiltersHeading': 'FILTRES :',
    'Filters.Client': 'Client',
    'Filters.ClientPlaceHolderHint': 'Rechercher des clients...',
    'Filters.Industries': 'Secteurs',
    'Filters.IndustriesPlaceHolderHint': 'Rechercher des secteurs...',
    'Filters.IndustriesDisabledTitle': 'Désactivé',
    'Filters.Project': 'Projet',
    'Filters.ProjectPlaceHolderHint': 'Rechercher des projets...',
    'Filters.ProjectDisabledTitle': 'Sélectionner d’abord les clients',
    'Filters.RoleDisabledTitle': 'La sélection du rôle est désactivée',
    'Filters.Scenario': 'Scénario',
    'Filters.ScenarioPlaceHolderHint': 'Rechercher des scénarios…',
    'Filters.ScenarioDisabledTitle': 'Sélectionnez d’abord les clients et les projets',
    'Filters.Team': 'Équipes',
    'Filters.TeamPlaceHolderHint': 'Rechercher des équipes…',
    'Filters.TeamDisabledTitle': 'Sélectionnez d’abord les clients et les projets',
    'Filters.SessionStatus': 'Statut de session',
    'Filters.SessionStatusPlaceHolderHint': 'Rechercher le statut de session...',
    'Filters.RegistrationStatus': 'Statut d’inscription',
    'Filters.RegistrationStatus.ShowAllRegistration': 'Statut d’inscription : Tous',
    'Filters.RegistrationStatus.ShowAcceptedRegistration': 'Inscription acceptée',
    'Filters.RegistrationStatus.ShowNotAcceptedRegistration': 'Inscription non acceptée',
    'Filters.Date.LastMonth': 'Mois dernier',
    'Filters.Date.Yesterday': 'Hier',
    'Filters.Date.Today': 'Aujourd’hui',
    'Filters.Date.Tomorrow': 'Demain',
    'Filters.Date.NextMonth': 'Le mois prochain',
    'Filters.Date.From': 'De',
    'Filters.Date.To': 'à',
    'Dashboard.UserActivityTable.HoverTitle': 'Afficher le récapitulatif utilisateur',
    'Dashboard.Export.ErrMsg': 'La limite maximale d’entrées pour l’exportation est de {limit}. Veuillez modifier votre demande et réessayer.',
    'MursionPortal.Close': 'Fermer',
    'Dashboard.UserTable.Column.Provider': 'Fournisseur',
    'Dashboard.UserTable.Column.Client': 'Client',
    'Dashboard.UserTable.Column.ProfileId': 'ID du profil',
    'Dashboard.UserTable.Column.UserEmail': 'Adresse e-mail de l’utilisateur',
    'Dashboard.UserTable.Column.UserEmail.ButtonHoverTitle': 'Copier l’adresse e-mail',
    'Dashboard.UserTable.Column.SSO': 'Authentification unique',
    'Dashboard.UserTable.Column.DateRegistered.ButtonTitle': 'Renvoyer l’invitation',
    'Dashboard.UserTable.Column.Scenarios': 'Scénarios',
    'Dashboard.UserTable.Column.Teams': 'Équipes',
    'Dashboard.UserTable.Column.Industry': 'Secteur',
    'Dashboard.UserTable.Column.Accepted': 'Accepté',
    'Session.Tab.Scheduled': 'Planifié',
    'Session.Tab.Active': 'Actif',
    'Session.Tab.Past': 'Passé',
    'Session.Tab.Cancelled': 'Annulé',
    'Session.Search': 'Rechercher',
    'Session.Table.Column.Time': 'Heure',
    'Session.Table.Column.Client': 'Client',
    'Session.Table.Column.Project': 'Projet',
    'Session.Table.Column.SoftwareVersion': 'Version du logiciel',
    'Session.Table.Column.Scenario': 'Scénario',
    'Session.Table.Column.Trainees': 'Stagiaires',
    'Session.Table.Column.Training': 'formation',
    'Session.Table.Column.Session': 'session',
    'Session.Token.Label.Clients': 'Clients',
    'Session.Token.Label.Projects': 'Projets',
    'Session.Token.Label.Scenarios': 'Scénarios',
    'Session.Token.Label.Teams': 'Équipes',
    'Session.Token.Label.SessionStatuses': 'Statuts de session',
    'Session.Button.More': 'Plus',
    'Session.Time.NotRunning': 'Pas encore en cours d’exécution',
    'Session.Time.Finished': 'Déjà fini',
    'Session.Time.Running': 'En cours d’exécution',
    'Session.Table.Column.Learners': 'Apprenants',
    'Session.Table.Column.Team': 'Équipe',
    'Session.Table.Column.Trainer': 'Formateur',
    'Session.Table.Column.SimSpecialist': 'Spécialiste Sim',
    'Session.Table.Column.TimeUntil': 'Heure jusqu’à',
    'Session.Table.Column.Created': 'Créé',
    'Session.Table.Column.Cancelled': 'Annulé',
    'Session.Table.Caption.SessionDetailsList': 'Informations concernant la session',
    'Session.Export': 'Exporter',
    'Session.Export.Message': 'Exporter le contenu filtré par',
    'Session.CalendarDate.Start': 'De :',
    'Session.CalendarDate.End': 'À :',
    'Session.Cancel': 'Annuler',
    'Session.Confirm': 'Confirmer',
    'Session.Total': 'TOTAL',
    'Session.Filter.SessionNotFoundMessage': 'Aucune session n’a été trouvée.',
    'Session.Filter.LearnerUpcomingSessionNotFoundMessage': 'Vous n’avez pas de sessions à venir programmées.',
    'Session.Filter.UpcomingSessionNotFoundMessage': 'Aucune session à venir programmée.',
    'Session.Filter.LearnerActiveSessionNotFoundMessage': 'Vous n’avez aucune session active.',
    'Session.Filter.ActiveSessionNotFoundMessage': 'Aucune session active.',
    'Session.Filter.LearnerCompletedSessionNotFoundMessage': 'Vous n’avez encore terminé aucune session.',
    'Session.Filter.SessionReportNotFoundMessage': 'Aucun rapport de session disponible.',
    'Session.Filter.CompletedSessionNotFoundMessage': 'Aucune session n’a été terminée.',
    'Session.Filter.CancelledSessionNotFoundMessage': 'Aucune session n’a été annulée.',
    'Session.Edit.Modal.Table.Column.DateTime': 'Date/Heure',
    'Session.Edit.Modal.Table.Column.UserRole': 'Utilisateur (Rôle)',
    'Session.Edit.Modal.Table.Column.Change': 'Changer',
    'Session.Edit.Modal.Table.Column.SessionStatus': 'Statut de la session',
    'Session.Edit.Modal.Table.Column.Reason': 'Raison',
    'Session.Edit.Modal.AssetIndustry': 'Secteur des ressources',
    'Session.Edit.Modal.SimulationEnvironment': 'Environnement(s) de simulation',
    'Session.Edit.Modal.AvatarInSimulation': 'Avatar(s) dans la(les) simulation(s)',
    'Session.Edit.Modal.Date': 'Date',
    'Session.Edit.Modal.Time': 'Heure',
    'Session.Edit.Modal.DeliveryMode': 'Mode de livraison',
    'Session.Edit.Modal.Duration': 'Durée',
    'Session.Edit.Modal.Status': 'Statut',
    'Session.Edit.Modal.LearnersAttended': 'Apprenants ont assisté',
    'Session.Edit.Modal.ReasonForCancellation': 'Motif d’annulation',
    'Session.Edit.Modal.MoreInfo': 'Plus d’informations…',
    'Session.Edit.Modal.HideAdditionalInfo': 'Masquer les informations supplémentaires…',
    'Session.Edit.Modal.AddToCalendar': 'Ajouter au calendrier',
    'Session.Edit.Modal.AttendSession': 'Assister à une session',
    'Session.Edit.Modal.PleaseWait': 'Veuillez patienter...',
    'Session.Edit.Modal.CopySessionLink': 'Copier le lien de session',
    'Session.Edit.Modal.SessionLink': 'Lien de session',
    'Session.Edit.Modal.SubmitReview': 'Envoyer un avis',
    'Session.Edit.Modal.SessionRequestPending': 'Demande de session en attente',
    'Session.Edit.Modal.Back': 'Retour',
    'Session.Edit.Modal.Learners': 'Apprenant(s) :',
    'Session.Edit.Modal.Learners.UnauthenticatedCheckbox': 'Seuls les utilisateurs non authentifiés se joindront',
    'Session.Edit.Modal.Trainees': 'Stagiaire(s) :',
    'Session.Edit.Modal.Trainer': 'Formateur :',
    'Session.Edit.Modal.SimSpecialist': 'Spécialiste Sim :',
    'Session.Edit.Modal.Notes': 'Remarques :',
    'Session.Edit.Modal.ClientNotes': 'Remarques du client :',
    'Session.Edit.Modal.Notes.EmptyMessage': 'Il n’y a pas de remarques',
    'Session.Edit.Modal.Attendance': 'Assiduité :',
    'Session.Edit.Modal.ShowHistory': 'Afficher l’historique…',
    'Session.Edit.Modal.HideHistory': 'Masquer l’historique…',
    'Session.Edit.Modal.LearnersAttendedLabel': 'Apprenants qui ont assisté :',
    'Session.Edit.Modal.SimulationContent': 'Contenu de simulation',
    'Session.Edit.Modal.SimulationContent.Name': 'Nom :',
    'Session.Edit.Modal.SimulationContent.Description': 'Descriptif :',
    'Session.Edit.Modal.SimulationContent.Image': 'Image :',
    'Session.Edit.Modal.RestoreSession.ConfirmationText': 'Voulez-vous vraiment restaurer cette session ?',
    'Session.Edit.Modal.CancelTraining.ConfirmationText': 'Voulez-vous vraiment annuler cette formation ?',
    'Session.Edit.Modal.CancelSession.ConfirmationText': 'Voulez-vous vraiment annuler cette session ?',
    'Session.Edit.Modal.SessionSwap.ConfirmationText': 'Voulez-vous vraiment accepter l’échange de cette session ?',
    'Session.Edit.Modal.AcceptRequest.ConfirmationText': 'Voulez-vous vraiment accepter cette demande de session ?',
    'Session.Edit.Modal.AssignSS.ConfirmationText': 'Voulez-vous vraiment affecter un nouveau spécialiste Sim ?',
    'Session.Edit.Modal.ChangeSS.ConfirmationText': 'Voulez-vous vraiment changer de spécialiste Sim ?',
    'Session.Edit.Modal.Button.RestoreSession': 'Restaurer la session',
    'Session.Edit.Modal.Button.CancelTraining': 'Annuler la formation',
    'Session.Edit.Modal.Button.CancelSession': 'Annuler la session',
    'Session.Edit.Modal.Button.AcceptSwap': 'Accepter l’échange',
    'Session.Edit.Modal.Button.Participate': 'Participer',
    'Session.Edit.Modal.Button.Swap': 'Échanger',
    'Session.Edit.Modal.Button.UpdateSession': 'Mettre à jour la session',
    'Session.Edit.Modal.Button.UpdateTraining': 'Mettre à jour la formation',
    'Session.Edit.Modal.Button.AcceptRequest': 'Accepter la demande',
    'Session.Edit.Modal.Button.AssignSS': 'Attribuer SS',
    'Session.Edit.Modal.Button.ChangeSS': 'Changer SS',
    'Session.Attendance.Table.Column.UserName': 'Nom d’utilisateur',
    'Session.Attendance.Table.Column.Time': 'Heure',
    'Session.Attendance.Table.Column.Status': 'Statut',
    'Session.ViewSessionReport': 'Afficher le rapport de session',
    'Session.ViewEdit': 'Afficher/modifier la session',
    'Session.Edit.Modal.Table.HistoryNotFoundMessage': 'Il n’y a pas encore d’historique',
    'Session.Export.ErrMsg': 'La limite maximale d’entrées pour l’exportation est de {limit}. Veuillez modifier votre demande et réessayer.',
    'Session.Table.Column.Action': 'Action',
    'Session.Edit.Modal.SessionSimSpecialistField.EmptyMsg.NoTrainers': 'Aucun formateur à sélectionner',
    'Session.Edit.Modal.SessionSimSpecialistField.EmptyMsg.NoSimSpecialists': 'Aucun spécialiste sim à sélectionner',
    'Session.Edit.Modal.SessionSimSpecialistField.NoSelectedMsg.NoTrainers': 'Aucun formateur sélectionné',
    'Session.Edit.Modal.SessionSimSpecialistField.NoSelectedMsg.NoSimSpecialists': 'Aucun spécialiste sim sélectionné',
    'Session.Edit.Modal.SessionSimSpecialistField.EditIconLabel': 'modifier',
    'Session.Edit.Modal.SessionSimSpecialistField.Search.Placeholder': 'Rechercher',
    'Session.Edit.Modal.SessionSimSpecialist.Button.Accept': 'Accepter',
    'ConfirmationDialogue.AddReasonForChange': 'Veuillez ajouter une raison pour le changement',
    'ConfirmationDialogue.Button.Submit': 'Envoyer',
    'ConfirmationDialogue.Button.Cancel': 'Annuler',
    'ConfirmationDialogue.Button.Ok': 'Ok',
    'ConfirmationDialogue.ReasonForChange': 'Raison du changement',
    'ConfirmButton.CancelSession.ConfirmCancellation': 'Confirmer l’annulation',
    'ConfirmButton.CancelSession': 'Annuler la session',
    'ConfirmButton.CancelSession.No': 'Non',
    'Pagination.Button.First': 'Premier',
    'Pagination.Button.Back': 'Retour',
    'MursionPortal.UserName': 'Nom d’utilisateur',
    'MursionPortal.Email': 'E-mail',
    'ClientUserTable.Column.Roles': 'Rôles',
    'MursionPortal.Department': 'Département',
    'MursionPortal.Location': 'Emplacement',
    'ClientUserTable.Column.Projects': 'Projets',
    'ClientUserTable.Column.Teams': 'Équipes',
    'ClientUserTable.Column.Accepted': 'Accepté',
    'ClientUserTable.HoverTitle': 'Afficher/modifier l’utilisateur',
    'ClientUserTable.NoUserError': 'Il n’y a pas encore d’utilisateurs',
    'MursionPortal.FirstName': 'Prénom',
    'MursionPortal.LastName': 'Nom',
    'MursionPortal.Phone': 'Téléphone',
    'Users.TableModal.EmailPlaceHolder': 'exemple@domain.com',
    'Users.TableModal.Title': 'Titre',
    'Users.TableModal.LocationPlaceHolder': 'Ville, Pays',
    'Users.TableModal.DepartmentPlaceHolder': 'Nom du département',
    'Users.TableModal.TitlePlaceHolder': 'Intitulé du poste',
    'Users.Table.Modal.SettingsHeader': 'Paramètres de l’animateur/apprenant',
    'Users.Table.ModalHeader': 'Infos sur l’utilisateur',
    'Users.Table.ModalButton.Cancel': 'Annuler',
    'Users.Table.ModalButton.Delete': 'Supprimer',
    'Users.Table.ModalButton.Save': 'Sauvegarder',
    'Users.Table.ModalButton.SendInvite': 'Envoyer une invitation',
    'Users.Table.ModalButton.Restore': 'Restaurer',
    'Users.Table.Modal.Teams.Text': 'Aucune équipe n’a encore été ajoutée',
    'Users.Table.Modal.Teams': 'Équipes',
    'Users.Table.Modal.EmployeeIdentification': 'Identification des employés',
    'Users.Table.Modal.Timezone': 'Fuseau horaire',
    'Users.Table.Modal.Roles': 'Rôles',
    'Users.Table.Modal.Settings.LearnerButton': 'En tant qu’apprenant',
    'Users.Table.Modal.Settings.FacilitatorButton': 'En tant qu’animateur',
    'Users.Button.CreateUserButton': 'Créer un utilisateur ',
    'Users.Button.Cancel': 'Annuler',
    'Users.Button.ImportUsers': 'Importer des utilisateurs',
    'Users.Button.Export': 'Exporter',
    'Users.heading.ExportContentFilteredBy': 'Exporter le contenu filtré par',
    'Users.Button.Active': 'ACTIF',
    'Users.Button.Archived': 'ARCHIVÉ',
    'Users.Button.All': 'TOUT',
    'Users.Text.Total': 'TOTAL',
    'Users.Heading.NewUsers': 'Nouveaux utilisateurs',
    'Users.Placeholder': 'exemple@domain.com',
    'Users.Button.AddUser': 'Ajouter un utilisateur ',
    'Users.Button.CreateUser': 'Créer un utilisateur',
    'Users.Button.CreateUsers': 'Créer des utilisateurs',
    'Users.Heading.ImportNewUsers': 'Importer de nouveaux utilisateurs',
    'Users.Heading.ImportNewExternalUsers': 'Importer de nouveaux utilisateurs externes',
    'Users.Button.ImportUserList': 'Importer la liste des utilisateurs',
    'Users.Text.InstructionMessage.UsersWithoutPredefined': 'Les utilisateurs sans équipes prédéfinies seront affectés à',
    'Users.Text.AddCsv': 'Ajoutez un fichier .csv ou .xslx contenant une liste d’adresses e-mail placées dans une colonne.',
    'Users.Text.ToImport': 'Pour importer des champs supplémentaires, veuillez suivre ces instructions :',
    'Users.Text.ListUser': 'L’e-mail de l’utilisateur doit être séparé de la liste des équipes d’utilisateurs par une virgule, un point-virgule, une barre verticale ou deux points.',
    'Users.Text.ListTeams': 'Les équipes doivent être séparées les unes des autres par une virgule.',
    'Users.Text.ListFields': 'Les champs et chacune des équipes doivent être spécifiés dans des cellules séparées.',
    'Users.Text.UserRole': 'Le type de rôle de l’utilisateur sera défini par défaut sur Apprenant',
    'Users.Text.UnlessOtherwiseIndicated': 'sauf indication contraire.',
    'Users.Text.SampleImport': 'Vous pouvez télécharger un exemple de fichiers d’importation ici :',
    'Users.DownloadCsvTemplate': 'Télécharger le modèle Csv',
    'Users.DownloadExcelTemplate': 'Télécharger le modèle Excel',
    'Users.Button.Update': 'Mettre à jour',
    'Users.Labels.Clients': 'Clients',
    'Users.Labels.Projects': 'Projets',
    'Users.Labels.Scenarios': 'Scénarios',
    'Users.Labels.Teams': 'Équipes',
    'Users.Labels.Roles': 'Rôles',
    'Users.Text.OnlyAccepted': 'Accepté uniquement :',
    'Users.Button.Confirm': 'Confirmer',
    'Users.Button.AccOwner': 'Propriétaire compte',
    'Users.Button.Facilitator': 'Animateur',
    'Users.Button.Learner': 'Apprenant',
    'Users.Button.ProfServices': 'Services prof.',
    'Users.Button.ProjectManager': 'Chef de projet',
    'Users.Button.SimSpecialist': 'Spécialiste Sim',
    'Users.Button.ITManager': 'Responsable informatique',
    'Users.Button.BusinessAdmin': 'Administrateur métier',
    'Users.Button.SupportAdmin': 'Administrateur assistance',
    'Users.Button.DevAdmin': 'Administrateur développement',
    'Users.Button.ImportExternalUsers': 'Importer des utilisateurs externes',
    'Users.Button.AssignLearners': 'Attribuer des apprenants',
    'Users.CreateUser.Email.Placeholder': 'exemple@domain.com',
    'Users.Text.Instructions': 'Ajoutez un fichier .csv ou .xslx qui contient la liste des utilisateurs avec les champs suivants : email, ssoid, nom, prénom, rôle, équipe. Tous les champs, à l’exception de l’équipe, sont obligatoires.',
    'Users.Text.Guidelines': 'Veuillez suivre ces directives :',
    'Users.Button.CreateUser.PencilIcon': 'Modifier les informations de l’utilisateur',
    'Users.Button.CreateUser.TrashIcon': 'Supprimer l’utilisateur',
    'Users.ExportButton.TextYes': 'Oui',
    'Users.ExportButton.TextNo': 'Non',
    'Users.Button.OK': 'OK',
    'Users.Button.AddProject': 'Ajouter un projet',
    'Users.CreateUser.Label.AddToProjects': 'Ajouter au(x) projet(s)',
    'Users.UserInfo.Label.AddTeams': 'Ajouter des équipes',
    'Users.UserInfo.Label. SelectATeam': 'Sélectionner une équipe',
    'Users.UserInfo.Label.AssignToTeam': 'Attribuer à l’équipe :',
    'Users.CreateUser.Label.NoProjectsSpecified': 'Aucun projet spécifié',
    'Users.Option.SelectClient': 'Sélectionner un client',
    'Users.Label.Client': 'Client :',
    'Users.Label.RemoveTeam': 'Supprimer l’équipe',
    'Users.Label.ChooseATeam': 'Choisir une équipe',
    'Users.Project.Warning': 'Ce client n’a pas de projets. Veuillez en sélectionner un autre.',
    'Users.Project.Warning.UnknownProject': 'Projet inconnu',
    'Users.ProjectContainer.Warning': 'Au moins un projet doit être ajouté',
    'Users.CompanyUsers.Button.Ok': 'Ok',
    'Users.CompanyUsers.Button.Accept': 'Accepter',
    'Users.CreateUser.Label.Tier': 'Niveau',
    'Users.CreateUser.Label.HireDate': 'Date d’embauche',
    'Users.CreateUser.Label.StationType': 'Type de station',
    'Users.CreateUser.ToggleButton.Home': 'Domicile',
    'Users.CreateUser.ToggleButton.Office': 'Bureau',
    'Users.CreateUser.Label.Hours': 'Heures',
    'Users.CreateUser.ToggleButton.FullTime': 'Plein temps',
    'Users.CreateUser.ToggleButton.PartTime': 'Temps partiel',
    'Users.CreateUser.Label.ZoomID': 'ID Zoom',
    'Users.CreateUser.ZoomID.Placeholder': 'ID Zoom',
    'Users.CreateUser.ZoomID.Hover': 'Veuillez saisir un ID Zoom',
    'Users.CreateUser.Label.UserRoles': 'Rôle(s) de l’utilisateur',
    'Users.CreateUser.Label.ProjectManagerSettings': 'Paramètres du gestionnaire de projet',
    'Users.CreateUser.Label.SimSpecialistSettings': 'Paramètres du spécialiste Sim',
    'Users.CreateUser.Label.Certificates': 'Certificats',
    'Users.CreateUser.Label.TurnOnEditCertificates': 'Activer la modification des certificats',
    'Users.CreateUser.Label.TurnOffEditCertificates': 'Désactiver la modification des certificats',
    'Users.CreateUser.Label.NoCertificatesHaveBeenAddedYet': 'Aucun certificat n’a encore été ajouté',
    'Users.CreateUser.Label.AddCertificate': 'Ajouter un certificat',
    'Users.CreateUser.Label.CertificateOwner': 'Propriétaire du certificat',
    'Users.CreateUser.Button.RemoveCertificate': 'Supprimer le certificat',
    'Users.TableHeading.Name': 'Nom',
    'Users.TableHeading.Email': 'E-mail',
    'Users.TableHeading.Roles': 'Rôles',
    'Users.TableHeading.Location': 'Emplacement',
    'Users.TableHeading.Accepted': 'Accepté',
    'Users.Table.Hover.ViewEditUser': 'Afficher/modifier l’utilisateur',
    'Users.Table.Hover.NoUsersYet': 'Il n’y a pas encore d’utilisateurs',
    'Users.CreateUser.Button.Client': 'Client',
    'Users.CreateUser.Label.ChooseAClient': 'Choisir un client',
    'Users.CreateUser.Label.SelectAClient': 'Sélectionner un client',
    'Users.CreateUser.Label.ChooseACertificate': 'Choisir un certificat',
    'Users.CreateUser.Label.SelectACertificate': 'Sélectionner un certificat',
    'Users.CreateUser.Label.NoCertificatesToSelect': 'Aucun certificat à sélectionner',
    'Users.CreateUser.Button.AddCertificate': 'Ajouter un certificat ',
    'Users.CreateUser.PlaceHolder.SelectTier': 'Sélectionner un niveau',
    'Users.RequiredField.Warning': 'Veuillez vérifier que tous les champs sont correctement remplis',
    'Users.RequiredField.ApplyChanges': 'Appliquer les modifications',
    'Users.Heading.NewUserInfo': 'Infos sur le nouvel utilisateur',
    'Users.SearchPlaceHolderText': 'Saisir le nom de l’utilisateur',
    'Users.SearchNoUserFoundError': 'Aucun utilisateur client n’a été trouvé.',
    'Users.Table.Modal.Settings.AddTeamHeader': 'Ajouter des équipes',
    'Users.Table.Modal.Settings.ChooseTeamHeader': 'Choisir une équipe',
    'Users.Table.Modal.Settings.AssignTeamHeader': 'Attribuer à l’équipe :',
    'Users.Table.Modal.Settings.SelectTeamOptionHeader': 'Sélectionner une équipe',
    'Users.Table.Modal.Settings.TeamLearner': '(apprenant)',
    'Users.Table.Modal.Settings.TeamFacilitator': '(animateur)',
    'Users.Table.Modal.Settings.RemoveTeamOption': 'Supprimer l’équipe',
    'Users.Table.Modal.Settings.TurnOnEditTeamsOption': 'Activer la modification des équipes',
    'Users.Table.Modal.Settings.TurnOffEditTeamsOption': 'Désactiver la modification des équipes',
    'Users.Total': 'TOTAL',
    'Users.Table.Modal.ArchiveHeader.ConfirmArchiveUser': 'Confirmer l’archivage de l’utilisateur',
    'Users.Table.Modal.ArchiveText': 'Voulez-vous vraiment archiver cet utilisateur ?',
    'Users.Table.Modal.ArchiveUserButton': 'Archiver l’utilisateur',
    'Users.Table.Modal.RestoreHeader.ConfirmRestoreUser': 'Confirmer la restauration de l’utilisateur',
    'Users.Table.Modal.RestoreText': 'Voulez-vous vraiment restaurer cet utilisateur ?',
    'Users.Table.Modal.RestoreUserButton': 'Restaurer l’utilisateur',
    'Users.Table.Modal.CancelButton': 'Annuler',
    'Users.Table.Modal.ArchiveButton': 'Archiver',
    'Users.Table.Modal.RestoreButton': 'Restaurer',
    'Users.Table.Modal.SendInviteButton': 'Envoyer une invitation',
    'Users.CreateUserButton.Modal.CreateUserButton': 'Créer un utilisateur',
    'Users.Table.Modal.UpdateUserButton': 'Mettre à jour l’utilisateur',
    'Users.ViewUserOptions': 'Afficher l’utilisateur {companyName}',
    'Users.NewUserOptions': 'Nouvel utilisateur {companyName}',
    'Users.EditUserOptions': 'Modifier l’utilisateur {companyName}',
    'Reports.SessionListHeader.Completed': 'Terminée',
    'Reports.SessionListHeader.TotalSessionTime': 'Durée totale de la session,',
    'Reports.SessionListHeader.TotalActualTime': 'Temps réel total,',
    'Reports.SessionListHeader.SimulationCompletionTime': 'Heure de fin de la simulation',
    'Reports.SessionListHeader.CantOpenReport': 'Veuillez patienter pendant que notre moteur d’analyse traite vos résultats. Vous devrez peut-être actualiser cette page après quelques minutes.',
    'Reports.SessionListHeader.NoReportsFound': 'Aucun rapport trouvé.',
    'Reports.ReportCard.Button.ReviewSimulation': 'EXAMINER LA SIMULATION',
    'Reports.ReportCard.Strategies': 'Stratégies',
    'Reports.ReportCard.NotChecked': 'Non vérifié',
    'Dashboard.SchedulingInfoTable.Modal.SortBy': 'Trier par :',
    'Dashboard.SchedulingInfoTable.Modal.NoItemsFound': 'Aucun élément n’a été trouvé.',
    'Dashboard.SchedulingInfoTable.Modal.Teams': 'Équipes',
    'Dashboard.SchedulingInfoTable.Modal.Teams.Placeholder': 'Saisir le nom de l’équipe',
    'Dashboard.SchedulingInfoTable.Modal.Teams.EmptyMessage': 'Aucune équipe n’a été trouvée',
    'Dashboard.SchedulingInfoTable.Modal.Capitalized.TeamName': 'Nom de l’équipe :',
    'Dashboard.SchedulingInfoTable.Modal.Learners': 'Apprenants :',
    'Dashboard.SchedulingInfoTable.Modal.Projects': 'Projets :',
    'Dashboard.SchedulingInfo.Filter.SearchIndustries.PlaceholderHint': 'Rechercher des secteurs...',
    'Dashboard.SchedulingInfo.Filter.IndustryDisabledTitle': 'Désactivé',
    'Dashboard.SchedulingInfo.Filter.UsedSoftwareVersion.PlaceholderHint': 'Versions logicielles utilisées…',
    'Dashboard.SchedulingInfoTable.Modal.Total': 'TOTAL',
    'Dashboard.SchedulingInfoTable.Modal.ScenarioName': 'Nom du scénario',
    'Dashboard.SchedulingInfoTable.Modal.SessionDate': 'Date de la session',
    'Dashboard.SchedulingInfoTable.Modal.ProjectName': 'Nom du projet',
    'Dashboard.SchedulingInfoTable.Modal.SoftwareVersion': 'Version du logiciel',
    'Dashboard.SchedulingInfoTable.Modal.Capitalized.DeliveryMode': 'Mode de livraison',
    'Dashboard.SchedulingInfoTable.Modal.SessionStatus': 'Statut de la session',
    'Dashboard.SchedulingInfoTable.Modal.Name': 'Nom',
    'Dashboard.SchedulingInfoTable.Modal.Status': 'Statut',
    'Dashboard.SchedulingInfoTable.Modal.Recording': 'L’enregistrement va à',
    'Dashboard.SchedulingInfoTable.Modal.TeamName': 'Nom de l’équipe',
    'Dashboard.SchedulingInfoTable.Modal.Learner': 'Apprenants',
    'Dashboard.SchedulingInfoTable.Modal.CompletedSessions': 'Sessions terminées',
    'Dashboard.SchedulingInfoTable.Modal.DeliveryMode': 'Mode de livraison',
    'Dashboard.SchedulingInfoTable.Modal.Capitalized.ProjectName': 'Nom du projet',
    'Dashboard.SchedulingInfoTable.Modal.Capitalized.SoftwareVersion': 'Version du logiciel',
    'Dashboard.SchedulingInfoTable.Modal.Timeline': 'Chronologie',
    'Dashboard.SchedulingInfoTable.Modal.SchedulesSessions': 'Sessions planifiées',
    'Dashboard.SchedulingInfoTable.Modal.MissedSessions': 'Sessions mixtes',
    'Dashboard.SchedulingInfoTable.Modal.LateCancellations': 'Annulations « tardives »',
    'Dashboard.SchedulingInfoTable.Modal.TimeSpent': 'Durée passée',
    'Dashboard.SchedulingInfoTable.Modal.CancelledBy': 'Annulé par',
    'Dashboard.SchedulingInfoTable.Modal.CancellationReason': 'Motif de l’annulation',
    'Dashboard.SchedulingInfoTable.Modal.DateScheduled': 'Date prévue',
    'Dashboard.SchedulingInfoTable.Modal.DateCancelled': 'Date annulation',
    'Dashboard.SchedulingInfoTable.Modal.Filter.Teams': 'Équipes',
    'Dashboard.SchedulingInfoTable.Modal.Placeholder.EnterTeamName': 'Saisir le nom de l’équipe',
    'Dashboard.SchedulingInfoTable.Modal.TeamsEmptyMessage': 'Aucune équipe n’a été trouvée',
    'Dashboard.SchedulingInfoTable.Modal.Filter.TeamName': 'Nom de l’équipe',
    'Dashboard.SchedulingInfoTable.Modal.Filter.Scenarios': 'Scénarios',
    'Dashboard.SchedulingInfoTable.Modal.Placeholder.EnterScenarioName': 'Saisir un nom de scénario',
    'Dashboard.SchedulingInfoTable.Modal.ScenariosEmptyMessage': 'Aucun scénario n’a été trouvé',
    'Dashboard.SchedulingInfoTable.Modal.Filter.ScenarioName': 'Nom du scénario',
    'Dashboard.SchedulingInfoTable.Modal.Filter.ProjectName': 'Nom du projet',
    'Dashboard.SchedulingInfoTable.Modal.Filter.SoftwareVersion': 'Version du logiciel',
    'Dashboard.SchedulingInfoTable.Modal.Filter.ScenarioStatus': 'Statut du scénario',
    'Dashboard.SchedulingInfoTable.Modal.Filter.DeliveryMode': 'Mode de livraison',
    'Dashboard.SchedulingInfoTable.Modal.Filter.SessionsScheduled': 'Sessions prévues',
    'Dashboard.SchedulingInfoTable.Modal.SessionsEmptyMessage': 'Aucune session n’a été trouvée',
    'Dashboard.SchedulingInfoTable.Modal.Filter.SessionsDateTime': 'Date/heure de la session',
    'Dashboard.SchedulingInfoTable.Modal.Filter.SessionsCompleted': 'Sessions terminées',
    'Dashboard.SchedulingInfoTable.Modal.Filter.SessionsMissed': 'Sessions manquées',
    'Dashboard.SchedulingInfoTable.Modal.Filter.LateCancellations': 'Annulations « tardives »',
    'Dashboard.SchedulingInfoTable.Modal.Filter.CancelledBy': 'Annulé par',
    'Dashboard.SchedulingInfoTable.Modal.Filter.SessionsError': 'Erreur de sessions',
    'RestorePassword.EnterEmailAddress': 'Saisissez votre adresse e-mail et cliquez sur le bouton Restaurer. Un e-mail vous sera envoyé avec un lien pour réinitialiser votre mot de passe.',
    'RestorePassword.Email': 'E-mail',
    'RestorePassword.Button.Cancel': 'Annuler',
    'RestorePassword.Button.Restore': 'Restaurer',
    'RestorePassword.Modal.IfValidEmailAddressEntered': 'Si une adresse e-mail valide a été saisie, un e-mail contenant des instructions de réinitialisation a été envoyé au compte associé. Contactez l\'assistance technique si le problème persiste :',
    'RestorePassword.Modal.Button.GotIt': 'OK',
    'Trainings.Heading.AllTrainingSessions': 'toutes les sessions de formation',
    'Requests.Table.Swap': 'SWAP',
    'Requests.Table.SchedulingRequests': 'Planification des demandes',
    'Requests.Total': 'TOTAL',
    'Requests.Table.NoSessionsFound': 'Aucune session n’a été trouvée.',
    'Requests.Table.NoUpcomingSwapSessions': 'Il n’y a pas de sessions SWAP à venir.',
    'Requests.Table.NoUpcomingRequests': 'Vous n’avez aucune demande à venir.',
    'GetTimeToEvents.Day': 'Jour',
    'GetTimeToEvents.Days': 'Jours',
    'GetTimeToEvents.Minute': 'Minute',
    'GetTimeToEvents.Minutes': 'Minutes',
    'GetTimeToEvents.Hour': 'Heure',
    'GetTimeToEvents.Hours': 'Heures',
    'GetTimeToEvents.LessThanOneMinute': 'Moins d’une minute',
    'SessionReports.SessionInformation': 'Informations sur la session',
    'SessionReports.SessionInformation.Hover.Information': 'Informations',
    'SessionReports.SmartMetrics': 'Indicateurs intelligents',
    'SessionReports.SendVideoLink.Modal.ShareLinkToVideo': 'Partager le lien vers la vidéo',
    'SessionReports.Button.BackToReports': 'Retour aux rapports',
    'SessionReports.Button.BackToTrainings': 'Retour aux formations',
    'SessionReports.Button.HideInfo': 'Masquer les infos',
    'SessionReports.Button.ShowInfo': 'Afficher les infos',
    'SessionReports.Button.SendVideoLink': 'Envoyer le lien vidéo',
    'SessionReports.TryAgain': 'Réessayer',
    'SessionReports.Mode': 'Mode :',
    'SessionReports.SessionLength': 'Durée de la session :',
    'SessionReports.LearnersAttended': 'Apprenants qui ont assisté :',
    'SessionReports.ScenarioTimeline': 'Chronologie du scénario :',
    'SessionReports.ActualSessionTime': 'Durée réelle de la session :',
    'SessionReports.ActualSimulationTime': 'Temps de simulation réel :',
    'SessionReports.LastUserStatus': 'Statut du dernier utilisateur :',
    'SessionReports.SessionStatus': 'Statut de la session :',
    'SessionReports.Delivery': 'Livraison',
    'SessionReports.SendVideoLink.Modal.AddEmails': 'Ajouter des adresses e-mail',
    'SessionReports.SendVideoLink.Modal.Button.AddEmail': 'Ajouter une adresse e-mail ',
    'SessionReports.SendVideoLink.Modal.EmailPlaceHolder': 'exemple@domain.com',
    'SessionReports.SendVideoLink.Modal.Button.Cancel': 'Annuler',
    'SessionReports.SendVideoLink.Modal.Button.Send': 'Envoyer',
    'SessionReports.FactSessionInfo': 'Infos sur la session',
    'SessionReports.Text.NoTraineeParticipatedInSession': 'Aucun stagiaire n’a participé à la session',
    'SessionReports.Text.NoLearnerParticipatedInSession': 'Aucun apprenant n’a participé à la session',
    'SessionReports.ToolTip.TrackingYours': 'Il est très important que votre perception d’un résultat ou d’un élément de stratégie corresponde à celle du ou des avatars. Cela indique que vous suivez votre impact sur le ou les avatars, contribuant ainsi à améliorer l’efficacité sociale. Ces informations figurent dans le tableau ci-après.',
    'SessionReports.ToolTip.TrackingLearners': 'Il est très important que la perception d’un apprenant d’un résultat ou d’un élément de stratégie corresponde à celle du ou des avatars. Cela indique que l’apprenant suit son impact sur le ou les avatars, contribuant ainsi à améliorer l’efficacité sociale. Ces informations figurent dans le tableau ci-après.',
    'SessionReports.ToolTip.AvatarsHeaderYours': 'Cette colonne indique la perception du ou des avatars de vos performances de simulation. Une coche indique que les avatars ont perçu que vous réussissiez à atteindre le résultat tandis qu’une croix indique qu’il y avait une marge d’amélioration.',
    'SessionReports.ToolTip.AvatarsHeaderLearners': 'Cette colonne indique la perception des avatars sur les performances de simulation de l’apprenant. Une coche indique que les avatars ont perçu que l’apprenant réussissait à atteindre le résultat, tandis qu’une croix indique qu’il y avait une marge d’amélioration.',
    'SessionReports.ToolTip.SelfHeaderYours': 'Cette colonne indique votre propre évaluation de vos performances de simulation. Une coche indique que vous pensiez avoir réussi, tandis qu’une croix indique qu’il y avait une marge d’amélioration.',
    'SessionReports.ToolTip.SelfHeaderLearners': 'Cette colonne indique l’évaluation par l’apprenant de ses performances de simulation. Une coche indique que l’apprenant pensait avoir réussi, tandis qu’une croix indique qu’il y avait une marge d’amélioration.',
    'SessionReports.ToolTip.VideoLink': 'Vous pouvez partager un lien vers votre vidéo de performance de simulation avec vos pairs. Pour ce faire, saisissez simplement leur adresse e-mail dans la fenêtre contextuelle. Notez que vos analyses ne seront pas partagées avec vos pairs.',
    'SessionReports.ToolTip.SmartMetrics': 'Les indicateurs intelligents sont générés grâce à l’utilisation d’algorithmes avancés. Observez ces indicateurs générés par ordinateur pour voir si ils ont un impact sur votre suivi global au cours de diverses simulations.',
    'SessionReports.ToolTip.MiScore': 'Le score de l’indice de Mursion (IM) est un indicateur de votre efficacité sociale au cours de cette conversation. Il est calculé en utilisant un panel de données, y compris votre modèle de prise de tour de conversation et son impact sur le ou les avatars. Essayez d’évaluer votre impact sur le ou les avatars et abordez les conversations de différentes manières pour voir comment vous pouvez avoir un impact positif sur votre score IM.',
    'SessionReports.ToolTip.ListeningSpeakingTime': 'Il s’agit d’un indicateur montrant le rapport entre le temps que vous avez passé à parler et à écouter pendant la conversation.',
    'SessionReports.ToolTip.Emotions': 'Émotions (bêta) : nous étudions actuellement des algorithmes qui nous permettent d’interpréter vos modèles de voix pour déterminer le sentiment général. Observez ces données pour voir si vous aviez une connotation positive, négative ou neutre dans vos schémas vocaux au fil du temps. Plus important encore, observez l’impact que vous avez eu sur les modèles vocaux des avatars.',
    'SessionReports.ToolTip.TurnTracking': 'La prise de parole est un ensemble de mesures qui permettent de mieux comprendre la nature collaborative / l’efficacité sociale entre vous et les avatars.',
    'SessionReports.ToolTip.Pauses': 'Les pauses sont une mesure indirecte de votre maîtrise de la conversation.',
    'SessionReports.ToolTip.ArticulationRate': 'Il s’agit d’une mesure du rythme auquel vous fournissez un ensemble continu d’informations.',
    'SessionReports.TableHeader.Outcome': 'Résultat',
    'SessionReports.TableHeader.Self': 'Vous-même',
    'SessionReports.TableHeader.Avatars': 'Avatar(s)',
    'SessionReports.TableHeader.Strategies': 'Stratégies',
    'MursionPortal.Button.Cancel': 'Annuler',
    'Settings.Config.Heading.Industries': 'SECTEURS',
    'Settings.Config.Button.New': 'Nouveau',
    'Settings.Config.Note': 'REMARQUE :',
    'Settings.Config.Note.Text.ConfigSettingsGlobal': 'Les paramètres de configuration sont généraux. Les modifications s’appliqueront à tous les clients et utilisateurs.',
    'Settings.Config.Heading.SimSpecialist': 'NIVEAUX SPÉCIALISTE SIM',
    'Settings.Config.Heading.Scheduling': 'PROGRAMMATION',
    'Settings.Config.Heading.Scheduling.Description': '(Spécialiste Sim)',
    'Settings.Config.Scheduling.MaximumFullTimeSimSpecialistHours': 'Nombre maximum d’heures d’un spécialiste Sim à plein temps',
    'Settings.Config.Scheduling.MaximumPartTimeSimSpecialistHours': 'Nombre maximum d’heures d’un spécialiste Sim à temps partiel',
    'Settings.Config.Scheduling.MaximumContinuousSimSpecialistHours': 'Nombre maximum d’heures d’un spécialiste Sim en continu',
    'Settings.Config.Scheduling.LengthOfSimSpecialistRequiredBreak': 'Durée de la pause requise par le spécialiste Sim',
    'Settings.Config.Scheduling.FutureAvailabilityForSimSpecialist': 'Fenêtre de disponibilité future pour les spécialistes Sim',
    'Settings.Config.Scheduling.SimSpecialistShiftTime': 'Temps de travail du spécialiste Sim',
    'Settings.Config.Scheduling.AvailableTimeBlockMaximumPerDay': 'Bloc horaire de disponibilité maximum par jour',
    'Settings.Config.Scheduling.AvailableTimeBlockMinimumPerDay': 'Bloc horaire de disponibilité minimum par jour',
    'Settings.Config.Scheduling.AvailabilityTimeStep': 'Intervalle de temps disponible',
    'Settings.Config.Scheduling.AdvanceSSScheduleConfirmation': 'Combien de temps à l’avance le calendrier SS doit-il être confirmé ?',
    'Settings.Config.Scheduling.RequestUnfulfilledReminder': 'Quand envoyer le rappel « la demande n’est pas satisfaite »',
    'Settings.Config.Scheduling.FirstEmailReminder': 'Quand envoyer le premier rappel par e-mail',
    'Settings.Config.Scheduling.SecondEmailReminder': 'Quand envoyer un deuxième e-mail de rappel',
    'Settings.Config.Scheduling.BookedSessionWithNoAssignedLearner': 'Quand notifier une session réservée sans apprenant affecté',
    'Settings.Config.Scheduling.UnfulfilledSessionSwapRequestReminder': 'Quand envoyer un rappel concernant la demande d’échange de session non satisfaite',
    'Settings.Config.Scheduling.NextSessionReminder': 'Quand envoyer le prochain rappel de session en fonction du temps écoulé entre la création de la session et l’heure de début de la session programmé',
    'Settings.Config.Scheduling.ReservedAvailabilityExpired': 'Quand la disponibilité réservée a expiré',
    'Settings.Config.Scheduling.StartTimeForConsideringMissedSession': 'Durée après l’heure de début lorsqu’une session est considérée comme « manquée »',
    'Settings.Config.Scheduling.EndTimeForConsideringMissedSession': 'Durée après l’heure de fin lorsqu’une session est considérée comme « manquée »',
    'Settings.Config.Scheduling.RejoinSessionAfterError': 'Le temps dont disposent les participants à la session pour rejoindre la session après une erreur',
    'Settings.Config.Scheduling.TimeDisabledForSessionScheduling': 'Durée désactivée pour la planification de session',
    'Settings.Config.Scheduling.SessionTimeStep': 'Intervalle de temps de la session',
    'Settings.Config.Heading.ProjectAndScenario': 'PROJET ET SCÉNARIO',
    'Settings.Config.ProjectAndScenario.MaximumProjectTimelineDuration': 'Durée maximale de la chronologie du projet',
    'Settings.Config.ProjectAndScenario.MinimumProjectTimelineDuration': 'Durée minimale de la chronologie du projet',
    'Settings.Config.ProjectAndScenario.MaximumScenarioTimelineDuration': 'Durée maximale de la chronologie du scénario',
    'Settings.Config.ProjectAndScenario.MinimumScenarioTimelineDuration': 'Durée minimale de la chronologie du scénario',
    'Settings.Config.ProjectAndScenario.MinimumSessionLength': 'Durée minimale de la session',
    'Settings.Config.ProjectAndScenario.MaximumSessionLength': 'Durée maximale de la session',
    'Settings.Config.ProjectAndScenario.MinimumSimulationLength': 'Durée minimale de la simulation',
    'Settings.Config.ProjectAndScenario.SessionLengthStep': 'Durée de la session',
    'Settings.Config.ProjectAndScenario.MinimumLearnersInRemoteSession': 'Nombre minimum d’apprenants dans les sessions à distance',
    'Settings.Config.ProjectAndScenario.MaximumLearnersInRemoteSession': 'Nombre maximum d’apprenants dans les sessions à distance',
    'Settings.Config.ProjectAndScenario.MinimumLearnersInFishbowlSession': 'Nombre minimum d’apprenants dans les sessions locales de fishbowl',
    'Settings.Config.ProjectAndScenario.MaximumLearnersInFishbowlSession': 'Nombre maximum d’apprenants dans les sessions locales de fishbowl',
    'Settings.Config.ProjectAndScenario.MinimumLearnersInGroupSession': 'Nombre minimum d’apprenants dans les sessions de groupe',
    'Settings.Config.ProjectAndScenario.MaximumLearnersInGroupSession': 'Nombre maximum d’apprenants dans les sessions de groupe',
    'Settings.Config.ProjectAndScenario.AllowedScenarioVersions': 'Versions de scénario autorisées',
    'Settings.Config.ProjectAndScenario.DefaultScenarioVersion': 'Version de scénario par défaut',
    'Settings.Config.Heading.CompanyEvent': 'ÉVÉNEMENT D’ENTREPRISE',
    'Settings.Config.CompanyEvent.MaximumEventDuration': 'Durée maximale de l’événement',
    'Settings.Config.CompanyEvent.MinimumEventDuration': 'Durée minimale de l’événement',
    'Settings.Config.CompanyEvent.EventTimeStep': 'Intervalle de temps de l’évènement',
    'Settings.Config.Heading.SessionReport': 'RAPPORT DE SESSION',
    'Settings.Config.SessionReport.ShowSessionReport': 'Afficher le rapport de session',
    'Settings.Config.Heading.FeedbackProvider': 'FOURNISSEUR D’ÉVALUATION',
    'Settings.Config.FeedbackProvider.ZenDeskSnippetKey': 'Clé d’extrait de code ZenDesk',
    'Settings.Config.Heading.SimulationContent': 'CONTENU SIMULATION',
    'Settings.Config.SimulationContent.SimulationContentFolder': 'Dossier contenu simulation S3',
    'Settings.Config.Heading.Invitation': 'INVITATION',
    'Settings.Config.Invitation.ExpireUserRegistrationInvitation': 'Quand faire expirer l’invitation d’inscription utilisateur',
    'Settings.Config.Invitation.ReminderToAcceptRegistrationInvitation': 'Quand rappeler aux utilisateurs non enregistrés d’accepter l’invitation d’inscription',
    'Settings.Config.Invitation.RegistrationInvitationExpiresAfter': 'L’invitation d’inscription expire après',
    'Settings.Config.IndustriesButton.Modal.EditIndustry': 'Modifier le secteur',
    'Settings.Config.NewButton.Modal.NewIndustry': 'Nouveau secteur',
    'Settings.Modal.Placeholder.IndustryName': 'Nom du secteur',
    'Settings.Config.IndustriesButton.Modal.Button.UpdateIndustry': 'Mise à jour du secteur',
    'Settings.Config.NewButton.Modal.Button.CreateIndustry': 'Créer un secteur',
    'Settings.Config.IndustriesButton.Modal.Button.RestoreIndustry': 'Restaurer le secteur',
    'Settings.Config.IndustriesButton.Modal.Button.ArchiveIndustry': 'Archiver le secteur',
    'MursionPortal.Modal.Heading.Name': 'Nom',
    'Settings.Config.RestoreIndustry.Modal.ConfirmRestore': 'Voulez-vous vraiment restaurer ce secteur ?',
    'Settings.Config.ArchiveIndustry.Modal.ConfirmArchive': 'Voulez-vous vraiment archiver ce secteur ?',
    'Settings.Config.Description.PerWeek': '(par semaine)',
    'Settings.Config.Description.PerDay': '(par jour)',
    'Settings.Config.Description.BeforeBreak': '(avant la pause)',
    'Settings.Config.Description.Minutes': '(minutes)',
    'Settings.Config.Description.Days': '(jours)',
    'Settings.Config.Description.Hours': '(heures)',
    'Settings.Config.TierButton.Modal.Button.UpdateTier': 'Mettre à jour le niveau',
    'Settings.Config.TierButton.Modal.Heading.EditTier': 'Modifier le niveau',
    'Settings.Config.TierButton.Modal.Placeholder.TierName': 'Nom du niveau',
    'MursionPortal.Button.Archive': 'Archiver',
    'Settings.Config.ArchiveIndustry.Modal.Heading.ConfirmArchive': 'Confirmer l’archivage',
    'Settings.Config': 'Config',
    'Settings.ScenarioBank': 'Banque de scénarios',
    'Settings.Environments': 'Environnements (ML2)',
    'Settings.Avatars': 'Avatars (ML2)',
    'Settings.SSO': 'Authentification unique',
    'Settings.Error.NoSettingsAvailable': 'Aucun paramètre disponible',
    'MursionPortal.Error.SomethingWentWrong': 'Désolé, un problème est survenu.',
    'MursionPortal.Error.ReloadingPage': 'Essayez de rafraîchir la page. L’erreur a été signalée.',
    'MursionPortal.Error.WorkingHard': 'Nous travaillons dur pour y remédier le plus rapidement possible.',
    'MursionPortal.Error.SupportAvailable': 'L’assistance est disponible du lundi au vendredi',
    'MursionPortal.Error.TechnicalAssistance': 'Si vous avez besoin d’assistance technique, veuillez contacter le service d’assistance de Mursion',
    'MursionPortal.At': 'à',
    'MursionPortal.Or': 'ou',
    'Settings.Environment.Modal.PlaceHolder.EnvironmentName': 'Nom de l’environnement',
    'Settings.Environment.Modal.Description': 'Description',
    'Settings.Environment.Modal.Industry': 'Secteur',
    'Settings.Environment.Modal.Picture': 'Image',
    'MursionPortal.Button.Delete': 'Supprimer',
    'Settings.Environment.Modal.Button.UploadFile': 'Importer un fichier',
    'Settings.Environment.Button.CreateEnvironment': 'Créer un environnement ',
    'Settings.Environment.ModalHeader.NewEnvironment': 'Nouvel environnement',
    'Settings.Environment.ModalHeader.Environment': 'Environnement',
    'Settings.Environment.ModalHeader.Archived': '(Archivé)',
    'Settings.Environment.ModalHeader.EditEnvironment': 'Modifier l’environnement',
    'Settings.Environment.Modal.Button.CreateEnvironment': 'Créer un environnement',
    'Settings.Environment.Modal.Button.UpdateEnvironment': 'Mettre à jour l’environnement',
    'Settings.Environment.Modal.ArchiveEnvironmentText': 'Voulez-vous vraiment archiver cet environnement ?',
    'Settings.Environment.Modal.RestoreEnvironmentText': 'Voulez-vous vraiment restaurer cet environnement ?',
    'MursionPortal.Button.Restore': 'Restaurer',
    'MursionPortal.Total': 'TOTAL',
    'MursionPortal.Text.Total': 'TOTAL',
    'MursionPortal.Button.ExportToEmail.HoverTitle.ExportData': 'Exporter les données',
    'MursionPortal.Button.ExportToEmail.HoverTitle.ExportNotAvailable': 'L’exportation n’est pas disponible',
    'MursionPortal.Button.ExportToEmail': 'Exporter par e-mail',
    'MursionPortal.WarningMessage.RequestNotImplemented': 'La demande de données n’est pas encore implémentée',
    'MursionPortal.Table.ColumnHeader.Client': 'Client',
    'MursionPortal.Table.ColumnHeader.Provider': 'Fournisseur',
    'MursionPortal.Table.ColumnHeader.Project': 'Projet',
    'MursionPortal.Table.ColumnHeader.Scenario': 'Scénario',
    'MursionPortal.Table.ColumnHeader.SoftwareVersion': 'Version du logiciel',
    'MursionPortal.Table.ColumnHeader.DeliveryMode': 'Mode de livraison',
    'MursionPortal.Table.ColumnHeader.SessionStart': 'Début de session',
    'MursionPortal.Table.ColumnHeader.SessionEnd': 'Fin de session',
    'MursionPortal.Table.ColumnHeader.LearnerStatus': 'Statut de l’apprenant',
    'MursionPortal.Table.ColumnHeader.SessionStatus': 'Statut de la session',
    'MursionPortal.Table.ColumnHeader.LearnerName': 'Nom de l’apprenant',
    'MursionPortal.Table.ColumnHeader.LearnerEmail': 'Adresse e-mail de l’apprenant',
    'MursionPortal.Table.LearnerEmail.HoverTitle.CopyEmail': 'Copier l’adresse e-mail',
    'MursionPortal.Table.LearnerEmail.HoverTitle.UserEmail': 'Adresse e-mail de l’utilisateur',
    'MursionPortal.Table.ColumnHeader.SessionId': 'Id de Session',
    'MursionPortal.Table.SessionId.HoverTitle.CopySessionId': 'Copier l’Id de session',
    'MursionPortal.Table.ColumnHeader.SessionReport': 'Rapport de session',
    'MursionPortal.Table.ColumnHeader.SimSpecialistName': 'Nom du spécialiste Sim',
    'MursionPortal.Table.ColumnHeader.LearnerTimeSpent': 'Temps passé par l’apprenant',
    'MursionPortal.Table.HoverTitle.SessionSummary': 'Résumé de la session',
    'MursionPortal.EmptyMessage.NoSessionsYet': 'Il n’y a pas encore de sessions',
    'MursionPortal.Button.ViewReport': 'Afficher le rapport',
    'Mursion.Portal.Status.Requested': 'Demandé',
    'Mursion.Portal.Status.Pending': 'En attente',
    'Mursion.Portal.Status.PendingSwap': 'En attente (échange)',
    'Mursion.Portal.Status.PreBooked': 'Pré-réservé',
    'Mursion.Portal.Status.Booked': 'Réservé',
    'Mursion.Portal.Status.Active': 'Actif',
    'Mursion.Portal.Status.Complete': 'Terminé',
    'Mursion.Portal.Status.Error': 'Erreur',
    'Mursion.Portal.Status.Missed': 'Manqué',
    'Mursion.Portal.Status.NeedsReview': 'Nécessite une révision',
    'Mursion.Portal.Status.Cancelled': 'Annulé',
    'Dashboard.UsersActivity.Filter.Roles.Placeholder': 'Rechercher…',
    'Settings.ScenarioBank.Table.ColumnHeader.Name': 'Nom',
    'MursionPortal.DeliveryMode': 'Mode de livraison',
    'Settings.ScenarioBank.Table.ColumnHeader.SessionSize': 'Taille de la session',
    'MursionPortal.SessionLength': 'Durée de la session',
    'Settings.ScenarioBank.Table.ViewEditScenario': 'Afficher/modifier le scénario',
    'Settings.ScenarioBank.Table.ThereAreNoScenarios': 'Il n’y a pas encore de scénario',
    'MursionPortal.Capitalized.Total': 'TOTAL',
    'Settings.ScenarioBank.Form.NewScenario': 'Nouveau scénario',
    'Settings.ScenarioBank.Form.Scenario': 'Scénario',
    'Settings.ScenarioBank.Form.EditScenario': 'Modifier le scénario',
    'Settings.ScenarioBank.Form.Save': 'Sauvegarder',
    'Settings.ScenarioBank.Form.ConfirmArchiveScenario': 'Voulez-vous vraiment archiver ce scénario ?',
    'Settings.ScenarioBank.Form.ConfirmRestoreScenario': 'Voulez-vous vraiment restaurer ce scénario ?',
    'Settings.ScenarioBank.Button.CreateScenario': 'Créer un scénario ',
    'MursionPortal.ShortLabel.Minutes': 'min',
    'Settings.ScenarioBank.Modal.Scenarios': 'Scénario(s)',
    'Settings.ScenarioBank.Modal.CompleteProjectInfoFirst': 'Renseigner d’abord les informations sur le projet',
    'Settings.ScenarioBank.Modal.ScenarioName': 'Nom du scénario',
    'Settings.ScenarioBank.Modal.Placeholder.EnterScenarioName': 'Saisir un nom de scénario',
    'Settings.ScenarioBank.Modal.Outcome': 'Résultat',
    'Settings.ScenarioBank.Modal.Strategies': 'Stratégies',
    'Settings.ScenarioBank.Modal.Attachments': 'Pièces jointes',
    'Settings.ScenarioBank.Modal.SoftwareVersion': 'Version du logiciel',
    'Settings.ScenarioBank.Modal.WeAlreadyHaveSessions': 'Nous avons déjà des sessions pour ce scénario',
    'Settings.ScenarioBank.Modal.SelectDeliveryMode': 'Choisir le mode de livraison',
    'Settings.ScenarioBank.Modal.ShouldBeAtLeast': 'Doit durer au moins {minutes} minutes',
    'Settings.ScenarioBank.Modal.SelectSessionLength': 'Sélectionner une durée de session',
    'Settings.ScenarioBank.Modal.SessionSize': 'Taille de la session',
    'Settings.ScenarioBank.Modal.Learner': 'Apprenant',
    'Settings.ScenarioBank.Modal.Learners': 'Apprenants',
    'Settings.ScenarioBank.Modal.SelectSessionSize': 'Sélectionner une taille de session',
    'MursionPortal.SimulationContent': 'Contenu de simulation',
    'Settings.ScenarioBank.Modal.ToolTip.ThisIsTheArtContent': 'Il s’agit du contenu artistique 3D des environnements et des avatars qui sera utilisé dans la simulation. Le contenu de ce dossier est affiché dans la section intitulée « ensemble de contenu » ci-dessous.',
    'Settings.ScenarioBank.Modal.ThisScenarioHasNoSimulation': 'Le scénario n’a pas de contenu de simulation attribué',
    'Settings.ScenarioBank.Modal.SelectSimulationContentForUse': 'Sélectionner le contenu de simulation à utiliser',
    'Settings.ScenarioBank.Modal.Label.ScenarioOutcome': 'Résultat du scénario',
    'Settings.ScenarioBank.Modal.Placeholder.EnterAnOutcome': 'Saisir un résultat',
    'Settings.ScenarioBank.Modal.Description': 'Description',
    'Settings.ScenarioBank.Modal.NoDescriptionSpecified': 'Aucune description spécifiée',
    'Settings.ScenarioBank.Modal.ScenarioDescription': 'Description du scénario',
    'Settings.ScenarioBank.Modal.AllowedCharactersLimit': 'La limite de caractères autorisée ({maxLength} caractères) a été dépassée',
    'Settings.ScenarioBank.Modal.ScenarioStrategies': 'Stratégies de scénario',
    'Settings.ScenarioBank.Modal.Placeholder.EnterAnItem': 'Saisir un élément',
    'Settings.ScenarioBank.Modal.Attachments.DragAndDrop': 'Faites glisser et déposer des fichiers ici ou cliquez pour sélectionner des fichiers',
    'Settings.ScenarioBank.Modal.Attachments.UploadedMaximumFiles': 'Vous avez téléchargé le nombre maximum de fichiers disponibles {maxFiles}.',
    'Settings.ScenarioBank.Modal.Attachments.UploadedFiles': 'Fichiers téléchargés,',
    'Settings.ScenarioBank.Modal.CertifiedSimSpecialists': 'Spécialistes Sim certifiés',
    'Settings.ScenarioBank.Modal.NoCertifiedSimSpecialist': 'Aucun spécialiste Sim certifié',
    'Settings.ScenarioBank.Modal.Button.Add': 'Ajouter ',
    'Settings.ScenarioBank.Modal.Edit': 'modifier',
    'Settings.ScenarioBank.Modal.AddAttachmentsToScenario': 'Ajouter des pièces jointes au scénario',
    'MursionPortal.Cancel': 'Annuler',
    'MursionPortal.OK': 'OK',
    'Settings.ScenarioBank.Modal.FileFormatsSupported': 'Les formats de fichiers suivants sont pris en charge :',
    'Settings.ScenarioBank.Modal.Images': 'Images',
    'Settings.ScenarioBank.Modal.Videos': 'Vidéos',
    'Settings.ScenarioBank.Modal.Audios': 'Audios',
    'Settings.ScenarioBank.Modal.Documents': 'Documents',
    'Settings.ScenarioBank.Modal.New': '(nouveau)',
    'Settings.ScenarioBank.Modal.FileSizeMustNotExceed': 'La taille du fichier ne doit pas dépasser {size}',
    'Settings.ScenarioBank.Modal.CertifySimSpecialists': 'Certifier les spécialistes Sim',
    'MursionPortal.Confirm': 'Confirmer',
    'MursionPortal.Delete': 'Supprimer',
    'Settings.ScenarioBank.Modal.Archived': '(Archivé)',
    'Settings.ScenarioBank.Modal.Restore': 'Restaurer',
    'Settings.ScenarioBank.Modal.ConfirmArchive': 'Confirmer l’archivage',
    'Settings.ScenarioBank.Modal.Archive': 'Archiver',
    'Settings.ScenarioBank.Modal.ConfirmRestore': 'Confirmer Restauration',
    'MursionPortal.PleaseWait': 'Veuillez patienter...',
    'MursionPortal.Loading': 'Chargement en cours…',
    'Settings.ScenarioBank.Modal.Attachments.FileTypeNotSupported': 'Le type de fichier {extensions} n’est pas pris en charge',
    'Settings.ScenarioBank.Modal.Attachments.FileTypesNotSupported': 'Les types de fichiers {extensions} ne sont pas pris en charge',
    'Settings.ScenarioBank.Modal.Attachments.FileExceedsSizeLimit': 'Le fichier {fileNames} dépasse la limite de taille',
    'Settings.ScenarioBank.Modal.Attachments.FilesExceedSizeLimit': 'Les fichiers {fileNames} dépassent la limite de taille',
    'MursionPortal.Label.Total': 'TOTAL',
    'MursionPortal.Button.HoverTitle.ExportData': 'Exporter les données',
    'MursionPortal.Button.HoverTitle.ExportNotAvailable': 'L’exportation n’est pas disponible',
    'MursionPortal.Heading.Filters': 'FILTRES :',
    'Dashboard.ProjectsTable.Checkbox.OnlyBillable': 'Facturable uniquement',
    'MursionPortal.Filters.SoftwareVersion': 'Version du logiciel',
    'MursionPortal.Filters.PlaceHolder.SearchVersion': 'Rechercher une version...',
    'MursionPortal.Filters.Status': 'Statut',
    'MursionPortal.Filters.PlaceHolder.SearchStatus': 'Statut de la recherche…',
    'Dashboard.ProjectsTable.ColumnHeader.Provider': 'Fournisseur',
    'Dashboard.ProjectsTable.ColumnHeader.Status': 'Statut',
    'Dashboard.ProjectsTable.ColumnHeader.StarAt': 'Démarrer à',
    'Dashboard.ProjectsTable.ColumnHeader.EndAt': 'Finir à',
    'Dashboard.ProjectsTable.ColumnHeader.Billable': 'Facturable',
    'Dashboard.ProjectsTable.ColumnHeader.CancellationWindow': 'Fenêtre d’annulation',
    'MursionPortal.Table.ColumnHeader.Scenarios': 'Scénarios',
    'MursionPortal.Table.ColumnHeader.Teams': 'Équipes',
    'MursionPortal.Table.ColumnHeader.SessionsScheduled': 'Sessions prévues',
    'MursionPortal.Table.ColumnHeader.SessionsCompleted': 'Sessions terminées',
    'MursionPortal.Table.ColumnHeader.SessionsMissed': 'Sessions manquées',
    'MursionPortal.Table.ColumnHeader.LateCancellations': 'Annulations tardives',
    'MursionPortal.Table.ColumnHeader.Learners': 'Apprenants',
    'Dashboard.ProjectsTable.ColumnHeader.SessionsRecorded': 'Sessions enregistrées',
    'MursionPortal.Table.ColumnHeader.SessionsError': 'Erreur de sessions',
    'Dashboard.ProjectsTable.ColumnHeader.ContractedHours': 'Heures contractuelles',
    'Dashboard.ProjectsTable.ColumnHeader.UtilizedHours': 'Heures utilisées',
    'Dashboard.ProjectsTable.ColumnHeader.RemainingHours': 'Heures restantes',
    'Dashboard.ProjectsTable.ColumnHeader.CompletedHours': 'Heures terminées',
    'Dashboard.ProjectsTable.ColumnHeader.MissedHours': 'Heures manquées',
    'Dashboard.ProjectsTable.HoverTitle.ViewEditProject': 'Afficher/modifier le projet',
    'Dashboard.ProjectsTable.EmptyMessage.NoProjectsYet': 'Il n’y a pas encore de projet',
    'MursionPortal.Button.Clear': 'Effacer',
    'Dashboard.Projects.AlertMessage.DataRequestNotImplemented': 'La demande de données n’est pas encore implémentée',
    'MursionPortal.SelectorMenu.SelectedCountOfTotal': '{formattedSelectedCount} sélectionné(s) sur {formattedTotal}',
    'MursionPortal.Dashboard.Table.Modal.Filter.Teams': 'Équipes',
    'MursionPortal.Dashboard.Table.Modal.Placeholder.EnterTeamName': 'Saisir le nom de l’équipe',
    'MursionPortal.Dashboard.Table.Modal.TeamsEmptyMessage': 'Aucune équipe n’a été trouvée',
    'MursionPortal.Dashboard.Table.Modal.Filter.NumberOfLearners': '# d’apprenants',
    'MursionPortal.Dashboard.Table.Modal.Filter.Scenarios': 'Scénarios',
    'MursionPortal.Dashboard.Table.Modal.Placeholder.EnterScenarioName': 'Saisir un nom de scénario',
    'MursionPortal.Dashboard.Table.Modal.ScenariosEmptyMessage': 'Aucun scénario n’a été trouvé',
    'MursionPortal.Dashboard.Table.Modal.Filter.Name': 'Nom',
    'MursionPortal.Dashboard.Table.Modal.Filter.Status': 'Statut',
    'MursionPortal.Dashboard.Table.Modal.Filter.DeliveryMode': 'Mode de livraison',
    'MursionPortal.Dashboard.Table.Modal.Filter.Learners': 'Apprenants',
    'MursionPortal.Dashboard.Table.Modal.Placeholder.EnterLearnerName': 'Saisir le nom de l’apprenant',
    'MursionPortal.Dashboard.Table.Modal.LearnersEmptyMessage': 'Aucun apprenant n’a été trouvé',
    'MursionPortal.Dashboard.Table.Modal.Filter.SessionsScheduled': 'Sessions prévues',
    'MursionPortal.Dashboard.Table.Modal.SessionsEmptyMessage': 'Aucune session n’a été trouvée',
    'MursionPortal.Dashboard.Table.Modal.Filter.ScenarioName': 'Nom du scénario',
    'MursionPortal.Dashboard.Table.Modal.Filter.SessionDateTime': 'Date/heure de la session',
    'MursionPortal.Dashboard.Table.Modal.Filter.SessionsCompleted': 'Sessions terminées',
    'MursionPortal.Dashboard.Table.Modal.Filter.SessionsError': 'Erreur de sessions',
    'Dashboard.ProjectsActivityTable.Modal.Filter.SessionsRecorded': 'Sessions enregistrées',
    'MursionPortal.Dashboard.Table.Modal.Filter.SessionsMissed': 'Sessions manquées',
    'MursionPortal.Dashboard.Table.Modal.Filter.LateCancellations': 'Annulations « tardives »',
    'MursionPortal.Dashboard.Table.Modal.Filter.CancelledBy': 'Annulé par',
    'MursionPortal.Dashboard.Table.Modal.Filter.DateScheduled': 'Date prévue',
    'MursionPortal.Dashboard.Table.Modal.Filter.DateCancelled': 'Date annulation',
    'MursionPortal.ProjectStatus.Active': 'Actif',
    'MursionPortal.ProjectStatus.Completed': 'Terminé',
    'MursionPortal.ProjectStatus.Archived': 'Archivé',
    'Settings.Avatars.Modal.Name': 'Nom',
    'Settings.Avatars.Modal.PlaceHolder.AvatarName': 'Nom de l’avatar',
    'Settings.Avatars.Modal.Description': 'Description',
    'MursionPortal.Name': 'Nom',
    'MursionPortal.Description': 'Description',
    'MursionPortal.Environments': 'Environnements',
    'Settings.Avatars.TableHover.ViewEditAvatar': 'Afficher/modifier l’avatar',
    'Settings.Avatars.NoAvatarText': 'Il n’y a pas encore d’avatars',
    'Settings.Avatars.ModalHeader.NewAvatar': 'Nouvel avatar',
    'Settings.Avatars.ModalHeader.Avatar': 'Avatar',
    'Settings.Avatars.ModalHeader.EditAvatar': 'Modifier l’avatar',
    'Settings.Avatars.Modal.Button.CreateAvatar': 'Créer un avatar',
    'Settings.Avatars.Modal.Button.UpdateAvatar': 'Mettre à jour l’avatar',
    'Settings.Avatars.Modal.ArchiveAvatarText': 'Voulez-vous vraiment archiver cet avatar ?',
    'Settings.Avatars.Modal.RestoreAvatarText': 'Voulez-vous vraiment restaurer cet avatar ?',
    'Settings.Avatars.Modal.Button.Edit': 'modifier',
    'Settings.Avatars.Modal.EditButton.Accept': 'Accepter',
    'Settings.Avatars.Modal.NoSelectedEnvironments': 'Aucun environnement sélectionné',
    'Settings.Avatars.Modal.NoEnvironmentsSetForSelectedIndustry': 'Aucun environnement défini pour le secteur sélectionné.',
    'Settings.Avatars.Button.CreateAvatar': 'Créer un avatar ',
    'MursionPortal.Dashboard': 'Tableau de bord',
    'MursionPortal.Tutorial': 'Tutoriel',
    'MursionPortal.Eula': 'Eula',
    'MursionPortal.Companies': 'Entreprises',
    'MursionPortal.SessionFlow': 'Déroulement de la session',
    'MursionPortal.SessionReport': 'Rapport de session',
    'MursionPortal.AssetSync': 'Synchronisation des ressources',
    'MursionPortal.Users': 'Utilisateurs',
    'MursionPortal.AppDownload': 'Téléchargement de l’application',
    'MursionPortal.Calendar': 'Calendrier',
    'MursionPortal.Clients': 'Clients',
    'MursionPortal.Sessions': 'Sessions',
    'MursionPortal.Trainings': 'Formations',
    'MursionPortal.Requests': 'Demandes',
    'MursionPortal.Settings': 'Paramètres',
    'MursionPortal.ArtDesigner': 'Art Designer',
    'MursionPortal.ArtDesigner.BodyContents': 'We\'ve opened a new browser tab with the Mursion Designer application.',
    'MursionPortal.Projects': 'Projets',
    'MursionPortal.Teams': 'Équipes',
    'MursionPortal.Reports': 'Rapports',
    'MursionPortal.Downloads': 'Téléchargements',
    'MursionPortal.Upcoming': 'à venir',
    'MursionPortal.Auth': 'OAuth',
    'Settings.Avatars.Modal.ErrorMessage.ItemsNotAvailable': 'Pas plus de {max} éléments ne sont disponibles à la sélection',
    'Settings.Avatars.Modal.ErrorMessage.ItemNotAvailable': 'Pas plus de {max} élément n\'est disponible à la sélection',
    'MursionPortal.Industry': 'Secteur',
    'Settings.Environment.TableHover.ViewEditEnvironment': 'Afficher/modifier l’environnement',
    'Settings.Environment.NoEnvironmentText': 'Il n’y a pas encore d’environnement',
    'Projects.Button.CreateProject': 'Créer un projet ',
    'Projects.ListHeader.CreationDate': 'Date de création',
    'Projects.ListHeader.ProjectManagers': 'Chefs de projets',
    'MursionPortal.Capitalized.Active': 'ACTIF',
    'MursionPortal.Capitalized.Completed': 'TERMINÉ',
    'MursionPortal.Capitalized.Archived': 'ARCHIVÉ',
    'MursionPortal.Capitalized.All': 'TOUT',
    'Projects.ProjectCard.BackToClient': 'Retour au client',
    'Projects.ProjectCard.NoProjectsFound': 'Aucun projet trouvé',
    'Projects.ProjectCard.PlaceHolder.EnterProjectName': 'Saisir le nom du projet',
    'Projects.ProjectCard.ProjectsList': 'LISTE DES PROJETS',
    'MursionPortal.Capitalized.Project': 'PROJET',
    'MursionPortal.Scenarios': 'Scénarios',
    'Projects.ScenarioList.ThereAreNoScenarios': 'Il n’y a pas encore de scénario.',
    'Projects.ScenarioList.NoScenariosFound': 'Aucun scénario n’a été trouvé.',
    'Projects.ScenarioList.Button.Draft': 'Brouillon',
    'MursionPortal.Finalized': 'Finalisé',
    'MursionPortal.Started': 'Débuté',
    'MursionPortal.Finished': 'Terminé',
    'MursionPortal.Archived': 'Archivé',
    'MursionPortal.EnterScenarioName': 'Saisir un nom de scénario',
    'Projects.AllProjects': 'tous les projets',
    'MursionPortal.Label.LowerCase.Archived': 'archivé',
    'MursionPortal.Label.Active': 'Actif',
    'MursionPortal.Label.Completed': 'Terminé',
    'Projects.Tab.Basic': 'Basique',
    'MursionPortal.Heading.ProjectName': 'Nom du projet',
    'MursionPortal.Label.Created': 'Créé',
    'MursionPortal.Label.Status': 'Statut',
    'MursionPortal.Label.Learners': 'Apprenants',
    'MursionPortal.Label.LearnersCount': '{count} apprenants',
    'Projects.Tab.NoLearners': 'Aucun apprenant',
    'MursionPortal.Label.SoftwareVersion': 'Version du logiciel',
    'Projects.ProjectInfo.Label.Non-Billable': 'Non facturable',
    'Projects.ProjectInfo.Label.Contracted': 'Contrats',
    'Projects.ProjectInfo.Label.CancellationWindow': 'Fenêtre d’annulation',
    'MursionPortal.Header.Recording': 'Enregistrement',
    'Projects.ProjectInfo.Checkbox.AllowRecording': 'Autoriser l’enregistrement',
    'MursionPortal.Label.VideoRecording': 'Enregistrement vidéo',
    'MursionPortal.Label.Allowed': 'Autorisé',
    'MursionPortal.Label.Disallowed': 'Non autorisé',
    'MursionPortal.Label.RecordedVideosGoBackTo': 'Les vidéos enregistrées remontent à :',
    'MursionPortal.Checkbox.RecordingPermissionLiesWithLearner': 'L’autorisation d’enregistrement appartient à l’apprenant',
    'MursionPortal.Label.LiesWithLearner': 'Appartient à l’apprenant',
    'Projects.ProjectInfo.Label.Enabled': 'Activé',
    'Projects.ProjectInfo.Label.Disabled': 'Désactivé',
    'MursionPortal.Heading.ProjectManagers': 'Chefs de projets',
    'MursionPortal.Heading.Tags': 'Balises',
    'MursionPortal.Button.AreYouSureEditProject': 'Voulez-vous vraiment modifier ce projet ?',
    'MursionPortal.Button.SaveProjectInfo': 'Enregistrer les informations sur le projet',
    'Projects.Tab.Utilization': 'Utilisation',
    'MursionPortal.Label.Loading': 'Chargement en cours…',
    'Projects.ProjectInfo.Label.NoProjectManagers': 'Aucun chef de projet',
    'Projects.ProjectInfo.Label.NoSelectedProjectManagers': 'Aucun chef de projet sélectionné',
    'MursionPortal.Label.ProjectManagers': 'Chefs de projets',
    'MursionPortal.Label.RecordingPermission': 'Autorisation d’enregistrement :',
    'Projects.Utilization.Label.UtilizedSessions': 'Sessions utilisées :',
    'Projects.Utilization.Label.UtilizedMissedSessions': 'Sessions manquées utilisées :',
    'Projects.Utilization.Label.UtilizedCancelledSessions': 'Sessions annulées utilisées :',
    'Projects.Utilization.Label.UtilizedDuration': 'Durée utilisée :',
    'Projects.Utilization.Label.RemainingDuration': 'Durée restante :',
    'MursionPortal.ScenarioListItem.Label.Timeline': 'chronologie',
    'MursionPortal.ScenarioListItem.Label.SessionLength': 'durée de la session',
    'MursionPortal.ScenarioListItem.Label.SessionSize': 'taille de la session',
    'MursionPortal.ScenarioListItem.Label.DeliveryMode': 'mode de livraison',
    'MursionPortal.ScenarioCard.BackToProject': 'Retour au projet',
    'MursionPortal.ScenarioCard.NoScenariosFound': 'Aucun scénario trouvé',
    'MursionPortal.Placeholder.EnterScenarioName': 'Saisir un nom de scénario',
    'MursionPortal.ScenarioCard.Label.ScenariosList': 'LISTE DES SCÉNARIOS',
    'MursionPortal.ScenarioCard.Header.Scenario': 'SCÉNARIO',
    'MursionPortal.ScenarioCard.Header.ChooseTypeOfEvent': 'Choisir le type d’événement à programmer',
    'MursionPortal.Label.Session': 'Session',
    'MursionPortal.Label.Training': 'Formation',
    'MursionPortal.Button.Confirm': 'Confirmer',
    'MursionPortal.Button.Continue': 'Continuer',
    'MursionPortal.Button.Schedule': 'Planifier',
    'MursionPortal.Label.Draft': 'Brouillon',
    'MursionPortal.Label.Finalized': 'Finalisé',
    'MursionPortal.Label.Started': 'Débuté',
    'MursionPortal.Label.Finished': 'Fin',
    'MursionPortal.Label.Archived': 'Archivé',
    'MursionPortal.Label.Cancel': 'annuler',
    'MursionPortal.Label.Edit': 'modifier',
    'MursionPortal.Label.CancelEdit': 'Annuler Modifier',
    'MursionPortal.Label.EditScenario': 'Modifier le scénario',
    'MursionPortal.Label.EditScenarioName': 'Modifier le nom du scénario',
    'MursionPortal.ScenarioCard.Label.ApplyNameChange': 'Appliquer le changement de nom',
    'MursionPortal.ScenarioCard.Label.Apply': 'appliquer',
    'MursionPortal.ScenarioCard.Tab.Info': 'INFOS',
    'MursionPortal.ScenarioCard.Tab.Learners': 'APPRENANTS',
    'MursionPortal.ScenarioCard.Tab.Sessions': 'SESSIONS',
    'MursionPortal.ScenarioCard.ConfirmButton.AreYouSureArchiveScenario': 'Voulez-vous vraiment archiver ce scénario ? Toutes les données associées seront également archivées.',
    'MursionPortal.ScenarioCard.ConfirmButton.ArchiveScenario': 'Archiver le scénario',
    'MursionPortal.Label.Delivery': 'Livraison',
    'MursionPortal.ScenarioInfo.Header.ArchivedScenario': 'SCÉNARIO ARCHIVÉ',
    'MursionPortal.ScenarioInfo.Button.YouMayOnlyEditTeams': 'Vous ne pouvez modifier les équipes et la chronologie que si vous finalisez un scénario',
    'MursionPortal.ScenarioInfo.Button.FinalizeScenario': 'Finaliser le scénario',
    'MursionPortal.ScenarioInfo.Button.SaveScenario': 'Enregistrer le scénario',
    'MursionPortal.ScenarioInfo.Button.CreatePlanning': 'Créer un planning ',
    'MursionPortal.ScenarioInfo.Label.SimLength': 'Longueur de la sim',
    'MursionPortal.Header.Outcome': 'Résultat',
    'MursionPortal.Header.Strategies': 'Stratégies',
    'MursionPortal.Header.Attachments': 'Pièces jointes',
    'MursionPortal.ScenarioInfo.Button.SaveScenarioInfo': 'Enregistrer les infos du scénario',
    'MursionPortal.Label.ContentBundle': 'Lot de contenu',
    'MursionPortal.ScenarioInfo.Button.ShowLess': 'Afficher moins',
    'MursionPortal.ScenarioInfo.Button.ShowMore': 'Afficher plus',
    'MursionPortal.AssetSettings.Label.CurrentScenarioNoAssetSettings': 'Le scénario actuel n’a pas de paramètres de ressources.',
    'MursionPortal.Teams.Label.NoSelectedTeams': 'Aucune équipe sélectionnée',
    'MursionPortal.Teams.Label.ClientHasNoAssignedTeams': 'Le client n’a pas d’équipes affectées',
    'MursionPortal.Label.Teams': 'Équipes',
    'MursionPortal.Label.NoTeamsSpecified': 'Aucune équipe spécifiée',
    'MursionPortal.Label.Capitalized.Draft': 'BROUILLON',
    'MursionPortal.Label.Capitalized.Finalized': 'FINALISÉ',
    'MursionPortal.Label.Capitalized.Started': 'DÉMARRÉ',
    'MursionPortal.Label.Capitalized.Finished': 'TERMINÉ',
    'MursionPortal.ScenarioInfo.Tooltip.ScenarioStillInDevelopmentStage': 'Le scénario est encore en phase de développement',
    'MursionPortal.ScenarioInfo.Tooltip.ScenarioDeployedTimelineUpcoming': 'Le scénario est déployé, mais la chronologie n’est pas encore finalisée',
    'MursionPortal.ScenarioInfo.Tooltip.ScenarioActiveLearnersCanSchedule': 'Le scénario est actif et les apprenants peuvent planifier des sessions',
    'MursionPortal.ScenarioInfo.Tooltip.ScenarioIsCompleted': 'Le scénario est terminé',
    'MursionPortal.Button.Add': 'Ajouter ',
    'MursionPortal.Label.Warning': 'Avertissement',
    'MursionPortal.SessionWizard.Label.UnableToCreateSession': 'Impossible de créer une session pour le {failedParam} sélectionné.',
    'MursionPortal.SessionWizard.Label.PleaseContinueToFindAnother': 'Appuyez sur Continuer pour trouver un autre <code>{failedParam}</code>.',
    'MursionPortal.SessionWizard.Label.ScheduleSession': 'Planifier une session',
    'MursionPortal.SessionWizard.Label.ScheduleTraining': 'Planifier une formation',
    'MursionPortal.SessionWizard.Label.AddNotes': 'Ajouter des notes',
    'MursionPortal.SessionWizard.Label.SelectSimulationContentForUse': 'Sélectionner le contenu de simulation à utiliser',
    'MursionPortal.SessionWizard.Label.SelectStep': 'Sélectionner {step}',
    'MursionPortal.SessionWizard.Label.Expired': 'expiré',
    'MursionPortal.SessionWizard.ConfirmButton.AreYouSureCreateEmergencySession': 'Voulez-vous vraiment créer une session d’urgence ?',
    'MursionPortal.SessionWizard.Button.Create': 'Créer',
    'MursionPortal.Button.Next': 'Suivant',
    'MursionPortal.SessionWizard.Label.RemainingTimeToBook': 'restant pour réserver ce bloc horaire avant l’expiration du délai de mise en attente.',
    'MursionPortal.SessionWizard.Label.TimeBlockHoldExpired': 'Le délai de mise en attente du bloc horaire a expiré.',
    'MursionPortal.Label.Date': 'Date',
    'MursionPortal.Label.Time': 'Heure',
    'MursionPortal.SessionWizard.Label.TimeNoLongerAvailableClickSubmit': 'Cette heure n’est plus disponible, souhaitez-vous sélectionner une autre option ? Revenez en arrière pour choisir une option disponible ou cliquez sur Envoyer pour demander l’option existante.',
    'MursionPortal.SessionWizard.Label.TimeNoLongerAvailable': 'Cette heure n’est plus disponible, souhaitez-vous sélectionner une autre option ? Revenez en arrière pour choisir une option disponible.',
    'MursionPortal.SessionWizard.Button.GoBack': 'Retour',
    'MursionPortal.Button.Submit': 'Envoyer',
    'MursionPortal.SessionWizard.Label.NoAvailableClients': 'Aucun client disponible',
    'MursionPortal.SessionWizard.Label.NoAvailableProjects': 'Aucun projet disponible',
    'MursionPortal.SessionWizard.Label.NoAvailableScenarios': 'Aucun scénario disponible',
    'MursionPortal.SessionWizard.Label.PleaseSelectScenario': 'Veuillez d’abord sélectionner le scénario',
    'MursionPortal.SessionWizard.Label.NoImage': 'Aucune image',
    'MursionPortal.SessionWizard.EmptyMessage.NoAvailableSImSpecialists': 'Aucun spécialiste Sim disponible',
    'MursionPortal.SessionWizard.Label.ScenarioHasNoContentBundles': 'Le scénario n’a pas de lot de contenu attribué',
    'MursionPortal.SessionWizard.Option.SelectAnAsset': 'Sélectionner une ressource',
    'MursionPortal.SimSpecialistStep.Label.NoSimSpecialistsAvailable': 'Il n’y a pas de spécialistes sim disponibles pour la période sélectionnée. La session sera attribuée sur demande.',
    'MursionPortal.SsLearnersStep.ErrorMessage.YouCanAddMaxTrainees': 'Vous ne pouvez ajouter que {maxLearners} stagiaires pour ce type de session.',
    'MursionPortal.SsLearnersStep.ErrorMessage.YouCanAddMaxTrainee': 'Vous ne pouvez ajouter que {maxLearners} stagiaire pour ce type de session.',
    'MursionPortal.EmptyMessage.NoUsersSelected': 'Aucun utilisateur sélectionné',
    'MursionPortal.Placeholder.Search': 'Rechercher',
    'MursionPortal.SsLearnersStep.Label.NoTraineeToSelect': 'Aucun stagiaire à sélectionner',
    'MursionPortal.LearnersStep.ErrorMessage.YouCanAddMaxLearners': 'Vous ne pouvez ajouter que {maxLearners} apprenants pour ce type de session.',
    'MursionPortal.LearnersStep.ErrorMessage.YouCanAddMaxLearner': 'Vous ne pouvez ajouter que {maxLearners} apprenant pour ce type de session.',
    'MursionPortal.LearnersStep.Label.NoLearnersToSelect': 'Aucun apprenant à sélectionner',
    'MursionPortal.LearnersStep.Label.NoUsersSelected': 'Aucun utilisateur sélectionné',
    'MursionPortal.LearnersStep.Option.AllTeams': 'Toutes les équipes',
    'MursionPortal.LearnersStep.Button.AddEntireTeam': 'Ajouter une équipe entière',
    'MursionPortal.LearnersStep.Note.AllowedSessionSIze': 'La taille de session autorisée est égale à {maxLearners}. Sélectionner des apprenants particuliers',
    'MursionPortal.LearnersStep.Checkbox.OnlyUnauthorizedUsers': 'Seuls les utilisateurs non authentifiés se joindront',
    'MursionPortal.NotesStep.SessionNotes': 'Remarques de session',
    'MursionPortal.NotesStep.ErrorMessage.AllowedLimitExceeded': 'La limite de caractères autorisée ({limit} caractères) a été dépassée',
    'MursionPortal.Button.LoadMore': 'Charger plus',
    'MursionPortal.Label.Trainer': 'Formateur',
    'MursionPortal.Label.Description': 'Description',
    'MursionPortal.Label.Outcome': 'Résultat',
    'MursionPortal.Label.Strategies': 'Stratégies',
    'MursionPortal.Label.DeliveryMode': 'Mode de livraison',
    'MursionPortal.Label.SessionLength': 'Durée de la session',
    'MursionPortal.Label.NoDescriptionProvided': 'Aucune description fournie',
    'MursionPortal.Label.NoOutcomeProvided': 'Aucun résultat fourni',
    'MursionPortal.Label.NoStrategiesProvided': 'Aucune stratégie fournie',
    'MursionPortal.Placeholder.Dotted.Search': 'Rechercher…',
    'MursionPortal.TimeStep.Label.NoAvailableTimeBlocks': 'Aucun bloc horaire disponible',
    'MursionPortal.TimeStep.Label.NoAvailableTimeBlocksInRange': 'Aucun bloc horaire disponible dans la plage sélectionnée',
    'MursionPortal.TimeStep.Footer.BySelectingThisTimeBlock': 'En sélectionnant ce bloc horaire, vous demanderez une heure de session actuellement indisponible. Nous ferons de notre mieux pour répondre à cette demande si vous continuez, ou faites glisser le bouton bascule ci-dessous sur « Off » pour choisir uniquement parmi les plages horaires disponibles confirmées.',
    'MursionPortal.TimeStep.Tooltip.SlideTheToggleOff': 'Faites glisser le bouton bascule sur « Off » pour sélectionner uniquement parmi les blocs horaires disponibles.',
    'MursionPortal.TimeStep.Tooltip.SlideTheToggleOn': 'Faites glisser la bouton bascule sur « On » pour demander un bloc horaire supplémentaire.',
    'MursionPortal.Label.LowerCase.Client': 'client',
    'MursionPortal.Label.Project': 'projet',
    'MursionPortal.Label.Scenario': 'scénario',
    'MursionPortal.Projects.NoProjectsFound': 'Aucun projet n’a été trouvé.',
    'MursionPortal.Projects.YourTeamsNotAssigned': 'Vos équipes n’ont encore été affectées à aucun projet.',
    'MursionPortal.Projects.NoProjectsCreated': 'Aucun projet n’a encore été créé.',
    'MursionPortal.Teams.NoTeamsFound': 'Aucune équipe n’a été trouvée',
    'MursionPortal.Teams.YouHaveNotBeen': 'Vous n’avez pas encore été ajouté à une équipe.',
    'MursionPortal.Teams.YouHaveNoTeams': 'Vous n’avez pas encore d’équipe.',
    'MursionPortal.Teams.NoTeamsCreated': 'Aucune équipe n’a encore été créée.',
    'Settings.SSO.Modal.ConfigurationName': 'Nom de la configuration',
    'Settings.SSO.Modal.AuthorizationEndpoint': 'Point de terminaison d’autorisation',
    'Settings.SSO.Modal.LogoutEndpoint': 'Déconnecter le point de terminaison d’autorisation',
    'Settings.SSO.Modal.ClientID': 'ID client',
    'Settings.SSO.Modal.ClientSecret': 'Secret Client',
    'Settings.SSO.Modal.Scope': 'Portée',
    'Settings.SSO.Modal.UserInfoEndpoint': 'Point de terminaison des informations utilisateur',
    'Settings.SSO.Modal.TokenEndpoint': 'Point de terminaison de jeton',
    'Settings.SSO.Modal.TokenHTTPMethod': 'Méthode HTTP de jeton',
    'Settings.SSO.Modal.UserIDClaimName': 'ID utilisateur Nom de la revendication',
    'Settings.SSO.Modal.EmailClaimName': 'Nom de la revendication par e-mail',
    'Settings.SSO.Modal.FirstNameClaimName': 'Prénom Nom de la revendication',
    'Settings.SSO.Modal.LastNameClaimName': 'Nom Nom de la revendication',
    'Settings.SSO.Modal.LinkAllowed': 'Lien autorisé',
    'Settings.SSO.Modal.UnlinkAllowed': 'Dissociation autorisée',
    'Settings.SSO.Modal.SingleLogoutEnabled': 'Déconnexion unique activée',
    'Settings.SSO.Modal.SingleSignOnServiceEndpoint': 'Point de terminaison du service d’authentification unique',
    'Settings.SSO.Modal.EntityID': 'ID de l’entité',
    'Settings.SSO.Modal.X509Certificate': 'Certificat X.509',
    'Settings.SSO.Button.CreateSsoConfiguration': 'Créer une configuration d’authentification unique ',
    'Settings.SSO.Modal.Protocol': 'Protocole :',
    'Settings.SSO.Modal.Type': 'Type :',
    'Settings.SSO.Modal.ID': 'Identifiant :',
    'Settings.SSO.Modal.AssignedClients': 'Clients attribués',
    'Settings.SSO.Modal.Button.DeleteSso': 'Supprimer l’authentification unique',
    'Settings.SSO.ModalHeader.EditSsoConfiguration': 'Modifier la configuration d’authentification unique',
    'Settings.SSO.ModalHeader.NewSsoConfiguration': 'Nouvelle configuration d’authentification unique',
    'Settings.SSO.Modal.Button.CreateSsoConfiguration': 'Créer une configuration d’authentification unique',
    'Settings.SSO.Modal.Button.SaveSsoConfiguration': 'Enregistrer la configuration d’authentification unique',
    'Settings.SSO.Modal.DeletionConfirmationText': 'Voulez-vous vraiment supprimer cette configuration d’authentification unique ?',
    'Settings.SSO.Modal.AddButton.GroupClaimName': 'Nom de la réclamation de groupe',
    'Settings.SSO.Modal.AddButton.Team': 'Équipe',
    'Settings.SSO.Modal.AddButton.PlaceHolder.GroupClaimValue': 'Nom de la réclamation de groupe',
    'Settings.SSO.Modal.SPInitiatedSsoUrl': 'URL d’authentification unique initiée par le SP :',
    'Settings.SSO.Modal.Button.DownloadSPMetadata': 'Télécharger les métadonnées SP',
    'Settings.SSO.Modal.Button.Public': 'Public',
    'Settings.SSO.Modal.Button.Private': 'Privé',
    'Settings.SSO.Modal.ConfigurationIcon': 'Icône de configuration :',
    'Settings.SSO.Modal.PlaceHolder.EnterTeamClaimName': 'Saisir le nom de la revendication d’équipe',
    'Settings.SSO.Modal.TeamClaimName': 'Nom de la revendication d’équipe',
    'Settings.SSO.Modal.TeamMapping': 'Correspondance de l’équipe :',
    'Settings.SSO.Modal.Button.Change': 'Changer',
    'MursionPortal.PlaceHolder.SelectAClient': 'Sélectionner un client',
    'Settings.SSO.Modal.AddButton.PlaceHolder.SelectATeam': 'Sélectionner une équipe',
    'MursionPortal.Label.Client': 'Client',
    'Settings.SSO.Modal.ConfirmDelete': 'Confirmer Supprimer',
    'Settings.SSO.Table.ColumnHeader.ConfigurationName': 'Nom de la configuration',
    'Settings.SSO.Table.ColumnHeader.Type': 'Type',
    'Settings.SSO.Table.ColumnHeader.Protocol': 'Protocole',
    'Settings.SSO.Table.ColumnHeader.ClientsCount': 'Nombre de clients',
    'Settings.SSO.PlaceHolder.EnterSsoName': 'Saisir un nom d’authentification unique',
    'Settings.SSO.Table.NoSsoSchemasFound': 'Aucun schéma d’authentification unique n’a été trouvé.',
    'Settings.SSO.Table.HoverTitle.ViewEditSso': 'Afficher/modifier l’authentification unique',
    'Settings.SSO.Modal.HoverTitle.CopySPInitiatedSsoUrl': 'Copier l’URL d’authentification unique initiée par le SP',
    'Settings.SSO.Modal.CopyButton.ModalHeader.SPInitiatedSsoUrl': 'URL d’authentification unique initiée par le SP',
    'MursionPortal.CopyText.CopiedToClipboard': 'a été copié dans le presse-papier !',
    'Settings.SSO.Text.Public': 'Public',
    'Settings.SSO.Text.Private': 'Privé',
    'Settings.SSO.Modal.NoClientsAssigned': 'Aucun client attribué',
    'MursionPortal.Label.NotAvailable': 'n/a',
    'Teams.Modal.Header.NewTeam': 'Nouvelle équipe',
    'MursionPortal.Button.More': 'Plus',
    'Teams.Modal.Header.AllTeams': 'toutes les équipes',
    'MursionPortal.Label.Name': 'Nom :',
    'MursionPortal.Label.Location': 'Lieu :',
    'MursionPortal.Label.Tags': 'Balises :',
    'MursionPortal.Header.Facilitators': 'Animateurs :',
    'MursionPortal.Header.Scenarios': 'Scénarios :',
    'MursionPortal.Button.SaveTeamInfo': 'Enregistrer les informations sur l’équipe',
    'MursionPortal.IconButton.Cancel': 'annuler',
    'MursionPortal.IconButton.Edit': 'modifier',
    'MursionPortal.ButtonTitle.CancelEdit': 'Annuler Modifier',
    'MursionPortal.ButtonTitle.EditTeam': 'Modifier l’équipe',
    'MursionPortal.ConfirmationText.RestoreTeam': 'Voulez-vous vraiment restaurer cette équipe ?',
    'MursionPortal.ButtonTitle.RestoreTeam': 'Restaurer l’équipe',
    'MursionPortal.ConfirmationText.ArchiveTeam': 'Voulez-vous vraiment archiver cette équipe ?',
    'MursionPortal.ButtonTitle.ArchiveTeam': 'Archiver l’équipe',
    'MursionPortal.EmptyMessage.NoFacilitators': 'Aucun animateur',
    'MursionPortal.EmptyMessage.NoSelectedFacilitators': 'Aucun animateur sélectionné',
    'MursionPortal.Title.Facilitators': 'Animateurs',
    'MursionPortal.Heading.Archived': '(Archivé)',
    'Teams.Modal.Header.AllLearners': 'Tous les apprenants',
    'Teams.Modal.Placeholder.SearchLearners': 'Rechercher des apprenants…',
    'Teams.Modal.EmptyMessage.NoLearnersToSelect': 'Aucun apprenant à sélectionner',
    'Teams.Modal.Header.TeamLearners': 'Apprenants d’équipe',
    'Teams.Modal.Placeholder.SearchTeamLearners': 'Rechercher des apprenants d’équipe...',
    'Teams.Modal.EmptyMessage.NoTeamLearnersToSelect': 'Aucun apprenant d’équipe à sélectionner',
    'Teams.Modal.Header.AssignLearners': 'Attribuer des apprenants à une équipe « {teamName} »',
    'Teams.AssignLearners.Modal.Button.SelectAll': 'Tout sélectionner',
    'Teams.AssignLearners.Modal.EmptyMessage.NoItems': 'Aucun élément à sélectionner',
    'MursionPortal.Header.Teams': 'Équipes',
    'Teams.Modal.AlertMessage.Timezone': ' En cas de changement de fuseau horaire, veuillez vérifier les événements existants et les disponibilités.',
    'Teams.SelectorMenu.SelectedOf': '{selected} sélectionné(s) sur {totalNumber}',
    'Teams.Modal.AlertMessage.ProfileTimezone': 'La sélection actuelle du fuseau horaire de votre profil ne correspond pas aux paramètres de date et d’heure de votre ordinateur ({updatedTimezone}).Cliquez sur « Enregistrer les modifications » pour continuer quand même, ou sélectionnez une option de fuseau horaire de profil différente.',
    'MursionPortal.Label.Learner': 'Apprenant',
    'Teams.Modal.Button.More': '{overlappedAmount} plus...',
    'MursionPortal.IconButton.Archive': 'archive',
    'Teams.ToggleButton.Learners': 'Apprenants',
    'Teams.ToggleButton.Sessions': 'Sessions',
    'MursionPortal.Status.RegistrationStatus': 'Statut d’inscription',
    'MursionPortal.Status.RegistrationAccepted': 'Inscription acceptée',
    'MursionPortal.Status.RegistrationNotAccepted': 'Inscription non acceptée',
    'MursionPortal.SortBy': 'Trier par',
    'MursionPortal.Placeholder.SortBy': 'trier-par',
    'Clients.Button.CreateClient': 'Créer un client ',
    'Clients.CreateClient.Header.NewClient': 'Nouveau client',
    'Clients.CreateClient.Button.CreateClient': 'Créer un client',
    'Clients.CreateClient.Label.ClientName': 'Nom du client',
    'Clients.CreateClient.Placeholder.CityCountry': 'Ville, Pays',
    'MursionPortal.Timezone': 'Fuseau horaire',
    'Clients.CreateClient.Label.Logo': 'Logo',
    'Clients.Text.NoClientsFound': 'Aucun client n’a été trouvé.',
    'MursionPortal.Text.Active': 'Actif',
    'MursionPortal.Text.Archived': 'Archivé',
    'Clients.Modal.Button.BackToClientList': 'Retour à la liste des clients',
    'Clients.Modal.EmptyMessage.NoClientsFound': 'Aucun client n’a été trouvé',
    'Clients.Modal.Placeholder.EnterClientName': 'Saisir le nom du client',
    'Clients.Modal.Header.ClientsList': 'LISTE DES CLIENTS',
    'Clients.Modal.Header.Client': 'CLIENT',
    'Clients.Modal.Header.AllClients': 'tous les clients',
    'MursionPortal.Search': 'Rechercher',
    'Clients.Modal.RestoreConfirmText': 'Voulez-vous vraiment restaurer ce client ?',
    'Clients.Modal.DeleteConfirmText': 'Voulez-vous vraiment archiver ce client ?',
    'Clients.Modal.RelatedDataText': 'Toutes les données associées seront également archivées.',
    'Clients.Modal.DeleteErrorText': 'Le client actuel a un ou des scénarios. Archivez le ou les scénarios de projet avant d’archiver ce client.',
    'Clients.Button.RestoreClient': 'Restaurer le client',
    'Clients.IconButton.Cancel': 'annuler',
    'Clients.IconButton.Edit': 'modifier',
    'Clients.IconButton.CancelEdit': 'Annuler Modifier',
    'Cleints.IconButton.EditScenarioName': 'Modifier le nom du scénario',
    'Cleints.IconButton.ApplyNameChange': 'Appliquer le changement de nom',
    'Clients.IconButton.Apply': 'appliquer',
    'Clients.IconButton.EditClient': 'Modifier le client',
    'Clients.Button.ArchiveClient': 'Archiver le client',
    'Clients.Modal.Heading.Archived': '(Archivé)',
    'Clients.Modal.Label.Name': 'Nom :',
    'Clients.Modal.Placeholder.ClientName': 'Nom du client',
    'Clients.Modal.Label.Location': 'Lieu :',
    'Clients.Modal.Label.Industry': 'Secteur :',
    'Clients.Modal.Placeholder.SelectIndustry': 'Sélectionner le secteur',
    'Clients.Modal.Label.Timezone': 'Fuseau horaire :',
    'Clients.Modal.Label.SchedulingByRequest': 'Planification par demande :',
    'MursionPortal.Enabled': 'Activée',
    'MursionPortal.Disabled': 'Désactivée',
    'Clients.Modal.Label.SchedulingWindow': 'Fenêtre de planification :',
    'Clients.Modal.Label.HowFarInAdvance': 'Combien de temps à l’avance les clients doivent planifier',
    'MursionPortal.Days': 'jours',
    'MursionPortal.Day': 'jour',
    'Clients.Modal.Label.AvailableSharedSSOTitle': 'Liste des authentification unique partagées disponibles pouvant être utilisées pour la connexion',
    'Clients.Modal.Label.AvailableSharedSSOLabel': 'Authentification unique partagée disponible:',
    'Clients.Modal.EmptySelectedMessage': 'Aucune authentification unique sélectionnée',
    'Clients.Modal.EmptyListMessage': 'Aucune authentification unique disponible',
    'Clients.Modal.SSOTitle': 'Sélectionner une authentification unique disponible',
    'Cleints.Modal.Label.AvailablePrivateSSOTitle': 'Liste des authentifications uniques privées disponibles pouvant être utilisées pour la connexion',
    'Clients.Modal.Label.AccountOwners': 'Propriétaires de compte :',
    'Clients.Modal.AccountOwner.EmptyListMessage': 'Aucun propriétaire de compte',
    'Clients.Modal.AccountOwner.EmptySelectedMessage': 'Aucun propriétaire de compte sélectionné',
    'Clients.Modal.AccountOwnersTitle': 'Propriétaire(s) de compte',
    'MursionPortal.Message.Updating': 'Mise à jour…',
    'MursionPortal.Button.SaveClientInfo': 'Enregistrer les informations client',
    'MursionPortal.Add': 'Ajouter ',
    'Clients.Modal.Label.EditSSO': 'Modifier l’authentification unique',
    'Clients.Modal.Label.Edit': 'modifier',
    'MursionPortal.NumberOfFacilitators': 'Nombre d’animateurs',
    'MursionPortal.NumberOfLearners': 'Nombre d’apprenants',
    'Clients.Modal.Button.CreateTeam': 'Créer une équipe ',
    'MursionPortal.NewTeam': 'Nouvelle équipe',
    'MursionPortal.NoTags': 'Aucune balise',
    'MursionPortal.Header.Tags': 'Balises',
    'MursionPortal.AddTag': 'Ajouter une balise',
    'Clients.Modal.Teams.FacilitatorsEmptyListMessage': 'Aucun animateur sélectionné',
    'Clients.Modal.Teams.LearnersEmptyListMessage': 'Aucun apprenant sélectionné',
    'MursionPortal.Learners': 'Apprenants',
    'MursionPortal.Facilitator': 'Animateur',
    'MursionPortal.CreateTeam': 'Créer une équipe',
    'Clients.Modal.Teams.ConfirmationText.RestoreTeam': 'Voulez-vous vraiment restaurer cette équipe ?',
    'Clients.Modal.Teams.ButtonTitle.RestoreTeam': 'Restaurer l’équipe',
    'Clients.Modal.Teams.ConfirmationText.ArchiveTeam': 'Voulez-vous vraiment archiver cette équipe ?',
    'Clients.Modal.Teams.ButtonTitle.ArchiveTeam': 'Archiver l’équipe',
    'Clients.Modal.Projects.Header.NewProject': 'Nouveau projet',
    'MursionPortal.ProjectName': 'Nom du projet',
    'MursionPortal.Contracted': 'Contrats',
    'MursionPortal.CancellationWindow': 'Fenêtre d’annulation',
    'MursionPortal.SoftwareVersion': 'Version du logiciel',
    'MursionPortal.Billing': 'Facturation',
    'Clients.Modal.Projects.Checkbox.NonBillable': 'Non facturable',
    'MursionPortal.Recording': 'Enregistrement',
    'Clients.Modal.Projects.Checkbox.AllowRecording': 'Autoriser l’enregistrement',
    'Clients.Modal.Projects.Label.RecordedVideos': 'Les vidéos enregistrées remontent à :',
    'Clients.Modal.Projects.Checkbox.RecordingPermission': 'L’autorisation d’enregistrement appartient à l’apprenant',
    'Clients.Modal.Projects.ButtonTitle.SaveProjectInfo': 'Enregistrer les informations sur le projet',
    'Clients.Modal.Projects.Header.ProjectManager': 'Chef de projet',
    'Clients.Modal.Projects.ProjectsEmptyListMessage': 'Aucun chef de projet sélectionné',
    'MursionPortal.Header.Bracketed.Scenarios': 'Scénario(s)',
    'Clients.Modal.Projects.Text.CompleteProjectInfoFirst': 'Renseigner d’abord les informations sur le projet',
    'MursionPortal.Header.CreateScenario': 'Créer un scénario',
    'Clients.Modal.Projects.ConfirmationText.RestoreProject': 'Voulez-vous vraiment restaurer ce projet ?',
    'Clients.Modal.Projects.ButtonTitle.RestoreProject': 'Restaurer le projet',
    'Clients.Modal.Projects.ButtonTitle.Edit': 'Modifier le projet',
    'Clients.Modal.Projects.ButtonTitle.CancelEdit': 'Annuler Modifier',
    'Clients.Modal.Label.Cancel': 'annuler',
    'Clients.Modal.Projects.ConfirmationText.ArchiveProject': 'Voulez-vous vraiment archiver ce projet ?',
    'Clients.Modal.Projects.ButtonTitle.ArchiveProject': 'Archiver le projet',
    'Clients.Modal.Projects.ArchiveErrorMessage': 'Le projet actuel a un ou plusieurs scénarios actifs. Archivez le ou les scénarios du projet avant d’archiver ce projet.',
    'Clients.Modal.Projects.ButtonTitle.EditTags': 'Modifier les balises',
    'Clients.Modal.Projects.Label.UtilizedMissedSessions': 'Sessions manquées utilisées,',
    'Clients.Modal.Projects.Button.CreateNew': 'Créer un nouveau ',
    'Clients.Modal.Projects.Button.RepeatClient': 'Répéter le client',
    'Clients.Modal.Projects.Button.RepeatBank': 'Répéter la banque',
    'Clients.Modal.Projects.ButtonTitle.CreateNewScenario': 'Créer un nouveau scénario',
    'Clients.Modal.Projects.ButtonTitle.RepeatClientScenario': 'Répéter le scénario client',
    'Clients.Modal.Projects.ButtonTitle.ScenarioBank': 'Créer un scénario à partir de la banque de scénarios',
    'Clients.Modal.Button.Schedule': 'Planifier',
    'MursionPortal.Button.ActivateScenario': 'Activer le scénario',
    'MursionPortal.Button.ChangeScenario': 'Changer le scénario',
    'Clients.IconButton.Confirm': 'confirmer',
    'Clients.IconButton.ConfirmEdit': 'Confirmer la modification',
    'Clients.Modal.Projects.HeaderText.ChooseScenario': 'Choisir le scénario à répéter',
    'Clients.Modal.Projects.HeaderText.ChooseScenarioBank': 'Choisir un scénario dans la banque de scénarios',
    'MursionPortal.Placeholder.LowerCase.EnterScenarioName': 'Saisir un nom de scénario',
    'MursionPortal.IconButton.ResetToDefault': 'Réinitialiser le nom par défaut',
    'MursionPortal.IconButton.Reset': 'réinitialiser',
    'Clients.Modal.Header.CreatePlanning': 'Créer un planning pour le nouveau scénario',
    'Clients.Modal.EmptyMessage.NoClientScenariosFound': 'Aucun scénario client n’a été trouvé.',
    'Clients.Modal.EmptyMessage.NoBankScenariosFound': 'Aucune banque de scénarios n’a été trouvée.',
    'Clients.Modal.Message.NoTeamsSpecified': 'Aucune équipe spécifiée',
    'Clients.Modal.EmptyMessage.NoSelectedTeams': 'Aucune équipe sélectionnée',
    'Clients.Modal.Message.NoAssignedTeams': 'Le client n’a pas d’équipes affectées',
    'Clients.Modal.Label.Certificate': 'Certificat',
    'Clients.Modal.Label.DeliveryMode': 'Mode de livraison',
    'Clients.Modal.Label.SessionSize': 'Taille de la session',
    'Clients.Modal.Label.SessionLength': 'Durée de la session',
    'Clients.Modal.Label.Outcome': 'Résultat',
    'Clients.Modal.Label.Strategies': 'Stratégies',
    'Clients.Modal.Label.Description': 'Description',
    'MursionPortal.Button.MoreInfo': 'Plus d’informations…',
    'MursionPortal.Button.HideAdditionalInfo': 'Masquer les informations supplémentaires…',
    'MursionPortal.Header.Timeline': 'Chronologie',
    'MursionPortal.Header.Projects': 'Projets',
    'MursionPortal.Header.Sessions': 'Sessions',
    'MursionPortal.Header.Users': 'Utilisateurs',
    'MursionPortal.Text.Yes': 'oui',
    'MursionPortal.Text.No': 'non',
    'MursionPortal.Button.Google': 'Google',
    'MursionPortal.Button.Outlook': 'Outlook, iCal, autre',
    'MursionPortal.Heading.GoogleCalendar': 'Calendrier Google',
    'MursionPortal.Heading.AddToCalendar': 'Ajouter au calendrier',
    'Dashboard.Legacy.GoogleCalendarList.Authorized': 'Si vous ne l’avez pas déjà fait, vous serez invité à <code>autoriser</code> le <code>portail {companyName}</code> à ajouter des événements à votre calendrier.',
    'Dashboard.Legacy.GoogleCalendarList.EventAdd': 'L’événement sera <code>ajouté à votre calendrier</code> et sera toujours accessible sur le portail {companyName}.',
    'MursionPortal.Heading.OtherCalendars': 'Autres calendriers',
    'Dashboard.Legacy.OtherCalendarList.Downloaded': 'Votre événement sera <code>téléchargé</code> en tant que <code>{companyName}</code>',
    'Dashboard.Legacy.OtherCalendarList.SelectCalendar': '<code>Faites un clic droit</code>, sélectionnez <code>« Ouvrir avec »</code> et sélectionnez votre application de calendrier.',
    'Dashboard.Legacy.OtherCalendarList.ImportWithinCalendar': '(Vous pouvez également sélectionner « Importer » dans votre calendrier et localiser le fichier téléchargé)',
    'Dashboard.Legacy.OtherCalendarList.AddedToCalendar': 'L’événement sera <code>ajouté à votre calendrier</code> et sera toujours accessible sur le portail {companyName}.',
    'MursionPortal.Text.NoLearnersAddedYet': 'Aucun apprenant n’a encore été ajouté',
    'MursionPortal.Heading.Schedule': 'Planifier',
    'MursionPortal.Heading.Users': 'UTILISATEURS',
    'MursionPortal.Heading.ProfServices': 'Services prof.',
    'MursionPortal.Heading.SimSpecialist': 'Spécialistes Sim',
    'MursionPortal.Heading.Clients': 'Clients',
    'MursionPortal.Heading.Sessions': 'Sessions',
    'MursionPortal.Heading.Projects': 'Projets',
    'MursionPortal.Heading.Teams': 'Équipes',
    'MyMessages.Button.ArchiveAll': 'Tout archiver',
    'MyMessages.Button.ArchivedMessages': 'Messages archivés',
    'MyMessages.Button.ShowMore': 'Afficher plus…',
    'MyMessages.Label.NewMessages': '<code>{newMessagesCount}</code> nouveaux messages',
    'MyMessages.Label.NewMessage': '<code>{newMessagesCount}</code> nouveau message',
    'MyMessages.HoverTitle.InboxButton': 'bouton de la boîte de réception',
    'MyMessages.Text.InboxButton': 'Bouton de la boîte de réception',
    'MyMessages.Text.HaveNewMessages': 'Vous avez {total} nouveaux messages',
    'MyMessages.Text.HaveNewMessage': 'Vous avez un nouveau message',
    'MursionPortal.CookiePolicy.Header.CookiePolicy': 'Politique sur les cookies',
    'MursionPortal.CookiePolicy.Text.MursionUsesEssentialCookiesOnly': 'Mursion utilise uniquement les cookies essentiels pour votre sécurité et pour le fonctionnement de notre application Web. Nous n’utilisons pas de cookies pour collecter des données personnelles ou d’autres informations, telles que les adresses IP ou l’utilisation du site Web.',
    'MursionPortal.CookiePolicy.Text.ForMoreInformation': ' Pour plus d’informations, veuillez consulter notre <code>Politique de confidentialité.</code>',
    'MursionPortal.Button.Ok': 'Ok',
    'MyProfile.Button.SaveChanges': 'Enregistrer les modifications',
    'MyProfile.Label.ExternalProfiles': 'Profils externes',
    'MyProfile.Label.UserRoles': 'Rôles d’utilisateur',
    'MyProfile.Label.UserRole': 'Rôle d’utilisateur',
    'MyProfile.Heading.ReviewAccountInformation': 'Veuillez vérifier les informations de votre compte',
    'MyProfile.HoverTitle.UnlinkProfile': 'Dissocier votre profil {linkName}',
    'MyProfile.HoverTitle.LinkProfile': 'Associer le profil {linkName}',
    'MyProfile.Button.Unlink': 'Dissocier',
    'MyProfile.Text.UnlinkConfirmationText': 'Voulez-vous vraiment dissocier votre profil {name} ? Veuillez noter que vous serez également déconnecté de {name}.',
    'MyProfile.Title.ProfileUnlink': 'Dissociation du profil {name}',
    'MyProfile.Text.SuccessfullyUnlinkedProfile': 'Vous avez dissocié votre profil {name} avec succès',
    'MyProfile.Text.TimeZoneIsSetAutomatically': 'Votre fuseau horaire est défini automatiquement sur {getTimeZone}. Cliquez sur « Confirmer » pour accepter cette sélection ou sur « Annuler » pour faire une autre sélection.',
    'MyProfile.Text.TimeZoneChange': 'En cas de changement de fuseau horaire, veuillez vérifier les événements existants et les disponibilités.',
    'MyProfile.Text.ProfileTimeZoneNotMatchComputer': 'La sélection actuelle du fuseau horaire de votre profil ne correspond pas aux paramètres de date et d’heure de votre ordinateur ({timeZone}).Cliquez sur « Enregistrer les modifications » pour continuer quand même, ou sélectionnez une option de fuseau horaire de profil différente.',
    'MursionPortal.Button.MyProfile': 'Mon profil',
    'MursionPortal.Button.LogOut': 'Déconnexion',
    'MyProfile.Label.Language': 'Langue',
    'MursionPortal.Label.Roles': 'Rôles',
    'MursionPortal.Label.Role': 'Rôle',
    'MursionPortal.Projects.Header.ScenarioType': 'Type de scénario',
    'MursionPortal.Projects.Header.Client': 'Client',
    'MursionPortal.Projects.Header.Project': 'Projet',
    'MursionPortal.Projects.Header.Scenario': 'Scénario',
    'MursionPortal.Projects.Header.SimulationContent': 'Contenu de simulation',
    'MursionPortal.Projects.Header.Date': 'Date',
    'MursionPortal.Projects.Header.Time': 'Heure',
    'MursionPortal.Projects.Header.Notes': 'Remarques',
    'Filters.Date.Week': 'Semaine',
    'Users.Table.Modal.SignLanguageInterpreter': 'Interprète en langue des signes',
    'Calendar.Button.Schedule': 'Planning',
    'Calendar.Button.Today': 'Aujourd\'hui',
    'MursionPortal.Availability': 'Disponibilité',
    'Calendar.Button.Schedule.QualityAssurance': 'Assurance qualité',
    'MursionPortal.Training': 'Formation',
    'Calendar.Button.Schedule.EmergencySession': 'Session extraordinaire',
    'Calendar.Button.Schedule.Session': 'Session',
    'Calendar.Button.Schedule.BreakPrep': 'Pause/préparation',
    'MursionPortal.Month': 'Mois',
    'MursionPortal.Week': 'Semaine',
    'Calendar.Heading.Calendars': 'Calendriers',
    'Calendar.Tip.SelectACalendar': 'Sélectionner un calendrier pour afficher la disponibilité',
    'Calendar.ScheduledHours.SumOfAllScheduledSessions': 'Somme de toutes les sessions planifiées dans la vue active',
    'MursionPortal.ShortLabel.Hours': 'h',
    'Calendar.ScheduledHours.SumOfAllTrainingSessions': 'Somme de toutes les sessions d\'entraînement planifiées dans la vue active',
    'Calendar.ScheduledHours.Trainings': 'Formations',
    'Calendar.ScheduledHours.SumOfRemainingAvailabilityHours': 'Somme de toutes les heures de disponibilité SS restantes dans la vue active',
    'Calendar.ScheduledHours.Available': 'Disponible',
    'MursionPortal.Scheduled': 'Planifié',
    'Calendar.Label.Display': 'Affichage',
    'MursionPortal.Client': 'Client',
    'Calendar.Filter.Company': 'Société',
    'Calendar.Filter.SwapRequests': 'Échanger les requêtes',
    'MursionPortal.QA': 'AQ',
    'Calendar.SearchFilter.FilterProjects': 'Filtrer les projets',
    'Calendar.SearchFilter.FilterClients': 'Filtrer les clients',
    'Calendar.SearchFilter.FilterSimSpecialists': 'Filtrer les spécialistes Sim',
    'Calendar.IndustrySelector.Options.SelectAnIndustry': 'Sélectionner un secteur',
    'Calendar.IndustrySelector.Options.AllIndustries': 'Tous les secteurs',
    'Calendar.IndustrySelector.Options.NoIndustriesToSelect': 'Aucun secteur à sélectionner',
    'MursionPortal.SelectAClient': 'Sélectionner un certificat',
    'Calendar.ClientSelector.Options.AllClients': 'Tous les clients',
    'Calendar.ClientSelector.Options.NoClientToSelect': 'Aucun client à sélectionner',
    'MursionPortal.SelectACertificate': 'Sélectionner un certificat',
    'Calendar.CertificateSelector.Options.AllCertificates': 'Tous les certificats',
    'Calendar.CertificateSelector.Options.NoCertificatesToSelect': 'Aucun certificat à sélectionner',
    'Calendar.ClientsList.NoClientsFound': 'Aucun client n\'a été trouvé.',
    'MursionPortal.UncheckAll': 'Tout désélectionner',
    'MursionPortal.CheckAll': 'Tout sélectionner',
    'Calendar.SimSpecialistList.NoSimSpecialistsFound': 'Aucun spécialiste sim n\'a été trouvé.',
    'Calendar.ProjectsList.CloseProjectOverview': 'Fermer l\'aperçu du projet',
    'Calendar.ProjectsList.AllProjects': 'TOUS LES PROJETS',
    'MursionPortal.Bracketed.Scenarios': 'Scénario(s)',
    'Calendar.ProjectsList.Overview': 'Aperçu',
    'Calendar.ProjectsList.CloseClientOverview': 'fermer l\'aperçu client',
    'MursionPortal.Scenario': 'scénario',
    'Calendar.ScenariosList.NoScenarios': 'Aucun scénario',
    'MursionPortal.Delivery': 'Livraison',
    'MursionPortal.Mode': 'Mode',
    'MursionPortal.Timeline': 'Chronologie',
    'Calendar.ScenariosList.Description': 'Description',
    'MursionPortal.Outcome': 'Résultat',
    'MursionPortal.Strategies': 'Stratégies',
    'MursionPortal.ContentBundle': 'Lot de contenu',
    'MursionPortal.SimulationEnvironments': 'Environnement(s) de simulation',
    'MursionPortal.AvatarsInSimulations': 'Avatar(s) dans la(les) simulation(s)',
    'Calendar.LearnersList.SessionNotScheduled': 'Session non programmée',
    'Calendar.LearnersList.SessionScheduled': 'Session programmée',
    'Calendar.LearnersList.SessionComplete': 'Session terminée',
    'Calendar.LearnersList.Learner': '{learnersCount} apprenant',
    'Calendar.LearnersList.Learners': '{learnersCount} apprenants',
    'Calendar.Button.CantSchedule': 'Impossible de planifier',
    'Calendar.Button.ScheduleEvent': 'Planifier un événement',
    'Calendar.MainPanel.ConfirmText.AreYouSure': 'Etes-vous sûr de vouloir annuler cet évènement ?',
    'Calendar.MainPanel.CancelEvent': 'Annuler l’événement',
    'Calendar.MainPanel.UpdateEvent': 'Mettre à jour l’évènement',
    'MursionPortal.Submit': 'Envoyer',
    'Calendar.MainPanel.NewEvent': 'Nouvel événement',
    'MursionPortal.Project': 'Projet',
    'MursionPortal.Button.Reload': 'Rafraîchir',
    'Clients.Modal.Label.FacilitatorVisibility': 'Vue de l\'animateur:',
    'Clients.Modal.Placeholder.FacilitatorVisibility': 'Sélectionner la vue de l\'animateur',
    'Clients.Modal.Projects.ConfirmationText.EditProject': 'Voulez-vous vraiment modifier ce projet ?',
    'Projects.ScenarioList.Legend.ScenariosStatusTab': 'Onglets de statut des scénarios',
    'Projects.ScenarioList.Legend.CreateNew': 'Boutons de scénario Créer un nouveau, Répéter le client, Répéter la banque',
    'MursionPortal.ScenarioCard.Tab.Legend.ViewModeTab': 'Onglet mode d\'affichage',
    'MursionPortal.SearchPlaceHolder.SearchClient': 'Rechercher un Client…',
    'Settings.SSO.Modal.AddButton.SearchPlaceHolder.SearchTeam': 'Sélectionner une équipe…',
    'Settings.SSO.Modal.Button.ChangeLabel': 'Changer {label}',
    'MursionPortal.Label.Yes': 'Oui',
    'MursionPortal.Label.No': 'Non',
    'Settings.SSO.Modal.AddButton.PlaceHolder.NoOptions': 'Pas d’options',
    'Settings.SSO.Modal.ClientName': 'Nom du client',
    'MursionPortal.Modal.Button.Add': 'Ajouter',
    'MursionPortal.Modal.Button.Remove': 'Supprimer',
    'Users.Table.Hover.ViewEditAcceptedUsers': 'Afficher/modifier les utilisateurs acceptés',
    'Users.Table.Hover.ViewEditUnacceptedUsers': 'Afficher/modifier les utilisateurs non acceptés',
    'MursionPortal.Label.Industry': 'Secteur',
    'MursionPortal.Label.Timezone': 'Fuseau horaire',
    'MursionPortal.Header.Client': 'Client',
    'MursionPortal.FilterByCertificate': 'Filtrer par certificat',
    'Calendar.ScheduleButton.QualityAssurance.Modal.Header.CreateQAEvent': 'Créer un événement AQ',
    'Calendar.ScheduleButton.QualityAssurance.Modal.Label.TimeBlock': 'Bloc(s) horaire(s)',
    'Calendar.ScheduleButton.QualityAssurance.Modal.Label.Participants': 'Participants',
    'Calendar.ScheduleButton.QualityAssurance.Modal.Button.SubmitNewEvent': 'Soumettre un nouvel événement',
    'Calendar.ScheduleButton.QualityAssurance.Modal.Error.TimeBlockOutsideRange': 'Le bloc horaire est en dehors de la plage disponible',
    'Calendar.ScheduleButton.QualityAssurance.Modal.Error.TimeBlockBeforeCurrentTime': 'Le bloc horaire est avant l\'heure actuelle',
    'Calendar.ScheduleButton.QualityAssurance.Modal.Label.Start': 'Début',
    'Calendar.ScheduleButton.QualityAssurance.Modal.Label.End': 'Fin',
    'Calendar.ScheduleButton.QualityAssurance.Modal.Label.Length': 'Durée',
    'Calendar.ScheduleButton.QualityAssurance.Modal.Error.NoAvailableTimeBlock': 'Il n\'y a pas de bloc horaire disponible pour le jour sélectionné',
    'Calendar.Modal.Button.Client': 'Client',
    'Calendar.Modal.Button.Project': 'Projet',
    'Calendar.Modal.Button.Scenario': 'Scénario',
    'Calendar.Modal.Header.CompanyEvents': 'Événements de l\'entreprise',
    'MursionPortal.Header.Session': 'Session',
    'MursionPortal.Header.Events': 'Événements',
    'MursionPortal.Header.Event': 'Événement',
    'Calendar.Modal.Label.Time': 'Heure',
    'Calendar.Label.SelectTimeZone': 'Sélectionner le fuseau horaire',
    'Calendar.Text.Training': 'Formation',
    'Calendar.Text.QA': 'AQ',
    'Calendar.Modal.Text.ConfirmDeleteTime': 'Confirmer la suppression du bloc horaire',
    'Calendar.Button.NewTimeBlock': 'Nouveau bloc horaire',
    'Calendar.Modal.Label.Capitalized.Time': 'HEURE',
    'Calendar.Modal.Label.Participants': 'PARTICIPANTS',
    'Calendar.Modal.Text.NoParticipants': 'Aucun participant',
    'Calendar.Modal.Label.SimulationSpecialist': 'SPÉCIALISTE DE LA SIMULATION',
    'Calendar.Modal.Label.MeetingOrganizer': 'ORGANISATEUR DE LA RÉUNION',
    'Calendar.Error.ErrorPrefixMustBeBetween': '{errorPrefix} doit être compris entre {duration1} et {duration2}.',
    'Calendar.Error.SelectedTimeBlockOverlapsExistingTime': 'Le bloc horaire sélectionné chevauche le bloc horaire existant.',
    'Calendar.Error.SelectedTimeBlockOverlapsUnavailableTime': 'Le bloc horaire sélectionné chevauche un intervalle de temps indisponible.',
    'Calendar.Error.SelectedTimeBlockIsNotMultipleOfEventGap': 'Le bloc horaire sélectionné n\'est pas un multiple de \'écart d\'événement ({duration})',
    'Calendar.Text.AvailabilityUser': 'Disponibilité {user}',
    'Calendar.Text.AvailabilityTime': 'Disponibilité {time}',
    'Calendar.Text.AvailabilityDurationAvailabilityTime': '{availabilityDuration} Disponibilité {time}',
    'Calendar.Text.TrainingsTime': '{eventsCount} Formations {time}',
    'Calendar.Text.SessionsTime': '{eventsCount} Sessions {time}',
    'Calendar.Text.AvailabilityName': 'Disponibilité {name}',
    'Calendar.Label.Learners': 'Apprenants',
    'Calendar.Text.FullAvailability': 'Disponibilité totale',
    'Calendar.Text.YourAvailability': 'Votre disponibilité',
    'Calendar.Text.Event': 'Événement',
    'Calendar.Header.Trainings': 'Formations',
    'Calendar.Header.CompanyEvents': 'Événements de l\'entreprise',
    'Calendar.Header.Session': 'Session ',
    'Calendar.Error.TimeBlockIsOutsideAvailableRange': 'Le bloc horaire est en dehors de la plage disponible',
    'Calendar.Error.TimeBlockIsBeforeCurrentTime': 'Le bloc horaire est avant l\'heure actuelle',
    'Calendar.Label.Break': 'Pause',
    'Calendar.Label.Prep': 'Préparation',
    'Calendar.Modal.Header.CreateBreakPrepEvent': 'Créer un événement de pause/préparation',
    'Calendar.Modal.Label.EventType': 'Type d\'événement',
    'Calendar.Modal.Label.TimeBlocks': 'Bloc(s) horaire(s)',
    'Calendar.Text.NoTimeBlocksInSelectedRange': 'Aucun bloc horaire disponible dans la plage sélectionnée',
    'Calendar.Text.NoAvailableTimeBlocks': 'Aucun bloc horaire disponible',
    'Calendar.Text.SelectingThisTimeBlock': 'En sélectionnant ce bloc horaire, vous demanderez une heure de session actuellement indisponible. Nous ferons de notre mieux pour répondre à cette demande si vous continuez, ou faites glisser le bouton bascule ci-dessous sur « Off » pour choisir uniquement parmi les plages horaires disponibles confirmées.',
    'Calendar.ToolTip.SlideToggleOff': 'Faites glisser le bouton bascule sur « Off » pour sélectionner uniquement parmi les blocs horaires disponibles.',
    'Calendar.ToolTip.SlideToggleOn': 'Faites glisser la bouton bascule sur « On » pour demander un bloc horaire supplémentaire.',
    'Calendar.Label.SelectAnyTimeBlock': 'Sélectionner n\'importe quel bloc horaire',
    'Users.CreateUser.Label.GoogleMeetID': 'ID Google Meet',
    'Users.CreateUser.GoogleMeetId.Placeholder.GoogleMeetID': 'ID Google Meet',
    'Users.CreateUser.GoogleMeetID.Hover': 'Veuillez saisir un ID Google Meet',
    'Setting.Edit.Modal.Error.UnableToCreateSession': 'Impossible de créer une session pour l\'heure sélectionnée {formattedStartTime} - {formattedEndTime}',
    'Session.Header.Session': 'Session ',
    'Session.Header.AcceptSessionRequest': 'Veuillez confirmer si vous souhaitez accepter cette demande de session et l\'ajouter à votre programme.',
    'CommonComponent.RecurrenceForm.Label.UntilDate': 'Jusqu\'au Date',
    'CommonComponent.RecurrenceForm.Label.Period': 'Période',
    'CommonComponent.RecurrenceForm.Option.Daily': 'Quotidien',
    'CommonComponent.RecurrenceForm.Option.Weekly': 'Hebdomadaire',
    'CommonComponent.RecurrenceForm.Label.Weekday': 'Jour de semaine',
    'CommonComponent.RecurrenceForm.Text.NoSelectedWeekdays': 'Aucun jour de la semaine sélectionné',
    'CommonComponent.RecurrenceForm.Error.PleaseSelectWeekdays': 'Veuillez sélectionner le(s) jour(s) de la semaine',
    'Calendar.EventType.Availability': 'Disponibilité',
    'Calendar.EventType.Session': 'Session',
    'Calendar.EventType.Training': 'Formation',
    'Calendar.EventType.CompanyEvent': 'Événement de l\'entreprise',
    'Calendar.EventType.NewEvent': 'Nouvel événement',
    'Calendar.Text.NewEventTime': 'Nouvel événement {time}',
    'Calendar.Header.Training': 'Formation',
    'Calendar.Modal.Text.Sessions': 'Sessions',
    'Calendar.Modal.Text.Trainings': 'Formations',
    'Calendar.Modal.Text.Events': 'Événements',
    'Calendar.Modal.Text.PendingNoSS': '- En attente (pas de SS).',
    'Calendar.Modal.Text.PendingSwap': '- En attente (échange).',
    'Calendar.Modal.Text.PreBooked': '- Pré-réservé.',
    'Calendar.Modal.Text.NeedsReview': '- Nécessite une révision.',
    'Calendar.Modal.Text.JoinSession': '- vous pouvez rejoindre la session.',
    'Calendar.Modal.Text.NoLearnersInSession': '- il n\'y a pas d\'apprenants dans la session.',
    'Calendar.Modal.Text.NoLearnersInSessionAfterBookedTime': '- il n\'y a pas d\'apprenants dans la session après l\'heure réservée.',
    'Calendar.Modal.Header.Events': 'Événements',
    'Calendar.Modal.Header.Session': 'session',
    'Calendar.Modal.Header.Training': 'formation',
    'Calendar.Modal.Header.Availability': 'disponibilité',
    'Calendar.Modal.Filter.Client': 'Client',
    'Calendar.Modal.Filter.Project': 'Projet',
    'Calendar.Modal.Filter.Time': 'Heure',
    'Calendar.Modal.Label.Recurrence': 'Récurrence',
    'Calendar.Modal.Header.CreateAvailability': 'Créer la disponibilité',
    'Calendar.Modal.Text.Recurring': 'Récurrent',
    'Calendar.SimSpecialistButton.Modal.Header.Scheduled': 'Planifié',
    'Calendar.SimSpecialistButton.Modal.Header.ThisPeriod': '(cette période)',
    'Calendar.SimSpecialistButton.Modal.Label.Sessions': 'Sessions',
    'Calendar.SimSpecialistButton.Modal.Label.Hours': 'Heures',
    'Calendar.SimSpecialistButton.Modal.Label.Trainings': 'Formations',
    'Calendar.SimSpecialistButton.Modal.Label.TrainingsHours': 'Heures de formation',
    'Calendar.SimSpecialistButton.Modal.Label.Available': 'Disponible',
    'Calendar.SimSpecialistButton.Modal.Header.Certification': 'Certification',
    'Calendar.SimSpecialistButton.Modal.Text.NoCertificates': 'Aucun certificat',
    'Calendar.SimSpecialistButton.Modal.Header.SimSpecialistOverviewPanel': 'Panneau d\'aperçu du spécialiste Sim',
    'Calendar.SimSpecialistButton.Modal.HoverTitle.CloseSimSpecialistOverview': 'fermer l\'aperçu du spécialiste sim',
    'MursionPortal.DeliveryMode.OneToOne': '1:1',
    'MursionPortal.DeliveryMode.OneToManyRemote': 'À distance',
    'MursionPortal.DeliveryMode.OneToManyLocal': 'Local (Fishbowl)',
    'MursionPortal.DeliveryMode.Group': 'Livraison de groupe',
    'MursionPortal.Projects.Header.Trainees': 'Stagiaires',
    'Projects.Placeholder.SimContentName': 'Entrez le nom du contenu de simulation',
    'Projects.Message.NoSimulationContentFound': 'Aucun contenu de simulation trouvé',
    'MursionPortal.Button.UncheckAll': 'Tout désélectionner',
    'MursionPortal.Button.CheckAll': 'Tout sélectionner',
    'Projects.Header.NewScenario': 'Projet {projectName}',
    'MursionPortal.Button.SaveAsDraft': 'Enregistrer en tant que brouillon',
    'MursionPortal.Button.Save': 'Sauvegarder',
    'Projects.Message.SelectEnvironmentFirst': 'Sélectionner d\'abord un environnement',
    'Projects.Message.LoadingAvatarsList': 'Chargement de la liste des avatars...',
    'Projects.Message.NoAssignedAvatars': 'L\'environnement sélectionné n\'a pas d\'avatars assigné',
    'Projetcs.EmptyMessage.NoSelectedAvatars': 'Aucun avatar sélectionné',
    'Projects.Avatars.NoAvatarsSpecified': 'Aucun avatars spécifié',
    'Projects.Message.SelectIndustryFirst': 'Sélectionner d\'abord un secteur',
    'Projects.Message.LoadingEnvironmentsList': 'Chargement de la liste des environnements...',
    'Projects.Message.NoAssignedEnvironments': 'Le secteur sélectionné n\'a pas d\'environnement assigné',
    'Projects.Message.ScenarioHasNoAssignedEnvironment': 'Le scénario n\'a pas d\'environnement assigné',
    'Projects.AltText.EnvironmentImage': 'image-environnement',
    'Projects.Placeholder.SelectAnEnviroment': 'Sélectionner un environnement',
    'Projects.Message.UnnamedIndustry': 'Secteur sans nom',
    'Projects.Placeholder.SelectAnIndustry': 'Sélectionner un secteur',
    'Projects.Header.NewProject': 'Nouveau projet',
    'Projects.AltText.ClientLogo': 'Logo du client',
    'Projects.Message.SelectAnyTimeBlock': 'Sélectionner n\'importe quel bloc horaire',
    'Projects.Message.SelectScenarioFirst': 'Veuillez d’abord sélectionner le scénario',
    'MursionPortal.Text.Asset': 'Ressource V2',
    'MursionPortal.Button.Accept': 'Accepter',
    'Projects.Modal.EmptyMessage.NoLearner': 'Aucun apprenant',
    'MursionPortal.Label.SelectAll': 'Sélectionner tout',
    'Projects.Modal.Placeholder.ChooseATeam': 'Choisir une équipe',
    'MursionPortal.Placeholder.SearchTeams': 'Rechercher des équipes…',
    'Projects.Modal.Text.SessionSize': 'La taille de session autorisée est égale à {maxLearners}. Sélectionner des apprenants spécifiques',
    'Projects.Modal.Placeholder.SearchLearners': 'Rechercher des apprenant',
    'MursionPortal.ErrorMessage.MaxLearners': 'Vous ne pouvez ajouter que {maxLearners} apprenants pour ce type de session.',
    'MursionPortal.ErrorMessage.MaxLearner': 'Vous ne pouvez ajouter que {maxLearners} apprenant pour ce type de session.',
    'MursionPortal.Label.ChooseMembers': 'Sélectionner les membres',
    'MursionPortal.Message.NoMembersToSelect': 'Aucun membre à sélectionner',
    'MursionPortal.Message.PleaseSelectATeamFirst': 'Veuillez d\'abord sélectionner une équipe',
    'AssetSync.Header.AssetSync': 'Synchronisation des ressources',
    'AssetSync.Text.AssetsSyncedSuccessfully': 'Les ressources ont été synchronisées avec succès',
    'AssetSync.Button.Sync': 'Synchronisation',
    'AssetSync.Button.TryAgain': 'Veuillez réessayer',
    'AssetSync.SyncButton.Text.Syncing': 'Synchronisation en cours',
    'SessionFlow.Label.SessionExport': 'Exportation de session',
    'MursionPortal.Button.Export': 'Exportation',
    'SessionFlow.Label.SessionFlowData': 'Données de déroulement de la session',
    'SessionFlow.Button.LoadSessionData': 'Charger les données de session',
    'SessionFlow.Button.StartSessionAnalytics': 'Démarrer l\'analyse de session',
    'SessionFlow.Label.Checklist': 'Liste de contrôle',
    'SessionFlow.Label.RecordingSettings': 'Paramètres d\'enregistrement',
    'SessionFlow.Label.SessionVideoFact': 'Fait sur la vidéo de session',
    'SessionFlow.Label.UserStatus': 'Statut de l\'utilisateur',
    'SessionFlow.SessionVideoFact.ColumnHeader.VideoAllowed': 'Vidéo autorisée',
    'MursionPortal.Table.ColumnHeader.UserName': 'Nom d’utilisateur',
    'MursionPortal.Table.ColumnHeader.Email': 'E-mail',
    'MursionPortal.Table.ColumnHeader.Status': 'Statut',
    'SessionFlow.StartSessionAnalyticsButton.Text.EventStartingAnalytics': 'L\'événement de démarrage du processus d\'analyse a été publié avec succès',
    'SessionFlow.RecordingSettings.Label.VideoRecording': 'Enregistrement vidéo',
    'SessionFlow.RecordingSettings.Text.Allowed': 'Autorisé',
    'SessionFlow.RecordingSettings.Text.Disallowed': 'Non autorisé',
    'SessionFlow.RecordingSettings.Label.RecordingPermission': 'Autorisation d\’enregistrement',
    'SessionFlow.RecordingSettings.Text.LiesWithLearner': 'Appartient à l’apprenant',
    'SessionFlow.RecordingSettings.Label.RecordedVideosGoBackTo': 'Les vidéos enregistrées remontent à',
    'SessionFlow.Error.CannotFindSession': 'Impossible de trouver une session avec l\'ID fourni',
    'SessionFlow.DetailedEventLogButton.Table.ColumnHeader.Type': 'Type',
    'SessionFlow.DetailedEventLogButton.Table.ColumnHeader.Date': 'Date',
    'SessionFlow.DetailedEventLogButton.Table.ColumnHeader.Error': 'Erreur',
    'SessionFlow.DetailedEventLogButton.Table.ColumnHeader.Payload': 'Payload',
    'SessionFlow.SessionFlowData.PlaceHolder.SessionID': 'ID de session',
    'SessionFlow.Button.HideDetailedEventLog': 'Masquer le journal détaillé des événements...',
    'SessionFlow.Button.ShowDetailedEventLog': 'Afficher le journal détaillé des événements...',
    'SessionFlow.Button.HideSessionHistory': 'Masquer l\'historique de la session...',
    'SessionFlow.Button.ShowSessionHistory': 'Afficher l\'historique de la session...',
    'SessionFlow.Text.NoEvents': 'Pas d’événements',
    'SessionFlow.SessionVideoFact.Label.VideoExists': 'La vidéo existe',
    'SessionFlow.Text.NoLearners': 'Aucun apprenant',
    'SessionFlow.Checklist.DataRecording': 'enregistrement des données',
    'SessionFlow.Checklist.DataUploading': 'téléchargement des données',
    'SessionFlow.Checklist.Analytics': 'analyse de données',
    'Companies.ArchiveIconButton.Modal.ConfirmationText.ArchiveCompany': 'Êtes-vous sûr de vouloir archiver cette entreprise ?',
    'Companies.ArchiveIconButton.Modal.Header.ArchiveCompany': 'Archiver l\'entreprise',
    'Companies.Label.CompanyInfo': 'Informations sur l\'entreprise',
    'Companies.CompanyInfo.Archived': '(Archivé)',
    'Companies.CancelIconButton.HoverTitle.CancelEdit': 'Annuler Modifier',
    'Companies.EditIconButton.HoverTitle.EditCompany': 'Modifier la société',
    'Companies.Button.InviteProfService': 'Inviter service prof.',
    'Companies.InviteProfServiceButton.Modal.Header.NewProfService': 'Nouveau service prof.',
    'Companies.Label.Config': 'CONFIGURATION',
    'Companies.EditIconButton.HoverTitle.EditConfig': 'Modifier Config',
    'Companies.Config.Button.Test': 'Test',
    'Companies.Config.Button.SaveCompanyConfig': 'Enregistrer la configuration de l\'entreprise',
    'Companies.CompanyInfo.Button.SaveCompanyInfo': 'Enregistrer les informations sur l\'entreprise',
    'Companies.ValidationError': 'Il y a des erreurs de validation',
    'Companies.Config.SaveCompanyConfigButton.FailedToSaveConfig': 'Échec de l\'enregistrement d\'une configuration. Veuillez corriger les erreurs.',
    'Companies.Config.Text.TestPassedSuccessfully': 'Test réussi avec succès',
    'Companies.CreateUserButton.PlaceHolder.CompanyName': 'Nom de l\'entreprise',
    'Companies.Button.CreateCompany': 'Créer une entreprise ',
    'Companies.Error.NoCompanies': 'Aucune entreprise',
    'Companies.InviteProfServiceButton.Modal.Label.ProfServices': 'Services prof.',
    'Companies.InviteProfServiceButton.Modal.Button.AddUser': 'Ajouter un utilisateur ',
    'Companies.CreateUserButton.Modal.Header.NewCompany': 'Nouvelle entreprise',
    'Companies.Header.AllCompanies': 'toutes les entreprises',
    'MursionPortal.Roles': 'Rôles',
    'Users.ArchiveButton.Modal.Header.ArchivedGlobalUser': 'Utilisateur global archivé',
    'Users.ActiveButton.Modal.Header.EditGlobalUser': 'Modifier l\'utilisateur global',
    'Users.CreateUserButton.Modal.Header.NewGlobalUser': 'Nouvel utilisateur global',
    'Users.NoGlobalUserFoundError': 'Aucun utilisateur global n’a été trouvé.',
    'Users.Table.ColumnHeader.Department': 'Département',
    'MursionPortal.TechSupport.HoverTitle.ShowFeedBack': 'afficher les commentaires',
    'MursionPortal.TechSupport.HoverTitle.HideFeedBack': 'cacher les commentaires',
    'MursionPortal.TechSupport': 'Support technique',
    'MursionPortal.TechSupport.Header.ContactSupport': 'Contacter l\'assistance technique',
    'MursionPortal.TechSupport.Label.Phone': 'Téléphone',
    'MursionPortal.TechSupport.Label.Email': 'E-mail',
    'MursionPortal.TechSupport.Label.Hours': 'Heures',
    'MursionPortal.TechSupport.Link.MursionSupport': 'Assistance Mursion',
    'Downloads.Text.ClickDownloadButton': 'Cliquez sur le bouton de téléchargement ci-dessous pour obtenir la dernière version du programme d\'installation. Cet exécutable sera enregistré dans l’emplacement de téléchargement par défaut de votre navigateur (par exemple, dossier de téléchargement).',
    'Downloads.Text.OnceDownloadIsComplete': 'Une fois le téléchargement terminé, cliquez sur l\'exécutable téléchargé et suivez les instructions à l\'écran pour terminer l\'installation.',
    'Downloads.Text.AppInstalled': 'Une fois l\'application installée, vous pouvez double-cliquer sur l\'icône Mursion la prochaine fois que vous devez la lancer.',
    'Downloads.Button.Download': 'Télécharger',
    'Downloads.Text.PlatformNotCurrentlySupported': 'Désolé, mais votre plateforme n\'est actuellement pas prise en charge.',
    'Downloads.Text.FollowOnScreenInstructions': 'Cliquez sur {info} et suivez les instructions à l\'écran pour installer l\'application',
    'Downloads.Text.ShortcutCreatedOnInstallationComplete': 'Une fois l\'installation terminée, un raccourci vers Mursion sera créé sur votre bureau. {lineBreak} Vous pouvez double-cliquer {image} sur le raccourci lorsque vous devez lancer l\'application pour faire l\'expérience d\'une simulation programmée.',
    'Filters.Date.LastWeek': 'La semaine dernière',
    'MursionPortal.Text.Mins': 'minutes',
    'MursionPortal.Text.Min': 'min',
    'Tutorial.Header.ManageYourTeams': 'Gérez vos équipes',
    'MursionPortal.Button.Awesome': 'Super',
    'Tutorial.Header.ReviewYourProjects': 'Passez en revue vos projets',
    'MursionPortal.Button.LooksGood': 'Ça a l\'air bien',
    'Tutorial.Header.LearnerScheduling': 'Planification des apprenants',
    'MursionPortal.Button.AllDone': 'C\'est fini !',
    'Tutorial.Header.InviteYourUsers': 'Invitez vos utilisateurs',
    'MursionPortal.Button.GotIt': 'Compris',
    'Tutorial.Header.CreateATeam': 'Créer une équipe',
    'Tutorial.Header.ManageYourProjects': 'Gérez vos projets',
    'Tutorial.Header.YourTeams': 'Vos équipes',
    'Tutorial.Header.YourProjects': 'Vos projets',
    'Tutorial.Header.SessionCalendar': 'Calendrier des sessions',
    'Tutorial.Tooltip.TeamsOrganize': 'Les équipes organisent vos apprenants, et seront ajoutées aux scénarios',
    'Tutorial.Text.ViewSessions': 'Voir les sessions <code>à venir et terminées</code> de l\'équipe',
    'Tutorial.Tooltip.ContactManager': 'ou contactez votre chef de projet {companyName}',
    'Tutorial.Tooltip.YourPointOfContact': 'Votre point de contact pour tout apprentissage',
    'Tutorial.Text.TeamAdded': 'Une équipe sera <code>ajoutée à un scénario</code> pour commencer la planification',
    'Tutorial.Text.ViewLearners': 'Afficher l\' <code>activité</code> et <code>le statut de la planification</code> des apprenants',
    'Tutorial.Text.EnterEmail': 'Saisir une <code>adresse e-mail</code> pour chaque nouvel utilisateur {companyName} du portail',
    'Tutorial.Text.RegistrationEmail': 'Un <code>e-mail d\'inscription</code> sera envoyé dans leur boîte de réception',
    'Tutorial.Text.TeamsOrganize': 'Les équipes <code>organisent vos apprenants</code> et sont gérées par un animateur',
    'Tutorial.Text.UniqueName': 'Donnez à votre équipe un <code>nom unique</code> et saisissez un lieu',
    'Tutorial.Text.SessionDelivery': 'Plus tard, une équipe sera <code>ajoutée à un scénario</code> pour la <code>livraison de session</code>',
    'Tutorial.Tooltip.Description': 'Description, résultat et stratégies',
    'Tutorial.Text.BeginScheduling': 'Une équipe sera <code>ajoutée à un scénario</code> pour commencer la planification',
    'Tutorial.Text.MonitorSessions': 'Surveiller les <code>séances à venir</code> et la <code>disponibilité</code> de chaque projet',
    'Tutorial.Text.LearnersWithoutSessions': 'Afficher les apprenants qui n\'ont <code>pas encore planifié</code> de session',
    'Tutorial.Tooltip.LearnersSchedule': 'Les apprenants peuvent également planifier leur programme eux-mêmes',
    'Tutorial.Text.AddedToLearners': 'Vous serez ajouté à <code>une ou plusieurs</code> équipes d\'apprenants',
    'Tutorial.Tooltip.TeamAdmin': 'L\'administrateur de votre équipe et votre point de contact',
    'Tutorial.Text.TeamAddedBeforeProject': 'Votre équipe sera <code>ajoutée à un scénario</code> avant le début du projet',
    'Tutorial.Text.AddRemoveLearners': '<code>Ajouter ou supprimer</code> des apprenants de vos <tooltip>équipes</tooltip>',
    'Tutorial.Text.AddedByCompany': 'Vous serez ajouté à un scénario/projet par le <tooltip>titulaire de compte</tooltip> de votre entreprise',
    'Tutorial.Text.ViewYourHours': 'Consultez vos horaires, vos scénarios ou communiquez avec votre <tooltip><code>chef de projet</code></tooltip>',
    'Tutorial.Text.ScenariosDetail': 'Les scénarios contiennent les <tooltip>détails</tooltip> qui motivent votre<code> expérience chez {companyName}</code>',
    'Tutorial.Text.SelectTimeSlot': 'Sélectionnez un créneau horaire ouvert pour <tooltip><code>planifier une session pour un apprenant</code></tooltip>',
    'Tutorial.Text.ChooseRole': 'Choisissez un rôle (<tooltip>titulaire de compte</tooltip>, <secondTooltip>animateur</secondTooltip> ou <thirdTooltip>apprenant</thirdTooltip>) pour chaque nouvel utilisateur',
    'Tutorial.Tooltip.AdminManages': 'Administrateur qui gère les utilisateurs et les projets',
    'Tutorial.Tooltip.ManagesScheduling': 'Gère la planification et la livraison des sessions',
    'Tutorial.Tooltip.SchedulesSessions': 'Planifie et assiste aux séances',
    'Tutorial.Text.ScenariosContain': 'Les scénarios contiennent les <tooltip>détails</tooltip> qui motivent votre<code> expérience chez {companyName}</code>',
    'Tutorial.Text.SelectOpenTimeSlot': 'Sélectionnez un créneau horaire ouvert pour <tooltip><code>planifier pour un apprenant</code></tooltip>',
    'Tutorial.Text.AdminOfTeam': 'L’<tooltip>animateur</tooltip> est l’administrateur et le point de contact de votre équipe',
    'Tutorial.Text.ProjectsContain': 'Les projets contiennent des <code>scénarios</code>, avec les <tooltip>détails</tooltip> qui motivent votre <code>expérience d\'apprentissage</code>',
    'Tutorial.Text.ViewAvailability': 'Voir la disponibilité et <code>planifier des sessions</code>',
    'Tutorial.Text.TrackSessions': 'Suivez <code>les sessions à venir</code> pour chaque projet. Annuler/reprogrammer si nécessaire',
    'Tutorial.Text.ReviewPerformance': 'Passez en revue vos <code>performances</code> et <code>l’achèvement de vos stratégies</code> après votre session',
    'Tutorial.Header.Complete': 'Terminer',
    'Tutorial.Text.RevisitThisGuide': 'Vous pouvez revoir ce guide et d\'autres informations utiles {lineBreak} <code>à tout moment</code> à partir de la <code>base de connaissances. </code>',
    'Tutorial.Button.TakeMeToThePortal': 'Emmenez-moi au portail',
    'Tutorial.Header.DownloadInstallMursionSimulator': 'Téléchargez et installez {lineBreak} le simulateur Mursion',
    'Tutorial.Text.PlanningToUseMursionOnComputer': 'Si vous prévoyez d’utiliser cet ordinateur pour exécuter des simulations Mursion, nous vous recommandons vivement d’installer le logiciel nécessaire maintenant en cliquant sur le bouton « Installer Mursion » ci-dessous.',
    'Tutorial.Text.InstallSoftwareFromPortal': 'Vous pouvez choisir d\'installer le logiciel ultérieurement directement à partir du portail. Veuillez garder à l\'esprit que le simulateur immersif de Mursion peut nécessiter plusieurs minutes pour être installé lors de la première utilisation. Veuillez prévoir d\'installer le logiciel au moins 30 minutes avant le début de votre simulation afin de vous laisser suffisamment de temps pour profiter pleinement de l\'expérience Mursion.',
    'Tutorial.Text.ProvideInstructionsForInstallation': 'Nous continuerons de fournir des instructions pour l\'installation et d\'autres flux de travail de temps en temps via des e-mails et des tutoriels dans le portail et le simulateur de Mursion. Nous espérons que vous apprécierez vos simulations autant que nous aimons les créer !',
    'Tutorial.Text.TheMursionTeam': 'L\'équipe Mursion.',
    'Tutorial.Button.InstallLater': 'Installer plus tard',
    'Tutorial.Button.InstallMursion': 'Installer Mursion',
    'Tutorial.Header.LetsGetStarted': 'Commençons',
    'Tutorial.Text.ShortIntro': 'Tout d\'abord, voici une <code>courte introduction</code> pour présenter certaines des {lineBreak} fonctions communes de <code>votre rôle d\'utilisateur</code>',
    'Tutorial.Button.Proceed': 'Démarrer',
    'Tutorial.Label.Step': 'ÉTAPE',
    'Tutorial.Label.NoSound': 'Cette vidéo n\'a pas de son.',
    'Tutorial.Button.SkipTutorial': 'Passer le tutoriel',
    'Tutorial.Button.CloseTutorial': 'Fermer le tutoriel',
    'MursionPortal.Text.On': 'On',
    'MursionPortal.Text.Off': 'Off',
    'MursionPortal.Text.MursionIcon': 'icône-mursion',
    'MursionPortal.AriaLabel.CreateNew': 'créer un nouveau',
    'MursionPortal.Legend.VisibilityHidden.Buttons': 'Boutons de scénario Créer un nouveau, Répéter le client, Répéter la banque',
    'MursionPortal.Label.VisibilityHidden.EnterEmailId': 'Entrer l’identifiant d’email',
    'MursionPortal.Label.VisibilityHidden.SelectRegistrationStatus': 'Sélectionner le statut d\'inscription',
    'MursionPortal.Label.VisibilityHidden.ClientLogoUpload': 'téléchargement du logo client',
    'MursionPortal.Header.VisibilityHidden.CompanyUsers': 'Utilisateurs de l\'entreprise',
    'MursionPortal.Legend.VisibilityHidden.StationType': 'Type de station',
    'MursionPortal.Legend.VisibilityHidden.Hours': 'Heures',
    'MursionPortal.Caption.VisibilityHidden.CompanyUsersList': 'liste des utilisateurs de l\'entreprise',
    'MursionPortal.AriaLabel.Search': 'rechercher',
    'MursionPortal.AriaLabel.From': 'De',
    'MursionPortal.AriaLabel.To': 'À',
    'MursionPortal.AriaLabel.RemoveAccountOwner': 'supprimer le propriétaire du compte',
    'MursionPortal.AriaLabel.InboxMessagePanel': 'panneau de message de la boîte de réception',
    'MursionPortal.AriaLabel.Breadcrumb': 'Fil d\'Ariane',
    'MursionPortal.Message.NoItemsHaveBeenFound': 'Aucun élément n’a été trouvé.',
    'MursionPortal.AriaLabel.Add': 'ajouter',
    'MursionPortal.AriaLabel.SortByDescendingOrder': 'trier par ordre décroissant',
    'MursionPortal.AriaLabel.SortByAscendingOrder': 'trier par ordre croissant',
    'MursionPortal.AriaLabel.AddButton': 'ajouter un bouton',
    'MursionPortal.AriaLabel.Timezone': 'fuseau horaire',
    'MursionPortal.AriaLabel.Combobox': 'combobox',
    'MursionPortal.AriaLabel.PreviousMonth': 'mois précédent',
    'MursionPortal.AriaLabel.NextMonth': 'mois suivant',
    'MursionPortal.AriaLabel.SearchInput': 'zone de saisie de recherche',
    'MursionPortal.AriaLabel.Informational': 'Informationnel',
    'MursionPortal.Text.RedirectToCalendarPage': 'rediriger vers la page de calendrier',
    'MursionPortal.AriaLabel.StartDate': 'date de début {startFormattedDate}',
    'MursionPortal.AriaLabel.EndDate': ' date de fin {endFormattedDate}',
    'MursionPortal.AriaLabel.CreateProject': 'créer un projet',
    'MursionPortal.AriaLabel.ContentInfo': 'informations sur le contenu',
    'MursionPortal.AriaLabel.DraftSuperScript': 'Projet d\'exposant {statusConfiguration}',
    'MursionPortal.AriaLabel.FinalizedSuperScript': 'Exposant finalisé {statusConfiguration}',
    'MursionPortal.AriaLabel.StartedSuperScript': 'Exposant commencé {statusConfiguration}',
    'MursionPortal.AriaLabel.FinishedSuperScript': 'Exposant terminé {statusConfiguration}',
    'MursionPortal.AriaLabel.ArchivedSuperScript': 'Exposant archivé {statusConfiguration}',
    'MursionPortal.AriaLabel.EnterScenarioName': 'saisir un nom de scénario',
    'MursionPortal.AriaLabel.Capitalized.Search': 'Rechercher',
    'MursionPortal.AriaLabel.Close': 'Fermer',
    'MursionPortal.AriaLabel.CreateTeam': 'créer une équipe',
    'MursionPortal.AriaLabel.CreateClient': 'créer un client',
    'MursionPortal.AriaLabel.CreateUser': 'créer un utilisateur',
    'MursionPortal.AriaLabel.ZoomId': 'ID Zoom',
    'MursionPortal.AriaLabel.PrimaryContent': 'Contenu principal',
    'MursionPortal.AriaLabel.RegistrationStatus': 'statut de l\'inscription',
    'MursionPortal.AriaLabel.EnterTagName': 'entrer le nom de balise',
    'MursionPortal.AriaLabel.EnterUserName': 'entrer le nom de l’utilisateur',
    'MursionPortal.AriaLabel.SearchProjectManagers': 'rechercher des chefs de projet',
    'MursionPortal.AriaLabel.SearchSSO': 'rechercher la signature unique',
    'MursionPortal.AriaLabel.SearchAccountOwner': 'rechercher le propriétaire du compte',
    'MursionPortal.WarningMessage.AllowedCharacterLimit': 'La limite de caractères autorisée ({maximumLength} caractères) a été dépassée',
    'MursionPortal.WarningMessage.InvalidCharactersDetected': 'Caractères non valides détectés',
    'MursionPortal.Days.Sunday': 'Dimanche',
    'MursionPortal.Days.Monday': 'Lundi',
    'MursionPortal.Days.Tuesday': 'Mardi',
    'MursionPortal.Days.Wednesday': 'Mercredi',
    'MursionPortal.Days.Thursday': 'Jeudi',
    'MursionPortal.Days.Friday': 'Vendredi',
    'MursionPortal.Days.Saturday': 'Samedi',
    'MursionPortal.Button.AddNew': 'Ajouter nouveau',
    'MursionPortal.Title.EditNotes': 'Modifier les notes',
    'MursionPortal.IconButton.Remove': 'supprimer',
    'MursionPortal.Filter.ProviderAll': 'Fournisseur',
    'MursionPortal.Placeholder.SearchProviders': 'Rechercher fournisseur…',
    'MursionPortal.AriaLabel.InboxButton': 'bouton boîte de réception Vous avez {messageCount} nouveaux messages',
    'MursionPortal.AriaLabel.Event': 'événement',
    'MursionPortal.Title.SelectedDate': 'Date sélectionnée',
    'MursionPortal.Title.CurrentDate': 'Date du jour',
    'MursionPortal.Title.OffRangeDate': 'date hors plage ',
    'MursionPortal.Label.ClientLevel': 'Niveau du client',
    'MursionPortal.Label.TeamLevel': 'Niveau de l\'équipe',
    'MursionPortal.AltText.UserImage': 'image-utilisateur',
    'MursionPortal.AltText.KbMenu': 'menu-kb',
    'MursionPortal.AltText.AvatarImage': 'image-avatar',
    'MursionPortal.AltText.EnvironmentImage': 'image-environnement',
    'MursionPortal.AriaLabel.Cancel': 'annuler',
    'MursionPortal.AriaLabel.Edit': 'modifier',
    'MursionPortal.AriaLabel.EditAccountOwner': 'modifier le propriétaire du compte',
    'MursionPortal.AriaLabel.Remove': 'supprimer',
    'MursionPortal.AriaLabel.SearchFilter': 'filtre de recherche',
    'MursionPortal.AriaLabel.TierCombobox': 'niveau combobox',
    'MursionPortal.AriaLabel.SortByAscending': 'trier par ordre croissant',
    'MursionPortal.AriaLabel.SortByDescending': 'trier par ordre décroissant',
    'MursionPortal.Message.VisibilityHidden.SelectingDeliveryMode': 'la sélection d\'un mode de livraison permettra de définir la durée de la session et la taille de la session',
    'MursionPortal.AltText.DownloadComputerIcon': 'Icône d\'ordinateur de téléchargement de Mursion',
    'MursionPortal.AltText.DownloadIcon': 'Icône de téléchargement Mursion',
    'MursionPortal.AltText.MursionLogo': 'Logo Mursion',
    'MursionPortal.AriaLabel.MursionSupport': 'assistance mursion',
    'MursionPortal.AltText.MursionDashboard': 'Tableau de bord-Mursion',
    'MursionPortal.AltText.BackgroundMaleUser': 'Arrière-plan utilisateur masculin Mursion',
    'MursionPortal.AltText.BackgroundGroupUser': 'Arrière-plan utilisateur de groupe Mursion',
    'MursionPortal.AltText.BackgroundFemaleUser': 'Arrière-plan utilisateur féminin Mursion',
    'MursionPortal.ErrorMessage.CharacterLimitExceeded': 'La limite de caractères autorisée ({maximumLength} caractères) a été dépassée',
    'MursionPortal.ErrorMessage.InvalidCharactersDetected': 'Caractères non valides détectés',
    'MursionPortal.ErrorMessage.EnterValidPhoneNumber': 'Vous devez saisir un numéro de téléphone valide.',
    'MursionPortal.Message.InvitationLinkInvalid': 'Votre lien d\'invitation n\'est pas valide ou a peut-être expiré. {lineBreak} Si vous n’avez pas encore terminé {lineBreak} votre inscription, veuillez demander une nouvelle invitation à votre responsable.',
    'MursionPortal.Header.SetYourPassword': 'Définir votre mot de passe',
    'MursionPortal.Message.PasswordMessage': 'Votre mot de passe doit contenir les éléments suivants :',
    'MursionPortal.Label.NewPassword': 'Nouveau mot de passe',
    'MursionPortal.Label.RepeatPassword': 'Répéter le mot de passe',
    'MursionPortal.Button.SetPassword': 'Définir le mot de passe',
    'MursionPortal.Header.ErrorMessage.SomethingWentWrong': 'Une erreur {lineBreak} s\'est produite, veuillez {lineBreak} contacter l\'assistance',
    'MursionPortal.Header.ContactSupport': 'CONTACTER L\'ASSISTANCE',
    'MursionPortal.Label.Phone': 'Téléphone',
    'MursionPortal.Label.Email': 'E-mail',
    'MursionPortal.Label.Hours': 'Horaires',
    'MursionPortal.Message.EnterPasswordToConfirm': 'Veuillez saisir votre mot de passe pour confirmer ce changement',
    'MursionPortal.Label.Password': 'Mot de passe',
    'MursionPortal.Message.BrowserDialog': 'Si vous ne voyez pas de boîte de dialogue de navigateur, cliquez sur <code>Ouvrir Mursion</code> ci-dessous {lineBreak} ou utilisez le raccourci du bureau Mursion pour ouvrir l\'application Mursion.',
    'MursionPortal.Button.OpenMursion': 'Ouvrir ML3 Desktop App',
    'MursionPortal.Message.FollowInstructions': 'Cliquez sur {appName} et suivez les instructions à l\'écran pour installer l\'application',
    'MursionPortal.Text.PositiveImpact': 'Lorsque vous sentez que vous avez eu un impact positif, cliquez sur le bouton <code>{icon}</code>.',
    'MursionPortal.Text.NegativeImpact': 'Lorsque vous sentez que vous avez eu un impact négatif, cliquez sur le bouton <code>{icon}</code>.',
    'MursionPortal.Text.NeutralImpact': 'Lorsque vous sentez que votre impact n’a été ni positif ni négatif, cliquez sur le bouton <code>{icon}</code>.',
    'MursionPortal.Title.Next': 'Suivant',
    'MursionPortal.Title.Start': 'Démarrer',
    'MursionPortal.Text.UpdateYourRating': 'Pendant que vous regardez la vidéo, vous pouvez mettre à jour votre note à tout moment si vous pensez avoir un impact différent sur l\'avatar.',
    'MursionPortal.Text.SeeCurrentRating': 'Le graphique sous la vidéo sera mis à jour afin que vous puissiez voir votre note actuelle et la modifier si vous pensez que votre impact a changé.',
    'MursionPortal.Text.ClickStartToBegin': 'Cliquez sur « Démarrer » pour débuter. Vous pouvez cliquer sur « Terminer » après avoir évalué l\'ensemble de la simulation. Veuillez noter que la fonctionnalité de recherche vidéo peut être désactivée pendant certaines parties de cette vidéo pour préserver l\'intégrité des données.',
    'MursionPortal.Header.HowWouldYouProceed': 'Comment aimeriez-vous procéder ?',
    'MursionPoral.Button.SwitchUser': 'Changer d\'utilisateur',
    'MursionPortal.Button.ContinueAs': 'Continuer en tant que <code>{userName}</code>',
    'MursionPortal.Text.ToContinue': 'Pour continuer en tant que <code>{loginData}</code> veuillez cliquer sur <code>Changer d\'utilisateur</code>.',
    'MursionPortal.Message.YouAreSignedIn': 'Vous êtes actuellement connecté en tant que <code>{userName}</code> ({userProfile}).',
    'MursionPortal.Title.ContinueAs': 'Continuer en tant que {userName}',
    'MursionPortal.Message.OpenLink': 'Ouvrir le lien',
    'MursionPortal.Message.Allow': 'Autoriser',
    'MursionPortal.Message.Open': 'Ouvrir',
    'MursionPortal.Message.Yes': 'Oui',
    'MursionPortal.Message.OpenMursionApp': 'Ouvrir MursionApp',
    'MursionPortal.PasswordRequirementMessage.MinimumCharacters': 'Au moins {PASSWORD_MIN_LENGTH} caractères',
    'MursionPortal.Message.PortalHeading': 'Concevez, planifiez et gérez votre',
    'MursionPortal.PasswordRequirementMessage.UppercaseLetters': 'Lettres majuscules + Lettres minuscules',
    'MursionPortal.PasswordRequirementMessage.Numbers': 'toute combinaison de nombres',
    'MursionPortal.ErrorMessage.InvalidEmailFormat': 'Format d\'adresse e-mail invalide.',
    'MursionPortal.ErrorMessage.EmailCanNotBeEmpty': 'L\'adresse e-mail ne peut être vide.',
    'MursionPortal.ErrorMessage.EnterValidEmailAddress': 'Veuillez saisir une adresse e-mail valide.',
    'MursionPortal.ErrorMessage.PasswordCannotBeEmpty': 'Le mot de passe doit être défini',
    'MursionPortal.ErrorMessage.UrlCannotBeEmpty': 'L\'URL ne peut pas être vide',
    'MursionPortal.ErrorMessage.UrlIsNotValid': 'L\’URL n’est pas valide',
    'MursionPortal.ErrorMessage.NoMeetingId': 'Ne contient pas d\'identifiant de réunion',
    'MursionPortal.ValidationErrorMessage.FileRequestFailed': 'La demande de fichier a échoué',
    'MursionPortal.ValidationErrorMessage.FileExceedsLimit': 'Le fichier dépasse la limite de 5 Mo',
    'MursionPortal.ValidationErrorMessage.EnterMessage': 'Veuillez saisir votre message avant d\'envoyer',
    'MursionPortal.ValidationErrorMessage.MessageTooLong': 'Message trop long',
    'MursionPortal.ValidationErrorMessage.InvalidUrl': 'L’URL du fichier n’est pas valide',
    'MursionPortal.Button.SubmitFeedback': 'Envoyer les commentaires',
    'MursionPortal.Header.Feedback': 'Commentaires',
    'MursionPortal.Text.AdditionalComments': 'Commentaires ou suggestions supplémentaires',
    'MursionPortal.Header.ThanksForFeedback': 'Merci pour vos commentaires !',
    'MursionPortal.Button.Close': 'Fermer',
    'MursionPortal.Title.ShowFeedback': 'afficher les commentaires',
    'MursionPortal.Title.HideFeedback': 'cacher les commentaires',
    'MursionPortal.ErrorMessage.FileTypeNotSupported': 'Le type de fichier {type} n\'est pas pris en charge',
    'MursionPortal.ErrorMessage.ScenarioDuration': 'La durée du scénario doit être comprise entre {durationMinimum} et {durationMaximum}.',
    'MursionPortal.ErrorMessage.ScenarioScheduledSessions': 'Il y a {sessionsCount} sessions planifiées pour ce scénario. Annulez ces sessions avant d’archiver ce scénario.',
    'MursionPortal.ErrorMessage.ScenarioScheduledSession': 'Il y a {sessionsCount} session planifiée pour ce scénario. Annulez cette session avant d’archiver ce scénario.',
    'MursionPortal.ErrorMessage.ClientScheduledSessions': 'Il y a {sessionsCount} sessions planifiées pour ce client. Annulez ces sessions avant d’archiver le client.',
    'MursionPortal.ErrorMessage.ClientScheduledSession': 'Il y a {sessionsCount} session planifiée pour ce client. Annulez cette session avant d’archiver le client.',
    'MursionPortal.EulaAgreement.EndUserAgreement': 'Ce contrat d\'utilisateur final (« Contrat ») est un accord juridique entre vous, l\'utilisateur et Mursion, Inc. (« Mursion ») et est soumis aux termes et conditions du logiciel Mursion en tant que Contrat de service entre Mursion et l\'entité cliente Mursion à travers laquelle vous utilisez le logiciel Mursion. Dans un souci de clarté, vous reconnaissez et acceptez qu\'en tant que condition de votre utilisation et de votre accès au Logiciel Mursion (« Logiciel Mursion »), vous, ainsi que l\'entité qui vous donne accès au Logiciel Mursion, êtes soumis à et lié aux termes et conditions du présent Contrat.',
    'MursionPortal.EulaAgreement.TermsAndConditions': 'Sous réserve des termes et conditions de ce Contrat et du Logiciel Mursion en tant que Contrat de service, Mursion vous accorde par la présente des droits limités, non exclusifs, non transférables et sans sous-licence pour accéder et utiliser le Logiciel Mursion.',
    'MursionPortal.EulaAgreement.AgreeAndAcknowledge': 'Vous acceptez et reconnaissez que vous n\'avez pas le droit de copier, modifier, faire de l\'ingénierie inverse, décompiler, désassembler ou créer des œuvres dérivées basées, en tout ou en partie, sur le Logiciel Mursion ou sur des matériaux associés. Vous acceptez de ne pas annuler, désactiver ou contourner tout mécanisme de protection lié au Logiciel Mursion. Dès que vous aurez connaissance d\'une possession ou d\'une utilisation non autorisée ou d\'un accès au Logiciel Mursion, vous en informerez Mursion dans les plus brefs délais et vous acceptez en outre de fournir rapidement les détails de chaque événement de ce type, afin d\'aider raisonnablement à empêcher toute récurrence de celui-ci (dans la mesure du possible) et de coopérer pleinement à toute procédure engagée pour protéger les droits de Mursion.',
    'MursionPortal.EulaAgreement.ConstruedAsGranting': 'À l\'exception de ce qui est explicitement indiqué dans les présentes, rien dans le présent Contrat ne doit être interprété comme accordant, par implication ou autrement, des droits supplémentaires sur la propriété intellectuelle de Mursion sans l\'approbation écrite préalable de Mursion.',
    'MursionPortal.EulaAgreement.NonDisclosure': 'Vous acceptez de ne pas montrer ou divulguer le Logiciel Mursion ou une partie de celui-ci à quiconque à des fins autres que celles expressément autorisées dans les présentes.',
    'MursionPortal.EulaAgreement.AgreeAndRelease': 'Vous acceptez, et par les présentes, libérez, déchargez et dégagez de toute autre responsabilité Mursion, ses employés, dirigeants, agents, sociétés affiliées, dirigeants et administrateurs de toutes les réclamations, connues et inconnues, chaque fois qu\'elles surviennent, qui résultent de, découlent de, ou sont liées à votre utilisation du logiciel Mursion.',
    'MursionPortal.EulaAgreement.UnderstandAndAcknowledge': 'Vous comprenez et reconnaissez que Mursion peut collecter, maintenir, utiliser, stocker et analyser les informations et les données collectées lors de votre utilisation du logiciel Mursion, sous réserve des termes de la politique de confidentialité de Mursion qui peut être trouvée ici.',
    'MursionPortal.EulaAgreement.AccessRights': 'Les droits d\'utilisation et d\'accès qui vous sont accordés par Mursion prendront fin immédiatement lorsque le Logiciel Mursion en tant que Contrat de service, par lequel l\'accès au Logiciel Mursion vous est fourni, expire ou prend fin, ou immédiatement en cas de violation par vous de toute disposition de cet accord.',
    'MursionPortal.EulaAgreement.AgreementGoverned': 'Ce Contrat sera régi et interprété conformément aux lois de l\'État du Delaware sans égard aux conflits de principes de lois de celui-ci. Tous les litiges découlant de ou liés à votre utilisation du Logiciel Mursion ou du présent contrat seront portés devant un tribunal d\'État ou fédéral de juridiction compétente dans l\'État du Delaware et vous consentez par la présente à la juridiction personnelle de ces tribunaux.',
    'MursionPortal.EulaAgreement.ProvisionsDeclaredInvalid': 'Si des dispositions du présent contrat sont déclarées invalides, ces dispositions seront supprimées du présent contrat et les autres dispositions de ce dernier resteront pleinement en vigueur et de plein effet.',
    'MursionPortal.EulaAgreement.AgreementConflict': 'En cas de conflit entre les termes de ce Contrat et les termes du Logiciel Mursion en tant que Contrat de service, les termes contenus dans le présent contrat prévaudront.',
    'MursionPortal.EulaAgreement.AccessToMursionSofware': 'Votre utilisation ou votre accès au logiciel Mursion constitue un consentement au présent Contrat et aux termes et conditions énoncés dans les présentes.',
    'MursionPortal.Header.MursionUserAgreement': 'Mursion et accord de l\'utilisateur',
    'MursionPortal.Button.Back': 'Retour',
    'MursionPortal.Button.Skip': 'Suivant',
    'MursionPortal.Title.BackToSessions': 'Retour aux sessions',
    'MursionPortal.Title.Proceed': 'Démarrer',
    'MursionPortal.Message.SkipActivity': 'Si vous choisissez d\'ignorer, vous perdrez les informations sur les performances qui peuvent être fournies par notre moteur d\'analyse comportementale. Nous vous recommandons de ne pas ignorer cette activité.',
    'MursionPortal.HeaderTitle.ConfirmCancellation': 'Confirmer l’annulation',
    'MursionPortal.Label.Delete': 'supprimer',
    'MursionPortal.Label.Restore': 'restaurer',
    'MursionPortal.HeaderTitle.ConfirmRestore': 'Confirmer la restauration',
    'MursionPortal.Label.Archive': 'archiver',
    'MursionPortal.Button.CancelTraining': 'Annuler la formation',
    'MursionPortal.Button.No': 'Non',
    'MursionPortal.Button.CancelEvent': 'Annuler l’événement',
    'MursionPortal.Label.Update': 'modifier',
    'MursionPortal.HeaderTitle.ConfirmUpdate': 'Confirmer la mise à jour',
    'MursionPortal.Button.UpdateSession': 'Mettre à jour la session',
    'MursionPortal.Label.Swap': 'échanger',
    'MursionPortal.HeaderTitle.ConfirmAcceptedSwap': 'Confirmer l\'échange accepté',
    'MursionPortal.Button.AcceptSwap': 'Accepter l’échange',
    'MursionPortal.Label.Reason': 'Raison',
    'MursionPortal.Placeholder.ReasonForSwapping': 'S\'il vous plaît, ajoutez une raison pour l\'échange.',
    'MursionPortal.Label.Request': 'demande',
    'MursionPortal.HeaderTitle.ConfirmAcceptedRequest': 'Confirmer la demande acceptée',
    'MursionPortal.Button.AcceptRequest': 'Accepter la demande',
    'MursionPortal.Placeholder.ReasonForAccepting': 'Veuillez ajouter une raison pour l\'acceptation.',
    'MursionPortal.Button.ConfirmProjectChange': 'Confirmer le changement de projet',
    'MursionPortal.HeaderTitle.Confirm': 'Confirmer',
    'MursionPortal.Button.ConfirmCreateSession': 'Confirmer la création de la session',
    'MursionPortal.Placeholder.ReasonForCreation': 'Veuillez ajouter une raison pour la création.',
    'MursionPortal.Button.ConfirmAssigningSimSpecialist': 'Confirmer l\'affectation du spécialiste Sim',
    'MursionPortal.Placeholder.ReasonForAssigning': 'Veuillez ajouter une raison pour l’affectation.',
    'MursionPortal.Button.ConfirmChangingSimSpecialist': 'Confirmer le changement de spécialiste Sim',
    'MursionPortal.Placeholder.ReasonForChanging': 'Veuillez ajouter une raison pour le changement.',
    'MursionPortal.HeaderTitle.ConfirmTimeZone': 'Confirmer le fuseau horaire',
    'MursionPortal.Message.DontHaveMursionInstalled': 'Vous n\'avez pas installé ML3 Desktop App? Cliquez sur <code>Télécharger</code> pour installer.',
    'MursionPortal.ErrorMessage.CannotContainCharacter': 'Le nom du scénario ne doit pas contenir le caractère « _ »',
    'MursionPortal.ErrorMessage.EnterDifferentName': '{capitalized} {fieldName} existe déjà. Veuillez saisir un autre {objectName} {fieldName}.',
    'MursionPortal.ErrorMessage.EnterDifferentEmail': 'L\'adresse e-mail de l\'utilisateur existe déjà. Veuillez utiliser une adresse électronique différente.',
    'MursionPortal.ErrorMessage.NameExists': 'Un utilisateur avec le même {fieldName} existe déjà. Veuillez saisir un autre {fieldName}.',
    'MursionPortal.ErrorMessage.UnableToRemove': 'Impossible de supprimer {entity}. {entity} est actuellement déjà utilisé dans un {depEntity} actif.',
    'MursionPortal.ErrorMessage.OverlapsExistingTimeBlock': 'Le bloc horaire sélectionné chevauche un bloc horaire existant.',
    'MursionPortal.ErrorMessage.AccessIsDenied': 'Accès refusé',
    'MursionPortal.ErrorMessage.SomethingMustBeBroken': 'Il doit y avoir un problème quelque part. Veuillez réessayer ultérieurement.',
    'MursionPortal.ErrorMessage.Unauthorized': 'Non autorisé',
    'MursionPortal.ErrorMessage.RefreshTokenNotFound': 'Jeton d\'actualisation introuvable',
    'MursionPortal.ErrorMessage.CannotRefreshToken': 'Impossible d\'actualiser le jeton',
    'MursionPortal.QADialog.NoParticipants': 'Aucun participant',
    'MursionPortal.ErrorMessage.UsersUnavailable': 'Certains de vos utilisateurs ne sont pas disponibles à l\'heure sélectionnée.',
    'Filters.SsoProtocolType': 'Protocole : Tous',
    'Filters.SsoProtocolTypePlaceHolderHint': 'Rechercher le protocole de signature unique',
    'Filters.SSO.PrivacyAll': 'Type Tous',
    'Filters.SSO.PrivacyPublic': 'Type Public',
    'Filters.SSO.PrivacyPrivate': 'Type Privé',
    'Filters.SSO.PrivacyAriaLabel': 'filtre de type de confidentialité de signature unique',
    'Cleints.Modal.Label.DomainsTitle': 'La liste des domaines pouvant être utilisés pour la connexion',
    'MursionPortal.NoDomains': 'Aucun domaine',
    'MursionPortal.Header.Domains': 'Domaines',
    'MursionPortal.AddDomain': 'Ajouter un domaine',
    'Clients.Modal.Projects.ButtonTitle.EditDomains': 'Modifier les domaines',
    'Clients.Modal.Projects.DomainField.EnterDomain': 'Entrer un nom de domaine (par exemple, example.com)',
    'Clients.Modal.Projects.DomainField.InvalidDomain': 'Format de domaine non valide',
    'MursionPortal.ErrorMessage.SomethingWrongWithResponse': 'Quelque chose ne va pas avec la réponse.',
    'MursionPortal.ErrorMessage.AuthorizationHeaderIsEmpty': 'L\'en-tête d\'autorisation est vide',
    'MursionPortal.ErrorMessage.CannotRefreshAccessToken': 'Impossible d\'actualiser le jeton d\'accès',
    'MursionPortal.ErrorMessage.AuthorizationTokenIsEmpty': 'Le jeton d\'autorisation est vide',
    'MursionPortal.ErrorMessage.AuthenticationFailed': 'Échec d’authentification.',
    'MursionPortal.ErrorMessage.UnsupportedResponseFormat': 'Format de réponse non pris en charge.',
    'MursionPortal.ErrorMessage.AccountBlocked': 'Votre compte a été bloqué jusqu\'au {blockedDate}. Essayez de vous connecter après.',
    'MursionPortal.ErrorMessage.AttemptsRemaining': '{attempts} tentatives restantes avant un verrouillage de 24 heures. Si vous avez besoin d\'aide, veuillez réinitialiser votre mot de passe.',
    'MursionPortal.ErrorMessage.ClientIdMustBeSpecified': 'L\'ID du client doit être spécifié',
    'MursionPortal.ErrorMessage.ProjectIdMustBeSpecified': 'L\'ID du projet doit être spécifié',
    'MursionPortal.ErrorMessage.SessionIdMustBeSpecified': 'L\'ID de la session doit être spécifié',
    'MursionPortal.ErrorMessage.SSOIdMustBeSpecified': 'L\'ID de signature unique doit être spécifié',
    'MursionPortal.ErrorMessage.LearnerIdMustBeSpecified': 'L\'ID de l\'apprenant doit être spécifié',
    'MursionPortal.ErrorMessage.UserIdMustBeSpecified': 'L\'ID de l\'utilisateur doit être spécifié',
    'MursionPortal.ErrorMessage.TeamIdMustBeSpecified': 'L\'ID de l\'équipe doit être spécifié',
    'MursionPortal.ErrorMessage.AssetProjectIdMustBeSpecified': 'L\'ID du projet de la ressource doit être spécifié',
    'MursionPortal.ErrorMessage.AssetSettingsIdMustBeSpecified': 'L\'ID des paramètres de la ressource doit être spécifié',
    'MursionPortal.ErrorMessage.ScenarioIdMustBeSpecified': 'L\'ID du scénario doit être spécifié',
    'MursionPortal.ErrorMessage.ScenarioTemplateIdMustBeSpecified': 'L\'ID du modèle de scénario doit être spécifié',
    'MursionPortal.ErrorMessage.LicenseIdMustBeSpecified': 'fetchUsersList : l\'identifiant du titulaire de licence doit être spécifié',
    'MursionPortal.ErrorMessage.LicenseCertificateIdMustBeSpecified': 'fetchLicenseeCertificates : l\'identifiant du titulaire de licence doit être spécifié',
    'MursionPortal.ErrorMessage.LicenseConfigIdMustBeSpecified': 'fetchConfig : l\'identifiant du titulaire de licence doit être spécifié',
    'MursionPortal.ErrorMessage.LicenseUpdateConfigIdMustBeSpecified': 'updateConfig : l\'identifiant du titulaire de licence doit être spécifié',
    'MursionPortal.ErrorMessage.LicenseTestConfigIdMustBeSpecified': 'testConfig : l\'identifiant du titulaire de licence doit être spécifié',
    'MursionPortal.ErrorMessage.LicenseFetchTiersIdMustBeSpecified': 'fetchTiers : l\'identifiant du titulaire de licence doit être spécifié',
    'MursionPortal.ErrorMessage.LicenseUpdateTiersIdMustBeSpecified': 'updateTiers : l\'identifiant du titulaire de licence doit être spécifié',
    'MursionPortal.ErrorMessage.GlobalUserIdMustBeSpecified': 'L\'identifiant d\'utilisateur global doit être spécifié',
    'MursionPortal.ErrorMessage.ScenarioTemplateListLicenseIdMustBeSpecified': 'fetchScenarioTemplatesList : l\'identifiant du titulaire de licence doit être spécifié',
    'MursionPortal.ErrorMessage.ScenarioAttachmentListIdMustBeSpecified': 'fetchScenarioAttachmentLink : les identifiants du scénario et de la pièce jointe doivent être spécifiés',
    'MursionPortal.ErrorMessage.FetchScenarioIdMustBeSpecified': 'fetchScenario : l\'identifiant du scénario doit être spécifié',
    'Projects.ProjectInfo.Checkbox.AllowRecordingInfo': 'Cela ne supprimera aucun enregistrement actuellement sauvegardé.',
    'MursionPortal.AriaLabel.ToggleMenu': 'basculer le menu',
    'MursionPortal.ClientListItem.RedirectToUserDetails': 'Rediriger vers les détails {name}',
    'MursionPortal.AriaLabel.PreviousWeek': 'semaine précédente',
    'MursionPortal.AriaLabel.PreviousDay': 'jour précédent',
    'MursionPortal.AriaLabel.NextWeek': 'semaine prochaine',
    'MursionPortal.AriaLabel.NextDay': 'jour précédent',
    'Dashboard.LeftPane.CategoryHeader.SessionsDashboard': 'Tableau de bord des sessions',
    'MursionPortal.Title.SessionsPage': 'Page des sessions',
    'Clients.heading.Clients': 'Clients',
    'MursionPortal.AriaLabel.Client': 'client',
    'MursionPortal.AriaLabel.SimSpecialist': 'Spécialiste Sim',
    'MursionPortal.Project.TimeZoneStartDate': 'Date de début du fuseau horaire',
    'MursionPortal.Project.TimeZoneEndDate': 'Date de fin du fuseau horaire',
    'MursionPortal.AriaLabel.SelectTimezoneCombobox': 'sélectionner la boîte combo du fuseau horaire',
    'MursionPortal.Support.PhoneNumber': '1-855-999-5818',
    'MursionPortal.Message.LearnerEmpty': 'Veuillez sélectionner les apprenants qui ont participé à la session',
    'Dashboard.ProjectsTable.StatusValue.Active': 'ACTIF',
    'Dashboard.ProjectsTable.StatusValue.Completed': 'FINALISÉ',
    'Dashboard.ProjectsTable.StatusValue.Archived': 'ARCHIVÉ',
    'MursionPortal.Button.Refresh': 'Actualiser',
    'MursionPortal.Prompt.CalendarUpdated': 'Le calendrier a été mis à jour.',
    'MursionPortal.Prompt.ClickRefresh': 'Cliquez sur « Actualiser » pour afficher les modifications.',
    'MursionPortal.Label.SessionAttendance': 'Participation aux sessions',
    'MursionPortal.Label.SessionVideoUrl': 'URL de la vidéo de session',
    'MursionPortal.Label.LearnerAttendance': 'Participation des apprenants',
    'MursionPortal.Text.UserProfileUpdated': 'Le profil de l’utilisateur a été mis à jour !',
    'MursionPortal.Text.ClientUpdated': 'Un client a été mis à jour !',
    'MursionPortal.Text.ProjectUpdated': 'Un projet a été mis à jour !',
    'MursionPortal.Text.ScenarioUpdated': 'Un scénario a été mis à jour !',
    'MursionPortal.Text.LicenseeConfigUpdated': 'La configuration du titulaire de licence a été mise à jour !',
    'MursionPortal.Prompt.TrainingScheduled': '<code>La formation</code> a été programmée !',
    'Settings.SSO.Table.ColumnHeader.ClientName': 'Nom du client',
    'MursionPortal.Text.UserCreatedSuccessfully': 'Utilisateur créé avec succès',
    'MursionPortal.Text.UserNotCreated': 'Utilisateur non créé',
    'MursionPortal.Text.AssignedTeams': 'Équipes assignées',
    'MursionPortal.Client.EmailSettings.Invitation': 'Invitation',
    'MursionPortal.Client.EmailSettings.InvitationReminder': 'Rappel d\'invitation',
    'MursionPortal.Client.EmailSettings.Confirmation': 'Confirmation',
    'MursionPortal.Client.EmailSettings.Cancellation': 'Annulation',
    'MursionPortal.Client.EmailSettings.FirstReminder': 'Premier rappel',
    'MursionPortal.Client.EmailSettings.SecondReminder': 'Deuxième rappel',
    'MursionPortal.Client.EmailSettings.SessionRecording': 'L\'enregistrement de la session est prêt pour le propriétaire du compte/facilitateur',
    'MursionPortal.Client.EmailSettings.LearnerSession': 'L\'enregistrement de session de l\'apprenant est prêt',
    'MursionPortal.Client.EmailSettings.SimulationSessionVideoRejected': 'Enregistrement vidéo de session de simulation rejeté',
    'MursionPortal.Client.EmailSettings.SimulationSessionVideoDisallowed': 'Enregistrement vidéo de session de simulation non autorisé',
    'MursionPortal.Client.EmailSettings.SimulationSessionVideoRejectedUser': 'Enregistrement vidéo de session de simulation rejeté par certains utilisateurs',
    'MursionPortal.Client.EmailSettings.RequestRecieved': 'Demande de confirmation reçue',
    'MursionPortal.Client.EmailSettings.RequestNotFullfilled': 'Demande non satisfaite',
    'MursionPortal.Client.EmailSettings.RequestDateTime': 'Demande de changement de date/heure nécessaire',
    'MursionPortal.Client.EmailSettings.Title.SelectEmails': 'Sélectionnez les e-mails que vous souhaitez envoyer aux apprenants. Mursion vous recommande de laisser tous les e-mails activés.',
    'MursionPortal.Client.EmailSettings.Header.Registration': 'Inscription',
    'MursionPortal.Client.EmailSettings.Header.TrainingSession': 'Session de formation',
    'MursionPortal.Client.EmailSettings.Header.Videos': 'Vidéos',
    'MursionPortal.Client.EmailSettings.Header.Requests': 'Demandes',
    'MursionPortal.AriaLabel.ToggleButton': 'Bouton de bascule',
    'MursionPortal.Client.EmailSettings.Hint.RecordingEnabled': 'uniquement si les enregistrements sont activés',
    'MursionPortal.Client.EmailSettings.Hint.RequestFeature': 'uniquement si la fonction de demande est activée',
    'MursionPortal.SuccessDialog.Title': 'Dialogue de réussite',
    'MursionPortal.Dialog.CreateSSOConfiguartion.SuccessMessage': 'La configuration SSO du client a été créée avec succès.',
    'ITManager.TechRestriction.Table.EmptyMessage': 'Aucune restriction technologique bancaire n\'a été trouvée.',
    'ITManager.TechRestriction.Table.Tag': 'Balise',
    'ITManager.TechRestriction.Table.StartDate': 'Date de début',
    'ITManager.TechRestriction.Table.EndDate': 'Date de fin',
    'ITManager.TechRestriction.EditDialog.EnterTag': 'Saisir le nom de balise',
    'ITManager.TechRestriction.Table.HoverTitle': 'Afficher/modifier la restriction technologique',
    'ITManager.TechRestriction.Table.NoTechRestrictionError': 'Il n\'y a pas encore de restrictions technologiques',
    'MursionPortal.DateTimePicker.StartTime': 'Heure de début',
    'MursionPortal.DateTimePicker.EndTime': 'Heure de fin',
    'MursionPortal.TechRestrictions': 'Restrictions techniques',
    'MursionPortal.Caption.ScenarioBankUsersList': 'liste des utilisateurs de la banque de scénarios',
    'MursionPortal.Caption.SsoConfigurationList': 'liste de configuration sso',
    'MursionPortal.AriaLabel.DateRangeCalendarButton': 'appuyez sur entrée pour interagir avec le calendrier et sélectionnez la date de début et de fin',
    'MursionPortal.Modal.Header.SessionTimeout': 'Expiration de la session',
    'MursionPortal.Modal.Body.SessionTimeout': 'Your session will timeout in 1 minute, please continue to extend your session', // TODO
    'MursionPortal.Status.PendingNoSS': 'En attente (pas de SS)',
    'MursionPortal.Status.PendingNoL': 'En attente (pas de L)',
    'MursionPortal.Status.PendingNoLearners': 'En attente (aucun apprenant)',
    'MursionPortal.Status.PendingNoLSS': 'En attente (pas de SS/L)',
    'Dashboard.LeftPane.SupplyManagement.SimView': 'Vue Sim',
    'Dashboard.LeftPane.SupplyManagement.SchedulingView': 'Vue de Planification',
    'MursionPortal.Table.ColumnSelection.Button.Label': 'Colonnes',
    'Session.Table.Column.SubmitRequest': 'Soumettre une demande',
    'Filters.SimSpecialistStatus': 'Statut de spécialiste Sim',
    'Filters.SimSpecialistStatusPlaceHolderHint': 'Filtre de recherche sur le statut de spécialiste Sim...',
    'Users.TableModal.SSOUserID': 'ID de l\'utilisateur SSO',
    'MursionPortal.UnavailableSlotsError.SorryText': 'Bonjour ! Je suis vraiment désolée !',
    'MursionPortal.UnavailableSlotsError.BusyText': 'Il semble que nous soyons occupés à simuler avec d\'autres apprenants.',
    'MursionPortal.UnavailableSlotsError.TryAgainText': 'Veuillez réessayer demain ou un autre jour !',
    'MursionPortal.Session.Survey.ClickHere': 'Cliquez ici',
    'MursionPortal.Session.SurveyNotLoading': 'L\'enquête ne se charge pas ?',
    'Users.Table.Heading.Viewonly': '(Voir uniquement)',
    'MursionPortal.BookItUI.BusyText': 'Il semble que nous soyons occupés à simuler avec d\'autres apprenants en ce moment.',
    'MursionPortal.BookItUI.TryLaterText': 'D\'autres sessions de simulation devraient s\'ouvrir bientôt, veuillez réessayer plus tard dans la journée.',
    'MursionPortal.BookItUI.MoreSimulationsText': 'D\'autres sessions de simulation devraient s\'ouvrir prochainement.',
    'MursionPortal.BookItUI.ComeBackText': 'Revenez si vous voulez faire votre simulation plus tard dans la journée.',
    'ClientUserTable.AddNewUsers.AssignTeams.UserEmails.Archived': 'Utilisateur archivé',
    'ClientUserTable.AddNewUsers.UpdateArchivedUserWarning': 'Les utilisateurs {archivedUsers} sont actuellement archivés. En procédant, vous ajouterez ces utilisateurs aux équipes sélectionnées et vous les désarchiverez.',
    'ClientUserTable.AddNewUsers.UpdateExistingArchivedUserWarning': 'Ces utilisateurs {existingUsers} existent déjà et les utilisateurs {archivedUsers} sont actuellement archivés. En procédant, vous ajouterez ces utilisateurs aux équipes sélectionnées et vous désarchiverez également les utilisateurs archivés.',
    'MursionPortal.ProjectCreateStep.UpperLimitValidation.Hours': 'N\’e peut être supérieur à {code} heures',
    'MursionPortal.ProjectCreateStep.UpcomingAvailability': 'Disponibilité prochaine',
    'MursionPortal.Table.ColumnHeader.TotalSession': 'Session totale',
    'MursionPortal.Table.ColumnHeader.ActiveCertifications': 'Certifications actives',
    'MursionPortal.Button.Decline': 'Refus',
    'MursionPortal.Having.Issues.Logging.In': 'Vous n\'arrivez pas à vous connecter ?',
    'MursionPortal.Label.Call': 'Appeler :',
    'MursionPortal.Project.Hours': 'Heure(s)',
    'Users.Button.ProfService': 'Service professionnel',
    'Users.Button.Operations': 'Service des opérations',
    'Users.Button.SimDesigner': 'Concepteur Sim',
    'MursionPortal.ArtProject.Placeholder.EnterOrSelect': 'Sélectionner ou Entrer',
    'MursionPortal.Setting.LearnerCommunicationPersonalization': 'Personnalisation de la communication avec l\'apprenant',
    'MursionPortal.LearnerCommunicationPersonalization.Heading': 'Vous pouvez personnaliser des messages spécifiques pour vos apprenants ici.',
    'MursionPortal.SupplyBasedScheduling.SubmitPage.SimulationConfirmation': 'Vous êtes confirmé pour votre simulation ! Nous vous recommandons de vous présenter quelques minutes avant l\'heure de début.',
    'MursionPortal.DemandBasedScheduling.SubmitPage.LearnerConfirmed': '{learners} confirmés pour la simulation !',
    'MursionPortal.DemandBasedScheduling.SubmitPage.DateAndTime': 'Date et heure',
    'MursionPortal.Personalized.LearnerDashboard.Section': 'Message que vous voulez que tous vos apprenants voient sur le Tableau de bord de l\'apprenant',
    'MursionPortal.Personalized.SchedulingPage.Section': 'Message que vous voulez que tous vos apprenants voient sur la Page de programmation',
    'MursionPortal.Personalized.Section.Tooltip': 'Le message sera mis à jour à tous les endroits',
    'MursionPortal.CheckboxLabel.JoinNow': 'Rejoignez-nous',
    'MursionPortal.ProjectCreateStep.UpcomingAvailability.Tooltip': 'Les rôles des clients verront apparaître les créneaux désactivés pour une heure de \'Disponibilité à venir\' donnée, après la \'Disponibilité Sim\'. Si \'Disponibilité à venir\' est égal à 0, aucun créneau désactivé ne sera affiché et seuls les créneaux de \'Disponibilité Sim\' seront affichés. Si la valeur de \'Disponibilité à venir\' est de 24 heures, tous les créneaux disponibles après les heures de \'Disponibilité Sim\' seront affichés en tant que créneaux désactivés pour une journée.',
    'MursionPortal.LearnerDashboard.JoinNow.OutsideOfficeHoursText': 'Revenez plus tard pendant les heures de bureau de votre organisatio\’n pour répondre à ce scénario.',
    'MursionPortal.Setting.Personalization.LastUpdated': 'Dernière mise à jour :',
    'MursionPortal.CheckboxLabel.JoinNowRequests': 'Rejoindre les demandes',
    'MursionPortal.LearnerDashboard.JoinNow.HeaderText': 'Nous vous mettons en contact avec un avatar <code>pour une simulation en direct</code>. Veuillez consulter les documents ci-dessous pour vous préparer.',
    'MursionPortal.LearnerDashboard.JoinNow.SorryText': 'Nous sommes désolés !',
    'MursionPortal.LearnerDashboard.JoinNow.AvatarsBusyText': 'Tous nos avatars sont occupés avec d\'autres apprenants. Revenez plus tard ou réessayez dans quelques minutes.',
    'MursionPortal.LearnerDashboard.JoinNow.CongratsText': 'Félicitations ! Un avatar vous a été attribué !',
    'MursionPortal.LearnerDashboard.JoinNow.AvatarsMatchText': 'L\'avatar vous attend ! Cliquez sur Rejoindre la simulation en direct lorsque vou\’s êtes prêt ou votre expérience commencera automatiquement après le compte à rebours.',
    'MursionPortal.LearnerDashboard.JoinNow.StartLearning': 'If you are not able to get connected, click below to start learning.',
    'MursionPortal.LearnerDashboard.JoinNow.StarBucks.MyLearning': 'Starbucks MyLearning',
    'MursionPortal.LearnerDashboard.JoinNow.StarBucks.LiveSimulationExp': 'This session is a live simulation experience. Partners can now choose to take an eLearning on de-escalating conflict in MyLearning, as an alternative to the live safety simulation.',
    'MursionPortal.Button.Leave': 'Laisser',
    'MursionPortal.Personalized.ScenarioPage.Section': 'Message que vous voulez que les apprenants voient sur la Page du scénario',
    'MursionPortal.Personalized.CreateNewScenario.Button.Label': 'Créer un nouveau scénario',
    'MursionPortal.Personalized.Scenario.Search.Placeholder': 'Rechercher ou sélectionner le(s) scénario(s) :',
    'MursionPortal.Personalized.Scenario.Selection.Title': 'Ajouter un Nouveau scénario spécifique',
    'MursionPortal.Personalized.NoMessage': 'Pas de message actuellement',
    'MursionPortal.Personalized.MetaData.NoHistory': 'Pas encore d\'historique.',
    'MursionPortal.JoinNow.AlreadyBooked.Message': '{code} est réservé pour une session en ce moment.',
    'Users.Table.SendInvite.TooltipText': 'La désactivation des courriels d\'inscription désactive également les boutons « cliquer pour inviter » dans le portail',
    'MursionPortal.JoinNow.AlreadyBooked.JoinSession.Message': 'Cliquez sur Rejoindre la simulation en direct pour rejoindre ou reprendre la session.',
    'Dashboard.LeftPane.CategoryHeader.Scenarios': 'Scénarios',
    'Dashboard.LeftPane.Learners.TeamAndProjectActivity': 'Activité d\'équipe et de projet',
    'MursionPortal.Dashboard.Chart.label.LearnerStatus.NotYetScheduled': 'Pas encore programmé',
    'MursionPortal.Dashboard.Chart.label.LearnerStatus.Scheduled': 'Pas encore achevé',
    'MursionPortal.Dashboard.Chart.label.LearnerStatus.LearnerCompleted': 'Achevé',
    'MursionPortal.Dashboard.Chart.label.LearnerStatus.NotYetAssigned': 'Pas encore attribué',
    'MursionPortal.Dashboard.Chart.Tooltip.LearnerStatus.NotYetScheduled': 'Nombre distinct d\'apprenants qui sont affectés à au moins une équipe qui est affectée à au moins un scénario mais l\'apprenant n\'a jamais programmé de session.',
    'MursionPortal.Dashboard.Chart.Tooltip.LearnerStatus.Scheduled': 'Nombre distinct d\'apprenants qui ont programmé au moins une session mais qui n\'ont pas encore assisté et terminé au moins une session.',
    'MursionPortal.Dashboard.Chart.Tooltip.LearnerStatus.CompletedAssigned': 'Nombre distinct d\'apprenants ayant suivi au moins une session.',
    'MursionPortal.Dashboard.Chart.Tooltip.LearnerStatus.NotYetAssigned': 'Nombre distinct d\'apprenants qui ne sont pas affectés à une équipe ou d\'apprenants qui font partie d\'une équipe qui n\'est affectée à aucun scénario.',
    'MursionPortal.Dashboard.ScenarioLearner.Header.LearnerStatus': 'Achèvement de l\'apprentissage',
    'MursionPortal.Dashboard.ScenarioLearner.Header.LearnerSequence': 'Des apprenants uniques',
    'MursionPortal.Dashboard.ScenarioLearner.Header.SequenceCount': '(Maximum de {sessionCount} sessions)',
    'Dashboard.SequenceOrder.Header.Title': 'Activité manquée, annulation tardive et reprogrammation tardive',
    'MursionPortal.Integrations': 'Intégrations',
    'MursionPortal.Personalized.Message.Delete.Title': 'Êtes-vous sûr de vouloir supprimer le message ?',
    'MursionPortal.Personalized.Message.Delete.Tooltip': 'Supprimer le message',
    'MursionPortal.Personalized.Scenario.Message.OnlyOneAtTime': 'Les modifications ne peuvent être apportées qu\'à un seul message à la fois. Veuillez enregistrer ou annuler celui qui est déjà en mode édition.',
    'MursionPortal.Error.Time': '6h00 - 22h00 ET',
    'MursionPortal.CheckboxLabel.JoinNow.Tooltip.Text': 'La programmation par BookIt est toujours activée lorsque l\'option Rejoindre maintenant est activée.',
    'MursionPortal.JoinNow.FinalizedScenario.Message': 'Le scénario est finalisé, veuillez revenir une fois que le scénario est actif.',
    'MursionPortal.CheckboxLabel.sessionMissedTime': 'Session manquée',
    'MursionPortal.Title.Seconds': 'Deuxième(s)',
    'MursionPortal.RequestTable.Accept.ErrorMessage': 'Désolé, la session est annulée.',
    'MursionPortal.Client.EmailTypeFilter.SchedulingLocked': 'Programmation bloqué',
    'MursionPortal.Client.EmailTypeFilter.SchedulingUnlocked': 'Programmation déverrouillé',
    'MursionPortal.ScenarioInfo.SchedulingLockout.Time': 'Blocage de la programmation',
    'MursionPortal.Table.ColumnHeader.SimAvailable': 'Sim disponible',
    'MursionPortal.CheckboxLabel.sessionMissedTime.Tooltip.Text': 'Temps après lequel la session sera marquée comme manquée si aucun apprenant ou SIM ne se joint à la session.',
    'MursionPortal.Dashboard.Chart.label.ScenarioActivity.NotYetCompleted': 'Pas encore achevé',
    'MursionPortal.Dashboard.Chart.label.ScenarioActivity.completedOneSession': 'Terminé 1 session',
    'MursionPortal.Dashboard.Chart.label.ScenarioActivity.completedTwoPlusSession': 'Terminé 2+ sessions',
    'MursionPortal.Dashboard.Chart.label.ScenarioActivity.OneSession': '1 Session',
    'MursionPortal.Dashboard.Chart.label.ScenarioActivity.TwoSession': '2 Sessions',
    'MursionPortal.Dashboard.Chart.label.ScenarioActivity.ThreeSession': '3 Sessions',
    'MursionPortal.Dashboard.Chart.label.ScenarioActivity.FourPlusSession': '4+ Sessions',
    'MursionPortal.Dashboard.Chart.Tooltip.ScenarioActivity.NotYetCompleted': 'Nombre d\'apprenants uniques affectés à ce scénario mais qui ne l\'ont pas encore terminé',
    'MursionPortal.Dashboard.Chart.Tooltip.ScenarioActivity.completedOneSession': 'Nombre d\'apprenants uniques assignés à ce scénario qui l\'ont terminé une fois.',
    'MursionPortal.Dashboard.Chart.Tooltip.ScenarioActivity.completedTwoPlusSession': 'Nombre d\'apprenants uniques assignés à ce scénario qui l\'ont terminé deux fois ou plus.',
    'MursionPortal.Dashboard.Chart.Tooltip.ScenarioActivity.OneSession': 'Nombre d\'apprenants uniques qui ont manqué, annulé ou reprogrammé tardivement une session.',
    'MursionPortal.Dashboard.Chart.Tooltip.ScenarioActivity.TwoSession': 'Nombre d\'apprenants uniques qui ont manqué, annulé tardivement et/ou reprogrammé tardivement deux sessions.',
    'MursionPortal.Dashboard.Chart.Tooltip.ScenarioActivity.ThreeSession': 'Nombre d\'apprenants uniques qui ont manqué, annulé tardivement et/ou reprogrammé tardivement trois sessions.',
    'MursionPortal.Dashboard.Chart.Tooltip.ScenarioActivity.FourPlusSession': 'Nombre d\'apprenants uniques qui ont manqué, annulé et/ou reprogrammé tardivement quatre sessions ou plus.',
    'MursionPortal.ScenarioBank.ItemBank.Label': 'Blocs de construction',
    'MursionPortal.ScenarioBank.ItemBank.Skills': 'Compétences',
    'MursionPortal.ScenarioBank.ItemBank.Domains': 'Domaines',
    'MursionPortal.ScenarioBank.ItemBank.Events': 'Evénements',
    'MursionPortal.ScenarioBank.ItemBank.Pathways': 'Parcours',
    'MursionPortal.ScenarioBank.ItemBank.Resources': 'Ressources',
    'MursionPortal.ScenarioBank.ItemBank.Outcomes': 'Résultats',
    'MursionPortal.ScenarioBank.ItemBank.Mindsets': 'L\'état d\'esprit',
    'MursionPortal.ScenarioBank.ItemBank.Avatars': 'Avatars',
    'MursionPortal.ScenarioBank.ItemBank.ResearchSummaries': 'Résumés de recherche',
    'MursionPortal.ScenarioBank.ItemBank.LearnerInstructions': 'Instructions pour l\'apprenant',
    'MursionPortal.ScenarioBank.ItemBank.Column.Level': 'Niveau',
    'MursionPortal.ScenarioBank.ItemBank.Column.Domain': 'Domaine',
    'MursionPortal.ScenarioBank.ItemBank.Column.Translations': 'Traductions',
    'MursionPortal.ScenarioBank.ItemBank.Button.Create.Skill': 'Créer une compétence',
    'MursionPortal.Dashboard.Label.ScenarioActivity.ErrorMessage': 'Veuillez sélectionner au moins un projet et un scénario dans les filtres.',
    'MursionPortal.Learner.Onboarding': 'Embarquement de l\'apprenant',
    'MursionPortal.Tab.Onboarding': 'ENTRÉE EN VIGUEUR',
    'MursionPortal.OrganizationName.Placeholder': 'entrez le nom de votre organisation',
    'Dashboard.LeftPane.Learners.LearnerLockedOut': 'L\'apprenant est bloqué',
    'Dashboard.Learners.LearnerLockedOut.TableHeader': 'Apprenants bloqués dans la programmation en raison d\'une erreur ou d\'une annulation',
    'Dashboard.Learners.LearnerLockedOut.LockedOutColumn': 'Date/heure bloquée pour la programmation',
    'Dashboard.Learners.LearnerLockedOut.AutoReleaseColumn': 'Date/heure jusqu\'à la libération automatique',
    'MursionPortal.DontHaveEmail.Text': 'Vous n\'avez pas d\'adresse email au sein de votre organisation ?',
    'MursionPortal.InvalidCompanyName.Error': 'Votre organisation exige que les utilisateurs se connectent à l\'aide de leur adresse email. Veuillez la saisir ci-dessus.',
    'MursionPortal.LearnerOnboardingPage.Text.Welcome': 'Bienvenue',
    'MursionPortal.LearnerOnboardingPage.Text.MursionVideoOverview': 'Présentation vidéo de Mursion',
    'MursionPortal.LearnerOnboardingPage.Text.KeyThingsToKnow': 'Ce qu\'il faut savoir',
    'MursionPortal.LearnerOnboardingPage.Welcome.Section.Text': 'Nous sommes ravis de vous accueillir dans votre programme d\'apprentissage Mursion ! Mursion utilise des simulations immersives pilotées par la technologie "human-in-the-loop" pour offrir une expérience d\'apprentissage complète qui vous dotera des outils et techniques nécessaires pour réussir dans un monde en constante évolution.',
    'MursionPortal.LearnerOnboardingPage.Welcome.Section.Beginner.Text': 'Que vous soyez débutant ou professionnel expérimenté, notre programme a quelque chose à offrir à chacun. Nous sommes impatients que vous commenciez.',
    'MursionPortal.LearnerOnboardingPage.Video.Section.Text': 'Tout d\'abord, regardez cette vidéo qui donne un aperçu de Mursion !',
    'MursionPortal.LearnerOnboardingPage.Scheduled.Section.Text': '<strong>Vous êtes maintenant prêt à :</strong>Planifier votre première simulation !',
    'MursionPortal.LearnerOnboardingPage.keysThingsToKnow.Section.Heading': 'Passons maintenant en revue les principaux éléments à connaître !',
    'MursionPortal.LearnerOnboardingPage.Mursion.Methodology': 'Méthodologie de Mursion',
    'MursionPortal.LearnerOnboardingPage.Mursion.Methodology.Summary': 'Mursion est fondé sur la recherche et a fait ses preuves en matière de changement de comportement. Nos simulations basées sur des avatars vous aideront à apprendre à réagir de manière appropriée dans des situations chargées d\'émotion.',
    'MursionPortal.LearnerOnboardingPage.Mursion.WhyAvatars.Text': 'Pourquoi des avatars ?',
    'MursionPortal.LearnerOnboardingPage.Mursion.WhyAvatars.Summary': 'Mursion est un outil plus efficace que les méthodes traditionnelles ou les jeux de rôle. Les études montrent que les gens sont plus confiants et curieux lorsqu\'ils s\'engagent avec des avatars que lorsqu\'ils interagissent avec leurs pairs ou leurs responsables.',
    'MursionPortal.LearnerOnboardingPage.Human.Text': 'L\'homme dans la boucle',
    'MursionPortal.LearnerOnboardingPage.Human.Summary': 'Chaque simulation de Mursion est animée par un Spécialiste de la simulation qui assure les dialogues et les mouvements de pas moins de cinq avatars virtuels dans chaque simulation.',
    'MursionPortal.LearnerOnboardingPage.Practice.Text': 'L\'importance de la pratique',
    'MursionPortal.LearnerOnboardingPage.Practice.Text.Summary': 'Améliorer ses compétences en matière de leadership n\'est pas une tâche facile. Mais la recherche montre que l\'apprentissage est plus efficace lorsqu\'il inclut la pratique. Mursion donne la priorité à la pratique en créant des espaces sûrs, dynamiques et accessibles.',
    'MursionPortal.ScenarioBank.ItemBank.Button.Create.Domain': 'Créer un domaine',
    'MursionPortal.ScenarioBank.ItemBank.Level.L1': 'L1',
    'MursionPortal.ScenarioBank.ItemBank.Level.L2': 'L2',
    'MursionPortal.ScenarioBank.ItemBank.Level.L3': 'L3',
    'MursionPortal.Label.OrganizationName': 'Nom de l\'organisation',
    'MursionPortal.ScenarioBank.ItemBank.Button.Create.Pathway': 'Créer un Parcours',
    'MursionPortal.ScenarioBank.ItemBank.Button.Create.Event': 'Créer un Événement',
    'MursionPortal.ScenarioBank.ItemBank.Button.Create.Resource': 'Créer une Ressource',
    'MursionPortal.ScenarioBank.ItemBank.Button.Create.Outcome': 'Créer un Résultat',
    'MursionPortal.ScenarioBank.ItemBank.Button.Create.Mindset': 'Créer un État d\'esprit',
    'MursionPortal.ScenarioBank.ItemBank.Button.Create.Avatar': 'Créer le Nom de l\'avatar',
    'MursionPortal.ScenarioBank.ItemBank.Button.Create.ResearchSummary': 'Créer un Résumé de la recherche',
    'MursionPortal.ScenarioBank.ItemBank.Button.Create.LearnerInstruction': 'Créer une Instruction pour l\'apprenant',
    'MursionPortal.ScenarioBank.ItemBank.BackButton.Label': 'Retour à la Banque de scénarios',
    'MursionPortal.ScenarioBank.ItemBank.Title.Placeholder': 'Tapez ici',
    'MursionPortal.Dashboard.NextOnProject.Footer.MissOrCancelationRateFulfilled.Text': 'You cannot schedule this simulation as you have reached the missed or cancellation limit set by your organization. Please reach out to your program admin to allow scheduling.',// TODO add translations
    'MursionPortal.ScenarioInfo.Show': 'Afficher',
    'MursionPortal.ScenarioInfo.CertificationHistory': 'Historique de la certification',
    'MursionPortal.Session.AlreadyBooked': 'Désolé, la session est déjà acceptée par un autre SIM.',
    'MursionPortal.CustomizeInvitation.Title': 'Personnalisez vos invitations',
    'MursionPortal.CustomizeInvitation.SubTitle': 'Pour personnaliser le texte, écrasez le texte de chaque champ ci-dessous ou laissez le texte par défaut.',
    'MursionPortal.CustomizeInvitation.IntroMessage': 'Message d\'introduction aux apprenants (les scénarios disponibles se remplissent automatiquement sous ce message) :',
    'MursionPortal.CustomizeInvitation.Label.SenderName': 'Nom de l\'expéditeur (l\'adresse email sera celle de noreply@mursion.com)',
    'MursionPortal.CustomizeInvitation.Label.Subject': 'Sujet',
    'MursionPortal.InviteLearnersTeam.Section.Title': 'Quelle(s) équipe(s) souhaitez-vous inviter à la programmation ?',
    'MursionPortal.InviteLearnersTeam.Section.SubTitle': 'Les apprenants seront invités à participer à tous les scénarios de ce projet pour lesquels ils sont éligibles',
    'MursionPortal.InviteLearnersTeam.ScenariosAssigned.Text': 'scénarios assignés',
    'MursionPortal.Confirmation.Modal.Invitations.Text': 'Vos invitations ont été programmées !',
    'MursionPortal.ScenarioSetting.ValidationMessage': 'doit être inférieur ou égal au même paramètre au niveau du client et/ou du projet.',
    'MursionPortal.ScenarioBank.EventDetail.Details': 'Détails',
    'MursionPortal.ScenarioBank.CharacterLimit.TitleMessage': 'Limite de caractères : {titleMaxLength}',
    'MursionPortal.ScenarioBank.CharacterLimit.DescriptionMessage': 'Limite de caractères : {descriptionMaxLength}',
    'MursionPortal.ScenarioBank': 'Modèle d\'événement',
    'MursionPortal.ScenarioBank.ItemBank.Edit.Header': 'Inspirer confiance aux autres pour qu\'ils partagent des informations sur leur travail',
    'MursionPortal.ScenarioBank.ItemBank.Edit.Header.Title': 'Détails du domaine',
    'MursionPortal.ScenarioBank.ItemBank.Edit.DescriptionOfUpdate.Label': 'L\'enregistrement de ces modifications mettra à jour ce {type} dans tous les scénarios. Veuillez ajouter une brève description des modifications :',
    'Dashboard.Learners.LearnerLockedOut.LearnerColumn.Tooltip': 'Nom ou email de l\'utilisateur',
    'MursionPortal.ScenarioInfo.CertificationHistory.Column.Certified': 'Certifié',
    'MursionPortal.ScenarioInfo.CertificationHistory.Column.Decertified': 'Décertifié',
    'MursionPortal.ScenarioInfo.CertificationHistory.Column.Inactive': 'Inactif',
    'MursionPortal.Dashboard.LearnerLockedOut.ManualRelease': 'Libération de l\'apprenant pour la programmation',
    'MursionPortal.CustomizeInvitation.Sender.Name': 'Mursion',
    'MursionPortal.CustomizeInvitation.Subject.Title': 'Préparez-vous à exercer vos talents ! Vos sessions Mursion sont prêtes à être programmées.',
    'MursionPortal.CustomizeInvitation.Intro.Message': 'Vous avez été invité à participer à une expérience de simulation à Mursion. Cliquez ci-dessous pour planifier une session, ou visitez votre Tableau de bord et explorez.',
    'MursionPortal.Dashboard.LearnerLockedOut.ManualRelease.ConfirmationText': 'Êtes-vous sûr de vouloir libérer {code} apprenant(s) pour la programmation ?',
    'MursionPortal.Dashboard.LearnerLockedOut.ManualRelease.SuccessText': 'Le(s) apprenant(s) de {code} a(ont) été libéré(s) pour la programmation.',
    'Dashboard.Learners.LearnerLockedOut.LockedOutColumn.Tooltip': 'Date/heure à laquelle l\'apprenant a été bloqué pour la programmation',
    'Dashboard.Learners.LearnerLockedOut.AutoReleaseColumn.Tooltip': 'Date/heure à laquelle l\'apprenant sera automatiquement libéré',
    'MursionPortal.ScenarioBank.ItemBank.NextGenScenario': 'Scénario NextGen',
    'MursionPortal.ScenarioBank.ResourceFilter.Book': 'Livre',
    'MursionPortal.ScenarioBank.ResourceFilter.Article': 'Article',
    'MursionPortal.ScenarioBank.Filter.Label.Publisher': 'Éditeur',
    'MursionPortal.ScenarioBank.ItemBank.Level.Placeholder': 'Sélectionner le niveau',
    'MursionPortal.ScenarioBank.ItemBank.Domain.Placeholder': 'Rechercher ou sélectionner un domaine :',
    'MursionPortal.Label.CompletionRate.Tooltip': 'Le taux d\'achèvement est le nombre de fois qu\'un apprenant peut terminer une simulation avant de perdre la possibilité de planifier (blocage)',
    'MursionPortal.Label.SchedulingRate.Tooltip': 'Le taux de programmation est le nombre de fois qu\'un apprenant peut programmer une simulation avant de perdre la possibilité de la programmer (blocage)',
    'MursionPortal.Label.MissCancellationRate.Tooltip': 'Le taux d\'échec/annulation est le nombre de fois qu\'un apprenant peut manquer ou annuler une simulation avant de perdre la possibilité de la planifier (blocage)',
    'MursionPortal.ScenarioInfo.SchedulingLockout.Time.Tooltip': 'Heures de blocage de la programmation est le nombre d\'heures que durera le blocage de la programmation',
    'MursionPortal.ScenarioBank.ItemBank.Mindset.Type.Derailing': 'Déraillement',
    'MursionPortal.ScenarioBank.ItemBank.Edit.Header.Title.Skill': 'Détails des compétences',
    'MursionPortal.ScenarioBank.ItemBank.Edit.Header.Title.Mindset': 'Détails de l\'état d\'esprit',
    'MursionPortal.ProjectCreate.DeliveryHours': 'Heures de livraison',
    'MursionPortal.ProjectCreate.DeliveryHours.Tooltip': 'Durée pendant laquelle les apprenants se verront proposer des créneaux horaires instantanés',
    'MursionPortal.ProjectCreate.DeliveryHours.Start': 'Début des heures de livraison',
    'MursionPortal.ProjectCreate.DeliveryHours.End': 'Fin des heures de livraison',
    'MursionPortal.ScenarioBank.ItemBank.Edit.Header.Title.LearnerInstruction': 'Détails de l\'instruction de l\'apprenant',
    'MursionPortal.ScenarioBank.ItemBank.Avatar.Edit.Header.Title': 'Détails de l\'avatar',
    'MursionPortal.ScenarioBank.Label.AddLink': 'Ajouter un lien',
    'MursionPortal.ScenarioBank.Year.placeholderText': 'Année',
    'MursionPortal.ScenarioBank.ItemBank.Resource.Edit.Header.Title': 'Détails de la ressource',
    'MursionPortal.ScenarioBank.ItemBank.Outcome.Edit.Header.Title': 'Détails des résultats',
    'MursionPortal.ScenarioBank.OutcomeLevelSelector.Advanced': 'Avancé',
    'MursionPortal.ScenarioBank.OutcomeLevelSelector.Intermediate': 'Intermédiaire',
    'MursionPortal.ScenarioBank.OutcomeLevelSelector.Foundational': 'Fondamental',
    'MursionPortal.ScenarioBank.ItemBank.Skill': 'Compétence',
    'MursionPortal.Resources.Search.Text': 'Rechercher ou sélectionner une (des) ressource(s)',
    'MursionPortal.ScenarioBank.ItemBank.ResearchSummaries.Details': 'Détails des résumés de recherche',
    'MursionPortal.ScenarioBank.ItemBank.ResearchSummary': 'Résumé de la recherche',
    'MursionPortal.ScenarioBank.ItemBank.Edit.Header.Title.Pathway': 'Détails du parcours',
    'MursionPortal.ScenarioBank.ItemBank.Pathway': 'Parcours',
    'MursionPortal.ScenarioBank.ConfirmationText.Archive': 'Êtes-vous sûr de vouloir archiver ce {block} ?',
    'MursionPortal.ScenarioBank.ConfirmationText.Unarchived': 'Êtes-vous sûr de vouloir désarchiver ce {block} ?',
    'MursionPortal.ScenarioBank.Unarchived': 'Désarchiver',
    'MursionPortal.ScenarioBank.ItemBank.Resource': 'Ressources',
    'MursionPortal.ScenarioBank.ItemBank.Mindset': 'L\'état d\'esprit',
    'MursionPortal.ScenarioBank.ItemBank.LearnerInstruction': 'Instruction de l\'apprenant',
    'MursionPortal.ScenarioBank.ItemBank.Edit.Header.Title.Event': 'Détails de l\'événement',
    'MursionPortal.ScenarioBank.NextGen.AvatarPerspectives': 'Perspectives d\'Avatar',
    'MursionPortal.ScenarioBank.NextGen.AvatarPerspectives.Intro': 'Intro',
    'MursionPortal.ScenarioBank.NextGen.AvatarPerspectives.DiscProfile': 'Profil du disque',
    'MursionPortal.ScenarioBank.NextGen.AvatarPerspectives.VerbalStyle': 'Style verbal',
    'MursionPortal.ScenarioBank.Content': 'Contenu',
    'MursionPortal.ScenarioBank.Content.ScenarioTitle': 'Titre du scénario',
    'MursionPortal.ScenarioBank.Content.ScenarioCoverStory': 'Scénario de couverture',
    'MursionPortal.ScenarioBank.ItemBank.Outcome.Placeholder': 'Sélectionner un résultat bancaire',
    'MursionPortal.ScenarioBank.Content.WhyThisMatters': 'Pourquoi c\'est important',
    'MursionPortal.ScenarioBank.NextGen.AvatarGuide': 'Guide de l\'avatar',
    'MursionPortal.ScenarioBank.NextGen.ViewScenario': 'Voir le scénario',
    'MursionPortal.ScenarioBank.NextGen.Event': 'Icône d\'événement',
    'MursionPortal.NextOnProject.Card.JoinNow': 'Rejoindre la Simulation en direct',
    'MursionPortal.JoinNowWaitingPage.HowItWorks.Title': 'Comment ça marche',
    'MursionPortal.JoinNowWaitingPage.HowItWorks.Intro': 'Bonjour ! Vous allez bientôt être mis en relation avec un avatar pour <code>participer à une simulation en direct</code>.',
    'MursionPortal.JoinNowWaitingPage.HowItWorks.Matched': 'Une fois qu\'un avatar vous a été attribué, cliquez sur <code>’Rejoindre la simulation en direct’</code>',
    'MursionPortal.JoinNowWaitingPage.HowItWorks.Allow': 'Cliquez sur <code>’Autoriser’</code> pour l\'accès au micro/caméra lorsque vous y êtes invité.',
    'MursionPortal.JoinNowWaitingPage.HowItWorks.StartSession': 'Cliquez sur <code>’Commencer la session’</code> et attendez que l\'avatar commence votre simulation',
    'MursionPortal.ScenarioBank.NextGen.OutOfSite.Label': 'Loin des regards, en tête des préoccupations',
    'MursionPortal.FirstGen.Scenario.Button.Text': '1ère génération',
    'MursionPortal.NextGen.Scenario.Button.Text': '2ème génération',
    'MursionPortal.NextGen.Scenario.Assessment': 'Évaluation',
    'MursionPortal.NextGen.Scenario.OutOfSite': 'Loin des regards, en tête des préoccupations',
    'MursionPortal.NextGen.Scenario.Synopsis': 'Synopsis',
    'MursionPortal.NextGen.Scenario.MinimumPoints': 'Points minimums requis pour un résultat positif',
    'MursionPortal.NextGen.Scenario.Successful': 'Réussite',
    'MursionPortal.NextGen.Scenario.OpportunityDevelop': 'Possibilité de développer davantage',
    'MursionPortal.NextGen.Scenario.LibraryInfo': 'Informations sur la bibliothèque',
    'MursionPortal.NextGen.Scenario.LibraryInfo.DifficultyLevel': 'Niveau de difficulté',
    'MursionPortal.NextGen.Scenario.LibraryInfo.ScenarioCoverImage': 'Image de couverture du scénario',
    'MursionPortal.NextGen.Scenario.LibraryInfo.ScenarioThumbnailImage': 'Image miniature du scénario',
    'MursionPortal.NextGen.Scenario.LibraryInfo.CoverImageDescription': 'Description de l\'image de couverture',
    'MursionPortal.NextGen.Scenario.LibraryInfo.ThumbnailImageDescription': 'Description de l\'image miniature',
    'MursionPortal.ScenarioBank.CharacterLimit.ResponseMessage': 'Limite de caractères : {responseMaxLength}',
    'MursionPortal.ScenarioBank.EventDetail.AvatarResponseTitle': 'Réponses possibles à propos d\'Avatar',
    'MursionPortal.NextGen.Scenario.ReflectionGuide': 'Guide de réflexion',
    'MursionPortal.NextGen.Scenario.Scoring': 'Notation',
    'MursionPortal.ErrorMessage.buildingBlock': 'L\'identifiant du bloc de construction doit être spécifié',
    'MursionPortal.NextGen.Scenario.LibraryInfo.DragAndDropText': 'Glissez-déposez votre fichier ici ou cliquez pour sélectionner des fichiers',
    'MursionPortal.NextGen.Scenario.Publish': 'Publier',
    'MursionPortal.NextGen.Scenario.Publish.Label': 'Veuillez remplir les champs obligatoires (*) pour publier.',
    'MursionPortal.NextGen.Scenario.Last.Publish.Label': 'Dernière publication {publishDate} par {publishedBy}',
    'MursionPortal.EulaAgreement.CodeOfConductCheckBox.Text': 'Je suis d\'accord avec le modèle de livraison des simulations de Mursion et je m\'engage à respecter et à honorer le Code de conduite de Mursion dans toutes les simulations auxquelles je participe. En savoir plus sur les simulations de Mursion et notre Code de conduite <code>ici.</code>',
    'MursionPortal.JoinNow.Cancellation.FirstOption.Text': 'Je n\'ai pas le temps de le faire pour l\instant.',
    'MursionPortal.JoinNow.Cancellation.SecondOption.Text': 'Je ne sais pas ce que c\'est',
    'MursionPortal.JoinNow.Cancellation.ThirdOption.Text': 'Je reviendrai le faire plus tard',
    'MursionPortal.JoinNow.Cancellation.FirstOption.Description.Text': 'La plupart des personnes qui ont fait cette simulation ont trouvé qu\'elle constituait une bonne utilisation de leur temps. Cela prendra 30 minutes ou moins.',
    'MursionPortal.JoinNow.Cancellation.SecondOption.Description.Text': 'Au lieu d\'une vidéo, cette pratique interactive vous aide à développer des compétences et des aptitudes dans un espace sûr et dynamique.',
    'MursionPortal.JoinNow.Cancellation.ThirdOption.Description.Text': 'Bien sûr ! Vous pouvez revenir à tout moment pendant les heures d\'ouverture des organisations et participer à la simulation en direct pour mettre en pratique vos compétences !',
    'MursionPortal.JoinNow.CancellationText.Heading': 'Pourquoi voulez-vous annuler ?',
    'MursionPortal.JoinNow.CancellationPage.Title': 'Possibilité de s\'entraîner avec l\'avatar !',
    'MursionPortal.JoinNow.CancellationPage.Text': 'Identifier des moyens positifs de résoudre la situation dans le respect des politiques et des lignes directrices de Starbucks et rechercher des occasions de créer des Meilleurs moments pour tous les clients.',
    'MursionPortal.JoinNow.JoinSimulation.Button.Text': 'Continuer pou Joindre la simulation',
    'MursionPortal.LearnerDashboard.JoinNow.SearchingAvatar': 'Nous recherchons un avatar <code>pour votre simulation en direct!</code>',
    'MursionPortal.LearnerDashboard.JoinNow.SuccessText': '<code>Félicitations</code>, un avatar vous a été attribué pour votre simulation en direct !',
    'MursionPortal.LearnerDashboard.JoinNow.ConnectingText': 'Nous vous <code>connectons à votre avatar</code>, et votre simulation en direct va bientôt commencer !',
    'MursionPortal.LearnerDashboard.JoinNow.DoNotCloseText': 'Ne fermez pas la fenêtre de votre navigateur !',
    'MursionPortal.LearnerDashboard.JoinNow.StrategiesToUse': 'Stratégies à utiliser',
    'MursionPortal.LearnerDashboard.JoinNow.PracticeOpportunityText': 'Possibilité de s\'entraîner avec l\'avatar !',
    'MursionPortal.Text.Sorry': 'Désolé',
    'MursionPortal.Text.Matching': 'Correspondance',
    'MursionPortal.Text.Connecting': 'Connexion',
    'MursionPortal.JoinNowScenarioInfoCard.Text': 'Revenez plus tard ou réessayez dans quelques minutes !',
    'MursionPortal.JoinNowScenarioInfoCard.HeaderText': 'Tous nos avatars sont occupés avec d\'autres apprenants !',
    'MursionPortal.JoinNow.CancellationPage.GoBackButton.Text': 'Annuler et revenir au Tableau de bord',
    'MursionPortal.NextGen.Scenario.Event.AddMessage': 'Ajouter un minimum de 2',
    'MursionPortal.ScenarioBank.ItemBank.Events.AddButton': '+Ajouter',
    'MursionPortal.NextGen.ScenarioBank.Event.Select.Placeholder': 'Sélectionner un événement',
    'MursionPortal.NextGen.ScenarioBank.Skill.Select.Placeholder': 'Sélectionner une compétence',
    'MursionPortal.NextGen.ScenarioBank.Event.AvatarBehavior.Label': 'Comportement de l\'avatar*',
    'MursionPortal.NextGen.ScenarioBank.Event.LearnerResponse.Label': 'Réponse attendue de l\'apprenant*',
    'MursionPortal.NextGen.ScenarioBank.Event.AvatarAdd.Button': 'Ajouter une réponse',
    'MursionPortal.NextGen.ScenarioBank.AvatarResponseName.Select.Placeholder': 'Sélectionner un avatar',
    'MursionPortal.NextGen.ScenarioBank.Label.AvatarPossibleResponse': 'Réponses possibles à propos d\'Avatar',
    'MursionPortal.NextGen.ScenarioBank.Event.AssessmentObservedAction.Label': 'Décrire l\'action observée',
    'MursionPortal.NextGen.ScenarioBank.Event.AssessmentImapct.Label': 'Expliquer l\'impact',
    'MursionPortal.NextGen.ScenarioBank.StrengthAssessment': 'Évaluation(s) des forces*',
    'MursionPortal.NextGen.ScenarioBank.Button.StrengthAssessment': '+ Ajouter l\'évaluation de la force',
    'MursionPortal.NextGen.ScenarioBank.Label.Strength': 'Force',
    'MursionPortal.NextGen.ScenarioBank.OpportunityAssessment': 'Évaluation des opportunités*',
    'MursionPortal.NextGen.ScenarioBank.Button.OpportunityAssessment': '+ Ajouter l\'évaluation des opportunités',
    'MursionPortal.NextGen.ScenarioBank.Label.Opportunity': 'Opportunité',
    'MursionPortal.NextGen.Scenario.Event.Mindset.Select.Placeholder': 'Choisir un état d\'esprit positif',
    'MursionPortal.NextGen.Scenario.Event.Derailing.Select.Placeholder': 'Sélectionner l\'état d\'esprit qui fait dérailler',
    'MursionPortal.NextGen.Scenario.Event.Scoring.Title': 'Notation des événements',
    'MursionPortal.NextGen.Scenario.Event.Mindset.Title': 'Ajouter les mentalités positives et négatives*',
    'MursionPortal.NextGen.Scenario.Event.Positive.Button.Text': 'Ajouter un état d\'esprit positif supplémentaire',
    'MursionPortal.NextGen.Scenario.Event.Derailing.Button.Text': 'Ajouter un autre état d\'esprit perturbateur',
    'MursionPortal.Text.Searching': 'Recherche',
    'MursionPortal.NextGen.Scenario.Event.MindsetsNavigated': 'Naviguer dans les mentalités',
    'Dashboard.ContractProgress.Column.RemainingUtilization': 'Utilisation restante',
    'Dashboard.ContractProgress.Column.EstimatedRemaining': 'Estimation de la durée restante',
    'MursionPortal.Label.Completion': 'Taux d\'achèvement',
    'Dashboard.Report.ReportSessionOption.AllDays': 'Toutes les sessions',
    'MursionPortal.Status.Abbreviation.EarlyCancelled': 'CE',
    'MursionPortal.Dashboard.Chart.label.SimulationBreakdown.EstimatedRemaining': 'Estimation de la durée restante',
    'Dashboard.ContractProgress.ColumnTooltip.RemainingUtilization': 'Simulations restantes et programmées en tant que nombre de simulations achetées',
    'MursionPortal.Dashboard.Chart.Header.SimulationBreakdownQuickView': 'Répartition des sessions - Aperçu rapide',
    'MursionPortal.NextGen.SelectorText.Race': 'Course',
    'MursionPortal.NextGen.SelectorText.Ethnicity': 'Ethnicité',
    'MursionPortal.NextGen.SelectorText.None': 'Aucun',
    'MursionPortal.NextGen.SelectorText.Woman': 'Il faut être une femme',
    'MursionPortal.NextGen.SelectorText.Man': 'Il faut être un homme',
    'MursionPortal.NextGen.SelectorText.Wheelchair': 'Une personne doit être en fauteuil roulant',
    'MursionPortal.NextGen.SelectorText.agedText': 'L\'un d\'entre eux doit être âgé de plus de 55 ans',
    'MursionPortal.NextGen.Avatar.Restriction': 'Restriction concernant les avatars',
    'MursionPortal.NextGen.AvatarOrScenarioComposition': 'Composition d\'Avatar/Scénario',
    'MursionPortal.Label.Opening': 'Ouverture',
    'MursionPortal.Label.Closing': 'Fermeture',
    'MursionPortal.Label.Introduction': 'Introduction',
    'MursionPortal.ReflectionGuide.LearnerAssessment': 'Évaluation de l\'apprenant',
    'MursionPortal.ReflectionGuide.HostAssessment': 'Évaluation de l\'hôte',
    'MursionPortal.ReflectionGuide.HostOpportunity': 'Opportunité d\'accueil',
    'MursionPortal.ReflectionGuide.Introduction.Text': 'Bienvenue à tous ! Vous sentez-vous prêt à réfléchir à votre expérience ? (permettre à l\'apprenant de donner son accord avant de continuer)',
    'MursionPortal.Label.Say': 'SAY',
    'MursionPortal.Label.Ask': 'ASK',
    'MursionPortal.Label.Bullet': 'Balle',
    'MursionPortal.ReflectionGuide.LearnerPreparation.FirstQuestion': 'Comment s\'est déroulée la conversation ?',
    'MursionPortal.ReflectionGuide.LearnerPreparation.SecondQuestion': 'Comment la réalité de la conversation correspond-elle à ce que vous aviez prévu ?',
    'MursionPortal.ReflectionGuide.LearnerPreparation.ThirdQuestion': 'Que s\'est-il passé ?',
    'MursionPortal.ReflectionGuide.LearnerPreparation.FourthQuestion': 'Y a-t-il eu des indices (verbaux et non verbaux) de la part de l\'avatar qui vous ont donné une idée de la façon dont la conversation allait se dérouler ?',
    'MursionPortal.ReflectionGuide.LearnerPreparation.FifthQuestion': 'Y a-t-il eu des indices (verbaux et non verbaux) de la part de l\'avatar qui vous ont donné une idée de la façon dont la conversation allait se dérouler ?',
    'MursionPortal.ReflectionGuide.LearnerAssessment.FirstSay': 'Au cours de cette conversation, vous avez dû faire face à des dynamiques difficiles - Mickey était frustré.',
    'MursionPortal.ReflectionGuide.LearnerAssessment.SecondSay': 'Bien qu\'ils soient bien intentionnés, je vois souvent des dirigeants réagir de manière peu productive :',
    'MursionPortal.ReflectionGuide.LearnerAssessment.ThirdSay': 'J\'ai également vu des dirigeants réagir de manière plus productive en :',
    'MursionPortal.ReflectionGuide.LearnerAssessment.FourthSay': 'Cette conversation vous a permis de naviguer dans des dynamiques difficiles',
    'MursionPortal.ReflectionGuide.LearnerAssessment.Ask': 'Où pensez-vous avoir atterri ? (suivi : pourquoi/ comment/ comment le savez-vous ?): Où pensez-vous avoir atterri ? (suite : pourquoi/ comment/ comment le savez-vous ?)',
    'MursionPortal.ReflectionGuide.HostAssessment.Opening': 'Si je pouvais mettre en valeur l\'un des points forts dont vous avez fait preuve au cours de la réunion (sélectionnez-en un) :',
    'MursionPortal.ReflectionGuide.HostAssessment.Closing': 'Mes réflexions correspondent-elles aux vôtres ? Pourquoi ou pourquoi pas ?',
    'MursionPortal.ReflectionGuide.HostAssessment.Opportunity.Opening': 'Si je pouvais améliorer l\'une des possibilités de développement que j\'ai observées (sélectionnez-en une) :',
    'MursionPortal.ReflectionGuide.Closing.Say': 'C\'EST un travail difficile et je vous remercie de mettre en pratique ces compétences importantes avec Mursion aujourd\'hui !',
    'MursionPortal.ReflectionGuide.Closing.Ask': 'Quelle est la chose que vous retenez de cette expérience et que vous utiliserez la prochaine fois que vous serez confronté à une situation similaire dans la vie réelle ?',
    'MursionPortal.ScenarioBank.ItemBank.ResearchSummary.Placeholder': 'Sélectionner un Résumé de recherche',
    'MursionPortal.ScenarioBank.ItemBank.LearnerInstruction.Placeholder': 'Sélectionner une Instruction de l\'apprenant',
    'MursionPortal.NextGen.Scenario.LibraryInfo.ClinkHereToReplaceThisFile': 'Cliquez ici pour remplacer ce fichier.',
    'MursionPortal.Status.Empty': ' ',
    'MursionPortal.ScenarioContent.Label':'Ajouter un minimum de {min} et un maximum de {max}',
    'MursionPortal.Search.Text.Skills':'Rechercher ou sélectionner une (des) compétence(s)',
    'MursionPortal.Search.Text.Avatar':'Rechercher ou sélectionner un ou plusieurs avatar(s)',
    'MursionPortal.NextGen.Scenario.TimelineAndScheduling':'Calendrier et programmation',
    'MursionPortal.NextGen.Session.Replay':'Reprise de la session',
    'MursionPortal.NextGen.StrengthsAndOpportunities':'Points forts et opportunités',
    'MursionPortal.NextGen.Recommendations':'Recommandations',
    'MursionPortal.NextGen.MoreToDiscover':'Plus à découvrir',
    'MursionPortal.NextGen.MissionAccomplished':'Mission accomplie !',
    'MursionPortal.NextGen.Learner.ScenarioMaterials':'Matériel de scénario',
    'MursionPortal.NextGen.Learner.PathwayAffiliation':'Affiliation à la filière',
    'MursionPortal.NextGen.Learner.SkillLevel':'Niveau de compétence',
    'MursionPortal.NextGen.Learner.YourMission':'Votre mission',
    'MursionPortal.ScenarioBank.ShowOnlySecondGenscenarios':'Afficher uniquement les scénarios de la 2e Gen',
    'MursionPortal.NextGen.CreateNewSkill.Header.Text':'Créer une nouvelle compétence bancaire',
    'MursionPortal.NextGen.Learner.StartSimulation':'Démarrer la simulation',
    'MursionPortal.SimMaterials.Label.CoverStory':'Article de couverture',
    'MursionPortal.SimMaterials.Label.LearnerMission':'Mission de l\'apprenant',
    'MursionPortal.SimMaterials.Label.Background':'Contexte',
    'MursionPortal.SimMaterials.Label.PerspectivesAndIdentities':'Perspectives et identités',
    'MursionPortal.SimMaterials.HeaderText.SimDashboard':'Tableau de bord SIM',
    'MursionPortal.NextGen.Learner.ToStart':'pour commencer',
    'MursionPortal.Project.DeliveryHours.Standard':'Heures de livraison standard',
    'MursionPortal.Project.DeliveryHours.Global':'Heures de livraison mondiales',
    'MursionPortal.Label.Monday.ShortForm':'Lun',
    'MursionPortal.Label.Tuesday.ShortForm':'Mar',
    'MursionPortal.Label.Wednesday.ShortForm':'Mer',
    'MursionPortal.Label.Thursday.ShortForm':'Jeu',
    'MursionPortal.Label.Friday.ShortForm':'Ven',
    'MursionPortal.Label.Saturday.ShortForm':'Sam',
    'MursionPortal.Label.Sunday.ShortForm':'Dim',
    'MursionPortal.NextGen.CreateFromBank.Customized.Label':'Personnalisé à partir du scénario de la banque',
    'MursionPortal.NextGen.CreateFromBank.CustomizedFromBank.Tooltip':'Ce scénario a été personnalisé à partir du scénario bancaire original. Toute mise à jour apportée au scénario bancaire ne sera pas répercutée sur ce scénario.',
    'MursionPortal.BuildingBlock.Label.PublishedDate':'Date de publication',
    'MursionPortal.Label.CopyTeamInviteLink':'Copier le lien d\'invitation de l\'équipe',
    'MursionPortal.NextGen.SessionReplayText':'Cette conversation vous a permis de vous entraîner à animer une conversation qui a soutenu un membre de l\'équipe dans une période de détresse. Votre réussite dépend de votre capacité à faire preuve d\'empathie à l\'égard de Jo.',
    'MursionPortal.NextGen.TheMission':'La mission :',
    'MursionPortal.NextGen.DemonstratedStrength':'Démonstration de force',
    'MursionPortal.NextGen.OpportunityForFutureGrowth':'Opportunité de croissance future',
    'MursionPortal.NextGen.CompetenciesFocus':'Les compétences visées sont les suivantes :',
    'MursionPortal.NextGen.StrengthAndOpportunitiesText':'Au cours du compte rendu de la conversation, une force observée et une opportunité de croissance ont été identifiées :',
    'MursionPortal.NextGen.WeObserved':'Nous avons observé....',
    'MursionPortal.NextGen.WhyThisMatters':'Pourquoi c\'est important',
    'MursionPortal.ScenarioSetting.MissOrCancelationRate.ValidationMessage':'Le délai de blocage de la programmation ne peut\ pas être nul si le taux de manques/d\'annulations n\'est pas nul.',
    'MursionPortal.NextGen.CreateFromBank.Customized.Modal.Text':'Vous avez personnalisé un scénario bancaire et toute modification future du scénario bancaire ne sera pas répercutée dans ce scénario. Voulez-vous continuer ?',
    'MursionPortal.SimMaterials.Event.SkillsInFocus.Label':'Les compétences en ligne de mire',
    'MursionPortal.SimMaterials.Label.SimulationEvents':'Événements de simulation',
    'MursionPortal.SimMaterials.Event.AvatarBehavior.Label':'Comportement de l\'avatar',
    'MursionPortal.SimMaterials.Event.LearnerResponse.Label':'Réponse attendue de l\'apprenant',
    'MursionPortal.Label.NoTimeSelected':'Pas de temps sélectionné',
    'MursionPortal.Label.StartTime.EndTime.Selected':'L\'heure de début et l\'heure de fin doivent être sélectionnées',
    'MursionPortal.SimMaterials.Label.ReflectionAndDebrief':'Réflexion et compte rendu',
    'MursionPortal.NextGen.Scenario.Planning':'Planification',
    'MursionPortal.HowItWorks.Experience.Text':'Votre expérience commencera automatiquement dans quelques secondes !',
    'MursionPortal.HowItWorks.MicCamera':'Micro/caméra',
    'MursionPortal.HowItWorks.Prompt.Text':'Lorsque vous y êtes invité, cliquez sur <strong>\'Autoriser\'</strong> pour accéder au micro/caméra afin que l\'avatar puisse vous voir et vous entendre !',
    'MursionPortal.HowItWorks.StartSession.Text':'Cliquez sur <strong>Démarrer la session\'</strong> et lisez la description du scénario pour vous préparer !',
    'MursionPortal.LearnerMaterials.KeyCompetencies':'Compétences clés',
    'MursionPortal.LearnerMaterials.ResearchMaterials':'Matériels de recherche',
    'MursionPortal.LearnerMaterials.PracticeOpportunity':'Opportunité de pratique',
    'MursionPortal.ScenarioInfo.Label.ScenarioBank':'Le scénario est créé à partir de la banque',
    'MursionPortal.DemandBasedScheduling.SlotSelectionMessage':'Veuillez choisir les créneaux horaires qui vous conviennent. Nous \programmerons votre scénario dans l\'un des créneaux horaires que vous aurez choisis.',
    'MursionPortal.NextGen.ScenarioBank.SubStrengthAssessment':'Force',
    'MursionPortal.NextGen.ScenarioBank.SubOpportunityAssessment':'Opportunité',
    // needs translations
    'MursionPortal.EngagementHub.EmailLearners': 'Email Learners', // TODO add translations
    'MursionPortal.EngagementHub.InviteToTeam': 'Invite to Team', // TODO add translations
    'MursionPortal.EngagementHub.InviteLearnersToScheduleTitle': 'Invite your learners to start or continue their practice', // TODO add translations
    'MursionPortal.EngagementHub.InviteLearnersToScheduleSummary': 'Engage your learners via email or by embedding shared links in your own communications.', // TODO add translations
    'MursionPortal.EngagementHub.ViewInvitations': 'View Invitations', // TODO add translations
    'MursionPortal.EngagementHub.CreateInvite': 'Create Invite', // TODO add translations
    'MursionPortal.NextGen.replayConversation':'Replay the Conversation',// TODO add translations
    'MursionPortal.EngagementHub.BackTo': 'Back to {clientName}', // TODO add translations
    'MursionPortal.EngagementHub.LearningPotential': 'At Mursion, we believe in maximizing every learner\'s potential.', // TODO add translations
    'MursionPortal.EngagementHub.CommunicationLearnMore': 'Communication Is Key! Learn more', // TODO add translations
    'MursionPortal.EngagementHub.UnlockTeamPotential': 'Unlock Your Team’s Potential', // TODO add translations
    'MursionPortal.EngagementHub.InviteLearners.Title': 'Invite your learners to schedule their simulations', // TODO add translations
    'MursionPortal.EngagementHub.InviteLearners.Summary': '58% of users are more likely to sign up for their simulation if you send a custom invitation to schedule.', // TODO add translations
    'MursionPortal.EngagementHub.ManageEmailSettings.Label': 'Your Email, Your Way!', // TODO add translations
    'MursionPortal.EngagementHub.ManageEmailSettings.Title': 'Manage email settings and gain insights', // TODO add translations
    'MursionPortal.EngagementHub.Customize': 'Customize', // TODO add translations
    'MursionPortal.EngagementHub.EmailConfigurations.Title': 'Email Configurations', // TODO add translations
    'MursionPortal.EngagementHub.EmailConfigurations.Summary': 'Customize email messages that resonate with your learners and drive adoption and engagement.', // TODO add translations
    'MursionPortal.EngagementHub.PowerfulEmailAnalytics.Title': 'Powerful Email Analytics', // TODO add translations
    'MursionPortal.EngagementHub.PowerfulEmailAnalytics.Summary': 'Measure email open, click, and unread rates to discover insights and make data-driven decisions.', // TODO add translations
    'MursionPortal.EngagementHub.PersonalizeMessaging.Label': 'Add Personal Touch', // TODO add translations
    'MursionPortal.EngagementHub.PersonalizeMessaging.Title': 'Personalize messaging in the Mursion Portal', // TODO add translations
    'MursionPortal.EngagementHub.LearnerDashboard': 'Learner Dashboard', // TODO add translations
    'MursionPortal.EngagementHub.ScenarioPage': 'Scenario Pages', // TODO add translations
    'MursionPortal.EngagementHub.SchedulingPage': 'Scheduling Page', // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.LearnerFeedbackTooltip': 'This email will only go out if feedback report is enabled at project level. Please reach out to your Mursion representative to learn more.', // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.Title': 'Email Settings', // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.Summary': 'Use these tools to help customize different emails.', // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.EmailName': 'Email Name', // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.SenderName': 'Sender Name (email will be no-reply@mursion.com)', // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.ViewCustomize': 'View/Customize', // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.EnableDisable': 'Enable/Disable', // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.LearnerLockout': 'Learner Lockout', // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.VideoFeedback': 'Video & Feedback', // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.ModifySettings': 'Click edit to modify the settings', // TODO add translations
    'MursionPortal.EngagementHub.BackTitle': 'Back to Engagement Hub', // TODO add translations
    'MursionPortal.EngagementHub.CommunicationKey': 'Communication Is Key!', // TODO add translations
    'MursionPortal.EngagementHub.CommunicationKey.summary': 'A great Mursion rollout starts with effective communication to your learners. It\'s important to ensure they are prepared and understand the connection between Mursion and your learning and development program goals.', // TODO add translations
    'MursionPortal.EngagementHub.CommunicationKey.TItle': 'Elements of Effective Learner Communication', // TODO add translations
    'MursionPortal.EngagementHub.CommunicationKey.LearnerBenefits.Title': 'Lead with Learner Benefits', // TODO add translations
    'MursionPortal.EngagementHub.CommunicationKey.LearnerBenefits.Summary': 'Lead with learner benefits, not product features. Share learner testimonials and tie the skills they are developing to business objectives.', // TODO add translations
    'MursionPortal.EngagementHub.CommunicationKey.PracticeProgress.Title': 'Practice makes Progress', // TODO add translations
    'MursionPortal.EngagementHub.CommunicationKey.PracticeProgress.Summary': 'Practice is essential to developing new skills. Ensure your message highlights The Importance of Practice - And Our Reluctance To Do It to acknowledge challenges that learners face.', // TODO add translations
    'MursionPortal.EngagementHub.CommunicationKey.LeadersMessage.Title': 'Message from Leaders', // TODO add translations
    'MursionPortal.EngagementHub.CommunicationKey.LeadersMessage.Summary': 'Ask senior leaders to communicate (meetings, written, video, etc) why Mursion sessions are valuable and how developing the target skills is tied to business priorities and growth opportunities.', // TODO add translations
    'MursionPortal.EngagementHub.CommunicationKey.SetExpectations.Title': 'Set Expectations', // TODO add translations
    'MursionPortal.EngagementHub.CommunicationKey.SetExpectations.Summary': 'Mursion is a new technology that uses avatars powered by humans. Prepare learners for these conversations by sharing why and how avatars create psychology safety and is an effective way to practice.', // TODO add translations
    'MursionPortal.Tab.Engagement': 'Engagement', // TODO add translations
    'MursionPortal.Tab.EngagementHub': 'Engagement Hub', // TODO add translations
    'MursionPortal.Tab.EmailManagement': 'Email Management', // TODO add translations
    'MursionPortal.Tab.PortalMessaging': 'Portal Messaging', // TODO add translations
    'MursionPortal.EngagementHub.ViewInvitations.BackButton.Label': 'Back to Invite to Schedule', // TODO add translations
    'MursionPortal.Portal.Messaging.Heading.Text': 'Inform, engage, and delight learners using your organization’s unique brand voice.',  // TODO add translations
    'MursionPortal.Portal.Messaging.SubHeading.Text': 'Add custom messaging throughout the Portal to engage your learners.',  // TODO add translations
    'MursionPortal.EngagementHub.ViewInvitations.Column.InvitationDate': 'Invitation Date', // TODO add translations
    'MursionPortal.EngagementHub.ViewInvitations.Column.MessageToLearners': 'Message to Learners', // TODO add translations
    'MursionPortal.EngagementHub.ViewInvitations.Column.ExcludedLearners': 'Excluded Learners', // TODO add translations
    'MursionPortal.EngagementHub.ViewInvitations.Column.CreatedBy': 'Created By', // TODO add translations
    'MursionPortal.Portal.EngagementHub.InviteToScheuled.Text': '{scenariosCount} scenarios assigned to {learnerCount} learners', // TODO add translations
    'MursionPortal.Portal.EngagementHub.InviteToScheuled.Text.AddLearners': 'Add Learners', // TODO add translations
    'MursionPortal.Tab.EmailManagement.Analytics': 'Analytics', // TODO add translations
    'MursionPortal.CustomizeLearnerInvitations.Teams.Invitation.Message': 'The following team(s) already have scheduled an invitation on this day:', // TODO add translations
    'MursionPortal.CustomizeLearnerInvitations.New.Invitation.Message': 'Please make a note and start a new invitation', // TODO add translations
    'MursionPortal.CustomizeLearnerInvitations.Learners.Invitation.Text': 'Learners will receive the invitation in the early morning hours (Pacific time) on this day.', // TODO add translations
    'MursionPortal.CustomizeLearnerInvitations.SendDate.Label': 'Send Date', // TODO add translations
    'MursionPortal.Scenario.Generation.FilterType.Label' : 'Generation', // TODO add translations
    'MursionPortal.EngagementHub.ViewInvitations.CancelInvitation.Modal.Text': 'Are you sure you want to cancel the invitation created by {createdBy}?', // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.LearnerFeedbackReport': 'Learner Feedback Report', // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.CancellationByLearner': 'Cancellation by Learner', // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.MissedSessionByLearner': 'Missed by Learner', // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.LearnerReleased': 'Learner Released', // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.LearnerLockOut': 'Learner Lockout', // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.SessionConfirmation': 'Session Confirmation', // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.Subject.Invitation': 'Welcome to Mursion - a platform for you to practice your skills', // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.Subject.InvitationReminder': `Reminder:You're invited to {licenseeName}`, // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.Subject.Confirmation': 'Thanks for signing up! Your Simulation is confirmed.', // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.Subject.FirstReminder': `Don't forget! Your Mursion simulation is soon!`, // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.Subject.SecondReminder': `Get excited! Your Mursion simulation starts in {upcomingBeforeStart} minutes.`, // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.Subject.CancellationByByLearner': 'Your simulation was canceled', // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.Subject.MissedSessionByLearner': 'We missed you today!', // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.Subject.LearnerLockedOut': 'You missed/cancelled this scenario too many times', // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.Subject.LearnerReleased': 'Your session is available to schedule', // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.Subject.LearnerSessionRecordingIsReady': 'Want to see how you did? Visit your Mursion Dashboard.', // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.Subject.LearnerFeedbackReport': 'Want to see how you did? Review your session report.', // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.LearnerSessionRecordingReady': 'Learner Session Recording Ready', // TODO add translations
    'MursionPortal.EngagementHub.BackToEmailSettings': 'Back to Email Settings', // TODO add translations
    'MursionPortal.EngagementHub.CustomizeEmail.Text': 'Learners will start receiving this email after you save.', // TODO add translations
    'MursionPortal.NextGen.Learner.FeedbackFrom': 'Feedback from', // TODO add translations
    'MursionPortal.NextGen.Scenario.SimMaterials': 'SIM Materials', // TODO add translations
    'MursionPortal.NextGen.Scenario.LearnerMaterials': 'Learner Materials', // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.Modal.BodyText': 'Are you sure you want to save the Enable/Disable settings', // TODO add translations
    'MursionPortal.Client.EmailSettings.SessionRescheduledByPS': 'Session Rescheduled by PS', // TODO add translations
    'MursionPortal.EngagementHub.EmailPreview.Sender': 'Sender', // TODO add translations
    'MursionPortal.Scenario.CoverStory.Tooltip.Text': 'Cover Story will be visible to clients in the scenario library as well as learners and SIMs in their materials',// TODO add translations
    'MursionPortal.Scenario.Summary.Tooltip.Text':'Summary will be visible to clients in the scenario library',// TODO add translations
    'MursionPortal.NextGen.Event.positiveMindset': 'There must be at least ({startCount}) positive mindset defined. You can specify a maximum of ({endCount}) positive mindsets.', // TODO add translations
    'MursionPortal.NextGen.Event.derailingMindset': 'There must be at least ({startCount}) derailing mindset defined. You can specify a maximum of ({endCount}) derailing mindsets.', // TODO add translations
    'MursionPortal.NextGen.Event.StrengthAssessment': 'There must be at least ({startCount}) strength assessment defined. You can specify a maximum of ({endCount}) strength assessments.', // TODO add translations
    'MursionPortal.NextGen.Event.OpportunityAssessment': 'There must be at least ({startCount}) opportunity assessment defined. You can specify a maximum of ({endCount}) opportunity assessments.', // TODO add translations
    'MursionPortal.NextGen.Event.StrengthAssessment.AvatarResponse': 'You have added {addedCount} avatar response for strength assessment(s), you must add at least {totalCount} avatar response for strength assessment(s).', // TODO add translations 
    'MursionPortal.NextGen.Event.OpportunityAssessment.AvatarResponse': 'You have added {addedCount} avatar response for opportunity assessment(s), you must add at least {totalCount} avatar response for opportunity assessment(s).', // TODO add translations 
    'MursionPortal.Label.ClientLevelMissCancelSetting': 'Client Level Miss/Cancel Setting', // TODO add translations 
    'MursionPortal.Label.ProjectLevelMissCancelSetting':'Project Level Miss/Cancel Setting', // TODO add translations 
    'MursionPortal.Label.ScenarioLevelMissCancelSetting':'Scenario Level Miss/Cancel Setting', // TODO add translations 
    'MursionPortal.Label.MissedCancelledSessions':'Missed/Cancelled Sessions', // TODO add translations 
    'MursionPortal.EngagementHub.ViewInvitations.Column.SenderName': 'Sender Name', // TODO add translations,  
    'MursionPortal.NextGen.Event.Delete.ConfirmBox': 'Are you sure you want to delete this {message}?', // TODO add translations,
    'MursionPortal.NextGen.Event.Message.PositiveMindset': 'Positive Mindset', // TODO add translations,
    'MursionPortal.NextGen.Event.Message.DerailingMindset': 'Derailing Mindset', // TODO add translations,
    'MursionPortal.NextGen.Event.Message.StrengthAssessment': 'Strength assessment', // TODO add translations,
    'MursionPortal.NextGen.Event.Message.OpportunityAssessment': 'Opportunity assessment', // TODO add translations,
    'MursionPortal.PersonalizedMessageTitle': 'Personalized message header. This will appear before every personalized message in Portal.', // TODO add translations
    'MursionPortal.NextGen.ScenarioReferences': 'Scenario References', // TODO add translations 
    'MursionPortal.NextGen.ChildScenarios': 'Child Scenarios', // TODO add translations 
    'MursionPortal.NextGen.Label.Customized': 'Customized', // TODO add translations  
    'MursionPortal.EngagementHub.EmailSettings.InvitationPasswordUsers': 'Invitation (Password Users)', // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.InvitationReminderPasswordUsers': 'Invitation Reminder (Password Users)', // TODO add translations
    'MursionPortal.Label.Clicked': 'Clicked', // TODO add translations
    'MursionPortal.Client.EmailSettings.SessionRescheduledByLearner': 'Session Rescheduled by Learner', // TODO add translations
    'MursionPortal.Client.Team.CopyTeamInviteLink.Tooltip':'Users who have not yet registered or have not yet been added to the Portal will not be able to access this link. Please click the "Add Learners" button.', // TODO add translations
    'Settings.Config.LearnerSessionReportDelay': 'Learner Session Report Delay', // TODO add translations
    'MursionPortal.SkillsReferences.Label': 'Skills References', // TODO add translations
    'MursionPortal.ResearchSummariesReferences.Label': 'Research Summaries References', // TODO add translations
    'MursionPortal.Search.Text': 'Search or select', // TODO add translations
    'MursionPortal.NextGen.ScenarioBank.ErrorMessage.Event': 'Please select Event', // TODO add translations
    'MursionPortal.NextGen.ScenarioBank.ErrorMessage.Skill': 'Please select Skill', // TODO add translations
    'MursionPortal.NextGen.ScenarioBank.ErrorMessage.AvatarBehavior': 'Avatar Behavior is required', // TODO add translations
    'MursionPortal.NextGen.ScenarioBank.ErrorMessage.ExpectedLearnerResponse': 'Expected Learner Response is required', // TODO add translations
    'MursionPortal.NextGen.ScenarioBank.ErrorMessage.PositiveMindset': 'Please Select Positive Mindset', // TODO add translations
    'MursionPortal.NextGen.ScenarioBank.ErrorMessage.PositiveMindsetDescription': 'Positive mindset is required', // TODO add translations
    'MursionPortal.NextGen.ScenarioBank.ErrorMessage.DerailingMindset': 'Please Select Derailing Mindset', // TODO add translations
    'MursionPortal.NextGen.ScenarioBank.ErrorMessage.DerailingMindsetDescription': 'Derailing mindset is required', // TODO add translations
    'MursionPortal.NextGen.ScenarioBank.ErrorMessage.DescribeObservedAction': 'Describe Observed Action is required', // TODO add translations
    'MursionPortal.NextGen.ScenarioBank.ErrorMessage.ExplainImpact': 'Explain Impact is required', // TODO add translations
    'MursionPortal.NextGen.ScenarioBank.ErrorMessage.AvatarName': 'Please select avatar name', // TODO add translations
    'MursionPortal.NextGen.ScenarioBank.ErrorMessage.PossibleAvatarResponse':'Possible Avatar Response is required', // TODO add translations
    'MursionPortal.Label.SchedulingLockoutDate': 'Scheduling Lockout Date', // TODO add translations
    'MursionPortal.Label.SchedulingLockoutDate.Tooltip': 'Lockout effective date for all learners who miss and/or late cancel', // TODO add translations
    'MursionPortal.NextGen.scorining.Message.MinimumScore': 'You’ve changed the number of events. Please update the minimum score.', // TODO add translations
    'MursionPortal.NextGen.scorining.Message.SynopsisDescription': 'Synopsis description is required', // TODO add translations
    'MursionPortal.NextGen.scorining.Message.SuccessfulAssessment': 'Successful Assessment is required', // TODO add translations
    'MursionPortal.NextGen.scorining.Message.OpportunityAssessment': 'Opportunity Assessment is required', // TODO add translations
    'MursionPortal.NextGen.Scenario.LibraryInfo.PathwaysAffiliation': 'Pathway Affiliation(s)', // TODO add translations
    'MursionPortal.NextGen.BankScenarioReferences': 'Bank Scenario References', // TODO add translations
    'MursionPortal.Dashboard.ScenarioLearner.Header.LearnerActivationStatus':'Learner Activation',  // TODO add translations
    'MursionPortal.Dashboard.ScenarioLearner.Header.LearnerInviteTitle':'Unlock Your Team’s Potential',  // TODO add translations
    'MursionPortal.Dashboard.ScenarioLearner.Header.LearnerInviteMessage':'Target invitations to your learners with custom messaging',  // TODO add translations
    'MursionPortal.Dashboard.ScenarioLearner.Header.LearnerInvite':'Invite to Schedule',  // TODO add translations
    'MursionPortal.Dashboard.Chart.label.ScenarioActivity.NotYetRegistered':'Not Yet Registered', // TODO add translations
    'MursionPortal.Dashboard.Chart.label.ScenarioActivity.NotYetScheduled':'Not Yet Scheduled', // TODO add translations
    'MursionPortal.Dashboard.Chart.label.ScenarioActivity.ScheduledNotYetCompleted':'Scheduled - Not Yet Completed', // TODO add translations
    'MursionPortal.NextGen.ViewAndInviteLearners': 'View & Invite Learners', // TODO add translations
    'MursionPortal.Dashboard.Tooltip.Label.SchedulingEmail': 'Session Specific Emails and other Join Session Emails', // TODO add translations
    'MursionPortal.Dashboard.EmailAnalytics.Label.InviteToScheduleEmail': 'Invite-to-Schedule Emails', // TODO add translations
    'MursionPortal.Dashboard.Tooltip.Label.CallToActionEmail': 'Emails sent to learners inviting them to schedule scenario(s)', // TODO add translations
    'MursionPortal.NextGen.Scenario.ClientCustomizations': 'Client Customizations', // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.InviteToSchedule.Tooltip': 'Invite to Schedule emails are able to be customized each time you send them.', // TODO add translations
    'MursionPortal.Contract.ContractTimezone': 'Contract Timezone', // TODO add translations
    'Dashboard.LeftPane.Simulations.SessionSearch':'Search for Session', // TODO add translations
    'MursionPortal.NextGen.Scenario.ClientCustomizations.ClientScenarioTitle': 'Client Scenario Title', // TODO add translations
    'MursionPortal.NextGen.Scenario.ClientCustomizations.ClientScenarioTitle.Tooltip': 'Client learners and program admins will see this title only. SIMs will see the bank scenario title and client scenario title in their SIM materials.', // TODO add translations
    'MursionPortal.NextGen.Event.DeleteMessage': 'Minimum 2 Events are mandatory for a Scenario.', // TODO add translations
    'Settings.Config.ProjectAndScenario.AllowSimPracticeSession': 'SIM Practice Session', // TODO add translations
    'MursionPortal.Calendar.Button.Practice.Session': 'Practice Session', // TODO add translations
    'MursionPortal.Scenario.InTrainingSimSpecialist': 'In Training SIM Specialists', // TODO add translations
    'MursionPortal.Scenario.NoInTrainingSimSpecialist': 'No In Training Sim Specialist', // TODO add translations
    'MursionPortal.Simspecialist.Thankyou.summary': 'You are scheduled for your practice simulation', // TODO add translations
    'MursionPortal.Button.DoNotSaveMyWork': 'Don’t Save my Work', // TODO add translations
    'MursionPortal.NextGen.Confirm.BrowserBack': 'You have not saved your changes, and navigating away will cause you to lose your work. Please complete required fields.', // TODO add translations
    'MursionPortal.UserConsent.Learner.Description': 'Si vous dites « Je consens », le logiciel Mursion traitera votre simulation en direct et réalisera un enregistrement vidéo de votre session de simulation pour votre examen sur le portail du logiciel Mursion. Sauf si la loi applicable l\'exige ou à des fins d\'assurance qualité, Mursion ne distribuera ni ne divulguera votre enregistrement vidéo sans votre autorisation. Mursion utilisera les données anonymisées issues de votre utilisation de son logiciel pour améliorer sa technologie de simulation.', // TODO add translations, this translation was generated using the Google Translate, retranslation required in the future 
    'MursionPortal.UserConsent.LearnerAndAccountOwnerOrFacilitator.Description': 'Si vous dites « Je consens », le logiciel Mursion traitera votre simulation en direct et réalisera un enregistrement vidéo de votre session de simulation pour votre examen sur le portail du logiciel Mursion. Veuillez noter qu\'à la demande du client qui a obtenu une licence pour le logiciel Mursion en votre nom, une copie de votre ou vos séances de simulation enregistrées sera partagée avec un coach, un mentor ou un évaluateur sélectionné par le client. Tous les évaluateurs sont contractuellement tenus de respecter l\'<code>avis de confidentialité de Mursion</code>, à moins que vous n\'ayez accepté des conditions de confidentialité différentes. Sauf si la loi applicable l\'exige ou à des fins d\'assurance qualité, Mursion ne distribuera ni ne divulguera votre enregistrement vidéo sans votre autorisation. Mursion utilisera les données anonymisées issues de votre utilisation de son logiciel pour améliorer sa technologie de simulation.', // TODO add translations, this translation was generated using the Google Translate, retranslation required in the future
    'MursionPortal.UserConsent.AccountOwnerOrFacilitator.Description': 'Votre ou vos sessions de simulation seront enregistrées en audio et en vidéo à la demande du client qui a autorisé le logiciel Mursion en votre nom. Si vous consentez à continuer, une copie de votre ou vos sessions de simulation enregistrées sera partagée avec un tiers sélectionné par le client afin d\'analyser et de noter le ou les enregistrements. Ces personnes sont contractuellement tenues de respecter l\'<code>avis de confidentialité de Mursion</code>, à moins que vous n\'ayez accepté des conditions de confidentialité différentes. Cependant, vous ne recevrez ni n\'aurez accès à une copie de votre ou de vos séances de simulation enregistrées conformément aux termes de la mission ou de l\'évaluation que vous avez accepté de réaliser. Mursion peut utiliser votre enregistrement vidéo à des fins d\'assurance qualité et utilisera les données anonymisées issues de votre utilisation de son logiciel pour améliorer sa technologie de simulation.', // TODO add translations, this translation was generated using the Google Translate, retranslation required in the future
    'MursionPortal.ProjectCreate.DeliveryHours.Validation': 'Delivery Hours Start time should be less than End time.', // TODO add translations
    'MursionPortal.Dashboard.Chart.Tooltip.ScenarioActivity.NotYetRegistered':'Unique number of learners who have been added to the Portal but have not yet completed registration', // TODO add translations
    'MursionPortal.Dashboard.Chart.Tooltip.ScenarioActivity.NotYetScheduled':'Unique number of learners who have registered but have not yet scheduled a session', // TODO add translations
    'MursionPortal.Dashboard.Chart.Tooltip.ScenarioActivity.ScheduledNotYetCompleted':'Unique number of learners who have registered and scheduled at least one session but have not yet completed a session', // TODO add translations 
    'MursionPortal.Restriction': 'Restriction', // TODO add translations
    'MursionPortal.Dashboard.SessionSearch.Placeholder' : 'Paste a session ID and hit return',  // TODO add translations
    'Operations.TechRestriction.CreateDialog.Title': 'New Holiday Tech Restriction', // TODO add translations
    'Operations.TechRestriction.EditDialog.Title': 'Edit Holiday Tech Restriction', // TODO add translations
    'Operations.TechRestriction.ViewDialog.Title': 'View Holiday Tech Restriction', // TODO add translations
    'MursionPortal.Client.EmailSettings.TriggerHours': 'Trigger (Hours)', // TODO add translations
    'MursionPortal.Client.EmailSettings.TriggerMin': 'Trigger (Minutes)', // TODO add translations
    'MursionPortal.Duplicate.Scenario.Confirmation.Text': 'Are you sure you want to duplicate this scenario?',  // TODO add translations 
    'MursionPortal.Contract.LineItem.ToolTip': 'A contract can only contain one type of line item. If your contract has both sessions and hours, please convert the sessions into hours and add it as an hours line item.', // TODO add translations
    'MursionPortal.ReflectionGuide.LearnerPerspective': 'Learner Perspective', // TODO add translations
    'MursionPortal.Dashboard.SessionSearch.Table.Placeholder': 'Search for Session will load once you search session ID', // TODO add translations
    'MursionPortal.Dashboard.SessionSearch.EmptySession': 'No sessions match that ID', // TODO add translations
    'MursionPortal.SimMaterials.Label.IntroAndPreReflection': 'Intro & Pre-Reflection', // TODO add translations
    'MursionPortal.Dashboard.Chart.Header.LearnerBreakdown': 'Learner Breakdown', // TODO add translations
    'MursionPortal.Dashboard.Chart.Header.LearnerBreakdown.Activated': 'Activated', // TODO add translations
    'MursionPortal.NextGen.Scenario.LibraryInfo.VersionNumber': 'Version Number', // TODO add translations
    'MursionPortal.NextGen.Scenario.LibraryInfo.VersionDetails': 'Version Details', // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.EmailReminderHours': 'Trigger {tiggerValue} hours before Session.', // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.EmailReminderMinutes': 'Trigger {tiggerValue} minutes before Session.', // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.FirstEmailReminder.Tooltip': 'Time before Session when First Reminder would go. If not set Licensee Setting would be used.', // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.SecondEmailReminder.Tooltip': 'Time before Session when Second Reminder would go. If not set Licensee Setting would be used.', // TODO add translations
    'MursionPortal.SimAttendance.MissedSimulation.Message': 'You missed this simulation so you are unable to edit attendance. Please contact Support if you did not miss the simulation or had a technical issue that caused you to miss the simulation.', // TODO add translations
    'MursionPortal.EngagementHub.ViewInvitations.Disclaimer': 'Invites are visible for last 30 days only', // TODO add translations
    'MursionPortal.PortalVersion': 'Portal Version', // TODO add translations
    'MursionPortal.ScenarioInfo.Label.AutoSIM': 'AutoSIM', // TODO add translations
    'MursionPortal.ProjectContract.AddConfirmation': 'Are you sure you want to add the contract to this project?', // TODO add translations
    'MursionPortal.ProjectContract.CompletedContract.AddConfirmation': 'Are you sure you want to add the completed contract to this project?', // TODO add translations
    'MursionPortal.Label.NewPortal' : 'New Portal', // TODO add translations
    'MursionPortal.Label.OldPortal' : 'Old Portal', // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.ReminderValidation': 'Second Reminder should be less than First Reminder', // TODO add translations
    'MursionPortal.Project.InviteToSchedule.EmailDisabled.Tooltip' :'Invite to Schedule is disabled as email settings are disabled. Enable them to send this invite', // TODO add translations
    'MursionPortal.SimAttendance.Error.SimReportedError' :'SIM Reported Error (Does not impact session status)', // TODO add translations
    'MursionPortal.SimAttendance.Error.SimLateOrNoShow' :'SIM late or no show', // TODO add translations
    'MursionPortal.SimAttendance.Error.SessionStartedTooEarly' :'Session started too early', // TODO add translations
    'MursionPortal.SimAttendance.Error.AvatarIssue' :'Avatar Issue', // TODO add translations
    'MursionPortal.SimAttendance.Error.MouthlipSyncIssueDuringSession' :'Mouth/lip sync issue during session', // TODO add translations
    'MursionPortal.SimAttendance.Error.Hardware' :'Hardware', // TODO add translations
    'MursionPortal.SimAttendance.Error.AudioVideoIssueForSim' :'Audio/video issue for SIM', // TODO add translations
    'MursionPortal.SimAttendance.Error.AudioVideoIssueForLearner' :'Audio/video issue for Learner', // TODO add translations
    'MursionPortal.SimAttendance.Error.OtherSimHardwareIssues' :'Other SIM hardware issues (controller, laptop, etc.)', // TODO add translations
    'MursionPortal.SimAttendance.Error.LearnerAndSimBothJoined' :'Learner and SIM both joined, but no connection established', // TODO add translations
    'MursionPortal.SimAttendance.Error.SimMomentIssue' :'SIM Moment issue', // TODO add translations
    'MursionPortal.SimAttendance.Error.WifiIssue' :'Wifi Issue', // TODO add translations
    'MursionPortal.SimAttendance.Error.LearnerWifi' :'Learner Wifi', // TODO add translations
    'MursionPortal.SimAttendance.Error.SimWifi' :'SIM Wifi', // TODO add translations
    'MursionPortal.SimAttendance.Error.AdditionalDetails' :'Additional Details', // TODO add translations
    'MursionPortal.SimAttendance.Error.DescribeYourError' :'Describe your error...', // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.CancellationByProfessionalServiceOrAccountOwner': 'Cancellation by PS or AO', // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.CompleteLearnerSurvey': 'Complete Learner Survey', // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.Subject.CompleteLearnerSurvey': 'How\'d it go?', // TODO add translations
    'MursionPortal.CustomizeInvitation.ButtonText.Label': 'Button Text', // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.Subject.Client.LearnerLockedOut': 'You are locked out!', // TODO add translations
    'MursionPortal.Filter.ReleaseLevel.Placeholder': 'Release Level', // TODO add translations
    'MursionPortal.TOTP.Page.Heading': 'We just sent a security code to your {email}. Enter the code to sign in.', // TODO add translations
    'MursionPortal.TOTP.ResendOTP.Text': 'Resend Code', // TODO add translations
    'MursionPortal.TOTP.Code.Label': 'Code', // TODO add translations
    'MursionPortal.TOTP.IncorrectCode.ErrorMsg': 'The code is incorrect. Enter the correct code.', // TODO add translations
    'MursionPortal.TOTP.ResendNewCode.ErrorMsg': 'The code has expired, click "Resend" to receive a new code.', // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.Subject.Client.LearnerReleased': 'You are ready to schedule again.', // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.NewPortal.Subject.FirstReminder': `You’ve an upcoming Mursion Session in {firstReminderTime} hours!`, // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.NewPortal.Subject.SecondReminder': `Your Mursion Session is starting in {upcomingBeforeStart} minutes!`, // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.NewPortal.Subject.LearnerFeedbackReport': 'Mursion - Review Your Session Performance Report.', // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.NewPortal.Subject.LearnerSessionRecordingIsReady': 'Your Session Recording is now up! Review how you did', // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.NewPortal.Subject.Confirmation': 'Your Mursion Session is Confirmed!', // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.NewPortal.Subject.CancellationByLearnerOrPSOrAO': 'Your {licenseeName} session was canceled', // TODO add translations
    'MursionPortal.Personalized.PathwayPage.Section': 'Message you want learners to see on Pathway Page', // TODO add translations
    'MursionPortal.Personalized.CreateNewPathway.Button.Label': 'Create new for specific pathway', // TODO add translations
    'Projects.ScenarioList.NoPathwaysFound': 'No Pathways have been found.',// TODO add translations
    'MursionPortal.Personalized.Pathway.Search.Placeholder': 'Search or select Pathway(s):',// TODO add translations
    'Mursion.Portal.Status.SimTechnicalError': 'Sim Technical Error',// TODO add translations
    'MursionPortal.SimAttendance.Sim.Tooltip.Error': 'Session was unable to be completed due to technical difficulties on the Sim’s side. (if the session was able to be completed after an error this session should be marked complete and not error).',// TODO add translations
    'Session.Edit.Modal.Notes.BlankNoteValidation': 'Either add a valid note or remove this block', // TODO add translations
    'MursionPortal.Scenario.Error.Msg': 'Please complete required field.', // TODO add translations
    'MursionPortal.Scenario.Skills.Error.Msg': 'The number of skills must be in the range of {minSkills} and {maxSkills}.', // TODO add translations
    'MursionPortal.Capitalized.Incomplete': 'INCOMPLETE', // TODO add translations
    'MursionPortal.Dashboard.Chart.LicenseBreakdown.Title': 'License Breakdown – Contract Item',  // TODO add translations
    'MursionPortal.Dashboard.Chart.LearnerLicenses': 'Learner Licenses',  // TODO add translations
    'MursionPortal.Dashboard.Chart.LearnersRegistered': 'Learners Registered',  // TODO add translations
    'MursionPortal.Dashboard.Chart.LearnersScheduled': 'Learners Scheduled',  // TODO add translations
    'MursionPortal.SessionAttendance.AddAttendees': 'Add attendees',  // TODO add translations
    'MursionPortal.SessionAttendance.SimulationSpecialist': 'Simulation Specialist',  // TODO add translations
    'MursionPortal.Dashboard.SimulationSpecialist.Status': 'Simulation Specialist Status',  // TODO add translations
    'MursionPortal.Dashboard.Technical.Issue.Summary': 'If a technical issue occurred during this session, please indicate the type(s) of issue encountered:',  // TODO add translations
    'MursionPortal.Dashboard.Provide.Details': 'Please provide more details on what happened',  // TODO add translations
    'MursionPortal.SessionAttendance.SwitchToSimRole.Message': 'Please switch role to Sim Specialist to view the page!', // TODO add translations
    'MursionPortal.SessionAttendance.Heading': '<code>Instructions:</code> Review the Session, Simulation Specialist, and Learner Status\' below and update as required. This form may be edited for 24 hours after the end of the session', // TODO add translations
    'MursionPortal.SessionAttendance.SessionStatus.Label': 'This is automatically determined by the Learner and Simulation Specialist status. No action required.', // TODO add translations
    'MursionPortal.SessionAttendance.SessionDetails.Heading': '(selections below do not impact session status)', // TODO add translations
    'MursionPortal.SessionAttendance.SessionDetails.Instructions': '<code>Instructions:</code> Session details <code>must</code> be provided for any session that has a Learner Status other than ‘Complete’ or ‘No Show’. To complete, select one or more issue type(s) and describe details in the text box. Session detail information does not impact the Learner, Simulation Specialist, or Session status.', // TODO add translations
    'MursionPortal.SessionAttendance.SessionDetails.Text': `Mursion Simulation Specialists only: If you filled out a UTS ticket for this session, please include the link to the Slack post in the text box (click the 3 vertical dots to the right of the post and select ‘Copy link').`, // TODO add translations
    'MursionPortal.SessionAttendance.SessionStatus.Simspecialist.Label': 'This is automatically determined by the system. If you encountered an error that prevented the session from being completed and the indicated status is ‘Left’, select ‘Technical Error (SIMS)’. Otherwise, no action required.', // TODO add translations
    'MursionPortal.SessionAttendance.SessionStatus.Learner.Label': 'This is automatically determined by the system. If the indicated status is inaccurate, select the correct status and explain the reason for the revision in Session Details. Otherwise, no action required.', // TODO add translations
    'MursionPortal.SessionAttendance.SessionStatus.SimStatus.Label': 'Technical Error (SIMs)', // TODO add translations
    'Mursion.Portal.Status.UnableToComplete': 'Unable to Complete', // TODO add translations
    'Mursion.Portal.Status.ElectedToLeave': 'Elected to Leave', // TODO add translations
    'Mursion.Portal.Status.TechnicalErrorLearner': 'Technical Error (Learner)', // TODO add translations
    'MursionPortal.SessionAttendance.Error.Audio': 'Audio', // TODO add translations
    'MursionPortal.SessionAttendance.Error.ConnectionInternet': 'Connection / Internet', // TODO add translations
    'MursionPortal.SessionAttendance.Error.LearnerTechnicalIssues': 'Learner technical issues', // TODO add translations
    'MursionPortal.SessionAttendance.Error.MursionTechnicalIssues': 'Mursion technical issues', // TODO add translations
    'MursionPortal.SessionAttendance.Error.OtherTechnicalIssues': 'Other technical issues', // TODO add translations
    'MursionPortal.SessionAttendance.Error.MomentSoftware': 'Moment Software', // TODO add translations
    'MursionPortal.SessionAttendance.Error.AvatarOrEnvironmentLipSyncMovementEtc': 'Avatar or Environment (lip sync, movement, etc.)', // TODO add translations
    'MursionPortal.SessionAttendance.Error.HardwareControllerLaptopEtc': 'Hardware (controller, laptop, etc.)', // TODO add translations
    'MursionPortal.SessionAttendance.Error.LearnerAndSimulationSpecialistBothJoined': 'Learner and Simulation Specialist both joined, no connection established', // TODO add translations
    'MursionPortal.SessionAttendance.Error.OtherPleaseExplainBelow': 'Other (please explain below)', // TODO add translations
    'MursionPortal.SessionAttendance.Error.NonTechnicalIssues': 'Non-technical issues', // TODO add translations
    'MursionPortal.SessionAttendance.Error.LearnerMaterialsOrPreparation': 'Learner materials or preparation ', // TODO add translations
    'MursionPortal.SessionAttendance.Error.LearnerLanguageFluency': 'Learner language fluency', // TODO add translations
    'MursionPortal.SessionAttendance.Error.LearnerElectedToLeave': 'Learner elected to leave', // TODO add translations
    'MursionPortal.Dashboard.Overview.Empty.Body.Message': 'Contract report will load once you make a selection', // TODO add translations
    'MursionPortal.Dashboard.Chart.Header.SimulationBreakdown.ContractItem.Simulation':'Session Breakdown – Simulation Contract Item', // TODO add translations
    'MursionPortal.Dashboard.Chart.Header.SimulationBreakdown.ContractItem.Hour':'Session Breakdown – Hour Contract Item', // TODO add translations
    'MursionPortal.Copy.Tooltip.Text': 'Copy (plain text only, no formatting)', // TODO add translations
    'MursionPortal.RTF.DropDown.Text.Green': 'Green', // TODO add translations
    'MursionPortal.RTF.DropDown.Text.Thick': 'Thick', // TODO add translations
    'MursionPortal.Status.Connected.Label': 'Connected',  // TODO add translations
    'MursionPortal.Status.Immersing.Label': 'Immersing',  // TODO add translations
    'MursionPortal.Dashboard.NonTechnical.Issue.Summary': 'If a non-technical issue occurred during this session, please indicate the type(s) of issue encountered:',  // TODO add translations
    'MursionPortal.AutoSim.Scenario.TooltipLabel': 'AutoSim scenario can’t be scheduled.', // TODO add translations
    'MursionPortal.Scenario.Planing.AutoAssignTeam': 'Auto-Assign Team', // TODO add translations
    'MursionPortal.Scenario.Planing.AutoAssignTeam.Tooltip': 'Automatically assign this scenario to the selected teams when learners access the scenario page via the provided link.', // TODO add translations
    'MursionPortal.Dashboard.Overview.NoAssociatedDataForSelectedContract': 'No associated data for the selected contract(s)', // TODO add translations
    'MursionPortal.ScenarioBank.OutcomeLevelSelector.LevelOne': 'Level 1', // TODO add translations
    'MursionPortal.ScenarioBank.OutcomeLevelSelector.LevelTwo': 'Level 2', // TODO add translations
    'MursionPortal.ScenarioBank.OutcomeLevelSelector.LevelThree': 'Level 3', // TODO add translations
    'MursionPortal.Dashboard.UpcomingSessions.IVS.Title': 'Make sure you’re ready to take the session by testing your connection before joining.',// TODO add translations
    'MursionPortal.Dashboard.UpcomingSessions.IVS.Button.Label': 'Test Connection',// TODO add translations
    'MursionPortal.Dashboard.UpcomingSessions.IVS.Support.Link.Title': 'If any test fails, copy results at the end of the test and share them with our support team by',// TODO add translations
    'MursionPortal.Dashboard.UpcomingSessions.IVS.Support.Link': 'Submitting a Support ticket.',// TODO add translations
    'MursionPortal.Dashboard.OnDemand.Text':'Ready, set, practice on-the-go',
    'MursionPortal.Dashboard.OnDemand.Title':'Try On-Demand Simulations',
    'MursionPortal.Dashboard.OnDemand.SubTitle':'Get a first look at the future of skill-building at work - dynamic GenAI simulations available instantly, no scheduling required. Practice anytime and receive actionable feedback that helps you grow and master new skills.',
    'MursionPortal.Dashboard.OnDemand.SmallTitle':'Early access, real results—get ahead now.',
    'MursionPortal.Dashboard.OnDemand.Button.Label':'Go to Mursion On-Demand',
    'MursionPortal.Dashboard.OnDemand.Button.Hide.Label':'Hide this',
    'MursionPortal.Dashboard.OnDemand.Preview':'PREVIEW',
    'MursionPortal.Dashboard.OnDemand.Text1':'✓ Scalable, focused practice',
    'MursionPortal.Dashboard.OnDemand.Text2':'✓ Flexible access',
    'MursionPortal.Dashboard.OnDemand.Text3':'✓ Targeted practice for mastery',
    'MursionPortal.Dashboard.OnDemand.Text4':'✓ Guided self-reflection',
    'MursionPortal.Dashboard.OnDemand.Mursion':'Mursion',
  },
};
export default frenchLangTranslations;
